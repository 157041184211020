import { downloads } from "../data/contact-us";
const DownloadsComponent = () => {
    return (<section className="relative mt-12 md:mt-0 md:pb-20 md:pt-11 px-5" id="faqs">
    <div className="absolute inset-0 h-1 w-4/5 -translate-x-6 bg-gradient-to-r from-[#F39711] to-[#F54C11] md:w-3/5" />

    <h1 className="pt-6 text-center text-32 md:text-40">
      <span className="text-[#F39711] uppercase">
       Downloads
      </span>
    </h1>

    <div className="flex flex-col md:flex-row md:pl-14 md:pt-4 md:pr-52">
      <img
        src="/pages/contact-us/faq.png"
        alt=""
        className="py-7 md:hidden object-contain"
      />
      <img
        src="/pages/contact-us/faq-md.png"
        alt=""
        className="hidden pr-24 md:block object-contain"
      />
      <ul className="space-y-5 pt-7 pb-10 lg:pb-0 w-full">
        {downloads.map((faq, index) => (
          <div key={index} className="">
            <div
              className="flex items-center justify-between"
             
            >
              <h3 className="text-lg md:text-[18px] font-medium ">
                <span className="mr-2">{index + 1}.</span>
                {faq.title}
              </h3>
              <a href={faq.url} target="blank">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-[#E08C10]">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
</svg>
              </a>
              

            
            </div>
          </div>
        ))}
      </ul>
    </div>
  </section>  );
}
 
export default DownloadsComponent;