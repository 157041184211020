
import { useState } from "react";
import { Container, Line } from "../components/shared";
import SharedHero from "../components/utils/hero";
import background from "../components/utils/pdi.jpg";


const PdiPage = () => {
  const items = [
    'Align primary functions, goals, strategy, value proposition, and ecosystem impact.',
    'Make data transformation a strategic imperative, not just an IT-driven initiative.',
    'Equip teams with the skills for effective data use and encourage a culture valuing data-driven insights.',
    'Architecture, Storage,Processing Power,Security Protocols,Connectivity, API’s, AI tools, Disaster '
  ]
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevSlide = () => {
    const newIndex = (currentIndex - 1 + items.length) % items.length;
    setCurrentIndex(newIndex);
  };

  const goToNextSlide = () => {
    const newIndex = (currentIndex + 1) % items.length;
    setCurrentIndex(newIndex);
  };

  return (
    <Container className="bg-[#404040] pdi">
      <SharedHero
        title={"PEOPLE. DATA. INFRASTRUCTURE."}
        subtitle={"Ignite Your Data Revolution with Adanian Labs."}
        background={background}
      />
      <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col">
        <h1 className="text-center">
          The Evolution of the<span className="p-2">Digital</span> Era.
        </h1>
        <Line
        variant='orange'
        className="mx-auto my-3 h-[1px] w-[240px]"
        ></Line>
        <p className="text-center">Welcome to Adanian Labs, where the future of data transformation unfolds through our groundbreaking PDI Model. We redefine the dynamics of data, bringing together PEOPLE, DATA, and INFRASTRUCTURE to lay the foundation for data-driven organizations in the Digital Era.</p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-5">
          <div className="card p-8 flex flex-col items-center justify-start gap-3">
            <div className="circle relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="107"
                height="107"
                viewBox="0 0 107 107"
                fill="none"
              >
                <circle
                  cx="53.5"
                  cy="53.5"
                  r="53.5"
                  fill="url(#paint0_linear_1239_4278)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1239_4278"
                    x1="45.5473"
                    y1="-4.32157e-06"
                    x2="93.8307"
                    y2="23.252"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F39711" />
                    <stop offset="1" stopColor="#F54C11" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="innner"> 01</div>
            </div>
            <h2>People-Centric Excellence</h2>
            <p>Empower your team with a culture of data fluency and curiosity.</p>
          </div>
          <div className="card p-10 flex flex-col items-center justify-start gap-3">
            <div className="circle relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="107"
                height="107"
                viewBox="0 0 107 107"
                fill="none"
              >
                <circle
                  cx="53.5"
                  cy="53.5"
                  r="53.5"
                  fill="url(#paint0_linear_1239_4278)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1239_4278"
                    x1="45.5473"
                    y1="-4.32157e-06"
                    x2="93.8307"
                    y2="23.252"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F39711" />
                    <stop offset="1" stopColor="#F54C11" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="innner"> 02</div>
            </div>
            <h2>Dynamic Data Insights</h2>
            <p>Witness your data responding dynamically to queries and aspirations.</p>
          </div>
          <div className="card p-8 flex flex-col items-center justify-start gap-3">
            <div className="circle relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="107"
                height="107"
                viewBox="0 0 107 107"
                fill="none"
              >
                <circle
                  cx="53.5"
                  cy="53.5"
                  r="53.5"
                  fill="url(#paint0_linear_1239_4278)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1239_4278"
                    x1="45.5473"
                    y1="-4.32157e-06"
                    x2="93.8307"
                    y2="23.252"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F39711" />
                    <stop offset="1" stopColor="#F54C11" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="innner"> 03</div>
            </div>
            <h2>Adaptive Infrastructure</h2>
            <p>Sculpt your tech foundation based on real insights, ensuring seamless alignment with evolving needs.</p>
          </div>
        </div>
      </section>
      <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col gap-5 idp relative">
        <div className="studio-orange absolute right-0 top-0">

        </div>
        <h1 className="text-center">Progressive Transformation</h1>
        <Line
        variant='orange'
        className="mx-auto mb-10 h-[1px] w-[240px]"
        ></Line>
        <div className="flex flex-col md:flex-row gap-10 my-10">
          <div className="space-y-5">
            <div>
              <h2>Strategic Evolution</h2>
              <p>
                Our approach involves a strategic cycle—comprehensive assessment, culture empowerment, data refinement, and infrastructure enhancement.
              </p>
            </div>
            <div>
              <h2>AI Integration</h2>
              <p>
                Elevate your data landscape with AI and Machine Learning, offering real-time insights and driving proactive decision-making.
              </p>
            </div>
          </div>
          <img src="/images/idp.png" alt="" />
        </div>
      </section>
      <section className="px-5 lg:px-28 py-10 idp">
        <h1 className="text-center">The PDI Model</h1>
        <div className="flex w-full justify-center my-5">
        <Line
        variant='orange'
        className="mx-auto mb-10 h-[1px] w-[240px]"
        ></Line>
        </div>
        <p>
          True transformation begins with People – nurturing a culture that
          celebrates data fluency and encourages curiosity.
        </p>
        <ul className=" list-inside list-disc">
          <li>
            Strategic assessment starts with culture and data maturity.
          </li>
          <li>
            {" "}
            Culture is integrated from day one, creating data champions shaping the future.
          </li>
          <li>
            {" "}
            Data Governance becomes the norm, driven by an empowered workforce.
          </li>
          <li>
            Refined data insights guide Infrastructure decisions for real-world utilization.
          </li>
          <li>
            PDI thrives on iteration, adapting to evolving business needs.
          </li>
        </ul>
      </section>
      <section className="px-5 lg:px-28 py-6 flex items-center justify-center flex-col gap-5 idp relative">
        <div className="studio-blue absolute top-0 right-0">

        </div>
        <h1 className="text-center">A Symphony of Possibilities</h1>
        <Line
        variant='orange'
        className="mx-auto mb-10 h-[1px] w-[240px]"
        ></Line>
        <div className="flex flex-col md:flex-row gap-10 my-10">
          <div className="space-y-3">
            <div>
              <h2>Holistic Innovation</h2>
              <p>
                The PDI Model isn't just a concept; it's a symphony where People empower Data, shaping Infrastructure for true innovation.
              </p>
            </div>
            <div>
              <h2>Join the Data Revolution!</h2>
              <p>
                Experience the future of data transformation with the PDI Model at Adanian Labs. We converge PEOPLE, DATA, and INFRASTRUCTURE, building the foundation for data-driven organizations in the Digital Era.
              </p>
            </div>

          </div>
          <img src="/images/pdi.png" alt="" />
        </div>
      </section>
      <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col gap-5 idp">
        <h1 className="text-center">The PDI Components</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16 md:gap-8 mt-10">
          <div className="card-2 relative flex flex-col items-center px-10">
            <div className="inner flex items-center justify-center -mt-10">
              <img src="/icons/networking.svg" alt="networking" />
            </div>
            <h3 className="my-2">People</h3>
            <p className="text-center">Torchbearers of transformation, putting humanity at the core</p>
          </div>
          <div className="card-2 relative flex flex-col items-center px-10">
            <div className="inner flex items-center justify-center -mt-10">
              <img src="/icons/data.svg" alt="networking" />
            </div>
            <h3 className="my-2">Data</h3>
            <p className="text-center">The bloodstream of possibilities, where insights come to life.</p>
          </div>
          <div className="card-2 relative flex flex-col items-center px-10">
            <div className="inner flex items-center justify-center -mt-10">
              <img src="/icons/infrastructure.svg" alt="networking" />
            </div>
            <h3 className="my-2">Infrastructure</h3>
            <p className="text-center">A sculpted capturer of revelation, shaped by real-world needs.</p>
          </div>
        </div>
      </section>
      <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col gap-5 idp">
        <h1 className="text-center">The PDI Blueprint</h1>
        <p className="text-center">
          <span className="font-bold mr-2"> Leveraging Data & AI Strategies:</span>
          Future-proof your organization with transformative strategies touching core business functions and fostering organizational transformation.

        </p>
        <div className="carousel">
          <h3>Core Business</h3>
          <div className="flex justify-between items-center my-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              onClick={goToPrevSlide}
              className=" cursor-pointer"
            >
              <mask
                id="mask0_1258_4179"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="35"
                height="35"
              >
                <rect width="35" height="35" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1258_4179)">
                <path
                  d="M14.5833 32.0827L0 17.4993L14.5833 2.91602L17.1719 5.50456L5.17708 17.4993L17.1719 29.4941L14.5833 32.0827Z"
                  fill="white"
                />
              </g>
            </svg>
            <p>
              {items[currentIndex]}
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              onClick={goToNextSlide}
              className=" cursor-pointer"
            >
              <mask
                id="mask0_1258_4176"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="35"
                height="35"
              >
                <rect width="35" height="35" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1258_4176)">
                <path
                  d="M11.7038 32.0827L9.11523 29.4941L21.11 17.4993L9.11523 5.50456L11.7038 2.91602L26.2871 17.4993L11.7038 32.0827Z"
                  fill="white"
                />
              </g>
            </svg>
          </div>
        </div>
        <img src="/images/pdi-line.png" alt="" />
      </section>
      <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col gap-5">
        <h1 className="text-center">
          The  <span className="p-2">Data</span>Evolution Journey
        </h1>
        <Line
        variant='orange'
        className="mx-auto mb-10 h-[1px] w-[240px]"
        ></Line>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="card p-8 flex flex-col items-center justify-start gap-3">
            <div className="circle relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="107"
                height="107"
                viewBox="0 0 107 107"
                fill="none"
              >
                <circle
                  cx="53.5"
                  cy="53.5"
                  r="53.5"
                  fill="url(#paint0_linear_1239_4278)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1239_4278"
                    x1="45.5473"
                    y1="-4.32157e-06"
                    x2="93.8307"
                    y2="23.252"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F39711" />
                    <stop offset="1" stopColor="#F54C11" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="innner"> 01</div>
            </div>
            <h2>Digitisation</h2>
            <p>Business Movement from analogue to digital, laying the groundwork for the digital era.</p>
          </div>
          <div className="card p-6 flex flex-col items-center justify-start gap-3">
            <div className="circle relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="107"
                height="107"
                viewBox="0 0 107 107"
                fill="none"
              >
                <circle
                  cx="53.5"
                  cy="53.5"
                  r="53.5"
                  fill="url(#paint0_linear_1239_4278)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1239_4278"
                    x1="45.5473"
                    y1="-4.32157e-06"
                    x2="93.8307"
                    y2="23.252"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F39711" />
                    <stop offset="1" stopColor="#F54C11" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="innner"> 02</div>
            </div>
            <h2>Digital Transformation</h2>
            <p>Transformation using cutting-edge digital technologies, embracing the shift in the digital landscape.
            </p>
          </div>
          <div className="card p-8 flex flex-col items-center justify-start gap-3">
            <div className="circle relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="107"
                height="107"
                viewBox="0 0 107 107"
                fill="none"
              >
                <circle
                  cx="53.5"
                  cy="53.5"
                  r="53.5"
                  fill="url(#paint0_linear_1239_4278)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1239_4278"
                    x1="45.5473"
                    y1="-4.32157e-06"
                    x2="93.8307"
                    y2="23.252"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F39711" />
                    <stop offset="1" stopColor="#F54C11" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="innner"> 03</div>
            </div>
            <h2>Data Transformation</h2>
            <p>Elevate your organization with data, setting the stage for exponential growth.</p>
          </div>
        </div>
      </section>
      <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col gap-5 idp">
        <h1 className="text-center">Organization Transformation</h1>
        <p>
          Data transformation is a strategic imperative and not just an
          IT-driven initiative.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          <div className="card-3 p-8 space-y-5">
            <h3>Training</h3>
            <div className="flex w-full items-center justify-center">
              <hr />
            </div>
            <p className="text-center">
              Training and Skill Development: Providing employees, LEADERS and
              technical teams with the awareness, knowledge, and resources
              needed to understand and work with data effectively to derive
              meaningful insights and make informed decisions.
            </p>
          </div>
          <div className="card-3 p-8 space-y-5">
            <h3>Culture</h3>
            <div className="flex w-full items-center justify-center">
              <hr />
            </div>
            <p className="text-center">
              Cultural Shift: Encouraging a culture that values data-driven
              insights, where employees and leaders feel empowered to question
              assumptions, propose hypotheses, and seek evidence in data.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-8 md:gap-16  my-10 md:my-20">
          <img src="/images/pd1-3.png" alt="pdi" loading="lazy" />
          <div>
            <h2>Data and Customer Index</h2>
            <ul className="list-disc list-outside mt-5">
              <li>
                Digitization: Achieves maximum 45% of growth for any organization
              </li>
              <li>
                {" "}
                Digital Transformation: Achieves up to 65% growth in the first 2 years before a decline for over 60% of all organizations{" "}
              </li>
              <li>
                Data Transformation: Secures an organization's future in the customer environment and focuses all the organization's value around the customer. Achieves over 90% growth consistently
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-8 md:gap-16">
          <div>
            <h2>The One Customer Theory - Data as the Fundamental Value:</h2>
            <p>
              Every organization exists because of one customer. Internal and external ecosystems revolve around data, connecting organizations internally and externally to the customer.
            </p>

          </div>
          <img src="/images/405.png" alt="pdi" loading="lazy" className="cutomer-theory-img" />
        </div>
      </section>
      <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col gap-5 idp">
        <h1 className="text-center">Opportunity for AI Transformed Organizations: Elevate with Adanian Labs</h1>
        <p>Return on their AI investments within 14 months.</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="yellow-card bg-[#F39711] p-10 relative">
            <img src="/images/Lingkaran.png" alt="" className="absolute top-0 right-0" />
            <span className="text-white">3x%</span>
            <p>Returns for every $1 <br /> invested in AI</p>

          </div>
          <div className="yellow-card bg-[#06400B] p-10 relative">
            <img src="/images/Lingkaran.png" alt="" className="absolute top-0 right-0" />
            <span className="text-white">92%</span>
            <p>AI deployments take around 12 months</p>

          </div>
          <div className="yellow-card bg-[#13A8BD] p-10 relative">
            <img src="/images/Lingkaran.png" alt="" className="absolute top-0 right-0" />
            <span className="text-white">52%</span>
            <p>Organizations cite lack of
              skilled data & AI talent</p>

          </div>

        </div>
        <div>

        </div>

      </section>
    </Container>
  );
};

export default PdiPage;
