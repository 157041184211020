import AbcHero from "../components/abc/abc-hero";
import MeetLeaders from "../components/capacity-building/meet-leaders";
import { Container } from "../components/shared";

const services = [
  {
    title: "RESEARCH AND DEVELOPMENT",
    service:
      "We believe continuous innovation and research are vital to the success of IoT solutions. Our dedicated team conducts extensive R&D activities to create and enhance IoT products and services. By closely collaborating with clients, we develop custom IoT solutions that meet their specific requirements. Our research covers areas such as hardware and software development, connectivity, data analytics, and security.",
  },
  {
    title: "IoT PRODUCTS AND SERVICES",
    service:
      "Leverage the power of IoT with our wide range of products and services. Our offerings include sensors, gateways, data analytics platforms, and more. We provide custom solution development, system integration, installation, and maintenance. Our experts work closely with you to design and implement IoT solutions that deliver measurable results and address your unique needs.",
  },
  {
    title: "KNOWLEDGE SHARING, THOUGHT LEADERSHIP, AND IoT BEST PRACTICES",
    service:
      "Stay up-to-date with the latest trends, technologies, and best practices in the IoT field. We offer training, workshops, seminars, and webinars where our experts share their knowledge on various IoT topics. Our thought leadership content, including research reports, whitepapers, and articles, keeps you informed about the latest developments. We also help implement IoT best practices, from data security and privacy to system integration and scalability.",
  },
];
const otherServices = [
  {
    title: "MANUFACTURING",
    service:
      "IoT has a profound impact on manufacturing, enabling better planning, control, integration, analysis, and optimization of processes. By creating a network of connected machines, systems, devices, and humans, IoT makes factories safer for frontline workers, while increasing productivity and efficiency.",
    icon: "manuafacturing",
  },
  {
    title: "TRANSPORTATION",
    service:
      "IoT in transportation is a thriving business. With IoT devices deployed in various areas within the sector, such as traffic congestion systems, telematics systems in vehicles, and tools for ticketing, security, and surveillance, the market value is expected to reach $328 billion USD by 2023.",
    icon: "transportation",
  },
  {
    title: "HEALTHCARE",
    service:
      "IoT-enabled devices facilitate remote monitoring in the healthcare sector, enhancing patient safety and enabling superior care delivery. Healthcare professionals can proactively connect with patients and leverage data collected from IoT devices to identify the best treatment processes and achieve desired outcomes.",
    icon: "healthcare",
  },
  {
    title: "AGRICULTURE",
    service:
      "IoT-enabled smart farming sensors, modules, and software aid in making informed decisions for productivity improvements. As the agricultural workforce declines, the adoption of IoT solutions in farming practices increases, reducing the need for manual labour. Our IoT solutions are designed to help farmers close the supply-demand gap.",
    icon: "agriculture",
  },
];
const IotceServices = () => (
  <section className="startups-section bg-cover bg-center px-5 lg:px-[129px] bg-[#454545] relative pb-[76px]">
    <div className="w-full flex  flex-col items-center space-y-6 mb-[56px] iotce">
      <p className="heading">Our Services</p>
      <hr />
      <p className="z-10">
        At IoT Centre of Excellence, we are passionate about creating value and
        sustainable solutions through implementation, using three modules:
        Research and development, IoT products and services, Knowledge sharing,
        Thought leadership and IoT best practices
      </p>
      {services.map((i) => (
        <div className="card space-y-6 p-8 z-10 ">
          <h3>{i.title}</h3>
          <hr />
          <p className="z-10">{i.service}</p>
        </div>
      ))}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        {otherServices.map((i) => (
          <div className="card-2 space-y-6 p-8 z-10 ">
            <div className="w-full flex items-center justify-center">
              <img src={"/images/iotce/" + i.icon + ".svg"} alt="" />
            </div>
            <h3>{i.title}</h3>
            <hr />
            <p className="z-10">{i.service}</p>
          </div>
        ))}
      </div>
      <div className="abc-ellipse-1"></div>
      <div className="abc-ellipse-2"></div>
    </div>
  </section>
);

const Iotce = () => {
  const leaders = [
    {
      overview: "",
      skills: [],
      image: "/images/abc/elijah.jpg",
      desc: "",
      name: "Elijah Cheruiyot",
      role: "Software Engineer",
    },
    {
      overview: "",
      skills: [],
      image: "/images/abc/amos.jpg",
      desc: "",
      name: "Amos Kibet",
      role: "Software Engineer",
    },
    {
      overview: "",
      skills: [],
      image: "/pages/content-portal/founders/bendon-murgor.png",
      desc: "",
      name: "Bendon Murgor",
      role: "Chief Technology Officer",
    },
  ];
  const heroContent = {
    title: "IoT Centre of Excellence",
    substitle: "Africa’s Leading High-Tech IoT solutions company.",
    links: [],
    bottomContent:
      "Welcome to the IoT Centre of Excellence, a leading provider of cutting-edge IoT solutions. Our mission is to help businesses and organizations harness the power of the Internet of Things to drive innovation, improve efficiency, and unlock new opportunities for growth. We specialize in delivering end-to-end IoT solutions tailored to the specific needs of our clients, from custom hardware and software development to system integration, data analytics, and more. At the IoT Centre of Excellence, we are dedicated to staying at the forefront of IoT technology and innovation, and to providing our clients with the highest levels of expertise, quality, and service.",
  };
  return (
    <Container className="bg-[#454545]">
      <AbcHero content={heroContent} />
      <IotceServices />
      <MeetLeaders
        desc={
          "Contact us today to learn how our IOT products can help digitally transform your business."
        }
        subtitle={"Learn more about IoT Center of Excellence for your business"}
        btnText="CONTACT US"
        link="submission-form"
        leaders={leaders}
      />
    </Container>
  );
};

export default Iotce;
