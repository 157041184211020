const BusinessStats = () => {
  return (
    <section>
      <div className="container mx-auto bg-light-dark business-section">
        <div className="marquee-hero hidden lg:block">
          <div className="marquee__inner" aria-hidden="true">
            <h3 className="mr-32 stats-title ">
              <span className="number-stats mr-2">3</span>Smart Tech<br></br>
              Centers
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">5</span>Labs within<br></br> Africa
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">100+</span>Tech Tool<br></br> Solutions
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">170+</span>Blockchain<br></br> Engineers
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">300+</span>Data <br></br> Scientists
            </h3>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">40+</span>
              <br></br>Startups <br></br> Incubated
            </p>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">3</span>Smart Tech<br></br>
              Centers
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">5</span>Labs within<br></br> Africa
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">100+</span>Tech Tool<br></br> Solutions
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">170+</span>Blockchain<br></br> Engineers
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">300+</span>Data <br></br> Scientists
            </h3>

            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">3</span>Smart Tech<br></br>
              Centers
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">5</span>Labs within<br></br> Africa
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">100+</span>Tech Tool<br></br> Solutions
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">170+</span>Blockchain<br></br> Engineers
            </h3>
            <h3 className="mr-32 stats-title">
              <span className="number-stats mr-2">300+</span>Data <br></br> Scientists
            </h3>
          </div>
        </div>
        <div className="marquee block lg:hidden">
          <div className="marquee__inner" aria-hidden="true">
            <p className="mr-28 stats-title">
              <span className="number-stats ">3</span>
              <br></br> Smart Tech<br></br>
              Centers
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">5</span>
              <br></br>Labs within<br></br> Africa
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">100+</span>
              <br></br>Tech Tool<br></br> Solutions
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">170+</span>
              <br></br>Blockchain<br></br> Engineers
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">300+</span>
              <br></br>Data <br></br> Scientists
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats ">3</span>
              <br></br> Smart Tech<br></br>
              Centers
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">5</span>
              <br></br>Labs within<br></br> Africa
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">100+</span>
              <br></br>Tech Tool<br></br> Solutions
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">170+</span>
              <br></br>Blockchain<br></br> Engineers
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">300+</span>
              <br></br>Data <br></br> Scientists
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">40+</span>
              <br></br>Startups <br></br> Incubated
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats ">3</span>
              <br></br> Smart Tech<br></br>
              Centers
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">5</span>
              <br></br>Labs within<br></br> Africa
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">100+</span>
              <br></br>Tech Tool<br></br> Solutions
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">170+</span>
              <br></br>Blockchain<br></br> Engineers
            </p>
            <p className="mr-28 stats-title">
              <span className="number-stats mr-2">300+</span>
              <br></br>Data <br></br> Scientists
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessStats;
