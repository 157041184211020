import { useState } from "react";

const CreatingKnowledge = () => {
  const [active, setActive] = useState("programs");

  return (
    <section>
      <div className="container mx-auto h-[700px] relative">
        <div className="ck-circle"></div>
        <div className="text-center w-full flex flex-col justify-center items-center mb-10">
          <h1 className="ck-header mb-3">Creating knowledgeable leaders</h1>
          <span className="ck-title-hr"></span>
        </div>
        <h2
          className={
            active === "programs"
              ? " absolute  top-[102px] left-[31px] lg:top-[102px] lg:left-[127px] ck-title "
              : "ck-title-active absolute  top-[102px] left-[31px] lg:top-[102px] lg:left-[127px]"
          }
          onClick={() => {
            setActive("programs");
          }}
        >
          Our Programs
        </h2>
        <h2
          className={
            active === "curriculums"
              ? "ck-title absolute top-[102px] left-[203px] lg:top-[239px] lg:left-[127px] uppercase "
              : "ck-title-active absolute  top-[102px] left-[203px] lg:top-[239px] lg:left-[127px] uppercase "
          }
          onClick={() => {
            setActive("curriculums");
          }}
        >
          SCHOLARSHIP <br></br>OPPORTUNITY
        </h2>
        <h2
          className={
            active === "certifications"
              ? "ck-title absolute top-[143px] left-[31px] lg:top-[376px] lg:left-[127px]"
              : "ck-title-active absolute top-[143px] left-[31px] lg:top-[376px] lg:left-[127px]"
          }
          onClick={() => {
            setActive("certifications");
          }}
        >
          certifications
        </h2>
        <h2
          className={
            active === "application"
              ? "ck-title absolute top-[143px] left-[203px] lg:top-[513px] lg:left-[127px]"
              : "ck-title-active absolute top-[143px] left-[203px] lg:top-[513px] lg:left-[127px]"
          }
          onClick={() => {
            setActive("application");
          }}
        >
          applications
        </h2>
        <div className="flex relative">
          <div className="flex items-start justify-start">
            {active === "programs" && (
              <div className="ck-hr absolute top-[15px] left-[72px] lg:top-[15px] lg:left-[402px]"></div>
            )}
            {active === "curriculums" && (
              <div className="ck-hr absolute  top-[15px] left-[213px] lg:top-[150px] lg:left-[402px]"></div>
            )}
            {active === "certifications" && (
              <div className="ck-hr absolute  top-[55px] left-[72px] lg:top-[290px] lg:left-[402px]"></div>
            )}

            {active === "application" && (
              <div className="ck-hr absolute top-[55px] left-[213px] lg:top-[425px] lg:left-[402px]"></div>
            )}

            <div className="ck-card">
              {active === "programs" && (
                <>
                  <p className="ck-card-content">
                    Our programs are designed to train youth, business leaders
                    and technicians in cutting-edge technologies, empowering
                    them to excel in their fields.
                  </p>
                  <ul className="ml-8 list-disc">
                    <li className="ck-card-content">
                      {" "}
                      Blockchain Expertise: Unleash the potential of
                      decentralised technology and revolutionise industries
                      through our expert-led training.
                    </li>
                    <li className="ck-card-content">
                      {" "}
                      AI and Data Science Mastery: Dive into the world of
                      artificial intelligence and data science to unlock
                      valuable insights and drive innovation.
                    </li>
                    <li className="ck-card-content">
                      {" "}
                      Software Development Excellence: Enhance your coding
                      skills and create robust software solutions that power the
                      digital landscape.
                    </li>
                    <li className="ck-card-content">
                      {" "}
                      Research and Development Innovation: Ignite creativity and
                      explore the forefront of technology through our research
                      and development programs.
                    </li>
                  </ul>
                </>
              )}
              {active === "curriculums" && (
                <>
                  <p className="ck-card-content h-100 overflow-y-scroll style-scrollbar">
                    We strive to break down barriers to technology skills
                    training by providing scholarships to students in
                    collaboration with private and public sector partners.{" "}
                    <br></br>
                    <br></br>
                    According to the World Economic Forum, AI and automation are
                    projected to create 97 million new jobs by 2025. With
                    collaborations with stakeholders from the private and public
                    sectors, we aim to increase access to technology training
                    for African youth.
                    <br></br>
                    <br></br>
                    With the increasing popularity and application of Smart
                    technologies, many jobs are evolving. Through our
                    scholarship support, we are equipping more Africans with the
                    essential technology skills required to bridge the talent
                    gap faced by tech startups and small and medium-sized
                    enterprises (SMMEs) on a global scale.
                    <br></br>
                    <br></br>
                    Join us in making a difference by supporting our scholarship
                    programs today. Together, we can empower African youth and
                    shape the future of technology.
                  </p>
                </>
              )}
              {active === "certifications" && (
                <>
                  <p className="ck-card-content h-100 overflow-y-scroll style-scrollbar">
                    Upon completion of our comprehensive training modules,
                    learners will receive a formal Certificate as recognition of
                    their course attendance and achievement.
                    <br></br>With this Certificate, you gain the ability to:
                    <br></br>
                    <br></br>
                    <ul className=" list-inside list-disc">
                      <li>
                        Enhance your CV: Include the certification to showcase
                        your commitment to continuous learning and dedication to
                        mastering relevant skills.
                      </li>
                      <li>
                        Boost job applications: Highlight your certified
                        expertise to potential employers, demonstrating your
                        competence and qualifications.
                      </li>
                      <li>
                        Showcase your abilities: Use the Certificate in your
                        professional portfolio to validate your aptitude for
                        learning and achieving outstanding results.
                      </li>
                    </ul>
                    <br></br>
                    <br></br>
                    Take the next step towards success and earn your certification today. Empower yourself with tangible proof of your capabilities and open doors to new opportunities.
                  </p>
                </>
              )}

              {active === "application" && (
                <>
                  <p className="ck-card-content ml-8">
                  Ready to take the next step? Contact us today to apply for our exceptional programs. Our dedicated team will promptly reach out to you, providing a comprehensive catalogue of available programs and the enrollment schedule. <br></br><br></br>
Don't miss out on the opportunity to advance your skills and seize exciting possibilities. Take action now and embark on a transformative learning journey with us. Apply today to shape your future in technology.

                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreatingKnowledge;
