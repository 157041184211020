import { useState,useEffect } from "react";
import ThankyouPage from "./thankyou-page";
import {useNavigate} from 'react-router-dom';
import { sendMail } from "../../services/mail";
import { submitForm } from "../../services";

const GeneralEnquiriesForm = () => {
  const [step, setStep]=useState(0);
  const[companyName,setCompanyName]=useState("")
  const[website,setWebsite]=useState("")
  const[location,setLocation]=useState("")
  const[channel,setChannel]=useState("")
  const[enquiries,setEnquires]=useState("")
  const [industry, setindustry] = useState('');
  const [timeframe, settimeframe] = useState('');
  const [means, setmeans] = useState('');
  const [aob, setaob] = useState('');
  const [size, setsize] = useState('');
  const navigate=useNavigate()
  useEffect(() => {
    if(step<0){
      navigate(0)
    }
   
  },[step]);

  const handleSubmit=(e)=>{
    e.preventDefault()
    const payload={"What type of support are you looking for (e.g. technical support, customer support, general inquiries)?": companyName,
    "What is your current challenge or issue that you need support with? ": website,
    "What specific products or services are you interested in?": location,
    "Have you used our products or services before? If so, which ones?": channel,
    " What is the size of your organisation?": size,
    'What industry are you in?':industry,
    'What is your timeframe for receiving support?':timeframe,
    'Would you like to receive support through email, phone, or other means?':means,
    'Are there any additional details or information you would like to provide about your support needs?':aob,
    'Please, provide any enquiries or support requests.':enquiries
  }
    const personalDetails =JSON.parse(window.localStorage.getItem('details'));

    const data={...payload, ...personalDetails}
    const tableRows = Object.entries(data).map(([question, answer]) => `
    <tr>
      <td>${question}</td>
      <td>${answer}</td>
    </tr>
  `);
  
  const table = `
    <table>
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        ${tableRows.join('')}
      </tbody>
    </table>
  `;
  submitForm( 'general_enquiries',{
    title:personalDetails.email,
    content:table,
    status:'publish',
    meta:data
  }).then((res)=>{
  })
  }
  return (
    <div className="space-y-6">
    {step===0&&(
          <>
             <div className="input-group">
        <p className="input-label">What type of support are you looking for (e.g. technical support, customer support, general inquiries)?</p>
        <hr className="input-hr"></hr>
        <textarea className="form-textarea"  value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}}></textarea>
      </div>
      <div className="input-group">
        <p className="input-label">What is your current challenge or issue that you need support with?</p>
        <hr className="input-hr"></hr>
        <input className="form-input"  value={website} onChange={(e)=>{setWebsite(e.target.value)}} id="website"></input>
      </div>
      <div className="input-group">
        <p className="input-label">What specific products or services are you interested in?</p>
        <hr className="input-hr"></hr>
        <input className="form-input"  value={location} onChange={(e)=>{setLocation(e.target.value)}} id="location"></input>
      </div>
      <div className="input-group">
        <p className="input-label">Have you used our products or services before? If so, which ones?</p>
        <hr className="input-hr"></hr>
        <textarea className="form-textarea"  value={channel} onChange={(e)=>{setChannel(e.target.value)}} id="channel"></textarea>
      </div>
          </>)}

          {step===1&&(<>
            <div className="input-group">
        <p className="input-label">What is the size of your organisation?</p>
        <hr className="input-hr"></hr>
        <textarea className="form-textarea"  value={size} onChange={(e)=>{setsize(e.target.value)}} id="size"></textarea>
      </div>
      <div className="input-group">
        <p className="input-label">What industry are you in?</p>
        <hr className="input-hr"></hr>
        <textarea className="form-textarea"  value={industry} onChange={(e)=>{setindustry(e.target.value)}} id="industry"></textarea>
      </div>
      <div className="input-group">
        <p className="input-label">What is your timeframe for receiving support?</p>
        <hr className="input-hr"></hr>
        <textarea className="form-textarea"  value={timeframe} onChange={(e)=>{settimeframe(e.target.value)}} id="timeframe"></textarea>
      </div>
      <div className="input-group">
        <p className="input-label">Would you like to receive support through email, phone, or other means?</p>
        <hr className="input-hr"></hr>
        <textarea className="form-textarea"  value={means} onChange={(e)=>{setmeans(e.target.value)}} id="means"></textarea>
      </div>
      <div className="input-group">
        <p className="input-label">Are there any additional details or information you would like to provide about your support needs?</p>
        <hr className="input-hr"></hr>
        <textarea className="form-textarea"  value={aob} onChange={(e)=>{setaob(e.target.value)}} id="aob"></textarea>
      </div>
          <div className="input-group">
          <p className="input-label">Please, provide any enquiries or support requests.</p>
          <hr className="input-hr"></hr>
          <textarea className="form-textarea"  value={enquiries} onChange={(e)=>{setEnquires(e.target.value)}} id="enquiries"></textarea>
        </div>
 
        </>)}
        {step===2&&(
            <ThankyouPage></ThankyouPage>
        )}
        
        {step !==2&&(
            <>
              <div className="w-[250px] lg:w-[450px] h-[89px] flex gap-0  items-center">
            <div
              className="prev-btn
              "
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <img
                src="images/submission-form/back.svg"
                alt="back"
                className="h-[25px] w-[25px] lg:w-[50px] lg:h-[50px]"
              ></img>
              <h3 className="prev-btn-text">Back</h3>
            </div>
            <div
              className="next-btn"
              onClick={() => {
                setStep(step + 1);
              }}
            >
                    {step===0&&(<h3 className="prev-btn-text">Next</h3>)}
                    {step===1&&(<h3 className="prev-btn-text" onClick={handleSubmit}>Submit</h3>)}
                    
                    <img src="images/submission-form/next.svg" alt="next" className="w-[25px] h-[25px] lg:w-[50px] lg:h-[50px]"></img>
                  </div>
                </div></>
        )}
    </div>
  );
};

export default GeneralEnquiriesForm;
