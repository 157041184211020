import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { footerPageLinks, footerSocialLinks, navLinks } from "../data";
import Navbar from "./navbar";

export const Ellipse = ({ variant, blur, size, ...styles }) => {
  if (variant == "orange") {
    styles.background = "#F5931D";
  }

  if (variant == "blue") {
    styles.background = "#13A3B7";
  }

  if (size) {
    styles.width = size;
    styles.height = size;
  }

  if (blur) {
    styles.filter = `blur(${blur}px)`;
  }

  return <span style={{ position: "absolute", ...styles }} className="ellipse" />;
};

export const Button = ({ variant, href, to, ...props }) => {
  let borderColor;

  if (href) {
    to = href;
  }

  if (variant == "orange") {
    borderColor = "#F39711";
  }

  if (variant == "blue") {
    borderColor = "#13E2FF";
  }

  const buttonStyles = {
    padding: "12px 27px",
    borderWidth: "2px 0px",
    borderStyle: "solid",
    borderColor,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: "20px",
  };

  if (to) {
    return (
      <Link to={to} className="inline-block">
        <button style={buttonStyles} {...props} />
      </Link>
    );
  }

  return <button style={buttonStyles} {...props} />;
};

export const Line = ({ className, variant, isBlur = false }) => {
  let styles = {};

  if (variant == "orange") {
    styles.background = "radial-gradient(49.96% 1483499.98% at 50.14% 49.94%, #F39711 0%, rgba(238, 238, 238, 0) 100%)";
  }

  if (variant == "blue") {
    styles.background = "radial-gradient(49.96% 1483499.98% at 50.14% 49.94%, #13E2FF 0%, rgba(238, 238, 238, 0) 100%)";
  }

  if (isBlur) {
    styles.filter = "blur(2px)";
  }

  return <div className={clsx(className, "h-0.5")} style={styles} />;
};
export const  Modal=({ isOpen, onClose, children })=> {
  const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
          // ESC key
          onClose();
      }
  };

  const handleOverlayClick = (event) => {
      if (event.target === event.currentTarget) {
          onClose();
      }
  };
  const modalStyles = {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: isOpen ? "block" : "none",
      zIndex: 20,
  };

  return isOpen ? (
      <div
          className="modal z-32"
          onKeyDown={handleKeyDown}
          onClick={handleOverlayClick}
          style={modalStyles}
      >
          <div className="partner-modal-content p-8 w-full lg:w-[1140px]">
              <div className="relatve h-full w-full">
                  <div className="modal-ellipse-1"></div>
                  <div className="modal-ellipse-2"></div>
                  {children}
              </div>
          </div>
      </div>
  ) : null;
}
 
export const Footer = ({ className = "bg-[#454545]" }) => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const isBrowser = () => typeof window !== "undefined";

  const scrollTop = () => {
    if (!isBrowser()) return;

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const links = [
    ["Home", "/"],
    ["Borderless Africa", "/borderless-africa"],
    ["Tech Tools", "/tech-tools"],
    ["Startup Submission", "/submission-form"],
    ["Capacity Building", "/capacity-building"],
    ["Content Portal", "/content-portal"],
    ["Venture Building", "/venture-building"],
    ["Contact Us & FAQ", "/contact-us"],
  ];

  return (
    <footer
      className={clsx(
        className,
        "relative flex md:flex-row flex-col items-center justify-between pt-[44px] md:pl-[37px] px-5 text-[#FFFFFF]"
      )}
    >
      <div className="flex flex-col items-center">
        <a href="/">
          <img src="/logo-large.png" alt="" />
        </a>
        <div className="mt-[25px] flex items-center space-x-[35px]">
          {footerSocialLinks.map((social) => (
            <a href={social.link} key={social.name} target="_blank" rel="noreferrer">
              <img src={`/icons/socials/${social.name}.png`} loading="lazy" alt="Adanian Labs" />
            </a>
          ))}
        </div>
        <p className="mt-[21px] text-[12px] leading-[16px] hidden md:flex">Copyright of Adanian Labs Registered 2020</p>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-2 gap-10 whitespace-nowrap pt-[44px] pb-[93px] hidden md:flex">
        {footerPageLinks.map((item, ix) => (
          <div key={ix} className="space-y-[12px]">
            <ul className="space-y-[12px]">
              {item.map(({ name, href }) => (
                <li key={name}>
                  <a href={href} className="leading-[21px] hover:underline">
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="mt-4 grid grid-cols-2 gap-x-10 px-0 gap-y-1 whitespace-nowrap mb-5 md:hidden">
        {links.map(([text, href]) => (
          <a key={text} href={href}>
            {text}
          </a>
        ))}
      </div>

      {showScroll && (
        <button className="absolute md:bottom-[29px] right-[5%] bottom-[80%] cursor-pointer bg-transparent">
          <img onClick={scrollTop} src="/icons/chevron-blue-up.png" loading="lazy" alt="Adanian Labs"  />
        </button>
      )}

      <div className="absolute left-0 top-0 h-[4px] w-3/5 bg-gradient-to-r from-[#F5931D] to-[#F54C11]" />
      <div className="absolute bottom-0 right-0 h-[4px] w-1/2 bg-gradient-to-r from-[#F5931D] to-[#F54C11]" />
    </footer>
  );
};
export const FooterHome = ({ className = "bg-[#000000]" }) => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const isBrowser = () => typeof window !== "undefined";

  const scrollTop = () => {
    if (!isBrowser()) return;

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const links = [
    ["Home", "/"],
    ["Borderless Africa", "/borderless-africa"],
    ["Tech Tools", "/tech-tools"],
    ["Startup Submission", "/submission-form"],
    ["Capacity Building", "/capacity-building"],
    ["Content Portal", "/content-portal"],
    ["Venture Building", "/venture-building"],
    ["Contact Us & FAQ", "/contact-us"],
  ];

  return (
    <footer
      className={clsx(
        className,
        "relative flex md:flex-row flex-col items-center justify-between pt-[44px] md:pl-[37px] px-5 text-[#FFFFFF]"
      )}
    >
      <div className="flex flex-col items-center">
        <a href="/">
          <img src="/logo-large.png" loading="lazy" alt="Adanian Labs"  />
        </a>
        <div className="mt-[25px] flex items-center space-x-[35px]">
          {footerSocialLinks.map((social) => (
            <a href={social.link} key={social.name} target="_blank" rel="noreferrer">
              <img src={`/icons/socials/${social.name}.png`} loading="lazy" alt="Adanian Labs" />
              
            </a>
          ))}
        </div>
        <p className="mt-[21px] text-[12px] leading-[16px] hidden md:flex">Copyright of Adanian Labs Registered 2020</p>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-2 gap-10 whitespace-nowrap pt-[44px] pb-[93px] hidden md:flex">
        {footerPageLinks.map((item, ix) => (
          <div key={ix} className="space-y-[12px]">
            <ul className="space-y-[12px]">
              {item.map(({ name, href }) => (
                <li key={name}>
                  <a href={href} className="leading-[21px] hover:underline">
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="mt-4 grid grid-cols-2 gap-x-10 px-0 gap-y-1 whitespace-nowrap mb-5 md:hidden">
        {links.map(([text, href]) => (
          <a key={text} href={href}>
            {text}
          </a>
        ))}
      </div>

      {showScroll && (
        <button className="absolute md:bottom-[29px] right-[5%] bottom-[80%] cursor-pointer bg-transparent">
          <img onClick={scrollTop} src="/icons/chevron-blue-up.png" loading="lazy" alt="Adanian Labs" />
        </button>
      )}

      <div className="absolute left-0 top-0 h-[4px] w-3/5 bg-gradient-to-r from-[#F5931D] to-[#F54C11]" />
      <div className="absolute bottom-0 right-0 h-[4px] w-1/2 bg-gradient-to-r from-[#F5931D] to-[#F54C11]" />
    </footer>
  );
};

export const Nav = ({ className }) => {
  const navbarRef = useRef(null);
  const location = useLocation();

  const classes = ["backdrop-filter", "backdrop-blur-lg", "bg-opacity-20"];

  useEffect(() => {
    const handleScroll = () => {
      if (navbarRef.current) {
        if (window.scrollY > 0) {
          navbarRef.current.classList.add(...classes);
        } else {
          navbarRef.current.classList.remove(...classes);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav
      ref={navbarRef}
      className={clsx(
        className,
        "max-w-full sm:max-w-[640px] md:max-w-[788px] lg:max-w-[1280px] xl:max-w-[1440px]",
        "fixed inset-x-0 top-0 z-20 mx-auto flex items-center justify-between py-4 pl-[150px] pr-[154px]"
      )}
    >
      <Link to="/">
        <img src="/logo.png" loading="lazy" alt="Adanian Labs" />
      </Link>

      <div className="flex items-center space-x-[50px] text-20">
        <button className="whitespace-nowrap rounded-md px-3 py-2">Services</button>
        {navLinks.map(([name, href]) => {
          return (
            <Link
              key={name}
              href={href}
              className={clsx("whitespace-nowrap rounded-md px-3 py-2", location.pathname === href && "bg-neutral-700")}
            >
              {name}
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export const BackIcon = (props) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12.5" cy="12.5" r="12.5" fill="#D9D9D9" />
    <path
      d="M3.93934 10.9393C3.35355 11.5251 3.35355 12.4749 3.93934 13.0607L13.4853 22.6066C14.0711 23.1924 15.0208 23.1924 15.6066 22.6066C16.1924 22.0208 16.1924 21.0711 15.6066 20.4853L7.12132 12L15.6066 3.51472C16.1924 2.92893 16.1924 1.97918 15.6066 1.3934C15.0208 0.807611 14.0711 0.807611 13.4853 1.3934L3.93934 10.9393ZM21 10.5L5 10.5L5 13.5L21 13.5L21 10.5Z"
      fill="#454545"
    />
  </svg>
);

export const Container = ({ children, className, navProps, footerProps, hideFooter = false, hideNav = false, ...props }) => (
  <main
    className={clsx(
      className,
      "relative mx-auto overflow-x-clip font-sans text-white",
      "max-w-full sm:max-w-[640px] md:max-w-[788px] lg:max-w-[1280px] xl:max-w-[1440px]"
    )}
    {...props}
  >
    {!hideNav && <Navbar {...navProps} />}
    {children}
    {!hideFooter && <Footer {...footerProps} />}
  </main>
);
