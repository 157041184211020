import StartupsHero from "../components/our-startups/startups-hero"
import { Footer } from "../components/shared"
import StartupsContent from "../components/our-startups/startups-section"
import '../css/our-startups.css'

const OurStartups=()=>{
    return (
        <div className="body-container">
        <div className="container mx-auto tools-body overflow-hidden" style={{background: '#454545}'}}>
            <StartupsHero/>
            <StartupsContent/>
            <Footer/>
            </div>
            </div>
    )
}
export default OurStartups