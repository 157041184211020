import { useState, useEffect } from "react";
import ThankyouPage from "./thankyou-page";
import { useNavigate } from "react-router-dom";
import { submitForm } from "../../services";

const VentureBuildingForm = () => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    if (step < 0) {
      navigate(0);
    }
  }, [step]);
  const [website, setwebsite] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCoutry] = useState("");
  const [age, setAge] = useState("");
  const [education, setEducation] = useState("");
  const [channel, setChannel] = useState("");
  const [skills, setSkills] = useState("");
  const [incorporated, setIncorporated] = useState("");
  const [launch, setLaunch] = useState("");
  const [category, setCagtegory] = useState("");
  const [countries, setCountries] = useState("");
  const [countryList, setCountryList] = useState("");
  const [mediaHandles, setMediaHandles] = useState("");
  const [incubation, setIncubation] = useState("");
  const [milestones, setMilestones] = useState("");
  const [problem, setProblem] = useState("");
  const [advantage, setAdvantage] = useState("");
  const [group, setGroup] = useState("");
  const [opportunity, setOpportunity] = useState("");
  const [expectedSize, setExpectedSize] = useState("");
  const [marketShare, setMarketShare] = useState("");
  const [prototype, setPrototype] = useState("");
  const [onboarded, setOnboarded] = useState("");
  const [revenue, setRevenue] = useState("");
  const [revenuesize, setRevenueSize] = useState("");
  const [funding, setFunding] = useState("");
  const [valuation, setValuation] = useState("");
  const [partnerships, setPartnerships] = useState("");
  const [cashflow, setCashflow] = useState("");
  const [burnRate, setByurnRate] = useState("");
  const [runWay, setRunWay] = useState("");
  const [stage, setStage] = useState("");
  const [competitors, setCompetitors] = useState([]);
  const [concept, setConcept] = useState("");
  const [blockchain, setBlockchain] = useState("");
  const [ai, setAi] = useState("");
  const [questions, setQuestions] = useState("");
  const [platform, setPlatform] = useState("");
  const [risks, setRisks] = useState("");
  const [ip, setIp] = useState("");
  const [ipStatus, setIpStatus] = useState("");
  const [pitchDeck, setPitchDeck] = useState("");
  const [pitchVideo, setPitchVideo] = useState("");
  const[founder1,setFounder1Name]=useState("")
  const[founder2,setFounder2Name]=useState("")
  const[founder3,setFounder3Name]=useState("")
  const[founder1Media,setFounder1Media]=useState("")
  const[founder2Media,setFounder2Media]=useState("")
  const[founder3Media,setFounder3Media]=useState("")
  const [productpilot, setproductpilot] = useState('');
  
  const tableRows = Object.entries(questions).map(([question, answer]) => `
  <tr>
    <td>${question}</td>
    <td>${answer}</td>
  </tr>
`);

const table = `
  <table>
    <thead>
      <tr>
        <th>Question</th>
        <th>Answer</th>
      </tr>
    </thead>
    <tbody>
      ${tableRows.join('')}
    </tbody>
  </table>
`;

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      "Company website":website,
      "Where is your company located?Street":street,
      "Where is your company located?City":city,
      "Where is your company located?country":country,
      "Age":age,
      "Education Background":education,
      " Where did you hear about Adanian Labs?":channel,
      " Describe your skills.":skills,
      "When was your company incorporated?":incorporated,
      "When was your product launched?":launch,
      " In which catagory would you group your company?":category,
     " List the main countries you operate in":countryList,
     "List your company's social media handles": mediaHandles,
     "Have you been incubated or been part of an accelerator program before?": incubation,
    " Which milestones are you most proud of as a company?" : milestones,
     " State the main problem that your company is solving.": problem,
     " What is your company's competitive advantage?" :advantage,
      " In which category would you group your company?":group,
      "How many countries do you operate in?":countries,
     "What is the market size opportunity for your business ? ":opportunity,
     " What is the expected market size in the next 5 years?": expectedSize,
    "What’s your expected market share percentage in the next 5 years?": marketShare,
     "Do you have a product prototype?":prototype,
    " Have you piloted your product or service?":productpilot,
     "How many on-boarded users does the platform have?": onboarded,
    "Are you generating revenue?" :revenue,
    "What is your average revenue in the past 3 months?" :revenuesize,
     " What is your last fund-raise size?":funding,
      "What is the last fundraise valuation?":valuation,
     " Have you signed partnership with large corporations?": partnerships,
    "Is your cashflow positive?": cashflow,
     "What is the monthly burn rate?":burnRate,
    "How long is your runway? (in relation to your burn rate)": runWay,
     "In which stage is your startup in?":stage,
     "Who are your main competitors?": competitors,
      " Has the concept / technology in use been tried and tested before?":concept,
     " Do you have a Blockchain strategy?": blockchain,
     "Do you have any AI (Artificial Intelligence) strategy?": ai,
     " If you selected yes for the above 2 questions on having an AI/Blockchain strategy or both, kindly explain further.": questions,
    " If you have a tech platform, who built it?" :platform,
     "What are the possible risks to the business?":risks,
    " What intellectual property does the company have?":ip,
     " What is the status of the intellectual property owned by the company?" :ipStatus,
    "Please upload a pitch Deck":pitchDeck,
     "Please upload a pitch video": pitchVideo,
    };
    const personalDetails =JSON.parse(window.localStorage.getItem('details'));

    const data={...payload, ...personalDetails}
    const tableRows = Object.entries(data).map(([question, answer]) => `
    <tr>
      <td>${question}</td>
      <td>${answer}</td>
    </tr>
  `);
  
  const table = `
    <table>
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        ${tableRows.join('')}
      </tbody>
    </table>
  `;
  submitForm( 'venture_building',{
    title:personalDetails.email,
    content:table,
    status:'publish',
    meta:data
  }).then((res)=>{
    return
  })
  };

  return (
    <div className="space-y-6">
      {step === 0 && (
        <>
          <div className="input-group">
            <p className="input-label">Company website</p>
            <hr className="input-hr"></hr>
            <input
              className="form-input"
              id="1"
              value={website}
              onChange={(e) => {
                setwebsite(e.target.value);

              }}
            ></input>
          </div>

          <div className="input-group">
            <p className="input-label">Where is your company located?</p>
            <hr className="input-hr"></hr>
            <input
              className="form-input text-base text-black"
              placeholder="street Address"
              id="2"
              value={street}
              onChange={(e) => {
                setStreet(e.target.value);
              }}
            ></input>
            <input
              className="form-input text-base text-black"
              placeholder="City"
              id="3"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            ></input>
            <div className="flex gap-2 md:gap-2 order-2 flex-col md:flex-row md:w-[50%]">
              <input className="form-input" placeholder="Postal Code"></input>
              <input
                className="form-input text-[#000000]"
                placeholder="Country"
                id="4"
                value={country}
                onChange={(e) => {
                  setCoutry(e.target.value);
                }}
              ></input>
            </div>
          </div>
        </>
      )}
      {step === 1 && (
        <>
          <div className="age-group">
            <p className="input-label">Age</p>
            <hr className="input-hr"></hr>
            <div className="ans-1">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  value="18-35"
                  id="5"
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                  name={age}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2">
                <p className="ans-sect-1-text">18-35</p>
              </div>
            </div>
            <div className="ans-3">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  value="35 and above"
                  id="5"
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                  name={age}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-4">
                <p className="ans-sect-1-text">35 and above</p>
              </div>
            </div>
          </div>
          <div className="age-group">
            <p className="input-label">Education Background</p>
            <hr className="input-hr"></hr>
            <div className="ans-1">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  value="High School"
                  id="6"
                  onChange={(e) => {
                    setEducation(e.target.value);
                  }}
                  name={education}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2 w-[147px] flex-none flex-grow-0 order-1">
                <p className="ans-sect-1-text">High School</p>
              </div>
            </div>
            <div className="ans-3">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  value="University"
                  id="University"
                  onChange={(e) => {
                    setEducation(e.target.value);
                  }}
                  name={education}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2 w-[126px] flex-none flex-grow-0 order-2">
                <p className="ans-sect-1-text">University</p>
              </div>
            </div>
            <div className="ans-5">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  value="Professional Course"
                  id="8"
                  onChange={(e) => {
                    setEducation(e.target.value);
                  }}
                  name={education}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2 w-[236px] flex-none flex-grow-0 order-2">
                <p className="ans-sect-1-text">Professional Course</p>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">
              Where did you hear about Adanian Labs?
            </p>
            <hr className="input-hr"></hr>
            <input
              className="form-input"
              value={channel}
              id="channel"
              onChange={(e) => {
                setChannel(e.target.value);
              }}
            ></input>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div className="input-group">
            <p className="input-label">
              Describe your skills.<br></br>
              <span className="label-sm">
                Share your areas of specialization and business highlights
              </span>
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={skills}
              id="skills"
              onChange={(e) => {
                setSkills(e.target.value);
              }}
            ></textarea>
          </div>

          <div className="input-group">
            <p className="input-label">When was your company incorporated?</p>
            <hr className="input-hr"></hr>
            <input
              className="form-input"
              placeholder="DD/MM/YYYY"
              value={incorporated}
              id="incorporated"
              onChange={(e) => {
                setIncorporated(e.target.value);
              }}
            ></input>
          </div>
          <div className="input-group">
            <p className="input-label">
              When was your product launched?<br></br>
              <span className="label-sm">
                (Select current date if product has not been launched yet)
              </span>
            </p>
            <hr className="input-hr"></hr>
            <input
            id="launch"
              className="form-input"
              placeholder="DD/MM/YYYY"
              value={launch}
              onChange={(e) => {
                setLaunch(e.target.value);
              }}
            ></input>
          </div>
        </>
      )}
      {step === 3 && (
        <>
          <div className="age-group">
            <p className="input-label">
              In which category would you group your company?
            </p>
            <hr className="input-hr"></hr>
            <div className="ans-1">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  value="Business to Consumer"
                  id="11"
                  onChange={(e) => {
                    setGroup(e.target.value);
                  }}
                  name={group}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2 w-[264px] flex-none flex-grow-0 order-1">
                <p className="ans-sect-1-text">Business to Consumer</p>
              </div>
            </div>
            <div className="ans-3 flex-none flex-grow-0 order-2">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  value="Business to Business"
                  id="12"
                  onChange={(e) => {
                    setGroup(e.target.value);
                  }}
                  name={group}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2 w-[247px] flex-none flex-grow-0 order-2">
                <p className="ans-sect-1-text">Business to Business</p>
              </div>
            </div>
            <div className="ans-3 flex-none flex-grow-0 order-3">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  value="Both"
                  id="both"
                  onChange={(e) => {
                    setGroup(e.target.value);
                  }}
                  type="radio"
                  name={group}
                ></input>
              </div>
              <div className="ans-sect-2 w-[68px] flex-none flex-grow-0 order-2">
                <p className="ans-sect-1-text">Both</p>
              </div>
            </div>
          </div>
          <div className="age-group">
            <p className="input-label">How many countries do you operate in?</p>
            <hr className="input-hr"></hr>
            <div className="ans-1">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  value="<2"
                  id="<2"
                  name={countries}
                  onChange={(e) => {
                    setCountries(e.target.value);
                  }}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2 w-[42px] flex-none flex-grow-0 order-1">
                <p className="ans-sect-1-text">&lt;2</p>
              </div>
            </div>
            <div className="ans-3">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  value="<4"
                  id="<4"
                  name={countries}
                  onChange={(e) => {
                    setCountries(e.target.value);
                  }}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2 w-[42px] flex-none flex-grow-0 order-2">
                <p className="ans-sect-1-text">&lt;4</p>
              </div>
            </div>
            <div className="ans-5">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  value=">4"
                  id=">4"
                  name={countries}
                  onChange={(e) => {
                    setCountries(e.target.value);
                  }}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2 w-[42px] flex-none flex-grow-0 order-2">
                <p className="ans-sect-1-text">&gt;4</p>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">
              List the main countries you operate in
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea-2"
              value={countryList}
              id="countryList"
              onChange={(e) => {
                setCountryList(e.target.value);
              }}
            ></textarea>
          </div>
        </>
      )}

      {step === 4 && (
        <>
          <div className="input-group">
            <p className="input-label">
              List your company's social media handles <br></br>
              <span className="label-sm">(type N/A if none)</span>
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={mediaHandles}
              onChange={(e) => {
                setMediaHandles(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
              Have you been incubated or been part of an accelerator program
              before?
              <span className="label-sm ml-1">
                If yes, explain further , if not type n/a.
              </span>
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={incubation}
              onChange={(e) => {
                setIncubation(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
              Which milestones are you most proud of as a company?
              <span className="label-sm">(competitions, impact or awards)</span>
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={milestones}
              onChange={(e) => {
                setMilestones(e.target.value);
              }}
            ></textarea>
          </div>
        </>
      )}
      {step === 5 && (
        <>
          <div className="input-group">
            <p className="input-label">
              State the main problem that your company is solving. <br></br>
              <span className="label-sm">(type N/A if none)</span>
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={problem}
              onChange={(e) => {
                setProblem(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
              What is your company's competitive advantage?
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={advantage}
              onChange={(e) => {
                setAdvantage(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="age-group">
            <p className="input-label">
              In which catagory would you group your company?
            </p>
            <hr className="input-hr"></hr>
            <div className="ans-1">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  name={category}
                  value="Pre-Seed"
                  onChange={(e) => {
                    setCagtegory(e.target.value);
                  }}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2 flex-none flex-grow-0 order-1">
                <p className="ans-sect-1-text">Pre-Seed</p>
              </div>
            </div>
            <div className="ans-3 flex-none flex-grow-0 order-2">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  name={category}
                  value="Pre Series A"
                  onChange={(e) => {
                    setCagtegory(e.target.value);
                  }}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                <p className="ans-sect-1-text">Pre Series A</p>
              </div>
            </div>
            <div className="ans-3 flex-none flex-grow-0 order-3">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  name={category}
                  value="Series A"
                  onChange={(e) => {
                    setCagtegory(e.target.value);
                  }}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                <p className="ans-sect-1-text">Series A</p>
              </div>
            </div>
            <div className="ans-3 flex-none flex-grow-0 order-4">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  name={category}
                  value="Post Series A"
                  onChange={(e) => {
                    setCagtegory(e.target.value);
                  }}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                <p className="ans-sect-1-text">Post Series A</p>
              </div>
            </div>
            <div className="ans-3 flex-none flex-grow-0 order-5">
              <div className="ans-sect-1">
                <input
                  className="ans-sect-1-input"
                  name={category}
                  value="Other"
                  onChange={(e) => {
                    setCagtegory(e.target.value);
                  }}
                  type="radio"
                ></input>
              </div>
              <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                <p className="ans-sect-1-text">Other</p>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 6 && (
        <>
          <div className="input-group">
            <p className="input-label">
              What are the names of the company's founders?
              <br></br>
              <span className="label-sm">
                Provide relevant social handles for each founder
              </span>
            </p>
            <hr className="input-hr"></hr>
            <div className="flex-none order-2 space-y-1">
            <div className="flex gap-[4px] ">
              <input
                className="form-input-2 w-[177px] lg:w-[304px]"
                placeholder="Full Name"
                value={founder1}
              onChange={(e) => {
                setFounder1Name(e.target.value);
              }}
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[304px] text-[#000000]"
                placeholder="Social Media Handles"
                value={founder1Media}
              onChange={(e) => {
                setFounder1Media(e.target.value);
              }}
              ></input>
            </div>
            <div className="flex gap-[4px] ">
              <input
                className="form-input-2 w-[177px] lg:w-[304px]"
                placeholder="Full Name"
                value={founder2}
              onChange={(e) => {
                setFounder2Name(e.target.value);
              }}
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[304px] text-[#000000]"
                placeholder="Social Media Handles"
                value={founder2Media}
              onChange={(e) => {
                setFounder2Media(e.target.value);
              }}
              ></input>
            </div>
         
            <div className="flex gap-[4px]">
              <input
                className="form-input-2 w-[177px] lg:w-[304px]"
                placeholder="Full Name"
                value={founder3}
              onChange={(e) => {
                setFounder3Name(e.target.value);
              }}
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[304px] text-[#000000]"
                placeholder="Social Media Handles"
                value={founder3Media}
              onChange={(e) => {
                setFounder3Media(e.target.value);
              }}
              ></input>
              {/* <img
                src="images/submission-form/add.svg"
                alt="add"
                className="hidden lg:flex order-3 "
              ></img> */}
            </div>
         
            </div>
            
          </div>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
              List any top companies in which the founders previously worked at.
            </p>
            <hr className="input-hr"></hr>
            <div className="grid grid-cols-2 lg:flex  gap-[4px]  flex-none order-2">
              <input
                className="form-input-2 w-[177px] lg:w-[206px]"
                placeholder="Company Name"
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[206px] text-[#000000]"
                placeholder="Position Held"
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[206px] text-[#000000]"
                placeholder="From (Start Date)"
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[206px] text-[#000000]"
                placeholder="To (End Date)"
              ></input>
            </div>
            <div className="grid grid-cols-2 lg:flex gap-[4px]  flex-none order-2">
              <input
                className="form-input-2 w-[177px] lg:w-[206px]"
                placeholder="Company Name"
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[206px] text-[#000000]"
                placeholder="Position Held"
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[206px] text-[#000000]"
                placeholder="From (Start Date)"
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[206px] text-[#000000]"
                placeholder="To (End Date)"
              ></input>
            </div>
            <div className="grid grid-cols-2 lg:flex gap-[4px]  flex-none order-2">
              <input
                className="form-input-2  w-[177px] lg:w-[206px]"
                placeholder="Company Name"
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[206px] text-[#000000]"
                placeholder="Position Held"
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[206px] text-[#000000]"
                placeholder="From (Start Date)"
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[206px] text-[#000000]"
                placeholder="To (End Date)"
              ></input>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">
              What is your Team Size
              <br></br>
              <span className="label-sm">
                Key staff details -Both full time and part time
              </span>
            </p>
            <hr className="input-hr"></hr>
            <div className="grid grid-cols-2 lg:flex gap-[4px]  flex-none order-2">
              <input
                className="form-input-2 w-[177px] lg:w-[304px]"
                placeholder="Position Description"
              ></input>
              <input
                className="form-input-2 w-[177px] lg:w-[304px] text-[#000000]"
                placeholder="Number of people"
              ></input>
            </div>
            <div className="grid grid-cols-2 lg:flex gap-[4px]  flex-none order-2">
              <input
                className="form-input-2  w-[177px] lg:w-[304px]"
                placeholder="Position Description"
              ></input>
              <input
                className="form-input-2  w-[177px] lg:w-[304px] text-[#000000]"
                placeholder="Number of people"
              ></input>
            </div>
            <div className="grid grid-cols-2 lg:flex gap-[4px]  flex-none order-2">
              <input
                className="form-input-2  w-[177px] lg:w-[304px]"
                placeholder="Position Description"
              ></input>
              <input
                className="form-input-2  w-[177px] lg:w-[304px] text-[#000000]"
                placeholder="Number of people"
              ></input>
            </div>
          </div>
        </>
      )}
      {step === 7 && (
        <>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
              What is the market size opportunity for your business ? <br></br>
              <span className="label-sm"> (in US Dollar)</span>
            </p>
            <hr className="input-hr"></hr>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={opportunity}
                    value="<$500M"
                    onChange={(e) => {
                      setOpportunity(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;$500M</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={opportunity}
                    value="<$10B
                    "
                    onChange={(e) => {
                      setOpportunity(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;$10B</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={opportunity}
                    value="<$1B"
                    onChange={(e) => {
                      setOpportunity(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;$1B</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={opportunity}
                    value=">$15B"
                    onChange={(e) => {
                      setOpportunity(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&gt;$15B</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={opportunity}
                    value="<$5B"
                    onChange={(e) => {
                      setOpportunity(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px]  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;$5B</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
              What is the expected market size in the next 5 years? <br></br>
              <span className="label-sm"> (in US Dollar)</span>
            </p>
            <hr className="input-hr"></hr>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={expectedSize}
                    value="<$500M market size"
                    onChange={(e) => {
                      setExpectedSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;$500M</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={expectedSize}
                    value="<$10B market size"
                    onChange={(e) => {
                      setExpectedSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;$10B</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={expectedSize}
                    value="<$1B market size"
                    onChange={(e) => {
                      setExpectedSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;$1B</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={expectedSize}
                    value="<$15B market size"
                    onChange={(e) => {
                      setExpectedSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&gt;$15B</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={expectedSize}
                    value="<$5B market size"
                    onChange={(e) => {
                      setExpectedSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px]  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;$5B</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={expectedSize}
                    value="<$20B  market size"
                    onChange={(e) => {
                      setExpectedSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px]  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&gt;$20B</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label  w-[361px] lg:w-full ">
              What’s your expected market share percentage in the next 5 years?
            </p>
            <hr className="input-hr"></hr>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={marketShare}
                    value="<10%"
                    onChange={(e) => {
                      setMarketShare(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;10%</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={marketShare}
                    value="<40%"
                    onChange={(e) => {
                      setMarketShare(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;40%</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={marketShare}
                    value="<20%"
                    onChange={(e) => {
                      setMarketShare(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&gt;20%</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={marketShare}
                    value=">40%"
                    onChange={(e) => {
                      setMarketShare(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&gt;40%</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={marketShare}
                    value="<30%"
                    onChange={(e) => {
                      setMarketShare(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px]  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;30%</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 8 && (
        <>
          <div className="input-group">
            <p className="input-label">Do you have a product prototype?</p>
            <hr className="input-hr"></hr>
            <div className="flex  flex-col gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={prototype}
                    value="yes"
                    onChange={(e) => {
                      setPrototype(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">YES</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={prototype}
                    value="No"
                    onChange={(e) => {
                      setPrototype(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">NO</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">
              Have you piloted your product or service?
            </p>
            <hr className="input-hr"></hr>
            <div className="flex flex-col gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={productpilot}
                    value="done"
                    onChange={(e) => {
                      setproductpilot(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">YES</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={productpilot}
                    value="not yet"
                    onChange={(e) => {
                      setproductpilot(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">NO</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
              How many on-boarded users does the platform have?
            </p>
            <hr className="input-hr"></hr>
            <div className="flex flex-col md:flex-row gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={onboarded}
                    value="0 users"
                    onChange={(e) => {
                      setOnboarded(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">0</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={onboarded}
                    value="50,000-100,000 users"
                    onChange={(e) => {
                      setOnboarded(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">50,000-100,000</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={onboarded}
                    value="<10,000 users"
                    onChange={(e) => {
                      setOnboarded(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;10000</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={onboarded}
                    value=">100,000 users"
                    onChange={(e) => {
                      setOnboarded(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&gt;100000</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={onboarded}
                    value="10,000-50,000 users"
                    onChange={(e) => {
                      setOnboarded(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">10,000-50,000</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 9 && (
        <>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
              How many on-boarded users does the platform have?
            </p>
            <hr className="input-hr"></hr>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={onboarded}
                    value="-Negative% users"
                    onChange={(e) => {
                      setOnboarded(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">-Negative%</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={onboarded}
                    value="<50% users"
                    onChange={(e) => {
                      setOnboarded(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;50%</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={onboarded}
                    value="0% users"
                    onChange={(e) => {
                      setOnboarded(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">0%</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={onboarded}
                    value="<100% users"
                    onChange={(e) => {
                      setOnboarded(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;100%</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={onboarded}
                    value="<25% users"
                    onChange={(e) => {
                      setOnboarded(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px]  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;25%</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={onboarded}
                    value=">100% users"
                    onChange={(e) => {
                      setOnboarded(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px]  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&gt;100%</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">Are you generating revenue?</p>
            <hr className="input-hr"></hr>
            <div className="flex flex-col md:flex-row gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={revenue}
                    value="Yes"
                    onChange={(e) => {
                      setRevenue(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">YES</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={revenue}
                    value="Still within Ideation Stage"
                    onChange={(e) => {
                      setRevenue(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Still within Ideation Stage</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={revenue}
                    value="No"
                    onChange={(e) => {
                      setRevenue(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">NO</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">
              What is your average revenue in the past 3 months?
            </p>
            <hr className="input-hr"></hr>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={revenuesize}
                    value="-Negative%"
                    onChange={(e) => {
                      setRevenueSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">-Negative%</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={revenuesize}
                    value="<50%"
                    onChange={(e) => {
                      setRevenueSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;50%</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={revenuesize}
                    value="0%"
                    onChange={(e) => {
                      setRevenueSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">0%</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={revenuesize}
                    value="<100%"
                    onChange={(e) => {
                      setRevenueSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px] flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;100%</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={revenuesize}
                    value="<25%"
                    onChange={(e) => {
                      setRevenueSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px]  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;25%</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={revenuesize}
                    value=">100%"
                    onChange={(e) => {
                      setRevenueSize(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 w-[111px]  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&gt;100%</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 10 && (
        <>
          <div className="input-group">
            <p className="input-label">
              What is your last fund-raise size? <br></br>
              <span className="label-sm">
                (Including Angel and Founders Capital)
              </span>
            </p>
            <hr className="input-hr"></hr>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={funding}
                    value="<$200000"
                    onChange={(e) => {
                      setFunding(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;$200000</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={funding}
                    value="<$2M"
                    onChange={(e) => {
                      setFunding(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&gt;$2M</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={funding}
                    value="<$750000"
                    onChange={(e) => {
                      setFunding(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;$750000</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={funding}
                    value="None"
                    onChange={(e) => {
                      setFunding(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">None</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={funding}
                    value="<2M"
                    onChange={(e) => {
                      setFunding(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;2M</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">What is the last fundraise valuation?</p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea-2 h-[112px] "
              value={valuation}
              onChange={(e) => {
                setValuation(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
              Have you signed partnership with large corporations?<br></br>{" "}
              <span className="label-sm">(yes or no. If yes, quote them)</span>
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea-2 h-[112px]"
              value={partnerships}
              onChange={(e) => {
                setPartnerships(e.target.value);
              }}
            ></textarea>
          </div>
        </>
      )}
      {step === 11 && (
        <>
          <div className="input-group">
            <p className="input-label">Is your cashflow positive?</p>
            <hr className="input-hr"></hr>
            <div className="flex f flex-col gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={cashflow}
                    value="Yes"
                    onChange={(e) => {
                      setCashflow(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">YES</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={cashflow}
                    value="No"
                    onChange={(e) => {
                      setCashflow(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">NO</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">What is the monthly burn rate?</p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea-2 h-[112px]"
              value={burnRate}
              onChange={(e) => {
                setByurnRate(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
              How long is your runway? (in relation to your burn rate) <br></br>
              <span className="label-sm">
                {" "}
                i.e. a measurement of how long your startup has before it runs
                out of cash?
              </span>
            </p>
            <hr className="input-hr"></hr>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={runWay}
                    value="<1 month"
                    onChange={(e) => {
                      setRunWay(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;1 month</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={runWay}
                    value="<9 months"
                    onChange={(e) => {
                      setRunWay(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;9 months</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={runWay}
                    value="<3 month"
                    onChange={(e) => {
                      setRunWay(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;3 months</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={runWay}
                    value="<12 month"
                    onChange={(e) => {
                      setRunWay(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&gt;12 months</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={runWay}
                    value="<6 months"
                    onChange={(e) => {
                      setRunWay(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">&lt;6 months</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 12 && (
        <>
          <div className="input-group">
            <p className="input-label">In which stage is your startup in?</p>
            <hr className="input-hr"></hr>
            <div className="flex f flex-col gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={stage}
                    value="Idea stage: Problem-Solution Fit"
                    onChange={(e) => {
                      setStage(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">
                    Idea stage: Problem-Solution Fit
                  </p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={stage}
                    value=" Pivot Stage: Product-Market Fit"
                    onChange={(e) => {
                      setStage(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">
                    Pivot Stage: Product-Market Fit
                  </p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={stage}
                    value=" Pivot Stage: Product-Market Fit"
                    onChange={(e) => {
                      setStage(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">
                    Growth Stage: Launch and Scale
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">Who are your main competitors?</p>
            <hr className="input-hr"></hr>
            <div className="flex gap-[4px]  flex-none order-2">
              <input
                className="form-input-2 w-[195px]"
                placeholder="Company Name"
              ></input>
              <input
                className="form-input-2 w-[112px] text-[#000000]"
                placeholder="Location"
              ></input>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <input
                className="form-input-2 w-[195px]"
                placeholder="Company Name"
              ></input>
              <input
                className="form-input-2 w-[112px] text-[#000000]"
                placeholder="Location"
              ></input>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <input
                className="form-input-2 w-[195px]"
                placeholder="Company Name"
              ></input>
              <input
                className="form-input-2 w-[112px] text-[#000000]"
                placeholder="Location"
              ></input>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
              Has the concept / technology in use been tried and tested before?
              <br></br>
              <span className="label-sm">
                Has what you are doing proven to be successful before
              </span>
            </p>
            <hr className="input-hr"></hr>
            <div className="flex flex-col md:flex-row gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={concept}
                    value="Yes"
                    onChange={(e) => {
                      setConcept(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Yes</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={concept}
                    value="Still within Ideation Stage"
                    onChange={(e) => {
                      setConcept(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Still within Ideation Stage</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={concept}
                    value="No"
                    onChange={(e) => {
                      setConcept(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">No</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 13 && (
        <>
          <div className="input-group">
            <p className="input-label">
              Do you have any AI (Artificial Intelligence) strategy?<br></br>
              <span className="label-sm">
                Current or future plan to integrate AI
              </span>
            </p>
            <hr className="input-hr"></hr>
            <div className="flex f flex-col gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={ai}
                    value="Yes"
                    onChange={(e) => {
                      setAi(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">YES</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={ai}
                    value="No"
                    onChange={(e) => {
                      setAi(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">NO</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">
              Do you have a Blockchain strategy?
              <br></br>
              <span className="label-sm">
                Current or future plan to integrate Blockchain
              </span>
            </p>
            <hr className="input-hr"></hr>
            <div className="flex f flex-col gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={blockchain}
                    value="we have"
                    onChange={(e) => {
                      setBlockchain(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">YES</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={blockchain}
                    value="we don't"
                    onChange={(e) => {
                      setBlockchain(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">NO</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
              If you selected yes for the above 2 questions on having an
              AI/Blockchain strategy or both, kindly explain further.
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={questions}
              onChange={(e) => {
                setQuestions(e.target.value);
              }}
            ></textarea>
          </div>
        </>
      )}
      {step === 14 && (
        <>
          <div className="input-group">
            <p className="input-label">
              If you have a tech platform, who built it?<br></br>
              <span className="label-sm">
                how conversant are you with building tech platforms
              </span>
            </p>
            <hr className="input-hr"></hr>
            <div className="flex f flex-col gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={platform}
                    value="Myself / My Team"
                    onChange={(e) => {
                      setPlatform(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Myself / My Team</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={platform}
                    value="Outsourced"
                    onChange={(e) => {
                      setPlatform(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Outsourced</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={platform}
                    value="Partially Outsourced"
                    onChange={(e) => {
                      setPlatform(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Partially Outsourced</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">
              What are the possible risks to the business?
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea-2 h-[139px]"
              value={risks}
              onChange={(e) => {
                setRisks(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
              What intellectual property does the company have?
            </p>
            <hr className="input-hr"></hr>
            <div className="flex flex-col md:flex-row gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={ip}
                    value="Copyrights"
                    onChange={(e) => {
                      setIp(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Copyrights</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={ip}
                    value="Trade Secrets"
                    onChange={(e) => {
                      setIp(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Trade Secrets</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={ip}
                    value="Patents"
                    onChange={(e) => {
                      setIp(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Patents</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={ip}
                    value="None"
                    onChange={(e) => {
                      setIp(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">None</p>
                </div>
              </div>
            </div>
            <div className="flex gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={ip}
                    value="Trademarks"
                    onChange={(e) => {
                      setIp(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Trademarks</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 15 && (
        <>
          <div className="input-group">
            <p className="input-label">
              What is the status of the intellectual property owned by the
              company?
            </p>
            <hr className="input-hr"></hr>
            <div className="flex f flex-col gap-[4px]  flex-none order-2">
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={ipStatus}
                    value="Full Ownership"
                    onChange={(e) => {
                      setIpStatus(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2  flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Full Ownership</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={ipStatus}
                    value="Partial Ownership"
                    onChange={(e) => {
                      setIpStatus(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Partial Ownership</p>
                </div>
              </div>
              <div className="ans-3 flex-none flex-grow-0 order-2">
                <div className="ans-sect-1">
                  <input
                    className="ans-sect-1-input"
                    name={ipStatus}
                    value="Unsure"
                    onChange={(e) => {
                      setIpStatus(e.target.value);
                    }}
                    type="radio"
                  ></input>
                </div>
                <div className="ans-sect-2 flex-none flex-grow-0 order-2">
                  <p className="ans-sect-1-text">Unsure</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <p className="input-label">Please upload your pitch deck</p>
            <hr className="input-hr"></hr>
            <label class="form-textarea-3 h-[139px] flex items-center justify-center">
              <input
                type="file"
                value={pitchDeck}
                onChange={(e) => {
                  setPitchDeck(e.target.value);
                }}
              />
              <img
                src="images/submission-form/Upload-Icon.svg"
                alt="upload pitch deck"
                className="h-[39px] w-[49px]"
              ></img>
              <p className="drop-text">
                Click Here /<br></br> Drop FIle Here
              </p>
            </label>
          </div>
          <div className="input-group">
            <label class="form-textarea-3 h-[139px] flex items-center justify-center">
              <input
                type="file"
                value={pitchVideo}
                onChange={(e) => {
                  setPitchVideo(e.target.value);
                }}
              />
              <img
                src="images/submission-form/Upload-Icon.svg"
                alt="upload pitch deck"
                className="h-[39px] w-[49px]"
              ></img>
              <p className="drop-text">
                Click Here /<br></br> Drop FIle Here
              </p>
            </label>
            <p className="input-label w-[361px] lg:w-full">
              Please upload a pitch video<br></br>
              <span className="label-sm">
                We want to hear about the problem you are solving, your
                solutions, how you make money and why you are different.
              </span>
            </p>
            <hr className="input-hr"></hr>
          </div>
        </>
      )}
      {step === 16 && <ThankyouPage></ThankyouPage>}
      {step !== 16 && (
          <div className="w-[250px] lg:w-[450px] h-[89px] flex gap-0  items-center">
          <div
            className="prev-btn
            "
            onClick={() => {
              setStep(step - 1);
            }}
          >
            <img
              src="images/submission-form/back.svg"
              alt="back"
              className="h-[25px] w-[25px] lg:w-[50px] lg:h-[50px]"
            ></img>
            <h3 className="prev-btn-text">Back</h3>
          </div>
          <div
            className="next-btn"
            onClick={() => {
              setStep(step + 1);
            }}
          >
            {step !== 15 && <h3 className="prev-btn-text">Next</h3>}
            {step === 15 && (
              <h3 className="prev-btn-text" onClick={handleSubmit}>
                Submit
              </h3>
            )}

            <img
              src="images/submission-form/next.svg"
              alt="next"
              className="w-[25px] h-[25px] lg:w-[50px] lg:h-[50px]"
            ></img>
          </div>
        </div>
      )}
    </div>
  );
};

export default VentureBuildingForm;
