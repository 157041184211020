const OurVsion = () => {
  return (
    <section className="container relative  px-5 lg:px-[150px] py-5 lg:py-24">
      <div className="vision-section flex flex-col items-center justify-center gap-[27px] mb-8">
        <h1>Our Vision</h1>
        <hr />
      </div>
      <div className="vision-container w-full py-12 px-16 flex flex-col items-center justify-between gap-10">
        <h3>
          A prosperous continent built by resilient and  impactful innovations
          and innovators.
        </h3>
        <hr />
        <p>
          At Adanian Labs we build smart technologies startups by nurturing them
          into successful and scalable data driven commercial businesses. We
          provide consulting services to corporations and the private sectors in
          support of digital transformation strategies. We build capacity in AI,
          Blockchain and Software development across verticals.
        </p>
      </div>
    </section>
  );
};

export default OurVsion;
