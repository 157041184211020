'use client'
import { useState } from 'react';
import { Button } from './shared';
import { submitForm } from '../services';
import swal from 'sweetalert';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    title: '',
    tickets:1,
    registrationOption: 'early-bird',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    console.log(formData);
    submitForm( 'general_enquiries',{
        title:formData.email,
        content:formData,
        status:'publish',
      }).then((res)=>{
        swal('form submitted successfully')
      })
  };

  return (
    <form className=" my-2 mx-auto bg-gray-800 p-8 rounded-lg shadow-md" onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="mb-5">
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-white dark:text-white">
            Your name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-5">
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-white dark:text-white">
            Your email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="name@flowbite.com"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-5">
          <label htmlFor="phone" className="block mb-2 text-sm font-medium text-white dark:text-white">
            Phone Number
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your phone number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-5">
          <label htmlFor="title" className="block mb-2 text-sm font-medium text-white dark:text-white">
            Title/Affiliation
          </label>
          <input
            type="text"
            id="title"
            name="title"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="e.g. CEO, Marketing Manager, Student"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-5">
          <label htmlFor="registrationOption" className="block mb-2 text-sm font-medium text-white dark:text-white">
            Select your registration option
          </label>
          <select
            id="registrationOption"
            name="registrationOption"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={formData.registrationOption}
            onChange={handleChange}
            required
          >
            <option value="early-bird">Early Bird (until June 15th) - R10,000</option>
            <option value="regular">Regular Price - R11,999</option>
          </select>
        </div>
        <div className="mb-5">
          <label htmlFor="title" className="block mb-2 text-sm font-medium text-white dark:text-white">
            Number of tickets
          </label>
          <input
            type="text"
            id="tickets"
            name="tickets"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Number of tickets"
            value={formData.tickets}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <Button variant="orange" className="text-sm text-white" type="submit">
              BOOK YOUR SPOT
            </Button>
    </form>
  );
};

export default RegistrationForm;
