export const selectionCriteria = [
  {
    id: 1,
    title: "Commercial viable solutions",
    desc: " We invest in businesses whose concept is viable and has commercial value. There is demand to scale the solution across multi markets. The concept is unique and disruptive with ability to leverage off collaboration.",
    image: "puzzle",
  },
  {
    id: 2,
    title: "Youth led/driven startups",
    desc: " The potential to lead Africa towards economic and social prosperity lies in the youth of Africa. We invest in young people and invest in building their capacity to create wealth for themselves and their communities.",
    image: "sprout",
  },
  {
    id: 3,
    title: "Potential for exponential growth",
    desc: "We aim to support businesses that will derive the most value from being Africa based but with scale-able potential into other markets. We gravitate towards businesses that have the potential for exponential growth.",
    image: "analytics-graph",
  },
];

export const criteria = [
  {
    id: 4,
    title: "Impact Driven Solutions",
    desc: " Impact is a key driver in our incubation. We believe in the power of technology to solve Africa and world challenges. Our startups are solving some of these challenges creating tangible impact in their sectors.",
    image: "chip",
  },
  {
    id: 5,
    title: "De-Risk-Able business Solutions",
    desc: "We get excited with solutions that have innovative business models that can be de-risked. Be a show off and show us how your solution’s revenue model is “essential”! We are flexible in mindset meaning we can be very open minded.",
    image: "business-presentation",
  },
  {
    id: 6,
    title: "Entrepreneur’s attitude and mindset",
    desc: " We love entrepreneurs who have a growth mindset! One who has what it takes to move from talk to action and is agile to adapt with vatious environment… our Adanian entrepreneur is resilient, persistent and hungry to change the world!",
    image: "frame",
  },
];
