import { Container } from "../components/shared";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const AdanianGazette = () => {
    return ( 
        <Container className="bg-[#404040] pdi">
            <div className="mt-20">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer fileUrl="/gazette.pdf" />
        </Worker>
            </div>
        </Container>
     );
}
 
export default AdanianGazette;