import { useNavigate } from "react-router-dom";


const ServiceComponent = () => {
  const navigate = useNavigate();

  return (
    <section>
      <div className="container mx-auto bg-services py-28 hidden md:block">
        <div className="eclipse-orange"></div>
        <div className="eclipse-blue"></div>
        <div className="bg-services-content">
          <h1 className="header-2">Our Services</h1>
          <p className="text-xl text-center body-text">
            Supercharge your enterprise with our world-class expertise in
            digital transformation - Unleash the true potential of your business
            today!
          </p>
          <div className="hr-container my-2">
            <div className="hr-services"></div>
          </div>
          <div className="space-y-4 px-12 ">
            <a href="tech-tools" className="flex items-center  px-6 lg:px-28" aria-label="learn more about Technology Tools">
              <div className="flex items-center justify-between  px-8 py-4 mx-auto  service-card">
                <img src="images/web-programming.png"   loading="lazy" alt="Adanian Labs"></img>

                <div className="flex flex-col items-center mx-5 space-y-1">
                  <h2 className="header-3">Technology Tools</h2>
                </div>
                <div className="service-divider mr-4"></div>
                <h3 className="services-desc">
                  Discover how our cutting-edge smart technology solutions can
                  transform your business today. Take advantage of our
                  industry-leading solutions and unlock your company's full
                  potential.
                </h3>
              </div>
              <div>
              <button onClick={()=>{navigate('/tech-tools')}}  aria-label="learn more about Technology Tools">
                <img
                  src="images/service-right.png"
                  style={{
                    height: "80px",
                    width: "80px",
                    objectFit: "contain",
                  }}
                  loading="lazy" alt="Adanian Labs"
                ></img>
              </button>
              </div>
            </a>
            <div className="hr-container mr-48">
              <div className="hr-services"></div>
            </div>
            <a
              href="capacity-building"
              className="flex items-center px-6 lg:px-28" aria-label="learn more about Digital Skilling"
            >
              <div className="flex items-center justify-between  px-8 py-4 mx-auto cursor-pointer  service-card ">
                <img src="images/graduation.png"   loading="lazy" alt="Adanian Labs"></img>

                <div className="flex flex-col items-center mx-5 space-y-1">
                  <h2 className="header-3">Digital Skilling </h2>
                </div>

                <div className="service-divider mr-4"></div>
                <h3 className="services-desc">
                  Through our Centers of Excellence, we have meticulously
                  cultivated one of the most robust pools of tech talent,
                  specializing in cutting-edge domains such as AI, blockchain,
                  IoT, and data transformation. Our skilled professionals can
                  upskill your teams and/or provide the support needed to propel
                  your business to the next level of success. Whether you
                  require assistance in scaling your operations, driving
                  innovation, or staying ahead in the digital era, our
                  professionals are ready to work with you.
                </h3>
              </div>
           <div>
              <button onClick={()=>{navigate('/capacity-building')}}  aria-label="learn more about Technology Tools">
                <img
                  src="images/service-right.png"
                  style={{
                    height: "80px",
                    width: "80px",
                    objectFit: "contain",
                  }}
                  loading="lazy" alt="Adanian Labs"
                ></img>
              </button>
           </div>
            </a>
            <div className="hr-container">
              <div className="hr-services"></div>
            </div>
            <a
              href="borderless-africa"
              className="flex items-center px-6 lg:px-28" aria-label="learn more about A Borderless Africa"
            >
              <div className="flex items-center justify-between  px-8 py-4 mx-auto  service-card">
                <img src="images/map.png" loading="lazy" alt="Adanian Labs"></img>

                <div className="flex flex-col  mx-4 ">
                  <h2 className="header-3" style={{ whiteSpace: "nowrap" }}>
                    A Borderless <br></br>africa
                  </h2>
                </div>

                <div className="service-divider mr-4"></div>
                <h3 className="services-desc">
                  Our startups can leverage our pan-Africa network of studios
                  and corporate partnerships enabling swift and ease of market
                  entries.
                </h3>
              </div>
           <div>
           <button onClick={()=>{navigate('/borderless-africa')}}  aria-label="learn more about Technology Tools">
                <img
                  src="images/service-right.png"
                  style={{
                    height: "80px",
                    width: "80px",
                    objectFit: "contain",
                  }}
                  loading="lazy" alt="Adanian Labs"
                ></img>
              </button>
           </div>
            </a>

            <div className="hr-container  mr-48">
              <div className="hr-services"></div>
            </div>
            <a
              href="venture-building"
              className="flex items-center px-6 lg:px-28" aria-label="learn more about Venture Building"
            >
              <div className="flex items-center justify-between  px-8 py-4 mx-auto cursor-pointer  service-card">
                <img src="images/agreement.png"   loading="lazy" alt="Adanian Labs"></img>

                <div className="flex flex-col items-center mx-5 space-y-1">
                  <h2 className="header-3">Venture Building</h2>
                </div>

                <div className="service-divider mr-4"></div>
                <h3 className="services-desc">
                  We incubate, build, nurture and launch scalable technology
                  startups that create sustainable impact in Africa focused on
                  the SGD goals.
                </h3>
              </div>
            <div>
            <button onClick={()=>{navigate('/venture-building')}}  aria-label="learn more about Technology Tools">
                <img
                  src="images/service-right.png"
                  style={{
                    height: "80px",
                    width: "80px",
                    objectFit: "contain",
                  }}
                  loading="lazy" alt="Adanian Labs"
                ></img>
              </button>
            </div>
            </a>
          </div>
        </div>
      </div>
      <div className="container mx-auto sm-bg-services block md:hidden">
        <div className="eclipse-orange"></div>
        <div className="eclipse-blue"></div>
        <div className="w-full absolute left-[opx] top-[28px] flex items-center justify-center flex-col">
          <h2 className="sm-services-header">Our Services</h2>
          <p className="text-center sm-service-desc">
            Supercharge your enterprise with our world-class expertise in
            digital transformation - Unleash the true potential of your business
            today!
          </p>
        </div>
        <div className="hr-container absolute top-[145px] left-[100px] ml-20">
          <div className="hr-services"></div>
        </div>
        <div className="space-y-4 absolute top-[160px] left-[34px]">
          <a className="flex items-center" href="tech-tools" aria-label="learn more about adanian labs">
            <div className="flex flex-col items-center justify-between  px-8 py-4 mx-auto cursor-pointer">
              <div className="flex justify-between items-center">
                <img src="images/web-programming.png"   loading="lazy" alt="Adanian Labs"></img>

                <div className="flex flex-col items-center mx-5 space-y-1">
                  <h2 className="header-3">Technology Tools</h2>
                </div>
                <span className="chevron-right">
                  <img
                    src="images/service-right.png"
                    style={{
                      height: "32px",
                      width: "32px",
                      objectFit: "contain",
                    }}
                    loading="lazy" alt="adanian labs"
                  ></img>
                </span>
              </div>

              <h3 className="services-desc mt-5">
                Discover how our cutting-edge smart technology solutions can
                transform your business today. Take advantage of our
                industry-leading solutions and unlock your company's full
                potential.
              </h3>
            </div>
          </a>
          <div className="hr-container ml-20">
            <div className="hr-services"></div>
          </div>
          <a className="flex items-center" href="capacity-building" aria-label="learn more about adanian labs">
            <div className="flex flex-col items-center justify-between  px-8 py-4 mx-auto cursor-pointer">
              <div className="flex justify-between items-center">
                <img src="images/graduation.png"   loading="lazy" alt="Adanian Labs"></img>

                <div className="flex flex-col items-center mx-5 space-y-1">
                  <h2 className="header-3">Capacity Building</h2>
                </div>
                <span className="chevron-right">
                  <img
                    src="images/service-right.png"
                    style={{
                      height: "32px",
                      width: "32px",
                      objectFit: "contain",
                    }}
                    loading="lazy" alt="Adanian Labs"
                  ></img>
                </span>
              </div>

              <h3 className="services-desc mt-5">
                Through a special focus in digital skills, AI, Blockchain and
                the creatives industry, we aim to fill the knowledge gap in
                Africa and facilitate technology knowledge transfer
              </h3>
            </div>
          </a>

          <div className="hr-container mr-20">
            <div className="hr-services"></div>
          </div>
          <a className="flex items-center" href="borderless-africa" aria-label="learn more about adanian labs">
            <div className="flex flex-col items-center justify-between  px-8 py-4 mx-auto cursor-pointer">
              <div className="flex justify-between items-center">
                <img src="images/map.png"   loading="lazy" alt="Adanian Labs"></img>

                <div className="flex flex-col items-center mx-5 space-y-1">
                  <h2 className="header-3">A Borderless africa</h2>
                </div>
                <span className="chevron-right">
                  <img
                    src="images/service-right.png"
                    style={{
                      height: "32px",
                      width: "32px",
                      objectFit: "contain",
                    }}   loading="lazy" alt="Adanian Labs"
                  ></img>
                </span>
              </div>

              <h3 className="services-desc mt-5">
                Our startups can leverage our pan-Africa network of studios and
                corporate partnerships enabling swift and ease of market
                entries.
              </h3>
            </div>
          </a>

          <div className="hr-container  ml-20">
            <div className="hr-services"></div>
          </div>
          <a className="flex items-center" href="venture-building" aria-label="learn more about adanian labs" >
            <div className="flex flex-col items-center justify-between  px-8 py-4 mx-auto cursor-pointer ">
              <div className="flex justify-between items-center">
                <img src="images/agreement.png"   loading="lazy" alt="Adanian Labs"></img>

                <div className="flex flex-col items-center mx-5 space-y-1">
                  <h2 className="header-3">Venture Building</h2>
                </div>
                <span className="chevron-right">
                  <img
                    src="images/service-right.png"
                    style={{
                      height: "32px",
                      width: "32px",
                      objectFit: "contain",
                    }}   loading="lazy" alt="Adanian Labs"
                  ></img>
                </span>
              </div>

              <h3 className="services-desc mt-5">
                We incubate, build, nurture and launch scalable technology
                startups that create sustainable impact in Africa focused on the
                SGD goals.
              </h3>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ServiceComponent;
