import Navbar from "../navbar";

const StartupsHero = () => {
  return (
    <section className="container pt-16  mx-auto text-center bg-center bg-cover  h-fit ">
      <Navbar />
      <div className="text-center">
      <div className="startup-hero h-[558px] flex flex-col text-center justify-start align-start space-y-3 py-[150px]  px-5 lg:px-[220px] relative">
        <span className="h-full w-full absolute top-0 left-0"></span>
        <h3 className=" hero-title z-10">OUR STARTUPS</h3>
        <p className="hero-subtitle z-10">Empowering startups to thrive: Our track record speaks for itself</p>
      </div></div>
      <div className="content-section  px-5 lg:px-[150px] py-[56px] flex flex-col items-center justify-center">
        <p className="heading">Introduction</p>
        <hr className="my-[28px]"/>
        <p className="desc">
        See how our clients have benefited from our venture building programs and the difference they are making.
        </p>
      </div>
    </section>
  );
};

export default StartupsHero;
