const BuildingAudience = () => {
  return (
    <section>
      <div className="container mx-auto text-center bg-center bg-cover relative h-fit lg:px-[36px] px-[11px] py-20">
        <div className="ba-circle-1"></div>
        <div className="ba-circle-2"></div>
        <div className=" hidden lg:grid grid-cols-1 lg:grid-cols-3 gap-[57px]">
          <div className="ba-card-outer relative flex flex-col items-center pt-8">
            <img src="images/community.png" className="audeince-img" loading="lazy"></img>
            <div className="ba-card-inner mt-8 flex flex-col items-center justify-start px-8 py-8">
              <h2 className="ba-card-title">People & Community</h2>
              <div className="ba-hr my-5"></div>
              <p className="ba-card-subtitle">
                Our mission focuses on the people, through building a community
                of informed and empowered young people in all the markets that
                we operate. We run programs geared towards building 4IR tech
                capacity in key communities.
              </p>
            </div>
          </div>
          <div className="ba-card-outer relative flex flex-col items-center pt-8">
            <img src="images/data.png" className="audeince-img" loading="lazy" alt="software devs"></img>
            <div className="ba-card-inner mt-8 flex flex-col items-center justify-start px-8 py-8">
              <h2 className="ba-card-title">Software Developers</h2>
              <div className="ba-hr my-5"></div>
              <p className="ba-card-subtitle">
                We aim to train 10,000 data scientists and AI engineers and 5000
                Block chain programmers in an effort to empower them with smart
                tech skills. We also work directly with businesses to transform
                their IT departments.
              </p>
            </div>
          </div>
          <div className="ba-card-outer relative flex flex-col items-center justify-center pt-8">
            <img src="images/leadership.png" className="audeince-img" loading="lazy" alt="Adanian Labs" ></img>
            <div className="ba-card-inner mt-8 flex flex-col items-center justify-start px-8 py-8">
              <h2 className="ba-card-title">industry leaders</h2>
              <div className="ba-hr my-5"></div>
              <p className="ba-card-subtitle">
                We run training programs based around smart technology to
                upskill teams and train business leaders on how to leverage
                smart tech to scale their businesses.
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px] lg:hidden">
          <div className="ba-card-outer relative flex flex-col items-center lg:pt-8">
            <img loading="lazy" alt="Adanian Labs" 
              src="images/community.png"
              className="object-contain h-[67px] width-[67px] absolute top-[11px] left-[46px] drop-shadow-[drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))]"
            ></img>
            <h2 className="ba-card-title">
              People & <br></br>Community
            </h2>
            <div className="ba-hr"></div>
            <div className="ba-card-inner pt-[11px] pl-[23px]">
              <p className="ba-card-subtitle">
                Our mission focuses on the people, through building a community
                of informed and empowered young people in all the markets that
                we operate. We run programs geared towards building 4IR tech
                capacity in key communities.
              </p>
            </div>
          </div>
          <div className="ba-card-outer relative flex flex-col items-center lg:pt-8">
            <img loading="lazy" alt="Adanian Labs" 
              src="images/data.png"
              className="object-contain h-[67px] width-[67px] absolute top-[11px] left-[46px] drop-shadow-[drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))]"
            ></img>
            <h2 className="ba-card-title">Software <br></br>Developers</h2>
            <div className="ba-hr"></div>
            <div className="ba-card-inner pt-[11px] pl-[23px]">
              <p className="ba-card-subtitle">
                We aim to train 10,000 data scientists and AI engineers and 5000
                Block chain programmers in an effort to empower them with smart
                tech skills. We also work directly with businesses to transform
                their IT departments.
              </p>
            </div>
          </div>
          <div className="ba-card-outer relative flex flex-col items-center lg:pt-8">
            <img
              src="images/leadership.png"
              className="object-contain h-[67px] width-[67px] absolute top-[11px] left-[46px] drop-shadow-[drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))]"
            ></img>
            <h2 className="ba-card-title">Industry <br></br>leaders</h2>
            <div className="ba-hr"></div>
            <div className="ba-card-inner pt-[11px] pl-[23px]">
              <p className="ba-card-subtitle">
                We run training programs based around smart technology to
                upskill teams and train business leaders on how to leverage
                smart tech to scale their businesses.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuildingAudience;
