import { useNavigate } from "react-router-dom";

const ValuesScetion = () => {
  const navigate = useNavigate();
  return (
    <section>
      <div className="container px-6 lg:px-32 py-10 lg:py-16  mx-auto values-bg relative">
        <div className="values-circle"></div>
        <div className="values-circle-2"></div> 
        <h3 className="lg-values-title text-center capitalize">
          A dynamic ecosystem to Innovate, Nurture, <br></br>Launch and Scale
          disruptive solutions.
        </h3>
        <div className="flex items-center text-center justify-center mb-16 mt-8 lg:mb-20 lg:mt-12">
          <hr className="values-divider"></hr>
        </div>
        <div className="grid grid-cols-1 gap-8 lg:mt-16 xl:mt-12 xl:gap-8 md:grid-cols-2 xl:grid-cols-3">
          <a href="iotce" aria-label="learn more about adanianlabs"
            className="flex flex-col items-center justify-around pt-8 pb-4 lg:pb-8 lg:py-8 px-4 text-center rounded-xl values-card "
            style={{ marginBottom: "35px", zIndex: "2" }}
          >
            <span className="rounded-full flex items-center justify-center lg:-mt-20 -mt-16 vx-icon">
              <img
                src="images/home/iotce.png"
                style={{ width: "50px", height: "50px" }} loading="lazy" alt="Adanian Labs" 
              ></img>
            </span>

            <h3 className="values-card-title  mt-2 mb-8 ">
            IoT CENTER  OF EXCELLENCE
            </h3>

            <hr className="values-hr mt-2 mb-8"></hr>
            <button onClick={()=>{navigate('/iotce')}} aria-label="learn more about iotce">
            <span className="values-btn btn-text py-2 px-8">
            Learn more
            </span>
            </button>
       
          </a>
          <a href="africa-blockchain-center" aria-label="learn more about IOTCE"
            className="flex flex-col items-center justify-around  pt-8 pb-4 lg:pb-8 lg:py-8 px-4 text-center rounded-xl values-card"
            style={{ marginBottom: "35px", zIndex: "2" }}
          >
            <span className="rounded-full flex items-center justify-center lg:-mt-20 -mt-16 vx-icon">
              <img
                src="images/home/abc.png" loading="lazy" alt="Adanian Labs" 
                style={{ width: "50px", height: "50px" }}
              ></img>
            </span>

            <h3 className="values-card-title mt-2 mb-8 uppercase">
            AFRICA BLOCKCHAIN CENTER 
            </h3>

            <hr className="values-hr mt-2 mb-8"></hr>
            <button onClick={()=>{navigate('/africa-blockchain-center')}} aria-label="learn more about AFRICA BLOCKCHAIN CENTER ">
            <span className="values-btn btn-text py-2 px-12">
             Learn more
            </span>
            </button>
           
          </a>

          <a  href="aice" aria-label="learn more about ABC"
            className="flex flex-col items-center justify-around  pt-8 pb-4 lg:pb-8 lg:py-8 px-4 text-center rounded-xl values-card"
            style={{ marginBottom: "35px", zIndex: "2" }}
          >
            <span className="rounded-full lg:-mt-20 -mt-16 vx-icon flex items-center">
              <img src="images/home/aice.svg" className="camel-icon" loading="lazy" alt="Adanian Labs" ></img>
            </span>

            <h3 className="values-card-title uppercase mt-2 mb-8 ">
            artificial intelligence center of excellence
            </h3>

            <hr className="values-hr mt-2 mb-8"></hr>
            <button onClick={()=>{navigate('/aice')}} aria-label="learn more about AICE">
            <span className="values-btn btn-text py-2 px-8">
             Learn more
            </span>
            </button>
           
          </a>
        </div>
      </div>
    </section>
  );
};

export default ValuesScetion;
