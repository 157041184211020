
import Article from "../components/content-portal/article";
import { Ellipse, Line, Container } from "../components/shared";
import { useState, useEffect } from "react";
import { getData } from "../services";
import "../css/content-portal.css";
import axios from "axios";
import Faqs from "../components/faqs";

const Articles = ({ blogs,postLiked,onLikeChange,likedPosts }) => {
  return (
    <section
      className="relative px-[11px] lg:px-60 pb-[102px] pt-14"
      id="articles"
    >
      <Ellipse blur={290} size={320} variant="orange" top={890} right={-87} />
      <Ellipse blur={290} size={320} variant="orange" top={356} />
      <h1 className="mb-3 text-center text-40">
        <span
          className="bg-clip-text text-transparent"
          style={{
            backgroundImage:
              "linear-gradient(115.71deg, #F39711 28.73%, #F54C11 66.25%)",
          }}
        >
          Articles
        </span>
      </h1>

      <Line
        variant="orange"
        className="mx-auto mb-10 h-[1px] w-[640px] hidden lg:block"
      />

      <div className="space-y-10">
        {blogs.map((blog, ix) => (
          <Article blog={blog} key={ix} postLiked={postLiked} handleLike={onLikeChange} likedPosts={likedPosts}/>
        ))}
      </div>
    </section>
  );
};
const Hero = () => {
  return (
    <div className="content-portal-hero text-center h-[558px] flex flex-col justify-start align-start space-y-3 py-[150px] px-5 lg:px-[220px] relative">
      <span className="h-full w-full absolute top-0 left-0"></span>
      <h3 className="hero-title z-10">ADANIAN BLOG</h3>
      <p className=" hero-subtitle z-10 ">
        Stay ahead of the curve with Adanian Labs' latest developments
      </p>
    </div>
  );
};

const ContentPortal = () => {
  const [blogs, setBlogs] = useState([]);
  const [postLiked, setpostLiked] = useState(false);
  const [postyouLiked, setpostyouLiked] = useState([]);



  
  // generate and store JWT token
  const generateToken = async () => {
    const username = "techadanian"
    const password = "i!gFe&ui3lX0BpaP("
  
    try {
      const response = await axios.post(
        "https://dashboard.adanianlabs.io/wp-json/jwt-auth/v1/token",
        {
          username,
          password,
        }
      );
      const token = response.data.data.token;
      localStorage.setItem("jwtToken", token);
      return token;
    } catch (error) {
      console.error(error);
    }
  };
  const handleLike = async(id,likes)=>{
    generateToken();
    const token = localStorage.getItem("jwtToken");
    fetch(`https://dashboard.adanianlabs.io/wp-json/wp/v2/posts/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        acf:{
          likes:likes+1
        }
      }),
    }).then(()=>{
      setpostLiked(!postLiked)
      localStorage.setItem("likes",JSON.stringify([...postyouLiked,id]))
    })
  }
  useEffect(() => {
    getData(
      "https://dashboard.adanianlabs.io/wp-json/wp/v2/posts/?categories=2&_embed"
    ).then((data) => {
      const storedArray = localStorage.getItem('likes');
      const myArray = storedArray ? JSON.parse(storedArray) : [];
      setBlogs(data);
      setpostyouLiked(myArray)
      
    });
  }, [postLiked]);


  return (
    <div>
       
                 <Container
      className="bg-[#1E1E1E]"
      footerProps={{ className: "bg-[#1E1E1E]" }}
    >
      
      <Hero />
      <Articles blogs={blogs} onLikeChange={handleLike} postLiked={postLiked} likedPosts={postyouLiked}/>
      <Faqs/>
    </Container>
    </div>
    
  );
};

export default ContentPortal;
