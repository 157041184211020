import { useState, useEffect } from "react";
import SharedForm from "./shared-from";
import ThankyouPage from "./thankyou-page";
import { useNavigate } from "react-router-dom";
import { submitForm } from "../../services";

const CapacityBuildingForm = () => {
  const [step, setStep] = useState(0);
  const [areas, setrAreas] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [website, setwebsite] = useState("");
  const [location, setlocation] = useState("");
  const [channel, setchannel] = useState("");
  const [budget, setbudget] = useState('');
  const [timeframe, settimeframe] = useState('');
  const [past, setpast] = useState('');
  const [priorities, setpriorities] = useState('');
  const [aob, setaob] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    if (step < 0) {
      navigate(0);
    }
  }, [step]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      "What specific skills or areas of expertise would you like to develop within your organisation?": companyName,
      "How many employees does your organisation have?": website,
      "What is your preferred mode of training delivery (e.g. in-person, online, blended)?": channel,
      "What is your estimated budget for capacity building services?": budget,
      " What areas do you wish to improve through the Capacity Building programs?":
        areas,
      "What is your desired timeframe for completing capacity building training?": timeframe,
      'Have you engaged with capacity building services in the past? If so, what was your experience?':past,
      "What are your organisation's top priorities for capacity building?":priorities,
      'Is there anything else you would like to share about your organisation or capacity building needs?':aob
    };
    const personalDetails =JSON.parse(window.localStorage.getItem('details'));

    const data={...payload, ...personalDetails}
    const tableRows = Object.entries(data).map(([question, answer]) => `
    <tr style="border:1px solid black;">
      <td>${question}</td>
      <td>${answer}</td>
    </tr>
  `);
  
  const table = `
    <table>
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        ${tableRows.join('')}
      </tbody>
    </table>
  `;
  submitForm( 'capacity_building',{
    title:personalDetails.email,
    content:table,
    status:'publish',
    meta:data
  }).then((res)=>{
  return
  })
  };
  return (
    <div className="space-y-8">
      {step === 0 && (
        <>
          <div className="input-group">
            <p className="input-label">What specific skills or areas of expertise would you like to develop within your organisation?</p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={companyName}
              onChange={(e) => {
                setcompanyName(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">How many employees does your organisation have?</p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={website}
              onChange={(e) => {
                setwebsite(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
            What is your preferred mode of training delivery (e.g. in-person, online, blended)?
            </p>
            <hr className="input-hr"></hr>
            <input
              className="form-input"
              value={channel}
              onChange={(e) => {
                setchannel(e.target.value);
              }}
              id="channel"
            ></input>
          </div>
          
        </>
      )}

      {step === 1 && (
        <>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
            What is your estimated budget for capacity building services?
            </p>
            <hr className="input-hr"></hr>
            <input
              className="form-input"
              id="budget"
              type="number"
              value={budget}
              onChange={(e) => {
                setbudget(e.target.value);
              }}
            ></input>
          </div>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
            What is your desired timeframe for completing capacity building training?
            </p>
            <hr className="input-hr"></hr>
            <input
              className="form-input"
              type="text"
              id="timeframe"
              value={timeframe}
              onChange={(e) => {
                settimeframe(e.target.value);
              }}
            ></input>
          </div>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
            Have you engaged with capacity building services in the past? If so, what was your experience?
            </p>
            <hr className="input-hr"></hr>
            <input
              className="form-input"
              type="text"
              id="past"
              value={past}
              onChange={(e) => {
                setpast(e.target.value);
              }}
            ></input>
          </div>
          <div className="input-group">
            <p className="input-label">
            What are your organisation's top priorities for capacity building?
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={priorities}
              onChange={(e) => {
                setpriorities(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
            Is there anything else you would like to share about your organisation or capacity building needs?
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={aob}
              onChange={(e) => {
                setaob(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
              Please, provide any enquiries or extra comments.
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={enquiry}
              onChange={(e) => {
                setEnquiry(e.target.value);
              }}
            ></textarea>
          </div>
        </>
      )}
      {step === 2 && <ThankyouPage></ThankyouPage>}

      {step !== 2 && (
               <>
               <div className="w-[250px] lg:w-[450px] h-[89px] flex gap-0  items-center">
                 <div
                   className="prev-btn
                   "
                   onClick={() => {
                     setStep(step - 1);
                   }}
                 >
                   <img
                     src="images/submission-form/back.svg"
                     alt="back"
                     className="h-[25px] w-[25px] lg:w-[50px] lg:h-[50px]"
                   ></img>
                   <h3 className="prev-btn-text">Back</h3>
                 </div>
                 <div
                   className="next-btn"
                   onClick={() => {
                     setStep(step + 1);
                   }}
                 >
                   {step === 0 && <h3 className="prev-btn-text">Next</h3>}
                   {step === 1 && (
                     <h3 className="prev-btn-text" onClick={handleSubmit}>
                       Submit
                     </h3>
                   )}
     
                   <img
                     src="images/submission-form/next.svg"
                     alt="next"
                     className="h-[25px] w-[25px] lg:w-[50px] lg:h-[50px]"
                   ></img>
                 </div>
               </div>
             </>
      )}
    </div>
  );
};

export default CapacityBuildingForm;
