import "../../css/tech-tools.css";
import { useState } from "react";
const ToolsShowcase = () => {
  const [tool, setTools] = useState("Agriculture");
  const [click, setClick] = useState(false);
  const handleClick = (tool) => {
    setClick(true);
    setTools(tool);
  };
  const items = [
    {
      tab: "Agriculture",
      tools: [
        {
          title: "target farmers management tool",
          subtitle:
            "With our tool, farmers can easily manage their land and crops, track expenses, and make informed decisions about resource allocation.",
          image: "agric-1",
        },
        {
          title: "farm management",
          subtitle:
            "Our software enables efficient documentation, tracking, and management of farming activities.",
          image: "agric-2",
        },
        {
          title: "farm produce traceability",
          subtitle:
            "Our Farm Produce Traceability Tool is a cutting-edge solution that empowers farmers and agribusinesses to track their produce from farm to market. ",
          image: "agric-3",
        },
        {
          title: "farm produce market place",
          subtitle:
            "A tool for farmers to connect with buyers, market products, access real-time market insights, and manage transactions with our Farm Produce Marketplace Tool.",
          image: "agric-4",
        },
      ],
    },
    {
      tab: "Health",
      tools: [
        {
          title: "EHR",
          subtitle:
            "From reducing administrative burdens to improving patient outcomes, our EHR tool is an indispensable asset for healthcare organisations looking to streamline their operations and provide the best possible care.",
          image: "health-1",
        },
        {
          title: "HMIS",
          subtitle:
            "With features like patient data tracking, inventory management, and reporting capabilities, our HMIS tool helps healthcare providers optimise resources and improve patient outcomes.",
          image: "health-2",
        },
        {
          title: "Tele-Medicine Tool",
          subtitle:
            "Our Tele-Medicine Tool improves access to healthcare services, reduces wait times, and enhances patient satisfaction by facilitating remote consultations for high-quality care.",
          image: "health-3",
        },
        {
          title: "UPP",
          subtitle:
            "Our patient-owned health data solution improves engagement and outcomes, and enhances communication and collaboration between patients and healthcare providers, meeting specific healthcare needs.",
          image: "health-4",
        },
        {
          title: "Special clinics",
          subtitle:
            "Our tool helps manage specialised clinics for healthcare providers, improving patient care and operational efficiency.",
          image: "health-5",
        },
        {
          title: "Disease management",
          subtitle:
            "Our outbreak and disease management system is a powerful tool that helps healthcare providers to track, monitor, and manage outbreaks and infectious diseases in real-time.",
          image: "health-6",
        },
        {
          title: "Diary as a tool",
          subtitle:
            "Our Diary Management Tool is a comprehensive solution designed to streamline scheduling, appointment management, and patient communication.",
          image: "health-7",
        },
        {
          title: "diagnosis tool",
          subtitle:
            "Our Diagnosis Tool is a powerful solution that leverages the latest advancements in AI and machine learning to help healthcare professionals quickly and accurately diagnose a wide range of conditions. ",
          image: "health-8",
        },
        {
          title: "diagnostic hospital management",
          subtitle:
            "Our Diagnostic tool helps healthcare providers track imaging data and securely share files with patients, improving workflow and patient engagement.",
          image: "health-9",
        },
        {
          title: "Patient monitoring",
          subtitle:
            "Our Patient Monitoring Tool allows healthcare providers to remotely monitor patient health data in real-time, enabling early detection and intervention of health issues.",
          image: "health-10",
        },
        {
          title: "pharmacy tool",
          subtitle:
            "Our Pharmacy Management Tool is a comprehensive solution designed to streamline pharmacy operations, improve medication safety, and enhance patient care.",
          image: "health-11",
        },
      ],
    },
    {
      tab: "Fintech",
      tools: [
        {
          title: "inua bizz business loans",
          subtitle:
            "Our loan tool provides a user-friendly platform that simplifies the loan application process, making it easy for borrowers to access financing quickly and efficiently.",
          image: "fintech-1",
        },
        {
          title: "changisha tool",
          subtitle:
            "Changisha is a crowdsourcing and crowdfunding platform that helps you champion and manage donations for the causes you care about",
          image: "fintech-2",
        },
        {
          title: "chamaa management",
          subtitle:
            "A powerful platform designed to streamline the management of savings groups, and investment clubs. With our solution, members can easily contribute to their group, manage group loans, schedule meetings, and monitor group finances.",
          image: "fintech-3",
        },
        {
          title: "Crowd Funding tool",
          subtitle:
            "A white-label private investor portal for peer-to-peer early-stage businesses",
          image: "fintech-4",
        },
        {
          title: "Payment & Gateway Integrations",
          subtitle:
            "With seamless integration with popular payment gateways, our solution enables businesses to streamline their payment processes, reduce fraud, and increase customer trust.",
          image: "fintech-5",
        },
        {
          title: "Mobile money integrations",
          subtitle:
            "Our Mobile Money Integrations Tool allows businesses to seamlessly integrate mobile money payments into their platform, providing customers with a fast, secure, and convenient way to make transactions.",
          image: "fintech-6",
        },
        {
          title: "payment reconciliation",
          subtitle:
            "Our solution allows businesses to easily track and manage their payment transactions, reducing errors, saving time, and enhancing overall financial performance.",
          image: "fintech-7",
        },
        {
          title: "Credit Scoring tool",
          subtitle:
            "Our tool integrates with multiple data sources, leveraging advanced analytics and machine learning algorithms to generate highly accurate credit scores.",
          image: "fintech-8",
        },
        {
          title: "remittance",
          subtitle:
            "With our secure and reliable platform, users can easily send and receive remittances across borders, while our comprehensive reporting and analytics features provide valuable insights to help organizations make data-driven decisions.",
          image: "fintech-9",
        },
        {
          title: "sacco management",
          subtitle:
            "Our SACCO Management Tool is a comprehensive platform that provides SACCOs with the tools they need to efficiently manage their members' accounts, loans, savings, and investments.",
          image: "fintech-10",
        },
        {
          title: "E-commerce",
          subtitle:
            "Our platform is designed to meet the specific needs of businesses of all sizes, providing them with the tools they need to increase sales, expand their customer base, and grow their business online.",
          image: "fintech-11",
        },
      ],
    },
    {
      tab: "Media",
      tools: [
        {
          title: "general & targeted advertisements",
          subtitle:
            "Our General & Targeted Advertisements Tool is a powerful platform that enables businesses to create and run effective advertising campaigns. The software allows direct engagement with consumers of targeted ads and helps businesses in data collection.",
          image: "media-1",
        },
        {
          title: "kyc manager",
          subtitle:
            "Our solution offers a variety of verification methods, including biometric data, document verification, and identity verification through third-party sources.",
          image: "media-2",
        },
        {
          title: "video conferencing",
          subtitle:
            "Our tool enables online communication for audio, video meetings, and seminars, with built-in chat, screen-sharing, and recording allowing remote communication and collaboration between teams and individuals.",
          image: "media-3",
        },
        {
          title: "podcasts",
          subtitle:
            "The podcast tool is designed to help users easily create, edit, and distribute podcasts to their audience. With its user-friendly interface, advanced editing features, and integration with popular hosting platforms, the tool makes podcast production and management simple and streamlined.",
          image: "media-4",
        },
        {
          title: "video streaming",
          subtitle:
            "Our Video Streaming Tool provides a seamless streaming experience for businesses and content creators. With high-quality video streaming and customizable features, our solution enhances engagement, expands reach, and increases revenue.",
          image: "media-5",
        },
        {
          title: "online games",
          subtitle:
            "Our platform also provides gaming companies with the opportunity to showcase their games and reach a wider audience. We prioritize user satisfaction and ensure the highest standards of security and privacy to offer a safe and trustworthy gaming environment.",
          image: "media-6",
        },
        {
          title: "music streaming",
          subtitle:
            "Our music streaming tool offers a fully customizable and user-friendly platform for businesses to launch their own music streaming service. ",
          image: "media-7",
        },
        {
          title: "Content portal",
          subtitle:
            "Our music streaming tool offers a fully customizable and user-friendly platform for businesses to launch their own music streaming service.",
          image: "media-8",
        },
        {
          title: "community management tool",
          subtitle:
            "This tool enables community managers to monitor and moderate user activity, schedule and publish content, analyze engagement and growth metrics, and collaborate with team members.",
          image: "media-9",
        },
      ],
    },
    {
      tab: "Logistics",
      tools: [
        {
          title: "Fleet Management",
          subtitle:
            "The fleet management software is ideal for businesses of all sizes looking to streamline their fleet operations and maximise their bottom line.",
          image: "logistics-1",
        },
        {
          title: "Access channels",
          subtitle:
            "This tool enables businesses to offer multiple access points for customers and employees to manage and track their shipments and deliveries. ( Mobile app; Web application; USSD & SMS enabled tool)",
          image: "logistics-2",
        },
        {
          title: "Logistics Analytics",
          subtitle:
            "Our tool is a powerful technology that allows businesses to track and analyze their logistics operations, including transportation, warehousing, and inventory management.",
          image: "logistics-3",
        },
        {
          title: "System Defaults",
          subtitle:
            "Our tool allows users to configure and customize the default settings of their software system. (System sub-modules include -Language; Currency; Terminals; Harbours; Depots; Routes; Airlines)",
          image: "logistics-4",
        },
        {
          title: "Quotation and Invoice management",
          subtitle:
            "Our tool not only simplifies the process of creating and managing quotes and invoices for businesses but also allows for easy tracking of payment status and sending of payment reminders, reducing the likelihood of late payments and improving cash flow.",
          image: "logistics-5",
        },
        {
          title: "Payments Integration",
          subtitle:
            "Our payment integration tool provides a secure and seamless way for businesses to accept payments online.",
          image: "logistics-6",
        },
        {
          title: "Tracking management",
          subtitle:
            "Our tracking management tool allows users to monitor and track various assets, including vehicles, packages, and equipment. It provides real-time location information and helps businesses to optimize their operations by providing insights into the movement of assets. ",
          image: "logistics-7",
        },
        {
          title: "Warehouse Management",
          subtitle:
            "A digital tool designed to help businesses streamline and optimize their warehouse operations. Our solution provides a centralised platform for managing inventory levels, tracking product movements, and automating various warehouse tasks.",
          image: "logistics-8",
        },
        {
          title: "System Notifications",
          subtitle:
            "Real-time tracking, alerts, and event management for logistics personnel, customers, and suppliers, ensuring everyone is up-to-date on delivery delays, order confirmations, and shipment updates.",
          image: "logistics-9",
        },
        {
          title: "User Management tool",
          subtitle:
            "A centralized platform to set user roles, permissions, and access levels, as well as to monitor user activities and perform necessary actions such as password resets or account deactivations.",
          image: "logistics-10",
        },
        {
          title: "Booking & Ticket Managements",
          subtitle:
            "Our tool is designed to simplify the process of ticket booking and reservation management for businesses in thelogistics, travel, hospitality, and event industries.",
          image: "logistics-11",
        },
        {
          title: "Clearing and Forwarding",
          subtitle:
            "Our software allows users to track shipments, automate customs clearance procedures, and manage documentation for import and export processes. The tool increases efficiency and reduces costs by optimizing cargo movement and minimizing delays at ports and borders.",
          image: "logistics-12",
        },
        {
          title: "Cargo Agent Mobile appLICATION",
          subtitle:
            "The mobile app is user-friendly, with a simple and intuitive interface, making it easy for cargo agents to access cargo information on the go.",
          image: "logistics-13",
        },
      ],
    },
    {
      tab: "AI",
      tools: [
        {
          title: "AI Credit Scoring Tool",
          subtitle:
            "Our tool uses advanced algorithms & ML techniques to analyze credit risk.",
          image: "ai-1",
        },
        {
          title: "Awareness Health Bot",
          subtitle:
            "Designed to provide users with health-related information and guidance.",
          image: "ai-2",
        },
        {
          title: "AI Maturity Diagnostic Tool",
          subtitle:
            "The tool is a powerful solution for businesses looking to assess their current level of AI readiness.",
          image: "ai-3",
        },
        {
          title: "CustomiZable AI Chatbot",
          subtitle:
            "Our chatbot platform allows users to customize conversation flows & integrate with external systems.",
          image: "ai-4",
        },
        {
          title: "Data pre-processing",
          subtitle:
            "The perfect solution for businesses looking to optimize their data analysis processes.",
          image: "ai-5",
        },
        {
          title: "Data Science services",
          subtitle:
            "We offer a comprehensive suite of services for businesses looking to leverage data analytics & modeling.",
          image: "ai-6",
        },
        {
          title: "AI analytical dashboards",
          subtitle:
            "Our AI analytical dashboards tool is a powerful solution for businesses looking to gain actionable insights.",
          image: "ai-7",
        },
        {
          title: "Intelligent automation",
          subtitle:
            "Our platform combines the power of AI, ML, and robotics to automate repetitive tasks.",
          image: "ai-8",
        },
        {
          title: "Recommendation engines",
          subtitle:
            "Our platform uses advanced algorithms to analyze customer behavior and preferences.",
          image: "ai-9",
        },
        {
          title: "Integration and deployment",
          subtitle:
            "Our tool allows businesses to integrate multiple systems and applications.",
          image: "ai-10",
        },
        {
          title: "Anomaly detection",
          subtitle:
            "With our tool, businesses can proactively identify and address issues & reducing downtime.",
          image: "ai-11",
        },
        {
          title: "Symptoms mapping",
          subtitle:
            "Our Symptoms Mapping tool is an innovative solution for healthcare professionals and researchers.",
          image: "ai-12",
        },
        {
          title: "Customer support",
          subtitle:
            "Our platform enables businesses to provide round-the-clock support & integrate with external systems.",
          image: "ai-13",
        },
        {
          title: "Sales enhancement",
          subtitle:
            "Our tool analyzes customer data, provides personalized recommendations to sales teams.",
          image: "ai-14",
        },
        {
          title: "IOT-AI integration",
          subtitle:
            "Our integration tool enables seamless integration between IOT devices and AI systems.",
          image: "ai-15",
        },
        {
          title: "Detection",
          subtitle:
            "Our Detection tool is designed to help businesses identify and prevent fraudulent activity.",
          image: "ai-16",
        },
      ],
    },
    {
      tab: "blockchain",
      tools: [
        {
          title: "Identity tool",
          subtitle:
            "Designed to help businesses verify the identity of their customers or users & minimize fraud risk",
          image: "btc-1",
        },
        {
          title: "Wallet",
          subtitle:
            "Our wallet is a secure and simple solution that allows clients to manage their funds & digital assets.",
          image: "btc-2",
        },
        {
          title: "Track & trace",
          subtitle:
            "Designed to help businesses track and monitor their products, assets, and inventory in real-time.",
          image: "btc-3",
        },
        {
          title: "Record Management",
          subtitle:
            "Aa reliable & cost-effective solution that offers unparalleled security & transparency.",
          image: "btc-4",
        },
        {
          title: "On-chain verification tool",
          subtitle:
            "Designed to help businesses verify the authenticity and validity of blockchain transactions.",
          image: "btc-5",
        },
        {
          title: "Protocol integration",
          subtitle:
            "Our tool is a powerful solution that enables clients to easily integrate with various blockchain protocols.",
          image: "btc-6",
        },
        {
          title: "Loyalty reward management system",
          subtitle:
            "We provide clients with the tools they need to create and manage customized loyalty programs.",
          image: "btc-7",
        },
        {
          title: "Automation (Smart contracts)",
          subtitle:
            "Our automation tool is a reliable and cost-effective solution that offers unparalleled efficiency.",
          image: "btc-8",
        },
        {
          title: "Storage management (IoT)",
          subtitle:
            "Our solution provides a scalable and secure platform that can handle data from various sources.",
          image: "btc-9",
        },
        {
          title: "NFT generator",
          subtitle:
            "Designed to be highly customizable, allowing clients to create NFTs that are truly unique.",
          image: "btc-10",
        },
        {
          title: "Encipher tool",
          subtitle:
            "Our encipher tool is a reliable and cost-effective solution that offers unparalleled data protection.",
          image: "btc-11",
        },
      ],
    },
    {
      tab: "Erp",
      tools: [
        {
          title: "End-to-end Sales Management tool",
          subtitle:
            "Designed to help clients streamline their sales processes and drive revenue growth.",
          image: "erp-1",
        },
        {
          title: "Inventory Management",
          subtitle:
            "Enables clients to efficiently manage their inventory levels and streamline their supply chain operations.",
          image: "erp-2",
        },
        {
          title: "E - library - Employee L&D",
          subtitle:
            "Clients can easily create and manage personalized learning paths for their employees.",
          image: "erp-3",
        },
        {
          title: "Front-to-back-office process automation",
          subtitle:
            "A solution that enables clients to streamline their business processes using automation.",
          image: "erp-4",
        },
        {
          title: "Payroll",
          subtitle:
            "A comprehensive solution designed to simplify and automate payroll management for clients.",
          image: "erp-5",
        },
        {
          title: "Supply Chain Management",
          subtitle:
            "A full solution, enabling clients to optimize their supply chain operations, from sourcing to distribution.",
          image: "erp-7",
        },
        {
          title: "Business intelligence AND Customized reporting",
          subtitle:
            "A powerful solution that enables clients to gain valuable insights into their business performance.",
          image: "erp-6",
        },
      ],
    },
  ];
  const mobileCards = [
    { image: "Agriculture", tool: "Agriculture" },
    { image: "Medical", tool: "Health" },
    { image: "Finances", tool: "Fintech" },
    { image: "Multimedia", tool: "Service" },
    { image: "logistics", tool: "Logistics" },
    { image: "ai", tool: "AI" },
    { image: "blockchain", tool: "blockchain" },
    { image: "erp", tool: "Erp" },
  ];
  const largeCards = [
    [
      { image: "Agriculture-lg", tool: "Agriculture" },
      { image: "health-lg", tool: "Health" },
      { image: "fintech-lg", tool: "Fintech" },
      { image: "media-lg", tool: "Service" },
      { image: "logistics-active", tool: "Logistics" },
      { image: "ai", tool: "AI" },
      { image: "blockchain", tool: "blockchain" },
      { image: "erp", tool: "Erp" },
    ],
    [
      { image: "Agriculture", tool: "Agriculture" },
      { image: "health-lg-1", tool: "Health" },
      { image: "fintech-lg", tool: "Fintech" },
      { image: "media-lg", tool: "Service" },
      { image: "logistics-active", tool: "Logistics" },
      { image: "ai", tool: "AI" },
      { image: "blockchain", tool: "blockchain" },
      { image: "erp", tool: "Erp" },
    ],
    [
      { image: "Agriculture", tool: "Agriculture" },
      { image: "health-lg", tool: "Health" },
      { image: "fintech-lg-1", tool: "Fintech" },
      { image: "media-lg", tool: "Service" },
      { image: "logistics-active", tool: "Logistics" },
      { image: "ai", tool: "AI" },
      { image: "blockchain", tool: "blockchain" },
      { image: "erp", tool: "Erp" },
    ],
    [
      { image: "Agriculture", tool: "Agriculture" },
      { image: "health-lg", tool: "Health" },
      { image: "fintech-lg", tool: "Fintech" },
      { image: "media-lg-1", tool: "Service" },
      { image: "logistics-active", tool: "Logistics" },
      { image: "ai", tool: "AI" },
      { image: "blockchain", tool: "blockchain" },
      { image: "erp", tool: "Erp" },
    ],
    [
      { image: "Agriculture", tool: "Agriculture" },
      { image: "health-lg", tool: "Health" },
      { image: "fintech-lg", tool: "Fintech" },
      { image: "media-lg", tool: "Service" },
      { image: "logistics", tool: "Logistics" },
      { image: "ai", tool: "AI" },
      { image: "blockchain", tool: "blockchain" },
      { image: "erp", tool: "Erp" },
    ],
    [
      { image: "Agriculture", tool: "Agriculture" },
      { image: "health-lg", tool: "Health" },
      { image: "fintech-lg", tool: "Fintech" },
      { image: "media-lg", tool: "Service" },
      { image: "logistics-active", tool: "Logistics" },
      { image: "ai-active", tool: "AI" },
      { image: "blockchain", tool: "blockchain" },
      { image: "erp", tool: "Erp" },
    ],
    [
      { image: "Agriculture", tool: "Agriculture" },
      { image: "health-lg", tool: "Health" },
      { image: "fintech-lg", tool: "Fintech" },
      { image: "media-lg", tool: "Service" },
      { image: "logistics-active", tool: "Logistics" },
      { image: "ai", tool: "AI" },
      { image: "blockchain-active", tool: "blockchain" },
      { image: "erp", tool: "Erp" },
    ],
    [
      { image: "Agriculture", tool: "Agriculture" },
      { image: "health-lg", tool: "Health" },
      { image: "fintech-lg", tool: "Fintech" },
      { image: "media-lg", tool: "Service" },
      { image: "logistics-active", tool: "Logistics" },
      { image: "ai", tool: "AI" },
      { image: "blockchain", tool: "blockchain" },
      { image: "erp-active", tool: "Erp" },
    ],
  ];
  const toolsMarketPlace=[
    {
      title:"Agriculture",
      subtitle:"Optimise farming practices with innovative technology solutions.",
      icon:"Agriculture-icon",
      link:"Agriculture"
    },
    {
      title:"Health sector",
      subtitle:"Revolutionise healthcare with cutting-edge technology tools",
      icon:"health-icon",
      link:"Health"
    },
    {
      title:"Financial Services",
      subtitle:"Stay ahead in finance with advanced technology tools.",
      icon:"finance-icon",
      link:"Fintech"
    },
    {
      title:"Media Sector",
      subtitle:"Engage audiences with digital innovation in media technology. ",
      icon:"media-icon",
      link:"Service"
    },
    {
      title:"Logistics",
      subtitle:"Enhance logistics operations with our advanced, cost-effective technology solutions",
      icon:"logistics-icon",
      link:"Logistics"
    },
    {
      title:"AI",
      subtitle:"Unlock business potential with powerful AI technology solutions.",
      icon:"ai-icon",
      link:"AI"
    },
    {
      title:"Blockchain",
      subtitle:"Secure transactions and data integrity with cutting-edge blockchain tools",
      icon:"blockchain-icon",
      link:"blockchain"
    },
    {
      title:"ERP",
      subtitle:"Streamline business processes with comprehensive ERP solutions",
      icon:"erp-icon",
      link:"Erp"
    },

  ]
  const url = "/images/tech-tools/";
  if (click) {
    return (
      <section className=" px-[18px] lg:px-[35px] py-10 lg:py-24 t-bg relative tools-anime  h-fit">
        <div className="t-circle"></div>
        <div className="t-circle2"></div>
        <h2 className="t-title text-center ">Tech Tool Showcase</h2>

        <div className="flex items-center text-center justify-center  mb-[15px] mt-[15px] lg:mb-16 lg:my-12">
          <hr className="t-hr"></hr>
        </div>

        {tool === "Agriculture" && (
          <>
            <div className="hidden lg:grid grid-cols-4 gap-[7px] ">
              {largeCards[0].map((item) => (
                <img
                key={item.image}
                  src={
                    "/images/tech-tools/cards/desktop/" + item.image + ".svg"
                  }
                  className=" h-[109px] object-contain"
                  onClick={() => setTools(item.tool)}
                  style={{ zIndex: 5 }}
                ></img>
              ))}
            </div>
            <div className="grid grid-cols-4 gap-3 lg:hidden">
              {mobileCards.map((item) => (
                <div
                key={item.image}
                  className={
                    tool === item.tool
                      ? "z-10 tools-card-mobile-active"
                      : "z-10 tools-card-mobile"
                  }
                  onClick={() => setTools(item.tool)}
                >
                  <img
                    src={
                      "/images/tech-tools/cards/mobile/" + item.image + ".svg"
                    }
                  ></img>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[24px]">
              {items[0].tools.map((item) => (
                <div className="tools-inner-card mt-5 relative" key={item.image}>
                  <div className="flex flex-col justify-between gap-[10px] lg:hidden">
                    <img
                      src={url + item.image + ".svg"}
                      alt="icon"
                      className=""
                    ></img>
                    <a
                      className="sc-tools-btn sc-btn-text  "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <img
                    src={url + item.image + ".svg"}
                    alt="icon"
                    className="hidden lg:block"
                  ></img>
                  <div className="hidden lg:flex flex-col items-start gap-[10px]">
                    <h3 className="card-inner-title">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                    <a
                      className="sc-tools-btn sc-btn-text ml-auto "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <div className="flex flex-col lg:hidden items-start gap-[10px]">
                    <h3 className="card-inner-title">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {tool === "Health" && (
          <>
            <div className="hidden lg:grid grid-cols-4 gap-[7px] ">
              {largeCards[1].map((item) => (
                <img
                key={item.image}
                  src={
                    "/images/tech-tools/cards/desktop/" + item.image + ".svg"
                  }
                  className=""
                  onClick={() => setTools(item.tool)}
                  style={{ zIndex: 5 }}
                ></img>
              ))}
            </div>
            <div className="grid grid-cols-4 gap-3 lg:hidden">
              {mobileCards.map((item) => (
                <div
                key={item.image}
                  className={
                    tool === item.tool
                      ? "z-10 tools-card-mobile-active"
                      : "z-10 tools-card-mobile"
                  }
                  onClick={() => setTools(item.tool)}
                >
                  <img
                    src={
                      "/images/tech-tools/cards/mobile/" + item.image + ".svg"
                    }
                  ></img>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[24px]">
              {items[1].tools.map((item) => (
                <div className="tools-inner-card mt-5 relative" key={item.image}>
                  <div className="flex flex-col justify-between gap-[10px] lg:hidden">
                    <img
                      src={url + item.image + ".svg"}
                      alt="icon"
                      className=""
                    ></img>
                    <a
                      className="sc-tools-btn sc-btn-text  "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <img
                    src={url + item.image + ".svg"}
                    alt="icon"
                    className="hidden lg:block"
                  ></img>
                  <div className="hidden lg:flex flex-col items-start gap-[10px]">
                    <h3 className="card-inner-title">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                    <a
                      className="sc-tools-btn sc-btn-text ml-auto "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <div className="flex flex-col lg:hidden items-start gap-[10px]">
                    <h3 className="card-inner-title">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {tool === "Fintech" && (
          <>
            <div className="hidden lg:grid grid-cols-4 gap-[7px] ">
              {largeCards[2].map((item) => (
                <img
                key={item.image}
                  src={
                    "/images/tech-tools/cards/desktop/" + item.image + ".svg"
                  }
                  className=""
                  onClick={() => setTools(item.tool)}
                  style={{ zIndex: 5 }}
                ></img>
              ))}
            </div>
            <div className="grid grid-cols-4 gap-3 lg:hidden">
              {mobileCards.map((item) => (
                <div
                key={item.image}
                  className={
                    tool === item.tool
                      ? "z-10 tools-card-mobile-active"
                      : "z-10 tools-card-mobile"
                  }
                  onClick={() => setTools(item.tool)}
                >
                  <img
                    src={
                      "/images/tech-tools/cards/mobile/" + item.image + ".svg"
                    }
                  ></img>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[24px]">
              {items[2].tools.map((item) => (
                <div className="tools-inner-card mt-5 relative" key={item.image}>
                  <div className="flex flex-col justify-between gap-[10px] lg:hidden">
                    <img
                      src={url + item.image + ".svg"}
                      alt="icon"
                      className=""
                    ></img>
                    <a
                      className="sc-tools-btn sc-btn-text  "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <img
                    src={url + item.image + ".svg"}
                    alt="icon"
                    className="hidden lg:block"
                  ></img>
                  <div className="hidden lg:flex flex-col items-start gap-[10px]">
                    <h3 className="card-inner-title">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                    <a
                      className="sc-tools-btn sc-btn-text ml-auto "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <div className="flex flex-col lg:hidden items-start gap-[10px]">
                    <h3 className="card-inner-title">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {tool === "Service" && (
          <>
            <div className="hidden lg:grid grid-cols-4 gap-[7px] ">
              {largeCards[3].map((item) => (
                <img
                key={item.image}
                  src={
                    "/images/tech-tools/cards/desktop/" + item.image + ".svg"
                  }
                  className=""
                  onClick={() => setTools(item.tool)}
                  style={{ zIndex: 5 }}
                ></img>
              ))}
            </div>
            <div className="grid grid-cols-4 gap-3 lg:hidden">
              {mobileCards.map((item) => (
                <div
                key={item.image}
                  className={
                    tool === item.tool
                      ? "z-10 tools-card-mobile-active"
                      : "z-10 tools-card-mobile"
                  }
                  onClick={() => setTools(item.tool)}
                >
                  <img
                    src={
                      "/images/tech-tools/cards/mobile/" + item.image + ".svg"
                    }
                  ></img>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[24px]">
              {items[3].tools.map((item) => (
                <div className="tools-inner-card mt-5 relative" key={item.image}>
                  <div className="flex flex-col justify-between gap-[10px] lg:hidden">
                    <img
                      src={url + item.image + ".svg"}
                      alt="icon"
                      className=""
                    ></img>
                    <a
                      className="sc-tools-btn sc-btn-text  "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <img
                    src={url + item.image + ".svg"}
                    alt="icon"
                    className="hidden lg:block"
                  ></img>
                  <div className="hidden lg:flex flex-col items-start gap-[10px]">
                    <h3 className="card-inner-title">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                    <a
                      className="sc-tools-btn sc-btn-text ml-auto "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <div className="flex flex-col lg:hidden items-start gap-[10px]">
                    <h3 className="card-inner-title ">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {tool === "Logistics" && (
          <>
            <div className="hidden lg:grid grid-cols-4 gap-[7px] ">
              {largeCards[4].map((item) => (
                <img
                key={item.image}
                  src={
                    "/images/tech-tools/cards/desktop/" + item.image + ".svg"
                  }
                  className=""
                  onClick={() => setTools(item.tool)}
                  style={{ zIndex: 5 }}
                ></img>
              ))}
            </div>
            <div className="grid grid-cols-4 gap-3 lg:hidden">
              {mobileCards.map((item) => (
                <div
                key={item.image}
                  className={
                    tool === item.tool
                      ? "z-10 tools-card-mobile-active"
                      : "z-10 tools-card-mobile"
                  }
                  onClick={() => setTools(item.tool)}
                >
                  <img
                    src={
                      "/images/tech-tools/cards/mobile/" + item.image + ".svg"
                    }
                  ></img>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[24px]">
              {items[4].tools.map((item) => (
                <div className="tools-inner-card mt-5 relative" key={item.image}>
                  <div className="flex flex-col justify-between gap-[10px] lg:hidden">
                    <img
                      src={url + item.image + ".svg"}
                      alt="icon"
                      className=""
                    ></img>
                    <a
                      className="sc-tools-btn sc-btn-text  "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <img
                    src={url + item.image + ".svg"}
                    alt="icon"
                    className="hidden lg:block"
                  ></img>
                  <div className="hidden lg:flex flex-col items-start gap-[10px]">
                    <h3 className="card-inner-title">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                    <a
                      className="sc-tools-btn sc-btn-text ml-auto "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <div className="flex flex-col lg:hidden items-start gap-[10px]">
                    <h3 className="card-inner-title ">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {tool === "AI" && (
          <>
            <div className="hidden lg:grid grid-cols-4 gap-[7px] ">
              {largeCards[5].map((item) => (
                <img
                key={item.image}
                  src={
                    "/images/tech-tools/cards/desktop/" + item.image + ".svg"
                  }
                  className=""
                  onClick={() => setTools(item.tool)}
                  style={{ zIndex: 5 }}
                ></img>
              ))}
            </div>
            <div className="grid grid-cols-4 gap-3 lg:hidden">
              {mobileCards.map((item) => (
                <div
                key={item.image}
                  className={
                    tool === item.tool
                      ? "z-10 tools-card-mobile-active"
                      : "z-10 tools-card-mobile"
                  }
                  onClick={() => setTools(item.tool)}
                >
                  <img
                    src={
                      "/images/tech-tools/cards/mobile/" + item.image + ".svg"
                    }
                  ></img>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[24px]">
              {items[5].tools.map((item) => (
                <div className="tools-inner-card mt-5 relative" key={item.image}>
                  <div className="flex flex-col justify-between gap-[10px] lg:hidden">
                    <img
                      src={url + item.image + ".svg"}
                      alt="icon"
                      className=""
                    ></img>
                    <a
                      className="sc-tools-btn sc-btn-text  "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <img
                    src={url + item.image + ".svg"}
                    alt="icon"
                    className="hidden lg:block"
                  ></img>
                  <div className="hidden lg:flex flex-col items-start gap-[10px]">
                    <h3 className="card-inner-title">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                    <a
                      className="sc-tools-btn sc-btn-text ml-auto "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <div className="flex flex-col lg:hidden items-start gap-[10px]">
                    <h3 className="card-inner-title ">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {tool === "blockchain" && (
          <>
            <div className="hidden lg:grid grid-cols-4 gap-[7px] ">
              {largeCards[6].map((item) => (
                <img
                key={item.image}
                  src={
                    "/images/tech-tools/cards/desktop/" + item.image + ".svg"
                  }
                  className=""
                  onClick={() => setTools(item.tool)}
                  style={{ zIndex: 5 }}
                ></img>
              ))}
            </div>
            <div className="grid grid-cols-4 gap-3 lg:hidden">
              {mobileCards.map((item) => (
                <div
                key={item.image}
                  className={
                    tool === item.tool
                      ? "z-10 tools-card-mobile-active"
                      : "z-10 tools-card-mobile"
                  }
                  onClick={() => setTools(item.tool)}
                >
                  <img
                    src={
                      "/images/tech-tools/cards/mobile/" + item.image + ".svg"
                    }
                  ></img>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[24px]">
              {items[6].tools.map((item) => (
                <div className="tools-inner-card mt-5 relative" key={item.image}>
                  <div className="flex flex-col justify-between gap-[10px] lg:hidden">
                    <img
                      src={url + item.image + ".svg"}
                      alt="icon"
                      className=""
                    ></img>
                    <a
                      className="sc-tools-btn sc-btn-text  "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <img
                    src={url + item.image + ".svg"}
                    alt="icon"
                    className="hidden lg:block"
                  ></img>
                  <div className="hidden lg:flex flex-col items-start gap-[10px]">
                    <h3 className="card-inner-title">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                    <a
                      className="sc-tools-btn sc-btn-text ml-auto "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <div className="flex flex-col lg:hidden items-start gap-[10px]">
                    <h3 className="card-inner-title ">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {tool === "Erp" && (
          <>
            <div className="hidden lg:grid grid-cols-4 gap-[7px] ">
              {largeCards[7].map((item) => (
                <img
                key={item.image}
                  src={
                    "/images/tech-tools/cards/desktop/" + item.image + ".svg"
                  }
                  className=""
                  onClick={() => setTools(item.tool)}
                  style={{ zIndex: 5 }}
                ></img>
              ))}
            </div>
            <div className="grid grid-cols-4 gap-3 lg:hidden">
              {mobileCards.map((item) => (
                <div
                key={item.image}
                  className={
                    tool === item.tool
                      ? "z-10 tools-card-mobile-active"
                      : "z-10 tools-card-mobile"
                  }
                  onClick={() => setTools(item.tool)}
                >
                  <img
                    src={
                      "/images/tech-tools/cards/mobile/" + item.image + ".svg"
                    }
                  ></img>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[24px]">
              {items[7].tools.map((item) => (
                <div className="tools-inner-card mt-5 relative" key={item.image}>
                  <div className="flex flex-col justify-between gap-[10px] lg:hidden">
                    <img
                      src={url + item.image + ".svg"}
                      alt="icon"
                      className=""
                    ></img>
                    <a
                      className="sc-tools-btn sc-btn-text  "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <img
                    src={url + item.image + ".svg"}
                    alt="icon"
                    className="hidden lg:block"
                  ></img>
                  <div className="hidden lg:flex flex-col items-start gap-[10px]">
                    <h3 className="card-inner-title">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                    <a
                      className="sc-tools-btn sc-btn-text ml-auto "
                      href="submission-form"
                    >
                      Request Info
                    </a>
                  </div>
                  <div className="flex flex-col lg:hidden items-start gap-[10px]">
                    <h3 className="card-inner-title ">{item.title}</h3>
                    <p className="card-inner-subtitle">{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </section>
    );
  } else {
    return (
      <section>
        <div className="container px-6 lg:px-28 py-10 lg:py-24  mx-auto relative">
          <div className="t-circle"></div>
          <div className="t-circle2"></div>
          <h2 className="t-title text-center ">ADANIA - TECH TOOLS MARKETPLACE</h2>


          <div className="flex items-center text-center justify-center mb-16 mt-[23px]  lg:my-12">
            <hr className="t-hr"></hr>
          </div>
          <p className="z-10 mb-4">
            Adania Tools are built to seamlessly integrate with various business
            processes, enabling organizations to digitize operations and unlock
            new levels of efficiency. Whether it's streamlining supply chain
            management, optimizing financial services, integrating sme’s,
            harnessing the potential of data analytics, or enhancing customer
            integration, Adania solutions offer comprehensive tools that cater
            to the distinct requirements of different industries.
          </p>
          <div className="grid grid-cols-1 gap-8 lg:mt-8 xl:mt-12 xl:gap-12 lg:grid-cols-2">
            {toolsMarketPlace.map((tool)=>(
              <div key={tool.title}>
            <div className="box hidden md:flex  gap-[36px] py-[56px] pl-[32px]"   onClick={() => handleClick(tool.link)}>
            <img src={'/images/tech-tools/icons/'+tool.icon+'.svg'}></img>
            <div>
              <h2>{tool.title}</h2>
              <h3>{tool.subtitle} </h3>
              <a  onClick={() => handleClick(tool.link)} className="mt-8">Click for more</a>
            </div>
          </div>
          <div className="box block md:hidden py-[56px] pl-[32px]" onClick={() => handleClick(tool.link)}>
           
            <div className="flex mb-4 gap-6">
            <img src={'/images/tech-tools/icons/'+tool.icon+'.svg'}></img>
            <h2>{tool.title}</h2>
            </div>
            <div>
              
              <h3>{tool.subtitle} </h3>
              <a  onClick={() => handleClick(tool.link)} className="mt-8">Click for more</a>
            </div>
          </div>
              </div>
 
            ))}

          </div>
        </div>
      </section>
    );
  }
};

export default ToolsShowcase;
