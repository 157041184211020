export const footerSocialLinks = [
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/adanianlabs/mycompany/?viewAsMember=true",
  },
  {
    name: "telegram",
    link: "https://t.me/+xrJH8w4Xxw04MTc8",
  },
  {
    name: "twitter",
    link: "https://twitter.com/Adanianlabs",
  },
  {
    name: "facebook",
    link: "https://www.facebook.com/adanianlabsafrica",
  },
  {
    name: "youtube",
    link: "https://www.youtube.com/channel/UCOeKw9V96Ijfxq72ifimQmg",
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/adanianlabs/",
  },
];

export const footerPageLinks = [
  [
    { name: "Home", href: "/" },
    { name: "Tech Tools", href: "/tech-tools" },
    { name: "Venture Building", href: "/venture-building" },
    { name: "Capacity Building", href: "/capacity-building" },
    { name: "PDI Model", href: "/pdi-model" },
  ],

  [
    { name: "Submission Form", href: "/submission-form" },
    { name: "Incubation Process", href: "/incubation-process" },
    { name: "A Borderless Africa", href: "/borderless-africa" },
    { name: "Content Portal", href: "/content-portal" },
    { name: "The Studio", href: "/studio" },
   
  ],
  [
    { name: "Support & FAQ", href: "/contact-us" },
    { name: "Contact Us", href: "/contact-us" },
    { name: "Privacy Policy", href: "/privacyPolicy" },
    { name: "Affiliate Partners", href: "/affiliatePartners" },
    { name: "Borderless Labs", href: "/borderless-lab" },
  ],
];

export const logos = ["abc", "afyarecord", "ada", "agricap", "Tausi", "twaa", "paylend"];

export const navLinks = [
  ["Venture Building", "/venture-building"],
  ["Content Portal", "/content-portal"],
  ["Contact", "/contact-us"],
];

export const trainingDevelopmentData =[
  {
    stat:'5,000',
    title:'Animators'
  },
  {
    stat:'10,000 ',
    title:'Artificial Intelligence Engineers'
  },
  {
    stat:'1 million',
    title:' Software Developers'
  },
  {
    stat:'10,000',
    title:'Blockchain Developers'
  },
]

export const abcTraction=[
  {
    stat:'5 ',
    title:'African Markets'
  },
  {
    stat:'5000+',
    title:'community members '
  },
  {
    stat:'30',
    title:'Projects'
  },
  {
    stat:'24',
    title:'Employess'
  },

 
]