import { useState } from "react";
import Navbar from "../navbar";
import { ReactComponent as Carousel1 } from "./ipbutton.svg";
import { ReactComponent as Carousel2 } from "./carousel-1.svg";
import { ReactComponent as Carousel3 } from "./carousel-2.svg";
import { ReactComponent as Carousel4 } from "./carousel-3.svg";
import { ReactComponent as Carousel5 } from "./carousel-7.svg";
import { ReactComponent as Carousel6 } from "./carousel-5.svg";
import { ReactComponent as Carousel7 } from "./carousel-6.svg";
const IncubationHero = () => {
  const [slide, setSlide] = useState(0);
  const handleClick = () => {
    if (slide <= 3) {
      setSlide(slide + 1);
    }
  };

  return (
    <section>
      <div className="container mx-auto bg-center bg-cover overflow-hidden pt-10 lg:pt-20 px-5 lg:px-20">
        <div className="inc-hero-circle"></div>
        <div className="inc-hero-circle-1"></div>
        <h1 className="inc-hero-title mb-2">
          Our Incubation Model
        </h1>
        <hr className="inc-hero-hr"></hr>
        {slide === 0 && (
          <div className="flex flex-col md:flex-row gap-5 justify-between py-10">
            <Carousel1
              className="inc-hero-image z-10 relative"
              onClick={handleClick}
            >
                    <button
              onClick={handleClick}
              className="click-text hidden lg:block z-40 absolute top-40 left-40"
            >
              Click
            </button>
            </Carousel1>
      

          <div className="space-y-4">
          <article className="inc-hero-subtitle">
              <p className="mb-[18px] inc-hero-desc">
                A dynamic ecosystem to Innovate, Nurture Launch and Scale
                disruptive solutions.
              </p>
              <p className="inc-hero-desc">
                Adanian Labs distinguishes itself in the incubation process
                through our focused approach to provide advisory services to our
                startups, technology teams to build their solutions, support
                functions to build frameworks for structures and processes and
                fund raising.
              </p>
            </article>

            <button className="inc-btn-cta btn-text" onClick={handleClick}>
              FInd out more
            </button>
            <a className="inc-btn-cta-1 find-btn-text" href="submission-form">
              Contact us to begin your journey
            </a>
          </div>
          </div>
        )}
        {slide === 1 && (
          <>
            <Carousel2
              className="inc-hero-image z-10"
              onClick={() => {
                setSlide(2);
              }}
            ></Carousel2>
            <img
              src="images/incubation-process/back-arrow.svg"
              alt="imge"
              className="absolute  top-[538px] left-[48px] lg:top-[545px] lg:left-[168px] w-[60px] h-[60px] z-10"
              onClick={() => {
                setSlide(0);
              }}
            ></img>
            <p className="inc-hero-title absolute top-[146px] left-[43.59px] lg:left-[796px] lg:top-[108px]">
              Month 1
            </p>

            <hr className="absolute top-[182px] lg:top-[176px] left-[43px] lg:left-[794px] w-[86px] lg:w-[140px] h-[796px]"></hr>
            <ul className="absolute  top-[190px] left-[35px] lg:left-[794px] lg:top-[196px] list-disc">
              <li className="list-text">Pitching</li>
              <li className="list-text">Due Dilligence</li>
              <li className="list-text">Planning</li>
              <li className="list-text">On boarding</li>
            </ul>
            <p className="carousel-2-text absolute  top-[308px] left-[11px] lg:top-[325px] lg:left-[793px]   w-[372px] lg:w-[511px]">
              On our first month we get to listen to your pitch, if it aligns
              with our mission and vision and it has a solid business case, we
              proceed to legal compliance, due diligence, plan on deliverables
              and get your startup onboarded.
            </p>
          </>
        )}
        {slide === 2 && (
          <>
            <Carousel3
              className="inc-hero-image z-10"
              onClick={() => {
                setSlide(3);
              }}
            ></Carousel3>
            <img
              src="images/incubation-process/back-arrow.svg"
              alt="imge"
              className="absolute  top-[538px] left-[48px] lg:top-[545px] lg:left-[168px] w-[60px] h-[60px] z-10"
              onClick={() => {
                setSlide(1);
              }}
            ></img>
            <p className="inc-hero-title absolute  top-[128px] left-[43.59px] lg:left-[796px] lg:top-[108px]">
              Months 2&3
            </p>

            <hr className="absolute top-[165px] left-[42px] lg:top-[176px] lg:left-[794px] w-[140px] h-[796px]"></hr>
            <ul className="absolute top-[180px] left-[35px] lg:left-[794px] lg:top-[196px] list-disc">
              <li className="list-text">Design Thinking</li>
              <li className="list-text">Tech Architecture</li>
              <li className="list-text">Training</li>
              <li className="list-text">Team Assignment</li>
              <li className="list-text">Mentorship</li>
              <li className="list-text">Tech Development</li>
            </ul>
            <p className="carousel-2-text absolute  top-[345px] left-[11px] lg:top-[385px] lg:left-[793px] w-[372px] lg:w-[511px] ">
              The second phase involves design thinking on your solution, which
              is followed by relevant tech training, assignment of startup team
              and the commencement of the product development. During this
              period you are assigned to a mentor.
            </p>
          </>
        )}
        {slide === 3 && (
          <>
            <Carousel4
              className="inc-hero-image z-10"
              onClick={() => {
                setSlide(4);
              }}
            ></Carousel4>
            <img
              src="images/incubation-process/back-arrow.svg"
              alt="imge"
              className="absolute  top-[538px] left-[48px] lg:top-[545px] lg:left-[168px] w-[60px] h-[60px] z-10"
              onClick={() => {
                setSlide(2);
              }}
            ></img>
            <p className="inc-hero-title absolute  top-[128px] left-[43.59px] lg:left-[796px] lg:top-[108px]">
              Months 4&5
            </p>

            <hr className="absolute top-[165px] left-[42px] lg:top-[176px] lg:left-[794px] w-[140px] h-[796px]"></hr>
            <ul className="absolute top-[180px] left-[35px] lg:left-[794px] lg:top-[196px] list-disc">
              <li className="list-text">MVP finalization</li>
              <li className="list-text">Branding and marketing</li>
              <li className="list-text">Validation</li>
              <li className="list-text">Go To Market strategies</li>
              <li className="list-text">Pitch Readiness</li>
            </ul>
            <p className="carousel-2-text absolute  top-[325px] left-[11px] lg:top-[380px] lg:left-[793px] w-[372px] lg:w-[511px]">
              On this phase we push to finish the MVP and run validation models
              to ensure everything makes sense. The branding team supports you
              on crafting a strong brand purpose and preparing a branding kit
              while the advisory team works with your team to prepare get you
              pitch ready.
            </p>
          </>
        )}
        {slide === 5 && (
          <>
            <Carousel6
              className="inc-hero-image z-10"
              onClick={() => {
                setSlide(6);
              }}
            ></Carousel6>
            <img
              src="images/incubation-process/back-arrow.svg"
              alt="imge"
              className="absolute  top-[538px] left-[48px] lg:top-[545px] lg:left-[168px] w-[60px] h-[60px] z-10"
              onClick={() => {
                setSlide(4);
              }}
            ></img>
            <p className="inc-hero-title absolute  top-[128px] left-[43.59px] lg:left-[796px] lg:top-[108px]">
              Months 7&8
            </p>

            <hr className="absolute top-[165px] left-[42px] lg:top-[176px] lg:left-[794px] w-[140px] h-[796px]"></hr>
            <ul className="absolute top-[180px] left-[35px] lg:left-[794px] lg:top-[196px] list-disc">
              <li className="list-text">Beta Roll Out</li>
              <li className="list-text">Investor Checklist</li>
              <li className="list-text">Iterations</li>
              <li className="list-text">Marketing</li>
            </ul>
            <p className="carousel-2-text absolute  top-[312px] left-[11px] lg:top-[355px] lg:left-[793px] w-[372px] lg:w-[511px]">
              On this phase we push to finish the MVP and run validation models
              to ensure everything makes sense. The branding team supports you
              on crafting a strong brand purpose and preparing a branding kit
              while the advisory team works with your team to prepare get you
              pitch ready.
            </p>
          </>
        )}
        {slide === 4 && (
          <>
            <Carousel5
              className="inc-hero-image z-10"
              onClick={() => {
                setSlide(5);
              }}
            ></Carousel5>

            <img
              src="images/incubation-process/back-arrow.svg"
              alt="imge"
              className="absolute  top-[538px] left-[48px] lg:top-[545px] lg:left-[168px] w-[60px] h-[60px] z-10"
              onClick={() => {
                setSlide(3);
              }}
            ></img>
            <p className="inc-hero-title absolute top-[128px] left-[43.59px] lg:left-[796px] lg:top-[108px]">
              Months 6
            </p>

            <hr className="absolute top-[165px] left-[42px] lg:top-[176px] lg:left-[794px] w-[140px] h-[796px]"></hr>
            <ul className="absolute top-[180px] left-[35px] lg:left-[794px] lg:top-[196px] list-disc">
              <li className="list-text">Pitching to Investors</li>
              <li className="list-text">Marketing Product</li>
              <li className="list-text">Media Appearances</li>
              <li className="list-text">Partnership Drive</li>
              <li className="list-text">Beta Tests</li>
            </ul>
            <p className="carousel-2-text absolute  top-[335px] left-[11px] lg:top-[380px] lg:left-[793px] w-[372px] lg:w-[511px]">
              On this phase you will be pitching to investors, interacting with
              the media, running beta tests with real consumers and securing
              partnerships for your startup. If there is any needs for
              iterations this is when to discover them.
            </p>
          </>
        )}
        {slide === 6 && (
          <>
            <Carousel6
              className="inc-hero-image z-10"
              onClick={() => {
                setSlide(7);
              }}
            ></Carousel6>
            <img
              src="images/incubation-process/back-arrow.svg"
              alt="imge"
              className="absolute  top-[538px] left-[48px] lg:top-[545px] lg:left-[168px] w-[60px] h-[60px] z-10"
              onClick={() => {
                setSlide(5);
              }}
            ></img>
            <p className="inc-hero-title absolute  top-[128px] left-[43.59px] lg:left-[796px] lg:top-[108px]">
              Months 9&10
            </p>

            <hr className="absolute top-[165px] left-[42px] lg:top-[176px] lg:left-[794px] w-[140px] h-[796px]"></hr>
            <ul className="absolute top-[180px] left-[35px] lg:left-[794px] lg:top-[196px] list-disc">
              <li className="list-text">Exhibitions</li>
              <li className="list-text">Mentorship</li>
              <li className="list-text">New Markets Pilots</li>
            </ul>
            <p className="carousel-2-text absolute  top-[305px] left-[11px] lg:top-[340px] lg:left-[793px] w-[372px] lg:w-[511px]">
              At this phase you will be exhibiting your solution through
              relevant platforms, undergoing a leadership mentorship program,
              and running mini pilots in new markets to test product
              scale-ability. You should be either closing a seed round and/or
              enjoying you initial revenues.
            </p>
          </>
        )}
        {slide === 7 && (
          <>
            <Carousel7 className="inc-hero-image z-10"></Carousel7>
            <img
              src="images/incubation-process/back-arrow.svg"
              alt="imge"
              className="absolute  top-[538px] left-[48px] lg:top-[545px] lg:left-[168px] w-[60px] h-[60px] z-10"
              onClick={() => {
                setSlide(6);
              }}
            ></img>
            <p className="inc-hero-title absolute  top-[128px] left-[43.59px] lg:left-[796px] lg:top-[108px]">
              Months 11&12
            </p>

            <hr className="absolute top-[165px] left-[42px] lg:top-[176px] lg:left-[794px] w-[140px] h-[796px]"></hr>
            <ul className="absolute top-[180px] left-[35px] lg:left-[794px] lg:top-[196px] list-disc">
              <li className="list-text">Networking</li>
              <li className="list-text">Certifications</li>
              <li className="list-text">Media and PR</li>
              <li className="list-text">Monitoring and Evaluation</li>
              <li className="list-text">Scale Planning</li>
            </ul>
            <p className="carousel-2-text absolute  top-[310px] left-[11px] lg:top-[380px] lg:left-[793px] w-[372px] lg:w-[511px]">
              At this phase you will be exhibiting your solution through
              relevant platforms, undergoing a leadership mentorship program,
              and running mini pilots in new markets to test product
              scale-ability. You should be either closing a seed round and/or
              enjoying you initial revenues.
            </p>
            <a
              className="btn-13 find-btn-text absolute top-[559px] left-[793px]"
              href="submission-form"
            >
              Contact us to begin your journey
            </a>
          </>
        )}
        <div className="bottom-rect"></div>
      </div>
    </section>
  );
};

export default IncubationHero;
