import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
const VideoSection = () => {
  const items = [
    <div className="w-full h-full flex items-center justify-center">
      <div
        className="video-overlay lg:w-9/12 w-full mt-12 h-[210px] md:h-[560px]"
        style={{ zIndex: "2" }}
      >
        <iframe
          className="w-full h-full"
          src="https://www.youtube.com/embed/fmu8YDLbMjU?enablejsapi=1&preload=auto"
          title="The AI Chat - Exploring AI Adoption and Transformative Value for Africa with John Kamara"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>,
    <div className="w-full h-full flex items-center justify-center">
      <div
        className="video-overlay lg:w-9/12 w-full mt-12 h-[210px] md:h-[560px]"
        style={{ zIndex: "2" }}
      >
        <iframe
          className="w-full h-full"
          src="https://www.youtube.com/embed/0ZgEpux2s-o?enablejsapi=1&preload=auto"
          title="AfyaRekod Pitch"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          
        ></iframe>
      </div>
    </div>,
    <div className="w-full h-full flex items-center justify-center">
      <div
        className="video-overlay lg:w-9/12 w-full mt-12 h-[210px] md:h-[560px]"
        style={{ zIndex: "2" }}
      >
        <iframe
          className="w-full h-full"
          src="https://www.youtube.com/embed/fmu8YDLbMjU?enablejsapi=1&preload=auto"
          title="The AI Chat - Exploring AI Adoption and Transformative Value for Africa with John Kamara"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>,
  ];
  return (
    <section>
      <div className="container lg:px-10 px-3 lg:py-24 py-8 mx-auto video-bg">
        <div></div>
        <div className="video-blue hidden lg:block"></div>
        <div className="video-orange1 hidden lg:block"></div>
        <div className="video-orange hidden lg:block"></div>
        <div className="video-blue1 hidden lg:block"></div>
        <AliceCarousel
          mouseTracking
          items={items}
          autoHeight={true}
          autoWidth={true}
        />
      </div>
    </section>
  );
};

export default VideoSection;
