import { useNavigate } from "react-router-dom";
import { ReactComponent as SmButton } from "./Smbutton.svg";
const StartupSection = () => {
  const navigate =useNavigate()
  
  return (
    <section>
      <div className="container mx-auto px-3 lg:px-32 lg:py-24 py-5 startup-bg  relative h-fit overflow-x-hidden">
        <div className="startup-circle"></div>
        <div className="startup-carousel py-16 z-10 mb-8 lg:mb-16 h-fit">
          <h1 className="text-center  capitalize statups-title mb-8">
            40+ Startups across multiple industries
          </h1>
          <a href="/our-startups" aria-label="learn more about adanianlabs startups">
            <div className="marquee hidden lg:block">
              <div className="marquee__inner-startup" aria-hidden="true">
                <img
                  src="images/startups/afyarecord.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/twaa.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/ada.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>

                <img
                  src="images/startups/paylend.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/ecoba-1.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/abc.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/agricap.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/Cryptosasa.svg"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/Frontia.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/Jobzy.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/jungo.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/mjenzi-1.png"
                  className="mr-10 h-[81px] w-[210px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/Ngamea.svg"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/serapay-1.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="pages/content-portal/startups/tausi-logo.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/basira-1.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/bizzyn.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/ecafrica.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>

                <img
                  src="images/startups/frontlix.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/keibala.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/mamlaka.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/stich.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/afyarecord.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/twaa.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/jobzy.svg"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
              </div>
            </div>
          </a>
          <a href="/our-startups" aria-label="learn more about adanianlabs startups">
          <div className="marquee block lg:hidden  pt-8 ">
            <div className="marquee__inner" aria-hidden="true">
            <img
                  src="images/startups/afyarecord.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/twaa.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/ada.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>

                <img
                  src="images/startups/paylend.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/ecoba-1.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/abc.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/agricap.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/Cryptosasa.svg"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/Frontia.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/Jobzy.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/jungo.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/mjenzi-1.png"
                  className="mr-10 h-[81px] w-[210px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/Ngamea.svg"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/serapay-1.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="pages/content-portal/startups/tausi-logo.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/basira-1.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/bizzyn.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/ecafrica.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>

                <img
                  src="images/startups/frontlix.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/keibala.png"
                  className="mr-10 h-[81px] object-contain"
                ></img>
                <img
                  src="images/startups/mamlaka.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/stich.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/afyarecord.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/twaa.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/jobzy.svg"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
            </div>
            <div className="marquee__second my-4" aria-hidden="true">
            <img
                  src="images/startups/Frontia.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/Jobzy.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/jungo.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/mjenzi-1.png"
                  className="mr-10 h-[81px] w-[210px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/Ngamea.svg"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/serapay-1.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="/pages/content-portal/startups/tausi-logo.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/basira-1.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/bizzyn.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/ecafrica.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>

                <img
                  src="images/startups/frontlix.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/keibala.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/mamlaka.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/stich.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/afyarecord.png"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/twaa.png"
                  className="mr-10  h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
                <img
                  src="images/startups/jobzy.svg"
                  className="mr-10 h-[81px] object-contain"
                  loading="lazy" alt="Adanian Labs" 
                ></img>
            </div>
          </div></a>
        </div>
        <button  onClick={()=>{navigate('/leadershipTeam')}} className=" w-full flex flex-col md:flex-row lg:items-center justify-center gap-5" aria-label="learn more about adanianlabs startups">
          <div><div className="startup-btn-sect"></div>
          <img src="/images/home/leadership.png" className="block lg:hidden" loading="lazy" alt="Adanian Labs" ></img></div>
         <a href="/affiliatePartners"> <img src="/images/partners/button.png" loading="lazy" alt="Adanian Labs" aria-label="learn more about adanianlabs startups"></img></a>
         
        </button>
      </div>
    </section>
  );
};

export default StartupSection;
