import { useState } from "react";
import { faqs } from "../data/contact-us";
const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <section className="relative mt-12 md:mt-0 md:pb-20 md:pt-11 px-5" id="faqs">
      <div className="absolute inset-0 h-1 w-4/5 -translate-x-6 bg-gradient-to-r from-[#F39711] to-[#F54C11] md:w-3/5" />

      <h1 className="pt-6 text-center text-32 md:text-40">
        <span className="bg-gradient-to-r from-[#F39711] to-[#F54C11] bg-clip-text text-transparent">
          Frequently Asked <br /> Questions
        </span>
      </h1>

      <div className="flex flex-col md:flex-row md:pl-14 md:pt-4 md:pr-52">
        <img
          src="/pages/contact-us/faq.png"
          alt=""
          className="py-7 md:hidden object-contain"
        />
        <img
          src="/pages/contact-us/faq-md.png"
          alt=""
          className="hidden pr-24 md:block object-contain"
        />
        <ul className="space-y-5 pt-7 pb-10 lg:pb-0 w-full">
          {faqs.map((faq, index) => (
            <div key={index} className="">
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                <h3 className="text-lg md:text-[18px] font-medium ">
                  <span className="mr-2">{index + 1}.</span>
                  {faq.question}
                </h3>
                {activeIndex === index && (
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.99951 17.75L0.249512 16L7.24951 9L0.249512 2L1.99951 0.25L8.99951 7.25L15.9995 0.25L17.7495 2L10.7495 9L17.7495 16L15.9995 17.75L8.99951 10.75L1.99951 17.75Z"
                      fill="#F39711"
                    />
                  </svg>
                )}
                {activeIndex !== index && (
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.74976 17.749V10.249H0.249756V7.74902H7.74976V0.249023H10.2498V7.74902H17.7498V10.249H10.2498V17.749H7.74976Z"
                      fill="#F39711"
                    />
                  </svg>
                )}
              </div>
              {activeIndex === index && (
                <div className="w-full mt-3">
                  {Array.isArray(faq.answers) && (
                    <ul className="list-outside list-disc pl-8">
                      {faq.answers.map((answer) => (
                        <li key={answer} className="md:text-20 w-full">
                          {answer}
                        </li>
                      ))}
                    </ul>
                  )}
                  {!Array.isArray(faq.answers) && (
                    <p className="md:text-20">{faq.answers}</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </ul>
      </div>
    </section>
  );
};
export default Faqs;
