import AbcHero from "../components/abc/abc-hero";
import Navbar from "../components/navbar";
import RegistrationForm from "../components/registrationForm";

const Accelerator = () => {
  return (
    <div className="container mx-auto tools-body overflow-hidden bg-[#454545]">
      <section className="container  mx-auto text-center bg-center bg-cover">
        <Navbar></Navbar>
        <div className="container mx-auto bg-center abc-hero h-full ">
          <div className="abc-inner-hero h-[558px] flex flex-col md:flex-row items-center justify-center gap-[50px] z-10 relative">
            <div className="h-full w-full absolute top-0 left-0 abc-hero-overlay"></div>
            <div className="abc-hero-text space-y-[25px] z-10">
              <h2 className="z-10 hero-title">HACK-A-PRENEURSHIP</h2>
              <p className="z-10 hero-subtitle">
                {" "}
                Accelerator Immersion Program
              </p>
              <div className="flex items-center justify-between">
              <div className="flex items-center gap-2 text-white text-md">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
</svg>

                <p>Thursday, July 4<sup>th</sup>, 2024</p>

              </div>
              <hr className="w-[2px] h-10 bg-white"/>
              <div className="flex items-center gap-2 text-white text-md">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
</svg>


                <p>Adanian Labs SA</p>

              </div>
              </div>
              <div className="flex items-center justify-center gap-2 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
</svg>

<h2 className=" text-lg text-white"> Early Bird:(until June 15th): R10,000</h2>
              </div>
              <div className="flex items-center justify-center gap-2 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
</svg>

<h2 className=" text-lg text-white"> Regular Price: R11,999
</h2>
              </div>
              
              
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container px-6 lg:px-32 py-10 lg:py-16  mx-auto values-bg relative">
          <div className="values-circle"></div>
          <div className="values-circle-2"></div>
          <h3 className="lg-values-title text-center capitalize">
            Are you a leader or an aspiring entreprenuer ready to thrive in the
            digital age?
          </h3>
          <div className="flex items-center text-center justify-center my-2">
            <hr className="values-divider"></hr>
          </div>
          <p className="desc text-center text-white text-md">
            The intensive one-day program equips you with the skills and the
            frameworks to spearhead digital transformation and lead your team to
            success
          </p>

          <div className="grid grid-cols-1 gap-8  mt-20  xl:mt-12 xl:gap-8 md:grid-cols-2 xl:grid-cols-3">
            <a
              href="#"
              aria-label="learn more about adanianlabs"
              className="flex flex-col items-center justify-around pt-8 pb-4 lg:pb-8 lg:py-8 px-4 text-center rounded-xl values-card "
              style={{ marginBottom: "35px", zIndex: "2" }}
            >
              <span className="rounded-full flex items-center justify-center lg:-mt-20 -mt-16 vx-icon text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-8 w-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                  />
                </svg>
              </span>

              <h3 className="values-card-title  mt-2 mb-8 ">
              Who Should Attend?
              </h3>

              <hr className="values-hr mt-2 mb-8"></hr>
              <ul className=" list-disc list-inside text-white text-start">
              <li>
                <span className="font-bold">Corporate Leaders:</span>
                Senior executives, managers, and team leaders
              </li>
              <li>
                <span className="font-bold">Business School Attendees:</span>
                Students and recent graduates
              </li>
              <li>
                <span className="font-bold mr-2">Aspiring Entrepreneurs</span>
              </li>
            </ul>
            </a>
            <a
              href="#"
              aria-label="learn more about adanianlabs"
              className="flex flex-col items-center justify-around pt-8 pb-4 lg:pb-8 lg:py-8 px-4 text-center rounded-xl values-card "
              style={{ marginBottom: "35px", zIndex: "2" }}
            >
              <span className="rounded-full flex items-center justify-center lg:-mt-20 -mt-16 vx-icon text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
</svg>

              </span>

              <h3 className="values-card-title  mt-2 mb-8 ">
             Program Highlights
              </h3>

              <hr className="values-hr mt-2 mb-8"></hr>
              <ul className=" list-disc list-inside text-white text-start">
              <li>
                <span className="">Expert-Led Sessions</span>
             
              </li>
              <li>
                <span className="">Real life case studies from innovative Entrepreneurs</span>
                
              </li>
              <li>
                <span className="">Interactive workshops</span>
              </li>
              <li>
                <span className="">Access to online learning resources</span>
              </li>
            </ul>
            </a>
            <a
              href="#"
              aria-label="learn more about adanianlabs"
              className="flex flex-col items-center justify-around pt-8 pb-4 lg:pb-8 lg:py-8 px-4 text-center rounded-xl values-card "
              style={{ marginBottom: "35px", zIndex: "2" }}
            >
              <span className="rounded-full flex items-center justify-center lg:-mt-20 -mt-16 vx-icon text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
</svg>

              </span>

              <h3 className="values-card-title  mt-2 mb-8 ">
              Program Immersion Overview
              </h3>

              <hr className="values-hr mt-2 mb-8"></hr>
              <ul className=" list-disc list-inside text-white text-start">
              <li>
                <span className="font-bold mr-2">Morning Session:</span>
                Welcome and introduction to digital transformation
              </li>
              <li>
                <span className="font-bold">Lunch Break:</span>
              Networking Opportunity
              </li>
              <li>
                <span className="font-bold mr-2">Afternoon Session</span>Breakout Sessions,Team presentations
              </li>
            </ul>
            </a>
          </div>
          <h2 className="text-2xl text-white">
          Please complete the form below to secure your spot in the program.
          </h2>
<RegistrationForm/>
        </div>
      </section>
    </div>
  );
};

export default Accelerator;
