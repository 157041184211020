import "../css/contact-us.css";
import background from "../components/utils/contact-us-hero.jpg"

import { Container } from "../components/shared";
import { faqs, offices } from "../data/contact-us";
import { HashLink } from "react-router-hash-link";
import SharedHero from "../components/utils/hero";
import { useState } from "react";
import Faqs from "../components/faqs";
import PolicyComponent from "../components/privacy-policy";

const Category = ({ title, content, to }) => {
    const styles = {
        background: "linear-gradient(140.96deg, rgba(19, 226, 255, 0.4) 4.48%, rgba(255, 255, 255, 0.1) 94.29%)",
        boxShadow: "-4px 4px 15px rgba(0, 0, 0, 0.25)",
        border: "1px solid #13E2FF",
    };

    return (
        <HashLink smooth to={to} className="flex">
            <div
                style={styles}
                className="flex max-h-[345px] max-w-[345px] flex-col items-center space-y-3 rounded-20 px-6 py-4 backdrop-blur-20"
            >
                <h1 className="text-center text-24 md:text-32">{title}</h1>
                <div className="h-px w-56 bg-white" />
                <p className="text-center text-20 md:text-[18px]">{content}</p>
            </div>
        </HashLink>
    );
};

const PrivacyPolicy = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <Container className="bg-[#2E2E2E]">
            <SharedHero title={'Our Privacy Policy'} subtitle={""} background={background} />
            <div className="bg-cover bg-no-repeat md:bg-contact-us-md bg-contact-us px-6 pt-4 ">
                <section className="flex flex-col items-center space-y-2 md:px-32 md:pt-5 md:pb-14">
                    We at Adanian Labs respect your privacy. This Privacy Policy is established to inform the users of Adanian Labs  (Adanian Labs, “the Company” “We”, “Us”) Website, App and services of collection, processing, sharing and storing of your personal data.
                    We are committed to conducting our business in accordance with these principles in order to ensure that the privacy of personal information is protected and maintained. Unless stated otherwise, this Privacy Policy applies when you visit or use the Company website, App or services (our platforms). By accessing the platforms, you agree to be bound by the terms of this Privacy Policy.
                    This statement should be read together with the applicable data protection laws and regulations and the Terms and Conditions of the Company’s services and APP.

                </section>
                <PolicyComponent />
            </div>
        </Container>
    );
};


export default PrivacyPolicy;