
import { ReactComponent as Particles } from "./Particles.svg";


const BockchainSection = () => {
  return (
    <section>
      <div className="container mx-auto bg-center bg-cover relative h-[270px] md:h-fit"> 
        
        <div className=" flex w-full h-full items-center justify-center">
        {/* <img src="/images/capacity-building/bdf.png" className="object-contain " ></img>   */}
        <Particles></Particles>
        </div>
        <div className="c-circle-1"></div>
        <div className="c-circle-2"></div>
        <div className="c-circle-3"></div>
        <div className="c-circle-1"></div>
      </div>
    </section>
  );
};

export default BockchainSection;
