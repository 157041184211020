import "./App.css";
import {  FooterHome } from "./components/shared";
import Hero from "./components/hero";
import ServiceComponent from "./components/services";
import ValuesScetion from "./components/values-section";
import StartupSection from "./components/startups-section";
import VideoSection from "./components/video-section";
import BorderlessAfrica from "./components/borderless-africa";

function App() {
  return (
    <div className='body-container container mx-auto relative'>
      <header>
        <Hero />
  
      </header>

      <ServiceComponent />
      <ValuesScetion />
      <StartupSection />
      <VideoSection />
      <BorderlessAfrica />

      <FooterHome />

    </div>
  );
}

export default App;
