import { ReactComponent as Pulley } from "./pully.svg";
import { ReactComponent as Gear } from "./Gearmobile.svg";
const BeginJourney = () => {
  return (
    <section>
      <div className="container px-6 lg:px-32 py-10 lg:py-24  mx-auto resources-bg relative hidden lg:block">
        <div className="b-circle"></div>
        <div className="b-circle2"></div>
        <div className="grid grid-cols-2 grid-flow-col gap-12">
          <div className="flex justify-center">
            <Pulley></Pulley>
          </div>
          <div className=" flex flex-col items-start justify-center ml-12">
            <p className="b-title my-3">
              Begin your custom <br></br>solution’s journey
            </p>
            <p className="b-subtitle">
              Engage with us for a comprehensive scope and let us help you as
              you introduce seamless end-to-end digital solutions for your
              business.
            </p>
            <a className="b-btn b-btn-text mt-5" href="/submission-form">
              CONTACT US
            </a>
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-32 py-10 lg:py-2 resources-bg relative block lg:hidden">
        <div className="b-circle"></div>
        <div className="b-circle2"></div>
        <div className="">
          <div className="grid grid-cols-2 gap-[13px]">
            <Gear></Gear>
            <p className="b-title my-3">
              READY TO IGNITE <br></br> YOUR BUSINESS TRANSFORMATION
            </p>
          </div>
          <div className=" flex flex-col items-center justify-center mt-[24px] ml-[15px]">
            <p className="b-subtitle">
              Begin your business's transformational journey by harnessing the
              power of our expertise and cutting-edge technology. Our seasoned
              professionals will guide you every step of the way, helping you
              identify technological opportunities, develop customized
              strategies, build relevant solutions and help you unlock
              unparalleled success.
            </p>
            <a className="b-btn b-btn-text mt-5" href="/submission-form">
              CONTACT US
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeginJourney;
