import {ReactComponent as Particles} from "./particles-2.svg"

const TrainingDevelopment = ({data,title}) => {
  return (
    <section>
      <div className="container mx-auto lg:px-32 py-3 relative  h-[683px] lg:h-[904px]">
        <Particles className="td-cover hidden sm:block"></Particles>
        <div className="tdc-cover"></div>
        <div className="text-center flex items-center justify-center w-full">
        <h1 className="td-title">
           {title}
          </h1>
        </div>
        <div className="td-circle"></div>
        {
          data.map((item,index)=>(
            <div key={index} className={`td-circle-${index} rounded-full flex items-center justify-center px-10`}>  
            <p className="td-circle-text mx-10">{item.stat} <br/>{item.title}</p>
          </div>
          ))
        }
      </div>
    </section>
  );
};

export default TrainingDevelopment;
