import Navbar from "../navbar";
import Carousel from "./carousel.js";
import "../../css/tech-tools.css";
import SharedHero from "../utils/hero";
import background from "../utils/tech-tools.jpg"

const ToolsHero = () => {
  return (
    <section>
      <div className="container pt-16  mx-auto text-center bg-center bg-cover relative h-fit">
        <Navbar></Navbar>
        <div className=" h-[558px] flex flex-col justify-start align-start space-y-3 py-[150px] px-5 md:px-[220px] relative" style={{ backgroundImage: `url(${background})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center' }}>
        <span className="h-full w-full absolute top-0 left-0"></span>
        <h3 className=" hero-title z-10">TECH TOOLS</h3>
        <p className=" hero-subtitle z-10">Harness the power of our game-changing tech tools that have fueled the growth of Africa's most dynamic and trailblazing startups. Experience the unrivaled potential of our proven products, meticulously crafted to drive success across industries. From supply chain management to elevating customer experiences and optimizing markets, our suite of technology solutions are the catalysts that empower innovation and propel businesses to unparalleled heights.
</p>
      </div>
        {/* <Carousel>
          <div className="carousel-item-1 relative">
            <div className="w-full portal-cover flex items-center justify-center">
              <button className="portal-btn h-btn-text">ADANIA MOJA</button>
            </div>
            <h2 className="ht-title absolute left-[84.59px] top-[84.99px] lg:top-[194px] lg:left-[187px] ">
              Tech Tools
            </h2>

            <p className="ht-subtitle absolute top-[144px] left-[17px] lg:top-[287px] lg:left-[97px]  w-[360.47px] lg:w-[480px]">
              We have built impact-driven technology tools to power key sectors
              including Health, Agriculture, Financial Services, Media, and
              others. Tried & tested products powering some of Africa's most
              innovative fast-growing startups
            </p>
            <div className="square-1 flex justify-center items-center">
              <img src="images/tech-tools/carousel/HMIS.svg"></img>
            </div>

            <div className="square-2  flex justify-center items-center">
              <img src="images/tech-tools/carousel/video.svg"></img>
            </div>
            <div className="square-3 flex justify-center items-center">
              <img src="images/tech-tools/carousel/ecommerce.svg"></img>
            </div>

            <div className="square-4 flex justify-center items-center">
              <img src="images/tech-tools/carousel/Farm.svg"></img>
            </div>

            <div className="square-5 flex justify-center items-center">
              <img src="images/tech-tools/carousel/Disease.svg"></img>
            </div>

            <div className="square-6 flex justify-center items-center">
              <img src="images/tech-tools/carousel/Changisha.svg"></img>
            </div>
          </div>
          <div className=" h-full carousel-item-2 relative">
            <div className="w-full portal-cover flex items-center justify-center">
              <button className="portal-btn-2 h-btn-text">ADANIA MOJA</button>
            </div>
            <h2 className="ht-title absolute left-[84.59px] top-[84.99px] lg:top-[194px] lg:left-[187px]">
              ADANIA MOJA
            </h2>
            <p className="ht-subtitle absolute top-[154px] left-[17px] lg:top-[287px] lg:left-[97px]  w-[360.47px] lg:w-[480px]">
              Your all-in-one technology Marketplace that offers a myriad of
              technology tools built to support you as a business and automate
              your processes thus improving efficiency!
            </p>
            <div className="circle-1  rounded-full flex justify-center items-center object-contain">
              <img
                src="images/tech-tools/carousel/Diagnostic.svg"
                className="object-contain"
              ></img>
            </div>
            <div className="circle-2  rounded-full flex justify-center items-center object-contain">
              <img
                src="images/tech-tools/carousel/Special-clinics.svg"
                className="object-contain"
              ></img>
            </div>
            <div className="circle-3  rounded-full flex justify-center items-center">
              <img src="images/tech-tools/carousel/EHR.svg"></img>
            </div>

            <div className="circle-4  rounded-full flex justify-center items-center">
              <img src="images/tech-tools/carousel/Remittance.svg"></img>
            </div>
            <div className="circle-5  rounded-full flex justify-center items-center">
              <img src="images/tech-tools/carousel/universal-portal.svg"></img>
            </div>

            <div className="circle-6  rounded-full flex justify-center items-center">
              <img src="images/tech-tools/carousel/Mobile.svg"></img>
            </div>

            <div className="circle-7 rounded-full flex justify-center items-center">
              <img src="images/tech-tools/carousel/crowdfunding.svg"></img>
            </div>
          </div>
        </Carousel> */}

      </div>
    </section>
  );
};

export default ToolsHero;
