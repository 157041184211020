import BottomContent from "./bottom-content";
import BusinessStats from "./business-stats";
import Navbar from "./navbar";
import {Helmet} from "react-helmet";

const Hero = () => {
  return (
    <section className="overflow-x-hidden">
      <Helmet>
        <link rel="preload" as="image" href="/images/hero-img.jpg" />
      </Helmet>
      <div
        className="container lg:px-6 py-16  mx-auto text-center bg-center bg-cover h-screen lg:h-[60rem]"
        style={{ backgroundImage: "url(images/hero-img.jpg)" }}
      >
        <Navbar></Navbar>
        <div className="flex items-start justify-center w-full h-full px-10 lg:px-0 relative">
          <div className="text-center mt-16 w-full">
            <div className="absolute top-[60px] left-0 md:top-[364px] lg:top-[232px] lg:left-[2%] w-full">
              <p className="hero-subtitle mb-16 lg:mb-2 hidden lg:block">
                A Pan African Web 3.0 Technology Studio
              </p>
              <p className="hero-subtitle  mb-[27px] lg:mb-2 block lg:hidden">
                A Pan African Venture Studio
              </p>
              <p className="hero-title mb-5">
                Actuating the Tech Revolution for a Borderless Africa
              </p>
              <p className="hero-subtitle  mx-5 md:mx-24">
                Unleash the full potential of your business with our
                groundbreaking software suite that revolutionizes your
                operations for unparalleled scalability. Seamlessly integrate
                your consumers and suppliers, and take your business to new
                heights of success.
              </p>
            </div>
            <a href="contact-us" aria-label="learn more about adanian labs">
              <button className="w-full px-4 hero-btn-text hero-btn">
                Connect with us
              </button>
            </a>
          </div>
        </div>
      </div>
      <BottomContent />
      <BusinessStats />
    </section>
  );
};

export default Hero;
