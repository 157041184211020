import { Link } from "react-router-dom";
import { Button, Container, Ellipse } from "../../components/shared";
import { footerSocialLinks } from "../../data";
import { getData } from "../../services";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useParams } from "react-router-dom";

const Preview = () => (
  <div>
    <h1 className=" text-[20px] lg:text-32 block lg:hidden">
      AFYA REKOD, AN ADANIAN LABS STARTUP CLOSES ITS SEED ROUND AT $ 2 MILLION
      TO SCALE ACROSS
    </h1>
    <img
      src="/pages/content-portal/articles/article-2.png"
      alt=""
      className="z-10 w-[160px] h-[120px] block lg:hidden float-left m-4"
    />
    <p className="text-[16px] block lg:hidden">
      Afya Rekod, an Adanian Labs startup, has raised $2million in Seed funding,
      led by Mac Venture Capital.{" "}
      <a
        href="https://macventurecapital.com/"
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer underline"
      >
        Mac Venture Capital
      </a>{" "}
      is a US based seed-stage venture capital firm that invests in technology
      startups leveraging shifts in cultural trends and behaviors. Other
      participants include{" "}
      <a href="http://chymia.io/" className="cursor-pointer underline">
        Next Chymia{" "}
      </a>
      an Asian private firm that focuses on blockchain based companies
    </p>
    <div className="z-10 hidden lg:flex space-x-[18px]">
      <img
        src="/pages/content-portal/articles/article-2.png"
        alt=""
        className="z-10"
      />
      <div className="space-y-5">
        <h1 className="text-32">
          AFYA REKOD, AN ADANIAN LABS STARTUP CLOSES ITS SEED ROUND AT $ 2
          MILLION TO SCALE ACROSS
        </h1>
        <p className="text-24">
          Afya Rekod, an Adanian Labs startup, has raised $2million in Seed
          funding, led by Mac Venture Capital.{" "}
          <a
            href="https://macventurecapital.com/"
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer underline"
          >
            Mac Venture Capital
          </a>{" "}
          is a US based seed-stage venture capital firm that invests in
          technology startups leveraging shifts in cultural trends and
          behaviors. Other participants include{" "}
          <a href="http://chymia.io/" className="cursor-pointer underline">
            Next Chymia{" "}
          </a>
          an Asian private firm that focuses on blockchain based companies
        </p>
      </div>
    </div>

    <div className="flex  space-x-[150px] pt-3">
      <div className="flex items-center space-x-3">
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5218 0C13.4892 0 11.4765 0.400902 9.59857 1.17982C7.72067 1.95873 6.01437 3.1004 4.57708 4.53964C1.67436 7.44633 0.0436274 11.3886 0.0436274 15.4993C0.0300961 19.0783 1.26763 22.5492 3.5417 25.3104L0.44606 28.4102C0.231289 28.6282 0.0857994 28.905 0.0279511 29.2056C-0.0298973 29.5063 0.00248718 29.8174 0.121018 30.0997C0.249577 30.3785 0.457985 30.6129 0.719787 30.7729C0.981589 30.933 1.28498 31.0115 1.59145 30.9986H15.5218C19.6269 30.9986 23.5638 29.3657 26.4665 26.459C29.3693 23.5523 31 19.61 31 15.4993C31 11.3886 29.3693 7.44633 26.4665 4.53964C23.5638 1.63296 19.6269 0 15.5218 0ZM15.5218 27.8988H5.32169L6.76116 26.4573C7.04944 26.1669 7.21125 25.7741 7.21125 25.3646C7.21125 24.9552 7.04944 24.5623 6.76116 24.2719C4.73442 22.2447 3.47232 19.5765 3.18986 16.7219C2.90741 13.8673 3.62209 11.003 5.21214 8.61688C6.8022 6.23078 9.16924 4.47056 11.91 3.6361C14.6508 2.80164 17.5957 2.94457 20.243 4.04054C22.8903 5.13651 25.0763 7.11771 26.4284 9.6466C27.7806 12.1755 28.2153 15.0956 27.6585 17.9095C27.1016 20.7233 25.5878 23.2568 23.3747 25.0783C21.1617 26.8997 18.3865 27.8965 15.5218 27.8988Z"
            fill="#EEEEEE"
          />
        </svg>
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 32.5697C17.3147 32.5697 17.1294 32.5273 16.9592 32.4421C16.7877 32.3563 12.7135 30.3033 8.58042 26.8592C6.12641 24.8142 4.16418 22.7005 2.7485 20.5768C0.924731 17.8413 0 15.0749 0 12.3547C0 7.19646 4.19646 3 9.35466 3C12.8433 3 15.892 4.91923 17.5 7.75764C19.108 4.91923 22.1568 3 25.6453 3C30.8034 3 35 7.19646 35 12.3547C35 15.0749 34.0753 17.8413 32.2515 20.5768C30.8357 22.7005 28.8736 24.8142 26.4196 26.8593C22.2866 30.3034 18.2123 32.3564 18.0408 32.4421C17.8706 32.5273 17.6853 32.5697 17.5 32.5697ZM9.35466 5.41865C5.53024 5.41865 2.41877 8.53012 2.41877 12.3545C2.41877 21.2791 14.7435 28.4912 17.4996 29.9918C20.2547 28.4893 32.5813 21.2661 32.5813 12.3545C32.5813 8.53012 29.4699 5.41865 25.6455 5.41865C21.8209 5.41865 18.7096 8.53012 18.7096 12.3545C18.7096 13.0224 18.1679 13.564 17.5001 13.564C16.8323 13.564 16.2907 13.0224 16.2907 12.3545C16.2906 8.53024 13.1792 5.41865 9.35466 5.41865Z"
            fill="#EEEEEE"
          />
        </svg>
        <span className="mr-[100px]">512</span>
      </div>

      <div className="flex flex-col">
        <span className="inline-block">Feb 7</span>
        <span className="inline-block">(2 min Read)</span>
      </div>
    </div>
  </div>
);

const Content = ({ post, loading }) => (
  <>
    <div className="prose max-w-none pt-5 text-[16px] lg:text-24 text-white blog-content">
      {loading && <p>Loading...</p>}
      {post && (
        <div
          dangerouslySetInnerHTML={{
            __html: post.content && post.content.rendered,
          }}
        />
      )}
    </div>
  </>
);

const UserIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shrink-0"
  >
    <path
      d="M18 0C15.6362 0 13.2956 0.465584 11.1117 1.37017C8.92784 2.27475 6.94353 3.60062 5.27208 5.27208C1.89642 8.64773 0 13.2261 0 18C0 22.7739 1.89642 27.3523 5.27208 30.7279C6.94353 32.3994 8.92784 33.7252 11.1117 34.6298C13.2956 35.5344 15.6362 36 18 36C22.7739 36 27.3523 34.1036 30.7279 30.7279C34.1036 27.3523 36 22.7739 36 18C36 15.6362 35.5344 13.2956 34.6298 11.1117C33.7252 8.92784 32.3994 6.94353 30.7279 5.27208C29.0565 3.60062 27.0722 2.27475 24.8883 1.37017C22.7044 0.465584 20.3638 0 18 0ZM9.126 29.304C9.9 27.684 14.616 26.1 18 26.1C21.384 26.1 26.1 27.684 26.874 29.304C24.3529 31.3128 21.2236 32.4046 18 32.4C14.652 32.4 11.574 31.248 9.126 29.304ZM29.448 26.694C26.874 23.562 20.628 22.5 18 22.5C15.372 22.5 9.126 23.562 6.552 26.694C4.63713 24.2003 3.5994 21.1441 3.6 18C3.6 10.062 10.062 3.6 18 3.6C25.938 3.6 32.4 10.062 32.4 18C32.4 21.276 31.284 24.3 29.448 26.694ZM18 7.2C14.508 7.2 11.7 10.008 11.7 13.5C11.7 16.992 14.508 19.8 18 19.8C21.492 19.8 24.3 16.992 24.3 13.5C24.3 10.008 21.492 7.2 18 7.2ZM18 16.2C17.2839 16.2 16.5972 15.9155 16.0908 15.4092C15.5845 14.9028 15.3 14.2161 15.3 13.5C15.3 12.7839 15.5845 12.0972 16.0908 11.5908C16.5972 11.0845 17.2839 10.8 18 10.8C18.7161 10.8 19.4028 11.0845 19.9092 11.5908C20.4155 12.0972 20.7 12.7839 20.7 13.5C20.7 14.2161 20.4155 14.9028 19.9092 15.4092C19.4028 15.9155 18.7161 16.2 18 16.2Z"
      fill="#EEEEEE"
    />
  </svg>
);
const Comment = ({ author, content }) => (
  <div className="space-y-2 text-24">
    <div className="flex items-center space-x-[10px]">
      <UserIcon />
      <span>Guest</span>
    </div>
    <p dangerouslySetInnerHTML={{ __html: content.rendered }}></p>
  </div>
);
const Comments = ({ comments }) => {
  return (
    <>
      <div className="mt-5 space-y-9">
        {comments.map((comment) => (
          <Comment author={comment.author_name} content={comment.content} />
        ))}
      </div>
    </>
  );
};
const SocialMediaLinks = () => (
  <>
    <div className="my-8 h-px w-[851px] bg-white" />

    <div className="mt-[25px] flex items-center space-x-[35px]">
      {footerSocialLinks.map((social) => (
        <a
          className="text-white"
          target="_blank"
          href={social.link}
          key={social.name}
          rel="noreferrer"
        >
          <img
            src={`/icons/socials/${social.name}.png`}
            alt=""
            className="z-10"
          />
        </a>
      ))}
    </div>

    <div className="my-8 h-px w-[387px] bg-white" />
  </>
);

const Blog = () => {
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [postLiked, setpostLiked] = useState(false);
  const [loading, setloading] = useState(false);
  const { id } = useParams();
  // generate and store JWT token
  const generateToken = async () => {
    const username = "techadanian";
    const password = "i!gFe&ui3lX0BpaP(";

    try {
      const response = await axios.post(
        "https://dashboard.adanianlabs.io/wp-json/jwt-auth/v1/token",
        {
          username,
          password,
        }
      );
      const token = response.data.data.token;
      localStorage.setItem("jwtToken", token);
      return token;
    } catch (error) {
      console.error(error);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    generateToken();
    const token = localStorage.getItem("jwtToken");
    const response = await fetch(
      `https://dashboard.adanianlabs.io/wp-json/wp/v2/comments`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          post: "11",
          content: comment,
        }),
      }
    );

    if (response.ok) {
      setComment("");
      // Do something to indicate the comment was successfully created
      swal("comment submmited successfully");
      getData(
        `https://dashboard.adanianlabs.io/wp-json/wp/v2/comments?post=${post.id}`
      ).then((data) => {
        setComments(data);
      });
    }
  }

  function handleCommentChange(event) {
    setComment(event.target.value);
  }

  const handleLike = async (likes) => {
    generateToken();
    const token = localStorage.getItem("jwtToken");
    fetch(`https://dashboard.adanianlabs.io/wp-json/wp/v2/posts/${post.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        acf: {
          likes: likes + 1,
        },
      }),
    }).then(() => {
      setpostLiked(true);
      getData(
        `https://dashboard.adanianlabs.io/wp-json/wp/v2/posts/?slug=${id}&_embed`
      ).then((data) => {
        setPost(data[0]);
      });
    });
  };

  useEffect(() => {
    setloading(true);
    getData(
      `https://dashboard.adanianlabs.io/wp-json/wp/v2/posts/?slug=${id}&_embed`
    ).then((data) => {
      setPost(data[0]);
      setloading(false);
      getData(
        `https://dashboard.adanianlabs.io/wp-json/wp/v2/comments?post=${data[0].id}`
      ).then((data) => {
        setComments(data);
      });
    });
  }, []);
  return (
    <div>

      <Container
        className="relative bg-[#232323]"
        footerProps={{ className: "bg-transparent" }}

      >
        
         <div className="hidden md:block">
         <Ellipse variant="blue" size={455} left={117} top={31} blur={290} />
         </div>
    
        <Ellipse variant="blue" size={455} left={745} top={963} blur={290} />
        <Ellipse variant="blue" size={455} left={35} top={1400} blur={290} />

        <Link to="/content-portal">
          <button
            className="absolute left-[-85px] top-[202px] -rotate-90 rounded-b-[25px] rounded-t-none border p-3 hidden lg:block"
            style={{
              background:
                "linear-gradient(176.47deg, rgba(217, 217, 217, 0.4) -1.94%, rgba(217, 217, 217, 0.1) 90.13%)",
            }}
          >
            Return to Content Portal
          </button>
        </Link>

        <div className="px-[11px] lg:px-[122px] pt-[102px] pb-10 z-10">
          {post && (
            <div className="z-10">
              <h1 className=" text-[20px]  block lg:hidden z-10 mb-2" dangerouslySetInnerHTML={{ __html: post.title.rendered }}>

              </h1>
              {id == 'the-future-of-tech-entrepreneurship-technology-affiliate-business-partners-mode' && (
                <div className="z-20 w-full h-fit block lg:hidden">
                  <iframe width="362" height="273" src="https://www.youtube.com/embed/f7lIXTi_5lY" title="Adanian Labs Affiliate Program" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="z-10"></iframe>
                </div>
              )}

            
                {id == 'adanian-labs-journey-of-resilience-reflections-by-co-founder-irene-kiwia' && (

           <div className="z-20 w-[362px] h-fit block lg:hidden">
           
           <iframe width="362" height="273" src="https://www.youtube.com/embed/DQo4J1RpkWk?si=Tef1VxDIdcz4vxwl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen className="z-10"></iframe>
         </div>
              )}
              {id != 'the-future-of-tech-entrepreneurship-technology-affiliate-business-partners-mode'&&id != 'adanian-labs-journey-of-resilience-reflections-by-co-founder-irene-kiwia' && (
                <img
                  src={post._embedded["wp:featuredmedia"][0].source_url}
                  alt=""
                  className="z-10 w-full h-fit block lg:hidden object-contain"
                />
              )}
              <p className="text-[16px] block lg:hidden">{post.acf.preview}</p>
              <div className="z-10 hidden lg:flex gap-10 ">
                {id != 'the-future-of-tech-entrepreneurship-technology-affiliate-business-partners-mode'&&id != 'adanian-labs-journey-of-resilience-reflections-by-co-founder-irene-kiwia' && (
                  <img
                    src={post._embedded["wp:featuredmedia"][0].source_url}
                    alt=""
                    className="z-10 object-contain max-w-[40%]"
                  />
                )}
                {id == 'the-future-of-tech-entrepreneurship-technology-affiliate-business-partners-mode' && (
                  <div className="z-10 hidden lg:flex gap-10">
                    <iframe width="362" height="273" src="https://www.youtube.com/embed/f7lIXTi_5lY" title="Adanian Labs Affiliate Program" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>
                )}
                   {id == 'adanian-labs-journey-of-resilience-reflections-by-co-founder-irene-kiwia' && (
                  <div className="z-10 hidden lg:flex gap-10">
                     <iframe width="362" height="273" src="https://www.youtube.com/embed/DQo4J1RpkWk?si=Tef1VxDIdcz4vxwl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                )}


                <div className="space-y-5">
                  <h1 className="text-32 article-title" dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h1>
                  <div className="flex  space-x-[150px] pt-3">
                    <div className="flex items-center space-x-3">
                      <svg
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.5218 0C13.4892 0 11.4765 0.400902 9.59857 1.17982C7.72067 1.95873 6.01437 3.1004 4.57708 4.53964C1.67436 7.44633 0.0436274 11.3886 0.0436274 15.4993C0.0300961 19.0783 1.26763 22.5492 3.5417 25.3104L0.44606 28.4102C0.231289 28.6282 0.0857994 28.905 0.0279511 29.2056C-0.0298973 29.5063 0.00248718 29.8174 0.121018 30.0997C0.249577 30.3785 0.457985 30.6129 0.719787 30.7729C0.981589 30.933 1.28498 31.0115 1.59145 30.9986H15.5218C19.6269 30.9986 23.5638 29.3657 26.4665 26.459C29.3693 23.5523 31 19.61 31 15.4993C31 11.3886 29.3693 7.44633 26.4665 4.53964C23.5638 1.63296 19.6269 0 15.5218 0ZM15.5218 27.8988H5.32169L6.76116 26.4573C7.04944 26.1669 7.21125 25.7741 7.21125 25.3646C7.21125 24.9552 7.04944 24.5623 6.76116 24.2719C4.73442 22.2447 3.47232 19.5765 3.18986 16.7219C2.90741 13.8673 3.62209 11.003 5.21214 8.61688C6.8022 6.23078 9.16924 4.47056 11.91 3.6361C14.6508 2.80164 17.5957 2.94457 20.243 4.04054C22.8903 5.13651 25.0763 7.11771 26.4284 9.6466C27.7806 12.1755 28.2153 15.0956 27.6585 17.9095C27.1016 20.7233 25.5878 23.2568 23.3747 25.0783C21.1617 26.8997 18.3865 27.8965 15.5218 27.8988Z"
                          fill="#EEEEEE"
                        />
                      </svg>
                      <span className="mr-[100px]">{comments.length}</span>
                      {postLiked ? (
                        <img
                          src="/icons/liked.svg"
                          alt="like"
                          onClick={() => {
                            handleLike(post.acf.likes);
                          }}
                          className="z-10"
                        />
                      ) : (
                        <img
                          src="/icons/heart.svg"
                          alt="like"
                          onClick={() => {
                            handleLike(post.acf.likes);
                          }}
                          className="z-10"
                        />
                      )}
                      <span className="mr-[100px]">{post.acf.likes}</span>
                    </div>

                    <div className="flex flex-col">
                      <span className="inline-block">
                        {new Date(post.date).toDateString()}
                      </span>
                      <span className="inline-block">(2 min Read)</span>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-24 mt-3">{post.acf.preview}</p>
            </div>
          )}
          <Content post={post} loading={loading} />
          <SocialMediaLinks />
          <form onSubmit={handleSubmit}>
            <div className="flex items-center space-x-[10px]" id="comment">
              <UserIcon />
              <textarea
                value={comment}
                onChange={handleCommentChange}
                className="w-[664px] rounded-[8px] bg-white py-4 px-6 text-black z-10"
                placeholder="type comment..."
              />
              <Button variant="orange" type="submit">
                Submit
              </Button>
            </div>
          </form>
          <Comments comments={comments} />
          <div className="mt-14 flex justify-center lg:justify-start lg:pr-20">
            <Button variant="orange" href="/content-portal">
              RETURN TO CONTENT PORTAL
            </Button>
          </div>
        </div>
      </Container>
    </div>

  );
};

export default Blog;
