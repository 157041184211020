

const AiceServices = ({services}) => {
    return (
      <section className="startups-section bg-cover bg-center px-5 lg:px-[129px] bg-[#454545] relative pb-[76px]">
        <div className="w-full flex  flex-col items-center space-y-3 mb-[56px]">
          <p className="heading">Our Services</p>
          <hr />
          <div className="abc-ellipse-1"></div>
          <div className="abc-ellipse-2"></div>
  
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {services.map((service,index)=>{
              return(
                <div className="abc-service-card gap-[30px] px-[22px] pt-[50px] flex flex-col items-center overflow-y-scroll">
                    <img src={`/images/abc/${index+1}.svg`} className="h-20" loading="lazy" alt="Adanian Labs" ></img>
                  <h2>{service.title}</h2>
                  <hr />
                  <p >{service.desc}</p>
  
                </div>
              )
            })}
  
          </div>
      </section>
    );
  };
  
  export default AiceServices;
  