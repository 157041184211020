import { useState } from "react";
import { Ellipse } from "../shared";

function Modal({ isOpen, onClose, children }) {
  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      // ESC key
      onClose();
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  const modalStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: isOpen ? "block" : "none",
    zIndex: 20,
  };


  return isOpen ? (
    <div
      className="modal z-32"
      onKeyDown={handleKeyDown}
      onClick={handleOverlayClick}
      style={modalStyles}
    >
      <div className="modal-content p-2 lg:p-4 overflow-y-scroll">
        <div className="relatve h-full w-full">
          <div className="modal-ellipse-1"></div>
          <div className="modal-ellipse-2"></div>
          {children}
        </div>
      </div>
    </div>
  ) : null;
}

const BusinessAcceleration = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeIndex, setactiveIndex] = useState(0);
  const largeCards = [

    {
      image: "agriculture", tool: "AGRICULTURE SECTOR",
      modal: `<p>
      Innovate and develop technology solutions to enhance agricultural practices, improve crop yields, optimise supply chains, and drive sustainable farming practices.
      <p/>
      <ul class="list-disc list-inside">
      <li>Precision farming solutions
      <li/>
      <li>Crop monitoring and analytics
      <li/>
      <li>Supply chain optimization
      <li/>
      <li>Smart irrigation systems
      <li/>
      <li>Agricultural drones and robotics
      <li/>
      <ul/>
      <p>
      These services, along with the additional support provided, are part of our comprehensive approach to venture building, ensuring that our partners receive the necessary resources and assistance to drive their projects forward:
      </p>
      <ul class="list-disc list-inside">
      <li>Funding support
      </li>
      <li>Infrastructure support
      </li>
      <li>Shared support services (Marketing, Legal, Finance)
      </li>
      <li>Tech dev team building
      </li>
      <li>Borderless Africa Market Access
      </li>
      <li>Investor readiness
      </li>
      </ul>
      `
    },
    {
      image: "energy", tool: "Energy & Power sector",
      modal: `<p>
      We are on a mission to revolutionise the energy sector with breakthrough technologies, including renewable energy systems, energy storage solutions, smart grids, and energy efficiency optimization. The lab aims to provide a platform to nurture, grow and scale cleantech solutions in Africa and beyond.
      <p/>
      <ul class="list-disc list-inside">
      <li>Smart grid solutions
      <li/>
      <li>Energy storage technologies
      <li/>
      <li>Solar and wind energy systems
      <li/>
      <li>Energy efficiency optimization
      <li/>
      <li>Electric vehicle infrastructure
      <li/>
      <ul/>
      <p>
      These services, along with the additional support provided, are part of our comprehensive approach to venture building, ensuring that our partners receive the necessary resources and assistance to drive their projects forward:
      </p>
      <ul class="list-disc list-inside">
      <li>Funding support
      </li>
      <li>Infrastructure support
      </li>
      <li>Shared support services (Marketing, Legal, Finance)
      </li>
      <li>TTech dev team building
      </li>
      <li>Borderless Africa Market Access
      </li>
      </ul>
      `},
    { image: "finances", tool: "Financial services sector",
    modal: `<p>
    We are at the forefront of driving financial innovation through technology, leveraging advancements in areas like mobile payments, digital banking, blockchain-based financial services, and personalised financial solutions.

    <p/>
    <ul class="list-disc list-inside">
    <li>Mobile payment solutions
    <li/>
    <li>Digital banking platforms
    <li/>
    <li>Blockchain-based financial services
    <li/>
    <li>Robo-advisory and wealth management
    <li/>
    <li>Peer-to-peer lending and crowdfunding platforms
    <li/>
    <ul/>
    <p>
    These services, along with the additional support provided, are part of our comprehensive approach to venture building, ensuring that our partners receive the necessary resources and assistance to drive their projects forward:
    </p>
    <ul class="list-disc list-inside">
    <li>Funding support
    </li>
    <li>Infrastructure support
    </li>
    <li>Shared support services (Marketing, Legal, Finance)
    </li>
    <li>TTech dev team building
    </li>
    <li>Borderless Africa Market Access
    </li>
    </ul>
    ` },
    { image: "climate", tool: "Climate & Conservation sector",
    modal: `<p>
    Develop cutting-edge technologies to mitigate climate change, promote renewable energy adoption, enable carbon capture and storage, and support sustainable resource management.
    <p/>
    <ul class="list-disc list-inside">
    <li>Renewable energy systems
    <li/>
    <li>Carbon capture and storage technologies
    <li/>
    <li>Sustainable waste management solutions
    <li/>
    <li>Robo-advisory and wealth management
    <li/>
    <li>Environmental monitoring and analytics
    <li/>
    <li>Climate risk assessment tools
    </li>
    <ul/>
    <p>
    These services, along with the additional support provided, are part of our comprehensive approach to venture building, ensuring that our partners receive the necessary resources and assistance to drive their projects forward:
    </p>
    <ul class="list-disc list-inside">
    <li>Funding support
    </li>
    <li>Infrastructure support
    </li>
    <li>Shared support services (Marketing, Legal, Finance)
    </li>
    <li>TTech dev team building
    </li>
    <li>Borderless Africa Market Access
    </li>
    </ul>
    `},
    { image: "bloc", tool: "BLOCKCHAIN SECTOR",
    modal: `<p>
    To explore the potential of blockchain technology across industries, facilitating secure transactions, transparent supply chains, decentralised applications, and digital identity management.
    <p/>
    <ul class="list-disc list-inside">
    <li>Blockchain development and implementation
    <li/>
    <li>Cryptocurrency and digital asset solutions
    <li/>
    <li>Sustainable waste management solutions
    <li/>
    <li>Supply chain transparency and traceability
    <li/>
    <li>Decentralised applications (DApps) development
    <li/>
    <li>Digital identity management platforms
    </li>
    <ul/>
    <p>
    These services, along with the additional support provided, are part of our comprehensive approach to venture building, ensuring that our partners receive the necessary resources and assistance to drive their projects forward:
    </p>
    <ul class="list-disc list-inside">
    <li>Funding support
    </li>
    <li>Infrastructure support
    </li>
    <li>Shared support services (Marketing, Legal, Finance)
    </li>
    <li>TTech dev team building
    </li>
    <li>Borderless Africa Market Access
    </li>
    </ul>
    `},
    { image: "iot", tool: "INTERNET OF THINGs SECTOR",
    modal: `<p>
    To explore the potential of blockchain technology across industries, facilitating secure transactions, transparent supply chains, decentralised applications, and digital identity management.
    <p/>
    <ul class="list-disc list-inside">
    <li>Smart city solutions
    <li/>
    <li>Connected devices and sensors.
    <li/>
    <li>IoT analytics and data management
    <li/>
    <li>Healthcare IoT applications
    <li/>
    <li>Industrial IoT solutions for resource optimization
    <li/>
    <ul/>
    <p>
    These services, along with the additional support provided, are part of our comprehensive approach to venture building, ensuring that our partners receive the necessary resources and assistance to drive their projects forward:
    </p>
    <ul class="list-disc list-inside">
    <li>Funding support
    </li>
    <li>Infrastructure support
    </li>
    <li>Shared support services (Marketing, Legal, Finance)
    </li>
    <li>TTech dev team building
    </li>
    <li>Borderless Africa Market Access
    </li>
    </ul>
    ` },

  ];
  const handleOpenModal = (index) => {
    setactiveIndex(index);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const url = "/pages/venture-building/";
  return (
    <div className="business-accel  px-5 lg:px-28 relative">
      <Ellipse left={-25} top='25%' size={308} blur={170} variant="orange" />
      <Ellipse left={-25} top='75%' size={308} blur={170} variant="orange" />
      <Ellipse right={50} top='15%' size={451} blur={150} variant="blue" />
      <Ellipse right={50} top='70%' size={451} blur={150} variant="blue" />
      <Modal isOpen={modalIsOpen} onClose={handleCloseModal}>

        <div className="flex flex-col md:flex-row gap-8">
          <div className={largeCards[activeIndex].overview ? 'z-10 basis-1/4' : 'z-10'}>
            <img
              src={largeCards[activeIndex].image}
              className="user-img-1 z-10"
            ></img>
            <p className="text-white">{largeCards[activeIndex].name}</p>
            <p className="text-white">{largeCards[activeIndex].role}</p>
          </div>
          <div className={largeCards[activeIndex].overview ? 'z-10 basis-3/4' : 'z-10'}>
            <div className="flex justify-end">
              <svg
                x-show="isOpen"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 z-10 justify-self-end"
                fill="#ffffff"
                viewBox="0 0 24 24"
                stroke="#ffffff"
                stroke-width="2"
                onClick={handleCloseModal}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div dangerouslySetInnerHTML={{ __html: largeCards[activeIndex].modal }}>

            </div>
          </div>
        </div>
      </Modal>
      <div className="grid grid-cols-1 md:grid-cols-2 py-12 gap-10">
        <img src="/images/business-acceleration.png" alt="" />
        <div className="space-y-8 z-10">
          <h3>Business Acceleration</h3>
          <div className=" w-full flex items-center justify-center">
            <hr />
          </div>

          <p className="z-20">
            Our comprehensive solution to help startups achieve rapid growth.
            Our experienced team provides tailored support in market analysis,
            business strategy, product development, branding, and more. <br />{" "}
            <br />
            We'll help you identify and overcome obstacles and execute a
            customized plan for sustainable growth. Perfect for businesses
            looking to achieve rapid growth without in-house expertise or
            resources.
          </p>
        </div>
      </div>
      <div className="py-8 space-y-8 z-10">
        <h3>VENTURE PARTNERSHIPS</h3>
        <div className="flex itemss-center justify-center">
          <hr />
        </div>
        <p className="z-20">
          Through our Labs As A Service offering, we partner with key
          stakeholders to champion technology in a bid to get Africa to actively
          participate in the 4IR opportunity. We assist local corporates and
          multinationals to incubate, develop and accelerate deliberate
          technology startups across their supply and value chain whereby the
          anchor client is the off-taker of the solutions e.g.  Fintech ,
          Agritech Lab, Climatetech Lab, Energytech etc to digitize, optimize
          and connect to end user/customer seamlessly  (B2B2C). <br /> <br />
          We have built a model that has worked that involves venture building
          tech startups and de-risking their probability for failure and
          training young people so they can actively service the energy tech
          industry globally with a special focus on smart technology (AI and
          Blockchain.) <br /> <br />
          Visionary investors invited to partner with Adanian Labs to build
          sector-focused tech ventures and capacity that address some of the
          challenges that are experienced within the identified sector, to
          significantly impact the industry. These ventures become the vehicles
          that create new opportunity avenues for economic growth. <br /> <br />
          This is achieved through sector-focused Labs set to capacitate the
          venture building and capacity building within the identified sectors.
          Some of the sectors we are focusing on include, but are not limited
          to:
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 ">
        {largeCards.map((item, index) => (
          <div key={index} className="card flex justify-start items-center gap-10 z-10" onClick={() => {
            handleOpenModal(index);
          }}>
            <img src={url + item.image + ".svg"} alt="" className="z-10" />
            <p>{item.tool}</p>
          </div>

        ))}
      </div>
    </div>
  );
};

export default BusinessAcceleration;
