import "../css/tech-tools.css"
import ToolsHero from "../components/tech-tools/tools-hero";
import {Footer } from "../components/shared";
import ResourcesSection from "../components/tech-tools/resources-section"
 import ToolsShowcase from "../components/tech-tools/tools-showcase.js"
import BeginJourney from "../components/tech-tools/begin-journey";
import ShowcaseFrame from "../components/tech-tools/showcase-frame";
import { Container } from "../components/shared";

const TechTools = () => {
    return ( 
      <Container className="bg-[#454545]">
         <ToolsHero></ToolsHero>
          <ResourcesSection></ResourcesSection>
          <ToolsShowcase></ToolsShowcase>
          <BeginJourney></BeginJourney>  
          <ShowcaseFrame></ShowcaseFrame>       
      </Container>
       
     );
}
 
export default TechTools;