const ShowcaseFrame = () => {
  return (
    <section>
      <div className="container px-6 lg:px-32 pt-10 pb-[11px] lg:pb-[60px] lg:pt-24  mx-auto resources-bg relative overflow-hidden">
        <div className="sf-circle-blue"></div>
        <div className="sf-circle"></div>
        <div className="sf-circle-2"></div>
        <div className="flex  justify-between gap-[42px] lg:gap-40 flex-col lg:flex-row ">
          <div className="sf-div flex flex-col items-center lg:py-10 lg:px-8 w-100 z-5 order-2 lg:order-1 z-10 space-y-4 p-4">
            <p className="sf-title">OUR STARTUP PORTFOLIO</p>
            <div className="sf-hr lg:my-5"></div>
            <p className="sf-subtitle lg:my-2 z-10">
              With pride, we have venture built over 40 cutting-edge tech
              startups that are designed to seamlessly deliver on their intended
              purpose. From industry pioneers to emerging disruptors, our
              portfolio showcases our unwavering commitment to actuating the
              technology revolution on the continent. Explore our companies and
              partner with us so together, we can help shape the future of your
              business.
            </p>
            <a className="sf-btn sf-btn-text lg:mt-10" href="/our-startups">
              Showcase
            </a>
          </div>
          <div className="sf-div-2 flex flex-col items-center py-10 px-4 w-85 justify-center z-5 order-1 lg:order-2">
            <h2 className="sf-title-2">
            SOLUTIONS  <br></br> MARKETPLACE
            </h2>
            <div className="sf-hr-2 lg:mt-3 lg:mb-16 "></div>
            <a className=" sf-btn-2 sf-btn-text" href="#">
              Adania
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShowcaseFrame;
