import AbcHero from "../components/abc/abc-hero";
import AbcServices from "../components/abc/abc-services";
import { Footer } from "../components/shared";
import TrainingDevelopment from "../components/capacity-building/Training-development";
import MeetLeaders from "../components/capacity-building/meet-leaders";
import '../css/abc.css'
import { abcServices } from "../data/our-startups";
import { abcTraction } from "../data";

const Abc = () => {
  const heroContent={
    title:"AFRICA BLOCKCHAIN CENTER",
    substitle:"Empowering Africa's Future with Blockchain Technology",
   
    links:[],
    bottomContent:"Welcome to the Africa Blockchain Center, the premier independent hub for capacity building and research in blockchain technology. We unite Africa's top industry leaders, academics, policy-makers, entrepreneurs, and researchers to drive groundbreaking research and fuel the pan-African blockchain revolution. Our goal is to train 10,000 blockchain developers by 2027, empowering Africa to lead in this transformative technology."


}
const leaders = [
  {
    overview:
      "",
    skills: [
  
    ],
    image: "/images/abc/jeff.jpg",
    desc: "",
    name: "Jeff Mkungusi",
    role: "CEO",
  },
  {
    overview:
      "",
    skills: [

    ],
    image: "/images/abc/anderson.jpg",
    desc: "",
    name: "Anderson Rioba",
    role: "Knowledge Manager",
  },
  {
    overview:
      "",
    skills: [
     
    ],
    image: "/images/abc/morris.jpg",
    desc: "",
    name: "Morris Mutonya",
    role: "Head of Operations",
  },
];
return (
  <div className="body-container">
  <div
    className="container mx-auto tools-body overflow-hidden"
    style={{ background: "#454545}" }}
  >
    <AbcHero content={heroContent}/>
   <AbcServices  services={abcServices}/>
   <TrainingDevelopment data={abcTraction} title="Our Traction"/>
   <MeetLeaders subtitle={'Are you an investor looking to invest in Africa Blockchain Center?'} btnText="INVEST" desc={'Contact us today to learn how our blockchain services can benefit your organisation.'} link="submission-form" leaders={leaders}/>
    <Footer></Footer>
  </div>
</div>
)
};
export default Abc;
