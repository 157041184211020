import clsx from "clsx";

const RegionPath = ({ selected, ...props }) => (
  <path
    stroke="#13E2FF"
    strokeOpacity="0.45"
    strokeLinejoin="round"
    className={clsx("hover:cursor-pointer hover:fill-[#F5931D]", props.id === selected && "fill-[#F5931D]")}
    {...props}
  >
    {props.id !== selected && <animate attributeName="fill" from="#13E2FF" to="#FFFFFF" dur="3s" repeatCount="indefinite" />}
  </path>
);

const InteractiveAfricaSvg = ({ selectedRegion, setSelectedRegion, className }) => {
  return (
    <svg
      width="714"
      height="715"
      viewBox="0 0 714 715"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("z-10 shrink-0", className)}
    >
      <g id="Property 1=Default" clipPath="url(#clip0_2266_2609)">
        <g id="Others">
          <path
            id="Angola"
            d="M353.644 427.572L327.94 427.429L324.87 428.643L322.371 428.429L318.73 429.786L317.944 431.715L322.228 437.929L323.942 444.572L326.512 454.143L323.799 458.072L323.371 460.072L325.441 466L327.655 472L330.225 475.572L330.653 481.143L329.654 488.5L326.798 492.857L321.728 499.357L319.658 503.357L316.73 512.286L316.159 516.5L313.089 525.572L311.732 534.286L312.446 540.5L316.659 538.572L321.8 536.929L327.369 537.215L332.438 541.715L333.795 541L368.638 540.572L374.493 545.286L395.27 546.715L411.264 542.643L405.838 536.5L400.268 528.429L401.411 497L419.475 497.072L418.761 493.715L420.189 490L418.761 485.357L419.832 480.572L418.975 477.5L415.048 476.929L409.622 478.357L405.838 478.143L403.696 479.072L404.338 467.286L401.554 463.643L400.982 457.572L402.339 451.572L400.625 447.786L400.554 441.572L389.987 441.643L390.772 438.072L386.345 438.143L385.846 439.857L380.419 440.215L378.206 446L376.849 448.429L372.065 447.072L369.209 448.429L363.426 449.215L360.142 444.072L358.214 440.857L355.715 434.929L353.644 427.572ZM319.801 425.643L320.086 421.357L321.514 418.857L324.727 416.786L321.443 413.357L318.801 415L315.231 419.286L317.587 426.715L319.801 425.643Z"
            fill="url(#paint0_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Burundi"
            d="M477.668 397.214L477.239 393.357L472.598 392.714L469.885 398.357L464.602 397.571L466.744 402.072L466.815 403.786L469.885 413.214V413.643L470.742 413.5L473.955 409.929L477.454 404.786L479.596 402.714L479.524 399.5L477.668 397.214Z"
            fill="url(#paint1_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Burkina Faso"
            d="M214.701 222H209.132L206.99 220.143L202.206 221.5L194.138 225.643L192.496 228.714L185.784 233.143L184.57 235.714L180.929 237.714L176.788 236.357L174.36 238.785L173.075 245.571L166.149 253.714L166.363 257.071L163.936 261.285L164.507 267L168.434 269.143L169.933 272.428L173.789 274.5L176.859 272.071L180.929 271.643L186.855 274.214L185.713 266.785L185.927 261.143L200.992 260.714L204.848 261.428L207.632 259.857L211.631 260.643L219.271 260.857L222.198 259.714L223.983 255.357L228.267 254.5L230.123 251.5L230.338 244.714L220.342 242.5L220.056 237.714L215.129 231.285L213.987 226.785L214.701 222Z"
            fill="url(#paint2_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Central African Rep."
            d="M419.119 284.357L418.405 283.857L415.406 281L414.764 277.929L416.12 273.857L416.049 269.786L410.908 263.643L409.837 259.429L404.339 261.072L400.055 265.072L393.915 275.857L385.847 280.429L377.421 279.857L374.994 280.714L375.851 284.214L371.352 287.643L367.711 291.5L356.787 295.286L354.645 293.072L353.146 292.857L351.575 295.429L344.435 296.143L340.079 306.143L338.009 308L337.438 315.643L338.294 319.786L337.652 322.714L341.793 327.929L342.507 331.429L345.791 336.572L349.79 339.714L350.218 344.214L351.146 347.072L355.644 337.929L360.857 332.643L366.711 334.357L372.352 334.857L373.066 328L376.422 322.929L381.063 319.786L388.274 323.143L393.843 326.786L400.269 327.786L406.767 329.714L409.337 323.714L410.48 322.929L414.478 323.929L424.117 319L427.616 321.143L430.4 320.786L431.685 318.429L434.898 317.572L441.467 318.572L447.108 318.857L449.964 317.786L448.536 314.643L441.967 310.714L439.682 304.786L435.969 300.5L430.043 295.357L429.972 292.143L425.117 288.214L419.119 284.357Z"
            fill="url(#paint3_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="C&#195;&#180;te d&#39;Ivoire"
            d="M164.506 267L160.936 268.5L158.866 269.714L157.581 265.5L155.082 266.643L153.582 266.429L151.94 269.286L145.228 269.214L142.872 267.714L141.73 268.643L139.945 269.429L139.231 272.786L141.23 276.857L143.372 284.786L140.088 285.929L139.302 287.286L139.945 289.214L139.374 293.571H138.017L137.589 296.429L138.445 301.214L136.518 305.571L139.088 308.286L141.801 308.929L145.514 313.071L145.728 317L144.943 318.214L144.229 326.357L145.871 326.643L154.51 323L160.579 320.143L170.932 318.429L176.502 318.286L182.571 320.214L186.569 320.143L186.926 316.214L183.213 307.714L185.498 296.571L189.139 288.286L186.855 274.214L180.928 271.643L176.859 272.071L173.788 274.5L169.933 272.429L168.433 269.143L164.506 267Z"
            fill="url(#paint4_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Congo"
            d="M372.353 334.857L366.712 334.357L360.857 332.643L355.645 337.928L351.147 347.071L350.504 352.428L343.578 350.071L336.51 347.5L325.514 347.071L324.872 351.428L327.299 356.5L333.797 355.714L336.01 357.643L332.226 369.143L336.367 375L337.295 382.714L336.153 389.285L333.511 393.928L325.8 393.5L321.159 388.785L320.445 393.143L314.519 394.357L311.52 396.857L314.804 403.357L308.164 408.857L315.233 419.285L318.803 415L321.444 413.357L324.729 416.785L327.014 417.785L330.012 414L334.725 414.071L335.296 416.857L338.509 418.643L343.65 412.428L348.791 407.571L351.004 404.428L350.79 396.285L354.574 386.714L358.572 381.643L364.356 376.857L365.355 373.714L365.57 370.071L366.998 366.643L366.498 361.071L367.569 352.285L369.282 346.143L371.853 340.857L372.353 334.857Z"
            fill="url(#paint5_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Djibouti"
            d="M585.695 256.214L581.911 253.5L586.766 251.143L586.837 247.071L584.695 244L582.125 246.429L578.412 245.571L575.556 249.929L572.771 254.571L573.557 257.286L573.771 260.286L578.626 260.429L580.697 259.786L582.696 261.5L585.695 256.214Z"
            fill="url(#paint6_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Algeria"
            d="M280.175 4.07171L274.606 4.64314L271.178 2.35742L262.468 2.42885L254.899 6.35742L250.758 4.85742L237.263 5.71456L223.412 7.50028L215.558 10.5717L210.417 14.7146L201.563 16.4289L193.638 21.9289L196.637 28.3574L197.137 34.3574L199.921 44.8574L202.135 46.9288L200.564 50.786L189.711 52.4288L185.855 56.0717L181.072 56.9288L180.643 64.2146L170.79 68.0717L167.506 73.0717L160.651 75.7146L152.226 77.2146L138.517 84.5003L138.374 96.1431V96.8574L138.16 98.786L169.648 122.715L198.208 144.286L227.053 165.857L229.124 170.429L234.479 173.286L238.406 174.857L238.549 181.143L248.045 180.215L260.111 175.786L284.744 156.429L313.661 137.572L309.806 131.357L302.951 126.786L299.024 128.572L295.954 123.072L295.526 118.857L290.385 111.643L293.669 107.5L292.741 101.286L293.741 95.8574L293.027 91.3574L294.312 83.286L293.741 78.7146L290.813 70.0003L286.744 52.3574L281.531 48.3574L281.389 46.0003L274.463 40.0717L273.535 32.6431L278.533 27.0717L280.246 18.786L278.604 9.21456L280.175 4.07171Z"
            fill="url(#paint7_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Eritrea"
            d="M555.208 217L550.21 212.143L547.354 203.071L541.784 191.643L537.715 197.285L531.431 198.928L528.861 201.928L528.361 208.5L525.291 223L526.362 227L536.572 229.071L538.928 221.643L544.355 226.143L549.353 223.857L551.495 225.928L557.421 226L565.132 230L567.56 233.428L571.487 236.571L575.342 242.357L578.413 245.571L582.125 246.428L584.696 244L580.269 241L577.413 237.643L572.486 231.928L567.417 226.285L555.208 217Z"
            fill="url(#paint8_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Gabon"
            d="M325.513 347.071L324.442 346.571L319.23 347.857L313.946 346.5L309.805 347.143L309.876 359.071L297.239 358.929L294.24 359.5L292.526 366.857L290.384 374.071L288.456 377.214L288.242 380.5L293.454 390.714L299.238 398.857L308.163 408.857L314.803 403.357L311.519 396.857L314.517 394.357L320.444 393.143L321.158 388.786L325.799 393.5L333.51 393.929L336.152 389.286L337.294 382.714L336.366 375L332.225 369.143L336.009 357.643L333.795 355.714L327.298 356.5L324.87 351.429L325.513 347.071Z"
            fill="url(#paint9_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Guinea"
            d="M111.67 246.571L110.385 247.071L105.673 246.285L105.03 247.428L103.102 247.643L96.8903 245.214L92.7491 245.143L92.5349 248.5L91.6067 249.5L92.2493 252.785L90.8927 254.071L88.9649 254.143L86.6801 255.785L84.0383 255.571L80.1113 260.428L82.6817 262L83.8955 264.214L84.8237 268.571L86.9657 270.5L89.1791 271.785L92.5349 275.643L96.3191 281.5L100.96 277.143L102.031 274.428L103.531 272.285L105.887 272.071L107.957 270.214H114.883L117.239 273.785L119.096 277.928L118.81 280.785L120.167 283.357L120.095 287L122.452 286.428L124.308 286.214L126.593 285.071L130.234 291.143L129.592 295.143L131.305 297.214L133.733 297.285L135.589 293.285L138.017 293.571H139.373L139.945 289.214L139.302 287.285L140.087 285.928L143.372 284.785L141.23 276.857L139.231 272.785L139.945 269.428L141.73 268.643L139.088 265.785L139.588 262.857L138.445 261.714L136.589 262.643L137.017 259.428L138.802 256.928L135.232 252.785L134.233 250.071L132.305 247.857L130.591 247.643L128.521 249L125.665 250.285L123.308 252.428L119.596 251.643L117.239 249.143L115.811 248.857L113.527 250.143H112.099L111.67 246.571Z"
            fill="url(#paint10_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Gambia"
            d="M65.6172 239.572L73.4712 239.715L75.6132 238.357L77.184 238.286L80.397 236L84.1098 238.072L87.8226 238.286L91.6068 236.072L89.8932 233.215L87.0372 234.857L84.324 234.786L80.9682 232.357L78.255 232.5L76.2558 234.857L66.831 235.143L65.6172 239.572Z"
            fill="url(#paint11_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Guinea-Bissau"
            d="M92.7491 245.143L76.7555 244.714L74.3993 245.857L71.5433 245.5L66.9023 247.143L67.4021 249.214L70.0439 251.357L69.9725 252.857L71.9003 255.643L75.5417 256.286L80.1113 260.429L84.0383 255.571L86.6801 255.786L88.9649 254.143L90.8927 254.072L92.2493 252.786L91.6067 249.5L92.535 248.5L92.7491 245.143Z"
            fill="url(#paint12_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Eq. Guinea"
            d="M309.806 347.143L295.597 346.929L292.67 358L294.241 359.5L297.24 358.929L309.877 359.072L309.806 347.143Z"
            fill="url(#paint13_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Liberia"
            d="M138.017 293.571L135.59 293.286L133.733 297.286L131.306 297.214L129.592 295.143L130.235 291.143L126.593 285.071L124.308 286.214L122.452 286.428L118.382 291.071L114.455 296.428L113.955 299.286L111.885 302.428L117.668 308.786L125.094 314.214L132.948 321.714L141.944 326.428L144.229 326.357L144.943 318.214L145.728 317L145.514 313.071L141.801 308.928L139.088 308.286L136.518 305.571L138.446 301.214L137.589 296.428L138.017 293.571Z"
            fill="url(#paint14_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Libya"
            d="M421.118 57.5711L418.905 54.3569L410.551 53.214L407.766 51.4997H404.625L401.483 47.0712L390.131 45.0711L384.49 46.3569L378.849 50.9997L376.493 55.7854L378.921 63.3569L375.137 67.8569L371.21 70.4283L361.999 65.4997L350.075 61.4283L342.507 59.4997L338.151 50.714L326.942 46.3569L319.944 44.714L316.517 45.5711L306.664 42.1426L306.45 49.714L302.451 52.5711L300.024 55.714L294.383 59.4997L295.454 63.5711L294.812 67.714L290.813 69.9997L293.741 78.714L294.312 83.2854L293.027 91.3569L293.741 95.8569L292.741 101.285L293.669 107.5L290.385 111.643L295.526 118.857L295.954 123.071L299.024 128.571L302.951 126.785L309.806 131.357L313.661 137.571L327.227 141.857L332.154 147.214L338.08 143.571L346.577 138.143L381.134 157.071L415.906 176L415.835 171.857H425.688L424.974 152.071L423.403 115.714L421.332 80.5712L418.334 72.5711L420.047 66.4997L418.476 62.2854L421.118 57.5711Z"
            fill="url(#paint15_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Lesotho"
            d="M446.466 671.357L448.251 668.214L453.035 666.643L454.748 663.429L457.676 658.643L454.962 655.643L451.535 652.643L447.465 654.643L442.61 658.5L437.684 664.714L443.396 672.286L446.466 671.357Z"
            fill="url(#paint16_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Madagascar"
            d="M644.671 503.429L642.886 496.929L640.744 492.714L637.96 488.5L634.89 492.929L634.39 498.857L629.32 505.714L625.679 504.5L626.607 508.786L623.751 513.714L616.326 519.786L611.113 525.429L607.258 525.571L603.973 527.357L599.047 529.357L594.763 529.786L593.192 536L589.836 541.571L589.979 550.643L591.193 556.857L592.906 561.571L591.693 567.857L587.123 575.357L586.909 578.643L582.839 580.357L580.84 587.5L581.125 594.571L583.553 602.429L583.482 611.214L585.338 616.429L591.835 620L596.476 622.5L604.259 618.357L611.328 616L616.183 604.5L620.538 590.786L627.178 572.071L632.391 558.429L636.603 546.929L637.746 538.571L640.245 536.286L641.316 532.071L640.102 524.786L641.958 521.857L644.457 527.643L646.171 524.714L647.385 520L645.385 515.357L644.671 503.429Z"
            fill="url(#paint17_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Mali"
            d="M248.045 180.214L238.548 181.143L238.406 174.857L234.479 173.286L229.124 170.429L227.053 165.857L198.207 144.286L169.647 122.714L156.653 122.857L160.294 165.5L164.15 208.143L165.578 209.429L163.65 216.286L129.021 216.429L127.664 218.643L124.38 218L119.453 219.929L113.456 217.214L110.742 217.429L109.172 223.286L106.244 225.072L106.53 231.214L108.172 236.857L111.385 239.643L112.099 243.429L111.671 246.572L112.099 250.143H113.527L115.812 248.857L117.24 249.143L119.596 251.643L123.309 252.429L125.665 250.286L128.521 249L130.592 247.643L132.305 247.857L134.233 250.072L135.233 252.786L138.803 256.929L137.018 259.429L136.589 262.643L138.446 261.714L139.588 262.857L139.088 265.786L141.73 268.643L142.872 267.714L145.229 269.214L151.94 269.286L153.582 266.429L155.082 266.643L157.581 265.5L158.866 269.714L160.937 268.5L164.507 267L163.935 261.286L166.363 257.071L166.149 253.714L173.075 245.571L174.36 238.786L176.787 236.357L180.929 237.714L184.57 235.714L185.784 233.143L192.495 228.714L194.138 225.643L202.206 221.5L206.99 220.143L209.132 222H214.701L220.199 221.572L223.412 218.071L235.193 217.214L242.832 215.643L243.546 209.571L248.259 203L248.045 180.214Z"
            fill="url(#paint18_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Mozambique"
            d="M562.775 475.857L561.562 471.429L554.422 477.214L544.854 481L539.642 480.857L536.429 483.857L530.431 484.072L528.075 485.357L517.793 482.572L514.438 482.929L512.081 492.286L513.081 503.572H513.581L516.58 506.714L519.864 513.857L520.078 526.572L516.223 528.714L513.295 535.572L507.94 529.429L507.583 522.5L509.654 517.857L509.297 513.929L505.941 511.429L503.585 512.286L498.73 507.572L472.24 515.714L472.811 522.786L473.24 526.429L480.38 526.214L484.307 528.357L486.092 530.786L490.162 531.5L494.517 534.643L493.946 547.143L491.947 554L491.233 561.429L492.446 564.357L491.233 570.143L489.876 571.072L487.306 578.143L477.738 589.357L481.094 603.286L482.879 610.357L480.737 621.357L481.237 624.929L482.165 629.357L482.593 633.714L489.019 633.786L490.09 628.572L488.02 627.929L487.591 623.786L491.518 620.072L502.085 614.714L509.297 611.429L513.081 607.857L514.58 603.786L512.653 602.072L514.366 597.5L515.152 587.786L513.581 588.286L513.652 585.357L512.296 579.572L508.583 572.072L509.725 565L513.367 562.714L519.721 555.714L523.077 553.929L533.359 543.286L543.355 538.5L551.423 534.714L557.206 528.643L560.919 521.786L563.846 514.714L562.561 509.857L562.847 494.429L562.133 485.714L562.775 475.857Z"
            fill="url(#paint19_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Mauritania"
            d="M169.647 122.714L138.16 98.7856L137.803 113.714L110.028 113.214L109.743 138.5L101.746 139.428L99.5324 144.5L100.889 158.786L67.4024 158.714L65.4746 162L69.83 166.286L71.972 170.928L70.9724 175.857L71.9006 180.786L72.6146 190.571L71.3294 199.857L68.8304 204.714L69.473 210.071L72.4718 206.857L76.7558 207.714L80.9684 205.571L85.8236 205.5L89.8934 208.357L95.534 211L100.675 218.286L106.244 225.071L109.171 223.286L110.742 217.428L113.455 217.214L119.453 219.928L124.38 218L127.664 218.643L129.021 216.428L163.65 216.286L165.577 209.429L164.149 208.143L160.294 165.5L156.652 122.857L169.647 122.714Z"
            fill="url(#paint20_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Malawi"
            d="M510.225 508.357L510.439 504.643L508.511 501.714L508.797 497.429L506.441 490.143L509.083 484.643L508.94 472.714L506.012 466.357L506.298 465.286L504.656 462.571L496.159 460.714L500.158 465.143L502.157 473.5L500.586 476.214L498.658 484.214L500.086 492.429L497.23 495.857L494.303 505L498.73 507.571L503.585 512.286L505.941 511.429L509.297 513.929L509.654 517.857L507.583 522.5L507.94 529.429L513.295 535.571L516.223 528.714L520.078 526.572L519.864 513.857L516.58 506.714L513.581 503.571H513.081L513.152 504.929L514.795 505.286L516.365 510.571L516.08 511.786L513.152 508L511.582 510.429L510.225 508.357Z"
            fill="url(#paint21_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Niger"
            d="M327.227 141.857L313.661 137.571L284.744 156.428L260.111 175.786L248.044 180.214L248.258 203L243.546 209.571L242.832 215.643L235.192 217.214L223.411 218.071L220.198 221.571L214.7 222L213.986 226.786L215.129 231.286L220.055 237.714L220.341 242.5L230.337 244.714L230.123 251.5L233.05 248.643L236.12 248.571L242.761 254.286L243.332 245.428L245.831 241.5L246.973 235.786L249.258 233.643L258.54 232.5L267.251 236.143L270.535 239.928L274.891 240.071L279.032 237.643L289.528 242.714L293.954 242.5L299.024 238.286L304.093 238.571L306.592 237.214L311.233 237.786L317.945 240.643L324.656 235.143L326.727 235.571L332.725 246.357L334.295 246.143L334.581 243L337.009 242.428L337.794 237.786L332.225 237.571L332.153 231.214L328.512 227.5L332.011 214.5L342.863 205.214L343.006 192.357L345.862 172.286L347.647 168L344.006 164.643L343.792 161.5L340.507 158.928L338.08 143.571L332.153 147.214L327.227 141.857Z"
            fill="url(#paint22_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Rwanda"
            d="M476.882 380.714L471.599 383.786L469.528 382.786L467.029 385.5L466.672 391.429L465.458 392.143L464.602 397.571L469.885 398.357L472.598 392.714L477.239 393.357L479.738 392.072L480.31 386.286L476.882 380.714Z"
            fill="url(#paint23_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="W. Sahara"
            d="M138.374 96.1426H137.089L137.16 101.5L131.948 101.785L129.163 104.071H125.308L122.309 102.785L115.169 103.785L112.17 111.5L109.528 112.285L105.244 124.714L93.1778 135.428L90.0362 149.071L86.4662 153.5L85.3238 157.071L66.0458 157.857L65.903 157.785L65.4746 162L67.4024 158.714L100.889 158.785L99.5324 144.5L101.746 139.428L109.743 138.5L110.028 113.214L137.803 113.714L138.16 98.7854L138.374 96.8569V96.1426Z"
            fill="url(#paint24_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Sudan"
            d="M528.36 208.5L528.86 201.928L531.43 198.928L537.714 197.286L541.783 191.643L536.857 188L533.43 185.571L529.645 173.786L527.86 163.571L529.645 161.786L526.29 152.071H492.446H459.316H424.973L425.687 171.857H415.834L415.905 176L417.619 215.214L410.122 214.571L406.409 221.857L404.267 227.928L406.123 230.214L403.41 233.286L404.481 237.357L402.339 241.5L401.482 245.071L404.553 244.5L406.409 248.357L406.623 254.071L409.908 257L409.836 259.428L410.907 263.643L416.048 269.786L416.119 273.857L414.763 277.928L415.405 281L418.404 283.857L419.118 284.357L421.831 283.214L424.759 281.357L426.829 272.571L429.114 268L435.397 266.643L436.897 269.357L441.538 275.071L443.894 275.928L447.036 274.286L453.247 274.571L454.533 276.643H463.172L463.386 274.571L467.884 272.714L468.67 269.857L471.954 267.786L479.38 273.571L483.807 272.571L487.948 265.428L492.589 260L491.661 254.071L489.519 251.214L494.731 250.643L495.231 248.5L499.3 249.143L498.444 256.428L499.657 263.571L504.298 267.5L505.369 270.857V275.786L506.583 276L506.512 274.786L508.868 264.428L512.866 261.714L513.652 257.643L517.079 250.071L522.148 245.214L525.361 235.5L526.361 227L525.29 223L528.36 208.5Z"
            fill="url(#paint25_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="S. Sudan"
            d="M505.37 270.857L504.299 267.5L499.658 263.571L498.445 256.429L499.301 249.143L495.232 248.5L494.732 250.643L489.52 251.214L491.662 254.071L492.59 260L487.949 265.429L483.808 272.571L479.381 273.571L471.955 267.786L468.671 269.857L467.885 272.714L463.387 274.571L463.173 276.643H454.534L453.248 274.571L447.037 274.286L443.895 275.929L441.539 275.071L436.898 269.357L435.398 266.643L429.115 268L426.83 272.571L424.76 281.357L421.832 283.214L419.119 284.357L425.117 288.214L429.972 292.143L430.043 295.357L435.97 300.5L439.682 304.786L441.967 310.714L448.536 314.643L449.964 317.786L455.319 325.929L459.246 327.143L461.531 325.5L465.601 326.143L470.456 324.071L472.526 328.286L480.309 334.857L483.879 332.143L489.448 334.357L496.445 332L502.586 332.071L507.869 327.5L513.153 321.571L518.936 315.071L513.653 304.357L509.583 302.071L508.012 298.071L503.514 293.286L498.159 292.571L501.015 286.929L505.585 286.714L506.87 283.714L506.584 276L505.37 275.786V270.857Z"
            fill="url(#paint26_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Sierra Leone"
            d="M122.453 286.429L120.097 287L120.168 283.357L118.811 280.786L119.097 277.929L117.241 273.786L114.884 270.214H107.959L105.888 272.072L103.532 272.286L102.032 274.429L100.961 277.143L96.3203 281.5L97.3199 288.857L98.8193 292.429L103.318 297.714L109.529 301.714L111.886 302.429L113.956 299.286L114.456 296.429L118.383 291.072L122.453 286.429Z"
            fill="url(#paint27_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Swaziland"
            d="M481.238 624.928L477.097 623.071L474.598 623.786L473.598 626.643L471.028 630.357L470.885 633.786L475.597 639.143L480.595 638.071L482.594 633.714L482.166 629.357L481.238 624.928Z"
            fill="url(#paint28_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Chad"
            d="M415.905 176L381.134 157.071L346.576 138.143L338.079 143.571L340.507 158.928L343.791 161.5L344.006 164.643L347.647 168L345.862 172.285L343.006 192.357L342.863 205.214L332.01 214.5L328.512 227.5L332.153 231.214L332.225 237.571L337.794 237.785L337.008 242.428L340.507 248.785L341.221 255.285L340.935 261.857L345.791 270.857L340.935 270.785L338.508 271.428L334.509 270.5L332.724 275.143L337.865 280.928L341.649 282.571L342.935 286.643L345.719 293.5L344.434 296.143L351.574 295.428L353.145 292.857L354.644 293.071L356.786 295.285L367.71 291.5L371.352 287.643L375.85 284.214L374.993 280.714L377.421 279.857L385.846 280.428L393.914 275.857L400.055 265.071L404.339 261.071L409.836 259.428L409.908 257L406.623 254.071L406.409 248.357L404.553 244.5L401.483 245.071L402.339 241.5L404.481 237.357L403.41 233.285L406.124 230.214L404.267 227.928L406.409 221.857L410.122 214.571L417.619 215.214L415.905 176Z"
            fill="url(#paint29_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Tunisia"
            d="M306.665 42.1429L303.452 40.5714L301.167 35.8571L296.883 35.7143L295.169 30.2857L300.382 25.2857L301.167 16.6429L298.24 14.1429L298.097 9.5L302.024 4.5L301.381 2.57143L294.598 6.28571L294.67 1.21429L288.886 0L280.175 4.07143L278.605 9.21429L280.247 18.7857L278.533 27.0714L273.535 32.6429L274.463 40.0714L281.389 46L281.532 48.3571L286.744 52.3571L290.814 70L294.812 67.7143L295.455 63.5714L294.384 59.5L300.025 55.7143L302.452 52.5714L306.451 49.7143L306.665 42.1429Z"
            fill="url(#paint30_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Somalia"
            d="M594.476 266.285L590.407 262.143L588.622 258.071L585.694 256.214L582.695 261.5L580.91 265L584.338 270.5L587.622 275.214L591.049 278.785L619.823 290.5L627.178 290.428L603.259 320.071L591.906 320.5L584.266 327.5L578.697 327.643L576.341 330.785L568.844 342L569.129 378L574.27 386.143L576.198 383.785L578.269 378.571L587.836 366.643L595.976 359.071L608.899 349.285L617.539 341.285L627.606 327.785L634.817 316.714L641.957 302.285L646.955 289.643L650.811 278.571L652.882 267.928L654.595 264.357L654.31 259.143L654.881 253.428L654.524 250.643L651.239 250.714L647.312 254.071L642.743 255.071L638.816 256.571L636.031 256.714L631.105 257.071L628.106 258.928L623.822 259.571L616.325 262.643L606.9 263.785L598.832 266.285H594.476Z"
            fill="url(#paint31_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
        </g>
        <g id="Regions">
          <RegionPath
            id="Kenya"
            d="M576.342 330.786H570.344L566.846 327.428L558.92 331.571L556.421 335.714L550.566 334.928L548.639 333.786L546.568 334.071L543.855 333.928L532.645 325.571H526.576L523.577 322.286L523.506 316.786L518.936 315.071L513.153 321.571L507.869 327.5L512.082 334.357L513.153 339.357L517.08 350.643L513.938 357.857L509.726 364.428L507.227 368.428V368.928L509.297 372.643L508.726 380L540.213 400.143L540.713 405.857L553.065 415.714L556.635 412.428L558.42 405.857L561.276 401.928L562.633 394.928L565.917 394.214L568.131 390.071L574.271 386.143L569.13 378L568.845 342L576.342 330.786Z"
            fill="url(#paint32_linear_2266_2609)"
            onClick={() => setSelectedRegion("Kenya")}
            selected={selectedRegion}
          />
          <RegionPath
            id="Nigeria"
            d="M334.295 246.143L332.724 246.357L326.727 235.571L324.656 235.143L317.944 240.643L311.233 237.786L306.592 237.214L304.093 238.571L299.023 238.286L293.954 242.5L289.527 242.714L279.031 237.643L274.89 240.071L270.535 239.929L267.25 236.143L258.54 232.5L249.258 233.643L246.973 235.786L245.83 241.5L243.331 245.429L242.76 254.286L242.403 257.571L244.402 263.429L242.689 267.429L243.617 270.071L239.404 276.143L236.763 279.214L235.12 285.429L235.335 291.714L234.906 307.643H242.546L249.115 307.571L255.255 314.071L258.183 321.214L262.824 327.357L269.821 327.571L273.177 325.357L276.461 325.929L285.529 322.357L287.742 315.357L291.812 305.786L294.382 305.714L299.523 299.929L302.808 299.786L307.734 303.857L313.66 300.5L314.446 296.429L316.374 292.429L317.73 287.429L322.3 283.357L324.013 276.429L325.87 274.214L327.012 269.071L329.226 262.786L336.508 255.143L336.865 251.857L337.794 250.071L334.295 246.143Z"
            fill="url(#paint33_linear_2266_2609)"
            onClick={() => setSelectedRegion("Nigeria")}
            selected={selectedRegion}
          />
          <RegionPath
            id="Tanzania"
            d="M479.95 379.5L476.88 380.714L480.307 386.286L479.736 392.071L477.237 393.357L477.665 397.214L479.522 399.5L479.593 402.714L477.451 404.786L473.952 409.929L470.739 413.5L469.883 413.643L469.383 417.857L471.025 419.286L470.668 423.5L472.31 427.429L470.24 431.214L477.165 437.929L477.737 444L481.949 454.143L482.378 454.429L485.805 456.071L491.303 457.786L496.158 460.714L504.654 462.571L506.297 465.286L506.868 463.357L511.295 468.643L511.723 479L514.508 482.786L514.436 482.929L517.792 482.571L528.074 485.357L530.43 484.071L536.427 483.857L539.64 480.857L544.853 481L554.42 477.214L561.56 471.429L558.419 469.286L555.063 459.571L552.207 453.357L552.921 448.643L552.492 445.643L554.991 439.643L554.777 437.071L549.279 433.5L548.851 427.929L553.064 415.714L540.711 405.857L540.212 400.143L508.724 380L504.44 384.357L501.513 388.857L504.94 392.214L499.942 394.643L498.871 393.5L493.802 394.143L489.875 396.357L487.518 392.5L489.161 385.571L489.518 379.643L479.95 379.5Z"
            fill="url(#paint34_linear_2266_2609)"
            onClick={() => setSelectedRegion("Tanzania")}
            selected={selectedRegion}
          />
          <RegionPath
            id="South Africa"
            d="M372.922 713.571L376.706 713.357L382.061 709.571L389.201 708L397.983 704.071L401.339 704.571L406.48 703.357L415.262 705.286L419.475 703.428L424.401 704.857L425.687 702.143L429.971 701.571L438.967 697.786L445.607 693.286L451.962 687.357L462.243 677.214L467.598 670.143L470.383 665.071L474.31 660.071L476.095 658.643L482.235 653.643L484.734 649.214L486.377 641L489.018 633.786L482.592 633.714L480.593 638.071L475.595 639.143L470.883 633.786L471.026 630.357L473.596 626.643L474.596 623.786L477.095 623.071L481.236 624.929L480.736 621.357L482.878 610.357L481.093 603.286L477.737 589.357L473.239 588.428L470.312 589.571L466.242 587.928L462.743 587.786L450.391 595.071L442.394 602.429L439.253 609.071L436.468 612.786L431.827 613.571L430.113 618.286L429.114 621.357L423.545 623.643L416.69 623.214L412.763 620.428L409.265 619.214L404.981 621.5L402.696 626.214L398.555 629.214L394.128 633.643L388.059 634.643L386.274 631.143L387.345 625.143L382.704 615.714L380.419 614.286L378.848 650.786L371.137 655.857L366.567 656.571L361.355 654.714L357.642 654L356.429 649.714L353.216 647.071L348.932 651.928L354.429 664.714V664.786L358.285 673.143L363.212 682.286L363.069 689.857L360.284 691.643L362.569 698.286L362.212 704.071L363.14 706.786L363.64 705.428L366.924 709.857L369.638 710L372.922 713.571ZM446.464 671.357L443.394 672.286L437.682 664.714L442.608 658.5L447.464 654.643L451.533 652.643L454.961 655.643L457.674 658.643L454.746 663.428L453.033 666.643L448.249 668.214L446.464 671.357Z"
            fill="url(#paint35_linear_2266_2609)"
            onClick={() => setSelectedRegion("South Africa")}
            selected={selectedRegion}
          />
          <RegionPath
            id="Zambia"
            d="M479.309 454.286L476.382 453.5L476.882 451.357L475.382 450.929L463.673 452.572L461.317 453.786L458.746 460.072L460.603 464.429L458.818 476.143L457.461 486.072L459.746 487.786L465.743 491.643L468.171 489.857L468.599 500.5L461.959 500.429L458.532 495L455.462 490.786L448.893 489.429L447.037 484.214L441.682 487.357L434.756 485.929L431.971 481.5L426.473 480.572L422.404 480.786L421.975 477.714L418.976 477.5L419.833 480.572L418.762 485.357L420.19 490L418.762 493.714L419.476 497.072L401.412 497L400.27 528.429L405.839 536.5L411.265 542.643L418.334 540.429L423.903 541L427.259 543.214V544.072L428.83 544.786L438.397 545.857L441.11 547L444.038 546.786L449.036 540.357L456.818 532.214L459.96 531.5L461.174 528.072L466.172 524.143L472.812 522.786L472.241 515.714L498.73 507.572L494.303 505L497.231 495.857L500.087 492.429L498.659 484.214L500.587 476.214L502.157 473.5L500.158 465.143L496.16 460.714L491.305 457.786L485.807 456.072L482.38 454.429L481.951 454.143L482.594 455.786L481.166 456.357L479.309 454.286Z"
            fill="url(#paint36_linear_2266_2609)"
            onClick={() => setSelectedRegion("Zambia")}
            selected={selectedRegion}
          />
        </g>{" "}
        <g id="Neighbours">
          <path
            id="Benin"
            d="M242.76 254.286L236.12 248.571L233.05 248.643L230.122 251.5L228.266 254.5L223.982 255.357L222.197 259.714L219.269 260.857L218.127 266L220.769 268.928L223.839 272.428L224.125 277.286L225.91 279.286L225.553 302L227.695 308.786L234.906 307.643L235.334 291.714L235.12 285.428L236.762 279.214L239.404 276.143L243.617 270.071L242.689 267.428L244.402 263.428L242.403 257.571L242.76 254.286Z"
            fill="url(#paint37_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Botswana"
            d="M428.829 544.786L427.258 544.072L422.332 546.286H419.761L414.121 550.143L410.979 546.072L397.627 549.572L391.201 549.857L389.845 585.072L381.42 585.429L380.42 614.286L382.705 615.714L387.346 625.143L386.275 631.143L388.06 634.643L394.129 633.643L398.556 629.214L402.697 626.214L404.982 621.5L409.266 619.214L412.764 620.429L416.691 623.214L423.546 623.643L429.115 621.357L430.114 618.286L431.828 613.572L436.469 612.786L439.254 609.072L442.395 602.429L450.392 595.072L462.744 587.786L457.532 583.357L450.963 581.857L448.75 575.572L448.893 572.072L445.251 571L435.969 560.143L433.47 554.429L431.828 552.714L428.829 544.786Z"
            fill="url(#paint38_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Cameroon"
            d="M340.935 261.857L341.22 255.286L340.506 248.786L337.008 242.429L334.58 243L334.295 246.143L337.793 250.072L336.865 251.857L336.508 255.143L329.225 262.786L327.012 269.072L325.869 274.214L324.013 276.429L322.299 283.357L317.73 287.429L316.373 292.429L314.445 296.429L313.66 300.5L307.734 303.857L302.807 299.786L299.523 299.929L294.382 305.714L291.812 305.786L287.742 315.357L285.528 322.357L285.457 325.072L287.67 326.5L289.455 330.929L293.454 332.572L296.881 339.143L295.596 346.929L309.804 347.143L313.946 346.5L319.229 347.857L324.441 346.572L325.512 347.072L336.508 347.5L343.577 350.072L350.502 352.429L351.145 347.072L350.217 344.214L349.788 339.714L345.79 336.572L342.506 331.429L341.792 327.929L337.65 322.714L338.293 319.786L337.436 315.643L338.007 308L340.078 306.143L344.433 296.143L345.719 293.5L342.934 286.643L341.649 282.572L337.865 280.929L332.724 275.143L334.509 270.5L338.507 271.429L340.935 270.786L345.79 270.857L340.935 261.857Z"
            fill="url(#paint39_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Dem. Rep. Congo"
            d="M441.467 318.571L434.898 317.571L431.685 318.428L430.4 320.786L427.615 321.143L424.117 319L414.478 323.928L410.479 322.928L409.337 323.714L406.767 329.714L400.269 327.786L393.843 326.786L388.274 323.143L381.063 319.786L376.422 322.928L373.066 328L372.352 334.857L371.852 340.857L369.282 346.143L367.568 352.286L366.497 361.071L366.997 366.643L365.569 370.071L365.355 373.714L364.355 376.857L358.572 381.643L354.573 386.714L350.789 396.286L351.003 404.428L348.79 407.571L343.649 412.428L338.508 418.643L335.295 416.857L334.724 414.071L330.012 414L327.013 417.786L324.728 416.786L321.515 418.857L320.087 421.357L319.801 425.643L317.588 426.714L318.73 429.786L322.372 428.428L324.871 428.643L327.941 427.428L353.645 427.571L355.716 434.928L358.215 440.857L360.142 444.071L363.427 449.214L369.21 448.428L372.066 447.071L376.85 448.428L378.207 446L380.42 440.214L385.846 439.857L386.346 438.143L390.773 438.071L389.988 441.643L400.555 441.571L400.626 447.786L402.34 451.571L400.983 457.571L401.554 463.643L404.339 467.286L403.696 479.071L405.838 478.143L409.623 478.357L415.049 476.928L418.976 477.5L421.975 477.714L422.403 480.786L426.473 480.571L431.971 481.5L434.755 485.928L441.681 487.357L447.036 484.214L448.893 489.428L455.461 490.786L458.532 495L461.959 500.428L468.599 500.5L468.171 489.857L465.743 491.643L459.745 487.786L457.461 486.071L458.817 476.143L460.602 464.428L458.746 460.071L461.316 453.786L463.672 452.571L475.382 450.928L476.881 451.357L477.31 449.714L474.882 447.071L473.811 441.643L468.599 436.214L465.672 429.143L467.314 425L465.029 419.428L466.743 403.643L466.814 403.786L466.743 402.071L464.601 397.571L465.457 392.143L466.671 391.428L467.028 385.5L469.527 382.786L469.599 375.286L471.669 371.5L472.098 363.571L473.954 359L477.238 353.857L480.594 351.214L483.379 347.714L479.88 346.357L480.309 334.857L472.526 328.286L470.455 324.071L465.6 326.143L461.53 325.5L459.245 327.143L455.319 325.928L449.964 317.786L447.107 318.857L441.467 318.571Z"
            fill="url(#paint40_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Egypt"
            d="M448.963 62.9287L440.466 60.0716L432.255 57.4287L421.117 57.5716L418.475 62.2859L420.046 66.5001L418.332 72.5716L421.331 80.5716L423.401 115.714L424.972 152.072H459.316H492.445H526.289L524.718 150.072L514.222 141.214L513.58 134.786L515.15 133.072L506.868 122.143L503.726 116.5L500.228 111.143L492.731 95.7144L486.733 85.7859L482.378 75.4287L483.163 74.5001L490.375 88.643L494.516 93.0716L497.586 96.2144L499.371 94.5001L501.299 89.3573L502.441 81.9287L504.44 77.9287L503.369 75.4287L497.3 61.0716L493.445 63.5001L486.876 62.9287L480.022 60.643L478.379 63.8573L475.666 59.0001L469.597 57.7144L462.314 58.5716L459.101 61.3573L452.961 64.5001L448.963 62.9287Z"
            fill="url(#paint41_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Ethiopia"
            d="M538.928 221.643L536.571 229.071L526.361 227L525.362 235.5L522.149 245.214L517.079 250.071L513.652 257.643L512.867 261.714L508.868 264.428L506.512 274.785L506.583 276L506.869 283.714L505.584 286.714L501.014 286.928L498.158 292.571L503.513 293.285L508.011 298.071L509.582 302.071L513.652 304.357L518.936 315.071L523.505 316.785L523.577 322.285L526.575 325.571H532.644L543.854 333.928L546.567 334.071L548.638 333.785L550.566 334.928L556.421 335.714L558.92 331.571L566.845 327.428L570.344 330.785H576.341L578.697 327.643L584.267 327.5L591.906 320.5L603.259 320.071L627.178 290.428L619.824 290.5L591.05 278.785L587.622 275.214L584.338 270.5L580.911 265L582.696 261.5L580.697 259.785L578.626 260.428L573.771 260.285L573.557 257.285L572.771 254.571L575.556 249.928L578.412 245.571L575.342 242.357L571.486 236.571L567.559 233.428L565.131 230L557.42 226L551.494 225.928L549.352 223.857L544.354 226.143L538.928 221.643Z"
            fill="url(#paint42_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Ghana"
            d="M211.63 260.643L207.632 259.857L204.847 261.429L200.991 260.715L185.926 261.143L185.712 266.786L186.854 274.215L189.139 288.286L185.498 296.572L183.213 307.715L186.926 316.215L186.569 320.143L194.351 322.929L202.205 320.072L206.989 316.715L220.698 310.929L218.699 307.429L216.414 301.143L215.7 296.286L217.628 287.357L215.486 283.786L214.629 276V268.786L210.987 263.715L211.63 260.643Z"
            fill="url(#paint43_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Morocco"
            d="M193.637 21.9286L190.067 21.8571L181.57 19.6429L173.716 20.2857L168.861 16.0714L162.792 16L160.079 22.1429L154.367 32.5L148.155 36.5714L139.73 41.1429L134.232 47.7857L132.947 53L129.52 61.4286L131.305 73.7143L124.093 81.9286L119.809 84.5714L112.955 91.2857L105.101 92.4286L100.746 96.2143L100.603 96.3571L94.9621 106.429L89.1787 110.071L85.9657 116.143L85.6087 121.429L83.1811 127.214L80.3251 128.786L75.3985 135.071L72.2569 142.071L72.7567 145.357L69.8293 150.571L66.4735 153.214L65.9023 157.786L66.0451 157.857L85.3231 157.071L86.4655 153.5L90.0355 149.071L93.1771 135.429L105.244 124.714L109.528 112.286L112.17 111.5L115.168 103.786L122.308 102.786L125.307 104.071H129.163L131.947 101.786L137.16 101.5L137.088 96.1429H138.373L138.516 84.5L152.225 77.2143L160.65 75.7143L167.505 73.0714L170.789 68.0714L180.642 64.2143L181.071 56.9286L185.854 56.0714L189.71 52.4286L200.563 50.7857L202.134 46.9286L199.92 44.8571L197.136 34.3571L196.636 28.3571L193.637 21.9286Z"
            fill="url(#paint44_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Namibia"
            d="M411.263 542.643L395.269 546.714L374.492 545.286L368.637 540.572L333.794 541L332.437 541.714L327.368 537.214L321.799 536.929L316.658 538.572L312.445 540.5L312.874 548.072L319.657 557.714L321.442 563.929L325.726 575.786L329.938 583.929L333.223 588L334.151 593.429L334.08 605.214L336.507 620.429L338.364 627.643L339.934 637.214L343.005 644.429L348.931 651.929L353.215 647.072L356.428 649.714L357.642 654L361.354 654.714L366.567 656.572L371.136 655.857L378.847 650.786L380.418 614.286L381.418 585.429L389.843 585.072L391.2 549.857L397.626 549.572L410.977 546.072L414.119 550.143L419.76 546.286H422.33L427.257 544.072V543.214L423.901 541L418.332 540.429L411.263 542.643Z"
            fill="url(#paint45_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Senegal"
            d="M106.243 225.071L100.674 218.286L95.5331 211L89.8925 208.357L85.8227 205.5L80.9675 205.571L76.7549 207.714L72.4709 206.857L69.4721 210.071L67.3301 215.143L62.9747 222.071L59.1191 223.929L63.4031 227.429L66.8303 235.143L76.2551 234.857L78.2543 232.5L80.9675 232.357L84.3233 234.786L87.0365 234.857L89.8925 233.214L91.6061 236.071L87.8219 238.286L84.1091 238.071L80.3963 236L77.1833 238.286L75.6125 238.357L73.4705 239.714L65.6165 239.571L66.9017 247.143L71.5427 245.5L74.3987 245.857L76.7549 244.714L92.7485 245.143L96.8897 245.214L103.102 247.643L105.029 247.429L105.672 246.286L110.384 247.071L111.67 246.571L112.098 243.429L111.384 239.643L108.171 236.857L106.529 231.214L106.243 225.071Z"
            fill="url(#paint46_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Togo"
            d="M219.269 260.857L211.629 260.643L210.986 263.714L214.628 268.785V276L215.485 283.785L217.627 287.357L215.699 296.285L216.413 301.143L218.698 307.428L220.697 310.928L227.694 308.785L225.552 302L225.909 279.285L224.124 277.285L223.838 272.428L220.768 268.928L218.126 266L219.269 260.857Z"
            fill="url(#paint47_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Uganda"
            d="M507.869 327.5L502.586 332.071L496.445 332L489.448 334.357L483.879 332.143L480.309 334.857L479.88 346.357L483.379 347.714L480.594 351.214L477.239 353.857L473.954 359L472.098 363.571L471.669 371.5L469.599 375.286L469.527 382.786L471.598 383.786L476.882 380.714L479.952 379.5L489.519 379.643L489.02 375.857L493.089 370.071L498.587 368.643L502.3 366.286L506.798 368.214L507.227 368.929V368.429L509.726 364.429L513.938 357.857L517.08 350.643L513.153 339.357L512.082 334.357L507.869 327.5Z"
            fill="url(#paint48_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
          <path
            id="Zimbabwe"
            d="M477.737 589.357L487.305 578.143L489.875 571.071L491.232 570.143L492.446 564.357L491.232 561.428L491.946 554L493.945 547.143L494.516 534.643L490.161 531.5L486.091 530.786L484.306 528.357L480.379 526.214L473.239 526.428L472.811 522.786L466.17 524.143L461.172 528.071L459.959 531.5L456.817 532.214L449.034 540.357L444.036 546.786L441.109 547L438.396 545.857L428.828 544.786L431.827 552.714L433.469 554.428L435.968 560.143L445.25 571L448.892 572.071L448.749 575.571L450.962 581.857L457.531 583.357L462.743 587.786L466.242 587.928L470.312 589.571L473.239 588.428L477.737 589.357Z"
            fill="url(#paint49_linear_2266_2609)"
            stroke="#13E2FF"
            strokeOpacity="0.45"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2266_2609"
          x1="297.2"
          y1="401.257"
          x2="470.695"
          y2="583.403"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2266_2609"
          x1="462.593"
          y1="390.815"
          x2="489.769"
          y2="415.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2266_2609"
          x1="155.038"
          y1="215.21"
          x2="219.08"
          y2="316.202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2266_2609"
          x1="322.36"
          y1="251.476"
          x2="423.35"
          y2="418.858"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2266_2609"
          x1="129.467"
          y1="259.952"
          x2="208.667"
          y2="347.944"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2266_2609"
          x1="299.564"
          y1="324.781"
          x2="412.305"
          y2="432.602"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2266_2609"
          x1="570.887"
          y1="242.412"
          x2="593.665"
          y2="266.047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2266_2609"
          x1="114.645"
          y1="-13.8647"
          x2="341.137"
          y2="273.143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2266_2609"
          x1="517.331"
          y1="186.672"
          x2="584.838"
          y2="281.163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2266_2609"
          x1="281.67"
          y1="340.842"
          x2="362.882"
          y2="423.309"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2266_2609"
          x1="71.6352"
          y1="240.411"
          x2="133.248"
          y2="336.905"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2266_2609"
          x1="62.1349"
          y1="231.69"
          x2="66.1449"
          y2="249.976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_2266_2609"
          x1="63.4392"
          y1="243.289"
          x2="79.2189"
          y2="276.793"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_2266_2609"
          x1="290.364"
          y1="345.788"
          x2="304.402"
          y2="370.592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_2266_2609"
          x1="107.35"
          y1="281.319"
          x2="161.138"
          y2="338.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_2266_2609"
          x1="272.256"
          y1="29.997"
          x2="440.612"
          y2="249.676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_2266_2609"
          x1="435.005"
          y1="650.861"
          x2="459.666"
          y2="683.261"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_2266_2609"
          x1="571.924"
          y1="476.341"
          x2="730.524"
          y2="578.015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_2266_2609"
          x1="87.2158"
          y1="109.376"
          x2="274.11"
          y2="342.456"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_2266_2609"
          x1="459.966"
          y1="456.697"
          x2="661.244"
          y2="603.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_2266_2609"
          x1="51.5167"
          y1="87.3271"
          x2="215.682"
          y2="262.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_2266_2609"
          x1="490.849"
          y1="453.922"
          x2="563.228"
          y2="486.094"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_2266_2609"
          x1="196.077"
          y1="126.981"
          x2="337.181"
          y2="335.579"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_2266_2609"
          x1="462.497"
          y1="379.114"
          x2="485.256"
          y2="405.272"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_2266_2609"
          x1="55.7069"
          y1="90.167"
          x2="136.299"
          y2="205.328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_2266_2609"
          x1="382.684"
          y1="140.068"
          x2="546.821"
          y2="364.792"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_2266_2609"
          x1="405.745"
          y1="240.664"
          x2="509.784"
          y2="395.902"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_2266_2609"
          x1="92.8189"
          y1="267.291"
          x2="134.734"
          y2="311.184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_2266_2609"
          x1="469.316"
          y1="621.613"
          x2="490.21"
          y2="641.265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_2266_2609"
          x1="316.572"
          y1="123.806"
          x2="512.421"
          y2="266.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_2266_2609"
          x1="269.096"
          y1="-6.35147"
          x2="350.224"
          y2="43.214"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_2266_2609"
          x1="557.316"
          y1="238.348"
          x2="730.374"
          y2="380.199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" stopOpacity="0.5" />
          <stop offset="1" stopColor="#505050" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_2266_2609"
          x1="504.108"
          y1="320.615"
          x2="593.181"
          y2="406.802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" />
          <stop offset="1" stopColor="#13E2FF" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_2266_2609"
          x1="230.264"
          y1="237.737"
          x2="307.478"
          y2="355.474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" />
          <stop offset="1" stopColor="#13E2FF" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_2266_2609"
          x1="465.224"
          y1="385.331"
          x2="557.036"
          y2="497.975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" />
          <stop offset="1" stopColor="#13E2FF" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_2266_2609"
          x1="342.611"
          y1="594.715"
          x2="443.575"
          y2="753.144"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" />
          <stop offset="1" stopColor="#13E2FF" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_2266_2609"
          x1="395.672"
          y1="456.221"
          x2="474.317"
          y2="573.737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A7BB" />
          <stop offset="1" stopColor="#13E2FF" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_2266_2609"
          x1="231.265"
          y1="248.571"
          x2="231.265"
          y2="308.786"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_2266_2609"
          x1="421.582"
          y1="544.072"
          x2="421.582"
          y2="634.643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_2266_2609"
          x1="318.301"
          y1="242.429"
          x2="318.301"
          y2="352.429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_2266_2609"
          x1="400.483"
          y1="317.571"
          x2="400.483"
          y2="500.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_2266_2609"
          x1="472.31"
          y1="57.4287"
          x2="472.31"
          y2="152.072"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_2266_2609"
          x1="562.668"
          y1="221.643"
          x2="562.668"
          y2="335.714"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_2266_2609"
          x1="201.955"
          y1="259.857"
          x2="201.955"
          y2="322.929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_2266_2609"
          x1="134.018"
          y1="16"
          x2="134.018"
          y2="157.857"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_2266_2609"
          x1="369.851"
          y1="536.929"
          x2="369.851"
          y2="656.572"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_2266_2609"
          x1="85.6085"
          y1="205.5"
          x2="85.6085"
          y2="247.643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_2266_2609"
          x1="219.34"
          y1="260.643"
          x2="219.34"
          y2="310.928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_2266_2609"
          x1="493.304"
          y1="327.5"
          x2="493.304"
          y2="383.786"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_2266_2609"
          x1="461.672"
          y1="522.786"
          x2="461.672"
          y2="589.571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#298E9D" />
          <stop offset="1" stopColor="#237985" />
        </linearGradient>

        <linearGradient
          id="paint0_linear_1369_2453"
          x1="-3.15917"
          y1="6.83118"
          x2="113.049"
          y2="164.429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39710" />
          <stop offset="1" stopColor="#F44336" />
        </linearGradient>

        <clipPath id="clip0_2266_2609">
          <rect width="714" height="715" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InteractiveAfricaSvg;
