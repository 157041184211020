import AbcHero from "../components/abc/abc-hero";
import OurVsion from "../components/about-us/our-vision";
import WhoWeAre from "../components/about-us/who-we-are";
import { Footer } from "../components/shared";
import "../css/about-us.css"

const AboutUS = () => {
  const heroContent={
    title:"About Adanian Labs",
    substitle:"Actuating the Tech Revolution on the continent. Building Technology Tools for a Borderless Africa",
    links:[],
    bottomContent:"In just three years since our establishment in January 2020, Adanian Labs has developed cutting-edge technologies to power various sectors, including health, agriculture, financial services, media, and logistics. Our products have been tried and tested by leading industry players, including some of the fastest-growing startups in Africa. Our mission is to revolutionise technology in Africa by making it accessible, affordable, and relevant through decentralisation. Adanian Labs provides a platform for African tech startups to access resources critical for sustainable growth, from funding to technical support and go-to-market strategies. Our goal is to create strong and resilient companies, entrepreneurs, and youth who will drive African economies forward, despite limited resources. We refer to this as the #CamelTheory."


}
  return (
    <div className="body-container">
      <div
        className="container mx-auto tools-body overflow-hidden"
        style={{ background: "#454545}" }}
      >
        <AbcHero content={heroContent}></AbcHero>
        <WhoWeAre/>
        <OurVsion/>
        <Footer/>
      </div>
    </div>
  );
};

export default AboutUS;
