import React, { useState, useEffect } from 'react';
const PolicyComponent = () => {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetch('https://dashboard.adanianlabs.io/wp-json/wp/v2/privacy_policy')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setPosts(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    return (
        <section className="relative mt-12 md:mt-0 md:pb-20 md:pt-11" id="faqs">
            <div className="absolute inset-0 h-1 w-4/5 -translate-x-6 bg-gradient-to-r from-[#F39711] to-[#F54C11] md:w-3/5" />

            <div className="px-5 md:px-28">
                {posts.map(post => (
                    <div key={post.id}>
                        <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} className='privacy-policy' />
                    </div>
                ))}
            </div>
        </section>
    );
}

export default PolicyComponent;