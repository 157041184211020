const ContactForm = ({email,phone,fullName,setName,setemail,setphone,companyName, setcompanyName,companyAddress, setcompanyAddress,companyType, setcompanyType,sector, setsector,companySize, setcompanySize,referral, setreferral}) => {
  return (
    <div className="space-y-10 z-10">
      <div className="input-group z-10">
        <p className="input-label z-10" >Full Name</p>
        <hr className="input-hr z-10"></hr>
        <input className="form-input z-10" id="fullName" value={fullName} onChange={(e)=>{setName(e.target.value)}}></input>
      </div>
      <div className="input-group z-10">
        <p className="input-label z-10">Email</p>
        <hr className="input-hr z-10"></hr>
        <input className="form-input z-10" id="email"  value={email} onChange={(e)=>{setemail(e.target.value)}}></input>
      </div>
      <div className="input-group">
        <p className="input-label z-10">Phone Number</p>
        <hr className="input-hr z-10"></hr>
        <div className="flex items-start gap-[10px] order-2 z-10">
          <input
          id="countryCode"
            className="code-input flex items-center justify-center text-center text-black"
            placeholder="country code"
            type="number"
          ></input>
          <input id="phone" className="phone-input" type="number" value={phone} onChange={(e)=>{setphone(e.target.value)}}></input>
        </div>
      </div>
      <div className="input-group z-10">
        <p className="input-label z-10">Company name</p>
        <hr className="input-hr z-10"></hr>
        <input id="companyName" className="form-input z-10"  value={companyName} onChange={(e)=>{setcompanyName(e.target.value)}}></input>
      </div>
      <div className="input-group z-10">
        <p className="input-label z-10">Sector</p>
        <hr className="input-hr z-10"></hr>
        <input id="sector" className="form-input z-10"  value={sector} onChange={(e)=>{setsector(e.target.value)}}></input>
      </div>
      <div className="input-group z-10">
        <p className="input-label z-10">Company Type</p>
        <hr className="input-hr z-10"></hr>
        <input id="companyType" className="form-input z-10"  value={companyType} onChange={(e)=>{setcompanyType(e.target.value)}}></input>
      </div>
      <div className="input-group z-10">
        <p className="input-label z-10">Company Address</p>
        <hr className="input-hr z-10"></hr>
        <input id="companyAddress" className="form-input z-10"  value={companyAddress} onChange={(e)=>{setcompanyAddress(e.target.value)}}></input>
      </div>
      <div className="input-group z-10">
        <p className="input-label z-10">Company Size</p>
        <hr className="input-hr z-10"></hr>
        <input className="form-input z-10" id="companySize"  value={companySize} onChange={(e)=>{setcompanySize(e.target.value)}} type="number"></input>
      </div>
      <div className="input-group z-10">
        <p className="input-label z-10">Where did you hear about Adanian Labs?</p>
        <hr className="input-hr z-10"></hr>
        <input className="form-input z-10" id="referral"  value={referral} onChange={(e)=>{setreferral(e.target.value)}}></input>
      </div>
      <p className="alt-text   text-[#ffffff]">
        <span className="text-[#FF0000]">*</span>Alternatively you can email us
        via info@adanianlabs.io
      </p>
    </div>
  );
};

export default ContactForm;
