export const defaultList = [
  "Strategic presence in key countries that attract 60% of African VC funding.",
  "Access to over 800 million potential customers.",
  "Established partnerships within and beyond the Adanian ecosystem to support startups.",
  "Rapid and cost-effective multi-market launch for your business.  ",
  "Fully compliant with the African Free Trade Agreement (AfCFTA), offering a truly borderless opportunity.",
];

export const regions = {
  Kenya: [
    "Kenya",
    "YEAR OF COMMENCEMENT - 2020",
    "23 Startups housed in Adanian Labs Campuses",
    "2 Campuses within Kenya",
    "including Adanian Labs Headquarters",
    "Solid teams and partnerships are established on the ground to support startups within and beyond the Adanian Labs ecosystem.",
  ],
  Nigeria: [
    "Nigeria",
    "YEAR OF COMMENCEMENT - 2021",
    "3 Startups housed at our Adanian Labs Nigeria.",
    "1000+ youths granted a fully-funded Software Development scholarships in 2022 under Power Learn Project.",
    "Established Media partnerships with Voice of Nigeria and 2023 Targeted training for 1000+ employees across Nigeria",
    "Experienced and hand-ons team of experts teams fully grounded and ready to support startups within and beyond Adanian Labs globally.",
  ],
  Tanzania: [
    "Tanzania",
    "YEAR OF COMMENCEMENT - 2022",
    "5 startups housed at our Dar es Salaam campus.",
    "2400+ youths granted a fully funded software development scholarship in 2022 under the Power learn project",
    "Diverse network of partners in and around the country",
    "A strong presence in the tech ecosystem",
  ],
  Zambia: [
    "Zambia",
    "YEAR OF COMMENCEMENT - 2021",
    "8 Startups housed at our Lusaka campus.",
    "1500+ youths granted a fully funded Software Development scholarships in 2022 under Power Learn Project.",
    "Leadership training collaboration with UNCDF for over 1000 participants",
    "Solid teams on the ground to support startups within and beyond Adanian Labs globally.",
    "Strong relationships with different tech startup ecosystem networks in Zambia.",
  ],
  "South Africa": [
    "South Africa",
    "YEAR OF COMMENCEMENT - 2021",
    "We are focused on growing our portfolio as a Web3.0 technology company offering tech tools to clients across all sectors.",
    "10 Startups housed at our Johannesburg campus.",
    "1000+ youths granted a fully funded Software Development scholarships in 2022 under Power Learn Project.",
    "Solid teams on the ground to support startups within and beyond Adanian Labs globally.",
    "Strong relationships with different tech startup ecosystem networks in South Africa.",
    "Our campus has, and will continue to host cutting edge innovation events.",
  ],
};

export const aiceList=[
  'Built AI powered solutions across industry for clients including Climate Fund, AfyaRekod, TWAA, eCOBbA, SunCulture and RewardAdz',
  'Trained over 150 business leaders in Kenya, Zambia and Eswatini.',
  'Trained 70 AI engineers.',
  'Launch of climate focused R&D to explore solutions for the continent.',
  'Mission - to train 6000 AI specialists in the next 5 years.',
  'Launching an automated learning platform.',
  'Global heavyweight partnerships with NVIDIA, MIIA, Cortex Logic and Adanian Labs.',
  'Development of off-the-shelf AI products.',
  'Presence in Kenya, Zambia, Tanzania and Eswatini.'
]