import "../css/submission-form.css";
import { Footer } from "../components/shared";
import Navbar from "../components/navbar";
import ContactForm from "../components/submission-forms/contact-form";
import { useState } from "react";
import TechtoolForm from "../components/submission-forms/techtool-form";
import BorderlessAfricaForm from "../components/submission-forms/borderlessafrica-form";
import VentureBuildingForm from "../components/submission-forms/venturebuilding-form";
import CapacityBuildingForm from "../components/submission-forms/capacitybuilding-form";
import GeneralEnquiriesForm from "../components/submission-forms/generalenquiries-form";
import swal from 'sweetalert';
import background from "../components/utils/submission-forms.jpg"
const SubmissionForm = () => {
  const [option, setOption] = useState("contact");
  const [fullName,setName] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [companyAddress, setcompanyAddress] = useState("");
  const [companyType, setcompanyType] = useState("");
  const [sector, setsector] = useState("");
  const [companySize, setcompanySize] = useState("");
  const [referral, setreferral] = useState("");

  const validateForm=(data)=>{
    if(email&&phone&&fullName){
      const payload={fullName,email,phone,companyName,referral}
      localStorage.setItem("details", JSON.stringify(payload))
      setOption(data)
    }else{
      swal({
        title: "please fill in contact details first",
        icon: "warning",
        dangerMode: true,
      })
    }
  }
  return (
    <div className="body-container">
      <div className="container mx-auto incubation-body">
      <div className="container pt-16  mx-auto text-center bg-center bg-cover relative h-fit bg-[#454545]">
       <Navbar/>
        <div className="h-fit flex flex-col justify-start align-start space-y-3 py-[150px] px-5 md:px-[220px] relative" style={{ backgroundImage: `url(${background})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center' }}>
        <span className="h-full w-full absolute top-0 left-0"></span>
        <h3 className=" hero-title z-10">SUBMISSION FORM</h3>
        <p className=" hero-subtitle z-10">Share your idea. Introduce your startup. Reach out to partner.</p>
      </div>
      </div>
        <section>
          <div className="container mx-auto h-fit relative overflow-hidden py-16 px-5 lg:px-28 flex flex-col items-center justify-center">
            <div className="ellipse-7"></div>
            <div className="ellipse-8"></div>
            <h2 className="form-title z-10 mb-10">Submission Form</h2>
            {option === "contact" && <ContactForm setName={setName} setemail={setemail} setphone={setphone} email={email} phone={phone} fullName={fullName} setcompanyName={setcompanyName} companyName={companyName} companyAddress={companyAddress}
            setcompanyAddress={setcompanyAddress} companySize={companySize} setcompanySize={setcompanySize} companyType={companyType} setcompanyType={setcompanyType}
            sector={sector} setsector={setsector} referral={referral} setreferral={setreferral}></ContactForm>}

            {option === "techtool" && <TechtoolForm></TechtoolForm>}
            {option === "borderless" && (
              <BorderlessAfricaForm></BorderlessAfricaForm>
            )}
            {option === "capacitybuilding" && (
             <CapacityBuildingForm></CapacityBuildingForm>
            )}
            {option === "venturebuilding" && (
              <VentureBuildingForm></VentureBuildingForm>
            )}
            {option === "enquiry" && (
              <GeneralEnquiriesForm></GeneralEnquiriesForm>
            )}

            {option === "contact" && (
              <div className="py-10 space-y-10">
             <div>
             <p className="what-text">
                  What are you interested in?
                </p>
                <hr className="input-hr"></hr>
             </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-[9px] lg:gap-[68px]">
                  <div
                    className="grid-card"
                    onClick={() => {
                      validateForm("techtool");
                    }}
                  >
                    <img src="images/submission-form/web-programming.svg"></img>
                    <h3 className="grid-card-title">Tech Tools</h3>
                  </div>
                  <div
                    className="grid-card"
                    onClick={() => {
                      validateForm("borderless");
                    }}
                  >
                    <img src="images/submission-form/map.svg"></img>
                    <h3 className="grid-card-title">Borderless africa</h3>
                  </div>
                </div>
                <div className="grid  gird-cols-1 lg:grid-cols-3  gap-[9px] lg:gap-[68px]">
                  <div
                    className="grid-card"
                    onClick={() => {
                      validateForm("capacitybuilding");
                    }}
                  >
                    <img src="images/submission-form/graduation.svg"></img>
                    <h3 className="grid-card-title">Capacity Building</h3>
                  </div>
                  <div
                    className="grid-card"
                    onClick={() => {
                      validateForm("venturebuilding");
                    }}
                  >
                    <img src="images/submission-form/agreement.svg"></img>
                    <h3 className="grid-card-title">Venture Building</h3>
                  </div>
                  <div
                    className="grid-card"
                    onClick={() => {
                      validateForm("enquiry");
                    }}
                  >
                    <h3 className="grid-card-title">
                      General Enquiry / support request
                    </h3>
                  </div>
                </div>
              </div>
            )}
           
          </div>
        </section>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default SubmissionForm;
