
import { Link } from "react-router-dom";

const Article = ({ blog,postLiked,handleLike,likedPosts}) => {
  const liked=[...new Set(likedPosts)]
  const repliesLength = blog['_embedded']['replies']?.length;
  return (
    <div >
       <div className="z-10 mb-2">
       <Link to={`/blogposts/${blog.slug}`} >
       <h1 className=" txt-[16px] lg:text-[18px] block lg:hidden z-10" dangerouslySetInnerHTML={{ __html: blog.title.rendered }}></h1>
       </Link>
       </div>
       
      <div className="flex flex-col md:flex-row space-x-5">
        {blog.slug !='the-future-of-tech-entrepreneurship-technology-affiliate-business-partners-mode'&& blog.slug !='adanian-labs-journey-of-resilience-reflections-by-co-founder-irene-kiwia'&&(
 <Link to={`/blogposts/${blog.slug}`} >
 <div
   className="z-10 h-[220px]  lg:h-[273px] w-full lg:w-[362px]  rounded-[20px] bg-cover bg-center shrink-0"
   style={{ backgroundImage: `url(${blog._embedded['wp:featuredmedia'][0]?.source_url})` }}
 /></Link>
        )
        }
         {blog.slug=='the-future-of-tech-entrepreneurship-technology-affiliate-business-partners-mode'&&(
          <Link to={`/blogposts/${blog.slug}`} >
          <div
            className="z-10 h-[290px]  lg:h-[273px] w-full lg:w-[362px]  rounded-[20px] bg-cover bg-center shrink-0"
          >
            <iframe width="362" height="273" src="https://www.youtube.com/embed/f7lIXTi_5lY" title="Adanian Labs Affiliate Program" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
          </Link>
         )}
         
         {blog.slug=='adanian-labs-journey-of-resilience-reflections-by-co-founder-irene-kiwia'&&(
          <Link to={`/blogposts/${blog.slug}`} >
          <div
            className="z-10 h-[290px]  lg:h-[273px] w-full lg:w-[362px]  rounded-[20px] bg-cover bg-center shrink-0"
          >
           <iframe width="362" height="273" src="https://www.youtube.com/embed/DQo4J1RpkWk?si=ZOodSpDT6H1ajLoo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          </Link>
         )}
     
        <div className="space-y-5 z-10">
        <Link to={`/blogposts/${blog.slug}`} >
          <h1 className="text-2xl hidden lg:block"  dangerouslySetInnerHTML={{ __html: blog.title.rendered }}></h1>
          <p className="max-w-lg text-[16px] lg:text-[18px] h-20 overflow-y-hidden  text-ellipsis mt-2 z-10"  dangerouslySetInnerHTML={{ __html: blog.content.rendered }}></p></Link>
          <div className="flex items-center space-x-5">
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5218 0C13.4892 0 11.4765 0.400902 9.59857 1.17982C7.72067 1.95873 6.01437 3.1004 4.57708 4.53964C1.67436 7.44633 0.0436274 11.3886 0.0436274 15.4993C0.0300961 19.0783 1.26763 22.5492 3.5417 25.3104L0.44606 28.4102C0.231289 28.6282 0.0857994 28.905 0.0279511 29.2056C-0.0298973 29.5063 0.00248718 29.8174 0.121018 30.0997C0.249577 30.3785 0.457985 30.6129 0.719787 30.7729C0.981589 30.933 1.28498 31.0115 1.59145 30.9986H15.5218C19.6269 30.9986 23.5638 29.3657 26.4665 26.459C29.3693 23.5523 31 19.61 31 15.4993C31 11.3886 29.3693 7.44633 26.4665 4.53964C23.5638 1.63296 19.6269 0 15.5218 0ZM15.5218 27.8988H5.32169L6.76116 26.4573C7.04944 26.1669 7.21125 25.7741 7.21125 25.3646C7.21125 24.9552 7.04944 24.5623 6.76116 24.2719C4.73442 22.2447 3.47232 19.5765 3.18986 16.7219C2.90741 13.8673 3.62209 11.003 5.21214 8.61688C6.8022 6.23078 9.16924 4.47056 11.91 3.6361C14.6508 2.80164 17.5957 2.94457 20.243 4.04054C22.8903 5.13651 25.0763 7.11771 26.4284 9.6466C27.7806 12.1755 28.2153 15.0956 27.6585 17.9095C27.1016 20.7233 25.5878 23.2568 23.3747 25.0783C21.1617 26.8997 18.3865 27.8965 15.5218 27.8988Z"
                fill="#EEEEEE"
              />
            </svg>
            <span className="mr-[100px]">{repliesLength?repliesLength:0}</span>
            {liked.includes(blog.id)? <img src="/icons/liked.svg" alt="like"   onClick={()=>{handleLike(blog.id,blog.acf.likes)}} className="z-10"/>: <img src="/icons/heart.svg" alt="like"   onClick={()=>{handleLike(blog.id,blog.acf.likes)}} className="z-10"/>}
            <span className="mr-[100px]">{blog.acf.likes}</span>
            {/* <span  dangerouslySetInnerHTML={{ __html: blog.title.rendered }}></span> */}
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <span className="inline-block"  dangerouslySetInnerHTML={{ __html: blog.title.date }}></span>
        <span className="inline-block"  dangerouslySetInnerHTML={{ __html: blog.title.date }}></span>
      </div>
    </div>
  );
};

export default Article;
