import React, { useState } from "react";

function Modal({ isOpen, onClose, children }) {
  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      // ESC key
      onClose();
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  const modalStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: isOpen ? "block" : "none",
    zIndex: 20,
  };


  return isOpen ? (
    <div
      className="modal z-32"
      onKeyDown={handleKeyDown}
      onClick={handleOverlayClick}
      style={modalStyles}
    >
      <div className="modal-content p-2 lg:p-4">
        <div className="relatve h-full w-full">
          <div className="modal-ellipse-1"></div>
          <div className="modal-ellipse-2"></div>
          {children}
        </div>
      </div>
    </div>
  ) : null;
}
const MeetLeaders = ({ subtitle, btnText, desc,link,leaders }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeIndex, setactiveIndex] = useState(0);
  const handleOpenModal = (index) => {
    setactiveIndex(index);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
 

  return (
    <section>
      <div className="container mx-auto relative ml-cover overflow-hidden">
        <Modal isOpen={modalIsOpen} onClose={handleCloseModal}>

          <div className="flex flex-col md:flex-row gap-8">
            <div className={leaders[activeIndex].overview?'z-10 basis-1/4':'z-10'}>
            <img
              src={leaders[activeIndex].image}
              className="user-img-1 z-10" loading="lazy" alt="Adanian Labs" 
            ></img>
            <p className="text-white">{leaders[activeIndex].name}</p>
            <p className="text-white">{leaders[activeIndex].role}</p>
            </div>
            <div className={leaders[activeIndex].overview?'z-10 basis-3/4':'z-10'}>
        <div className="flex justify-end">
        <svg
          x-show="isOpen"
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 z-10 justify-self-end"
          fill="#ffffff"
          viewBox="0 0 24 24"
          stroke="#ffffff"
          stroke-width="2"
          onClick={handleCloseModal}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        </div>
              <p  className="text-white">{leaders[activeIndex].overview}</p> <br></br>
              <p  className="text-white">{leaders[activeIndex].desc}</p> <br></br>
              <ul className="ml-4 list-disc">
              {leaders[activeIndex].skills.map((i)=>(
                <li className="mb-2 text-white">{i}</li>
              ))}
              </ul>
              {/* <p className="text-white">{leaders[activeIndex].desc}</p> */}
             
            </div>
          </div>
        </Modal>
        <div className="flex  flex-col items-start justify-start  absolute top-[30px] left-[25px] lg:left-[237px] lg:top-[174px] w-[40%]">
          <h2 className="ml-header mb-4 lg:mb-10">Meet the leaders</h2>
          <p className="ml-subheader mb-4 lg:mb-20 ">{subtitle}</p>
          <a className="ml-btn ml-btn-text" href={link}>
            {btnText}
          </a>
        </div>
        <div className="user-1 flex justify-center items-center">
          <img
            src={leaders[0].image}
            loading="lazy" alt="Adanian Labs" 
            onClick={() => {
              handleOpenModal(0);
            }}
          ></img>
        </div>
        <div className="user-2 flex justify-center items-center">
          <img
            src={leaders[1].image}
            loading="lazy" alt="Adanian Labs" 
            onClick={() => {
              handleOpenModal(1);
            }}
          ></img>
        </div>
        <div className="user-3 flex justify-center items-center">
          <img
            src={leaders[2].image}
            onClick={() => {
              handleOpenModal(2);
            }}
            loading="lazy" alt="Adanian Labs" 
          ></img>
        </div>
        {/* <div className="user-4 flex justify-center items-center">
          <img src="images/user4.png" className="user-img-4"></img>
        </div> */}

        <img
          src="pages/venture-building/Icons.svg"
          className="absolute  -left-[10px] top-[248px]  block lg:hidden" loading="lazy" alt="Adanian Labs" 
        />
        <div className="circle-container hidden lg:block"></div>
        <div className="ml-left-content absolute top-[247px] left-[202px] lg:top-[775px] lg:left-[795px]">
          <h2 className="ml-header lg:mb-10">Begin your journey</h2>
          <p className="ml-subheader lg:mb-20 w-[462px] mb-4">{desc}</p>
          <a className="ml-2-btn btn-text" href="contact-us">
            CONTACT US
          </a>
        </div>
      </div>
    </section>
  );
};

export default MeetLeaders;
