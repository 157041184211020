import Navbar from "../navbar";

const AbcHero = ({ content }) => {
  return (
    <section className="container py-16  mx-auto text-center bg-center bg-cover">
      <Navbar></Navbar>
      <div className="container mx-auto bg-center abc-hero h-full ">
        <div className="abc-inner-hero h-[558px] flex flex-col md:flex-row items-center justify-center gap-[50px] z-10 relative">
          <div className="h-full w-full absolute top-0 left-0 abc-hero-overlay"></div>
          {content.logo&&(<img src={content.logo} alt="ABC" loading="lazy" className="z-10" />)}
          <div className="abc-hero-text space-y-[25px] z-10">
            <h2 className="z-10 hero-title">{content.title}</h2>
            <p className="z-10 hero-subtitle">{content.substitle}</p>
          </div>
        </div>
        <div className="content-section  px-5 lg:px-[150px] py-[56px] flex flex-col items-center justify-center">
          {/* <p className="heading">Introduction</p> */}
          {/* <hr className="my-[28px]"/> */}
          <p className="desc">{content.bottomContent}</p>
        </div>
      </div>
    </section>
  );
};

export default AbcHero;
