import axios from "axios";
import swal from "sweetalert";


const generateToken = async () => {
  const username = "techadanian";
  const password = "i!gFe&ui3lX0BpaP(";

  try {
    const response = await axios.post(
      "https://dashboard.adanianlabs.io/wp-json/jwt-auth/v1/token",
      {
        username,
        password,
      }
    );
    const token = response.data.data.token;
    localStorage.setItem("jwtToken", token);
    return token;
  } catch (error) {

  }
};

export const getData = async (url) => {
  return fetch(url).then((response) => {
    return response.json();
  });
};

// export const submitForm = async (data) => {
//   generateToken()
//   const token = localStorage.getItem("jwtToken");

//   return axios
//     .post("https://dashboard.adanianlabs.io/wp-json/wp/v2/posts", data,  {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       return err;
//     });
// };

export const submitForm = async(postype,newPost)=> {
  const username = "techadanian";
  const password = "i!gFe&ui3lX0BpaP(";
  try {
    const tokenResponse = await axios.post(`https://dashboard.adanianlabs.io/wp-json/jwt-auth/v1/token`, {
      username,
      password,
    });
    const postResponse = await axios.post(`https://dashboard.adanianlabs.io/wp-json/wp/v2/${postype}`, newPost, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenResponse.data.data.token}`,
      },
    });
    swal('thank you for your submission')
  } catch (error) {
    console.error('Error:', error.response.data);
  }
}
