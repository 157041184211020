import { useState } from "react";
import Navbar from "../components/navbar";
import { Button, Footer, Modal } from "../components/shared";
import background from "../components/utils/partners.jpg"
import swal from "sweetalert";
import { submitForm } from "../services";
import { data } from "autoprefixer";


const Hero = ({ title, subtitle, background }) => {
    return (
        <section className="container pt-16  mx-auto text-center bg-center bg-cover">
            <Navbar />
            <div className="h-[558px] flex flex-col justify-center items-center space-y-3 py-[150px]  px-5 lg:px-[220px] relative" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <span className="h-full w-full absolute top-0 left-0"></span>
                <h3 className=" hero-title z-10">{title}</h3>
                <p className=" hero-subtitle z-10">{subtitle}</p>
                <Button variant="orange" className="text-white w-fit ml-0 md:ml-16 ">SIGN UP</Button>
            </div>
        </section>
    )
}

const AffiliatePartner = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        country: '',
        contactNumber: '',
        businessName: '',
        businessType: '',
        businessWebsite: '',
        businessSize: '',
        sector: '',
        technicalSkills: '',
        marketingChannels: '',
        prevExperience: "",
        marketingStrategy: '',
        audience: '',
        portfolio: null,
        agreeToTerms: false,
    });
    const [shareModal, setshareModal] = useState(false);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://dashboard.adanianlabs.io/wp-json/wp/v2/affiliate_partners', {
                method: 'POST',
                body: formData,
            });
            
            swal('Thank you for your submission')

            if (response.ok) {
                // Successfully created the item
             
            } else {
                // Handle errors
             
            }
        } catch (error) {
         
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        const payload = {
            "First Name": formData.firstName,
            "Last Name": formData.lastName,
            "Business Name": formData.businessName,
            "Business Size": formData.businessSize,
            "business type": formData.businessType,
            "Contact Number": formData.contactNumber,
            'email': formData.emailAddress,
            'Would you prefer a fully managed service or a self-managed solution?': formData.country,
            'profile': formData.portfolio,
            "Business website": formData.businessWebsite,
            "What country (countries) is your business operating?":formData.country,
            "Target Audience/Niche:":formData.audience,
            "Describe Your Marketing Channels":formData.marketingChannels,
            "Marketing Strategy":formData.marketingStrategy,
            "Previous Affiliate Experience":formData.prevExperience,
            "Sector":formData.sector,
            "What are the key technical skills for your team?":formData.technicalSkills


        }
        const tableRows = Object.entries(payload).map(([question, answer]) => `
          <tr  style="border:1px solid black;">
            <td >${question}</td>
            <td>${answer}</td>
          </tr>
        `);

        const table = `
          <table style="border:1px solid black;">
            <thead style="border:1px solid black;">
              <tr style="border:1px solid black;">
                <th style="border:1px solid black;">Question</th>
                <th style="border:1px solid black;">Answer</th>
              </tr>
            </thead>
            <tbody style="border:1px solid black;">
              ${tableRows.join('')}
            </tbody>
          </table>
        `;

        const data = payload
        submitForm('affiliate_partners', {
            title: formData.emailAddress,
            content: table,
            status: 'publish',
            meta: data
        }).then((res) => {

        })
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        // Handle different input types
        const inputValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value;

        setFormData({
            ...formData,
            [name]: inputValue,
        });
    };

    const handleOpenModal = (index, name) => {
        setshareModal(true);
    };

    const handleCloseModal = () => {
        setshareModal(false);
    };
    return (
        <div className="body-container">
            <div
                className="container mx-auto tools-body overflow-hidden partner"
                style={{ background: "#454545}" }}
            >
                <Modal isOpen={shareModal} onClose={handleCloseModal}>
                <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-4 z-10">
                        {/* First Name and Last Name fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10 z-10">
                            <div className="space-y-2 w-full z-10">
                                <p>First Name</p>
                                <input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} className="z-10 text-white" id="firstName"/>
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Last Name</p>
                                <input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange}  className="text-white" id="lastName" />
                            </div>
                        </div>
                        {/* Email and Country fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10 ">
                            <div className="space-y-2 w-full z-10">
                                <p>Email Address</p>
                                <input type="text" name="emailAddress" value={formData.emailAddress} onChange={handleInputChange}  className="text-white" id="emailAddress"/>
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>What country (countries) is your business operating?</p>
                                <input type="text" name="country" value={formData.country} onChange={handleInputChange}  className="text-white" id="country"/>
                            </div>
                        </div>

                        {/* Contact Number and Business Name fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Contact Number</p>
                                <input type="text" name="contactNumber" value={formData.contactNumber} onChange={handleInputChange}  className="text-white"/>
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Business Name</p>
                                <input type="text" name="businessName" value={formData.businessName} onChange={handleInputChange} className="text-white" />
                            </div>
                        </div>

                        {/* Business Type and Business Website fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Business Type(Sole Proprietorship, LLC, Corporation...etc):</p>
                                <input type="text" name="businessType" value={formData.businessType} onChange={handleInputChange}  className="text-white" id="businessType"/>
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Business Website</p>
                                <input type="text" name="businessWebsite" value={formData.businessWebsite} onChange={handleInputChange}  className="text-white" id="businessWebsite"/>
                            </div>
                        </div>

                        {/* Business Size and Sector fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Business Size</p>
                                <input type="text" name="businessSize" value={formData.businessSize} onChange={handleInputChange}  className="text-white" />
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Sector</p>
                                <input type="text" name="sector" value={formData.sector} onChange={handleInputChange}  className="text-white" />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p> Marketing Strategy (briefly describe how you plan to promote our products/services):</p>
                                <input
                                    type="text"
                                    name="marketingStrategy"
                                    value={formData.marketingStrategy}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    className="text-white"
                                />
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Describe Your Marketing Channels (website, social media, email marketing):</p>
                                <input
                                    type="text"
                                    name="marketingChannels"
                                    value={formData.marketingChannels}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    className="text-white"
                                />
                            </div>

                        </div>
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Previous Affiliate Experience (if any):</p>
                                <input
                                    type="text"
                                    name="prevExperience"
                                    value={formData.prevExperience}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    className="text-white"
                                />
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>What are the key technical skills for your team?</p>
                                <input
                                    type="text"
                                    name="technicalSkills"
                                    value={formData.technicalSkills}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    className="text-white"
                                />
                            </div>

                        </div>
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Target Audience/Niche:</p>
                                <input
                                    type="text"
                                    name="audience"
                                    value={formData.audience}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    className="text-white"
                                />
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Please attach a copy of your profile</p>
                                <input type="file" name="portfolio" onChange={handleInputChange} />
                                <label class="input-file h-[139px] flex items-center justify-center">
                                    <input
                                        type="file"
                                        ame="portfolio" onChange={handleInputChange}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="62" viewBox="0 0 96 62" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M53.0579 0C64.5198 0 74.5843 7.19947 78.3975 18.0126C88.6528 20.1318 96.0015 29.14 96.0015 39.7886C96.0015 52.0346 86.1033 61.9971 73.9308 61.9971H61.2839C60.4279 61.9971 59.7355 61.2988 59.7355 60.4359C59.7355 59.5759 60.4279 58.8746 61.2839 58.8746H73.9308C84.3948 58.8746 92.9048 50.312 92.9048 39.7886C92.9048 30.3627 86.203 22.4313 76.9762 20.9279L76.0357 20.7723L75.7455 19.8575C72.5973 9.84643 63.4793 3.12545 53.0641 3.12545C42.9708 3.12545 33.9527 9.58416 30.6153 19.2052L29.9774 21.0438L28.3382 20.0191C26.4315 18.8298 24.248 18.1987 22.0236 18.1987C15.3915 18.1987 9.9936 23.6389 9.9936 30.3293L10.0707 31.6342L9.3584 32.3478C5.4376 35.071 3.0968 39.5597 3.0968 44.347C3.0968 52.3549 9.5777 58.8683 17.5435 58.8683L17.8593 58.8715C17.8832 58.872 17.9074 58.8725 17.9316 58.8731C18.0606 58.8761 18.1932 58.8792 18.3205 58.8715H34.7207C35.5751 58.8715 36.2691 59.5728 36.2691 60.4328C36.2691 61.2956 35.5751 61.9939 34.7207 61.9939H18.4007C18.3267 62 18.2344 62 18.1285 62C18.0801 62 18.0291 61.9993 17.9767 61.9986C17.9212 61.9979 17.864 61.9971 17.8065 61.9971C7.872 61.9939 0 54.0776 0 44.3502C0 38.8278 2.5644 33.6377 6.8968 30.2987C6.9117 21.9005 13.6936 15.0762 22.0236 15.0762C24.2102 15.0762 26.3634 15.555 28.3413 16.4789C32.5434 6.55 42.2586 0 53.0579 0ZM64.1751 47.0244C63.7786 47.0244 63.3826 46.8753 63.08 46.5701L49.55 32.9305V60.4328C49.55 61.2956 48.8575 61.9939 48.0031 61.9939C47.1487 61.9939 46.4547 61.2956 46.4547 60.4328V32.9305L32.9242 46.5701C32.321 47.1771 31.3397 47.1771 30.7348 46.5701C30.1316 45.9603 30.1316 44.9691 30.7348 44.3626L46.9068 28.0573L46.9099 28.0543L48.0031 26.9535L49.0962 28.0543L49.0993 28.0573L65.2698 44.3626C65.8745 44.9691 65.8745 45.9603 65.2698 46.5701C64.9672 46.8721 64.5711 47.0244 64.1751 47.0244Z" fill="#F39710" />
                                    </svg>
                                    <p className="text-black">
                                        Click Here / Drop FIle Here
                                    </p>
                                </label>

                            </div>
                        </div>

                        {/* Terms and Conditions Checkbox */}
                        <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full">I consent to the collection and processing of my personal data for the purpose of affiliate program management. <br/>
                                I understand that my data will be handled in accordance with Adanian Labs' privacy policy.</p>
                        </div>

                        {/* Submit Button */}
                        <Button type="submit" className={formData.agreeToTerms? 'cursor-pointer w-full text-white z-10 uppercase' : 'cursor-not-allowed w-full text-white z-10 uppercase'} variant={'orange'} disabled={!formData.agreeToTerms}>sign up</Button>
                    </form>
                </Modal>
                <section className="container pt-16  mx-auto text-center bg-center bg-cover">
            <Navbar />
            <div className="h-[558px] flex flex-col justify-center items-center space-y-3 py-[150px]  px-5 lg:px-[220px] relative" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <span className="h-full w-full absolute top-0 left-0"></span>
                <h3 className=" hero-title z-10">Adanian Labs Affiliate Partners Program</h3>
                <p className=" hero-subtitle z-10">Empowering Africa's Tech Revolution Together: Adanian Labs Partnership Program</p>
                <Button variant="orange" className="text-white w-fit ml-0 md:ml-16 " onClick={handleOpenModal}>SIGN UP</Button>
            </div>
        </section>
                <section className="px-5 lg:px-28 py-10">
                    <h1 className="text-center">Why Join the<span className="p-2">Adanian Labs</span> Affiliate Partners Program?</h1>
                    <p className="text-center">Are you a tech entrepreneur, tech consultant, or tech enthusiast looking to boost and grow your revenues while  making an impact? Join the Adanian Labs Affiliate Program and become a business affiliate partner. Connect with cutting-edge solutions and embark on a long term journey of revenue share. Sign up to learn more.  <br />
                        <span className="p-3"> #AdanianAffiliate</span> <br /><br />

                        It’s time to leverage your tech expertise and network for financial success. Join us in building a borderless continent and beyond, powered by technology! As a business affiliate partner with Adanian Labs, you'll step into a world of opportunity. You can unlock your earning potential while promoting cutting-edge tech solutions designed to address real-world challenges. The best part? You get to be a part of ongoing revenue share by connecting your network with our game-changing solutions. It’s a fantastic opportunity for passive income, making your passion for tech a profitable business.</p>
                </section>
                <section className="px-5 lg:px-28 py-10">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
                        <div className="card">
                            <img src="/images/partners/6.png" alt="" className="w-full" />
                            <p className="p-5 text-center">Over 100+ tech tools to choose from</p>

                        </div>
                        <div className="card">
                            <img src="/images/partners/4.png" alt="" className="w-full" />
                            <p className="p-5 text-center">Earn 30% commission on deals closed</p>

                        </div>
                        <div className="card">
                            <img src="/images/partners/5.png" alt="" className="w-full" />
                            <p className="p-5 text-center">Leverage our sales, marketing & IT support</p>

                        </div>
                        <div className="card">
                            <img src="/images/partners/3.png" alt="" className="w-full" />
                            <p className="p-5 text-center">Become a leader in emerging technologies</p>

                        </div>
                    </div>



                </section>
                <section className="px-5 lg:px-28 py-10 relative">
                    <h1>Who Should Join the Adanian Labs Reseller  Program?</h1>
                    <div className="w-full flex justify-center items-center my-2"><div className="divider"></div></div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="970" height="1400" viewBox="0 0 970 1400" fill="none" className="absolute top-0 right-0">
                        <g filter="url(#filter0_f_1141_4177)">
                            <circle cx="700" cy="700" r="120" fill="#13A3B7" />
                        </g>
                        <defs>
                            <filter id="filter0_f_1141_4177" x="0" y="0" width="1400" height="1400" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="290" result="effect1_foregroundBlur_1141_4177" />
                            </filter>
                        </defs>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="970" height="1400" viewBox="0 0 970 1400" fill="none" className="absolute top-0 left-0">
                        <g filter="url(#filter0_f_1141_4192)">
                            <circle cx="270" cy="700" r="120" fill="#F5931D" />
                        </g>
                        <defs>
                            <filter id="filter0_f_1141_4192" x="-430" y="0" width="1400" height="1400" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="290" result="effect1_foregroundBlur_1141_4192" />
                            </filter>
                        </defs>
                    </svg>
                    <div className="flex flex-col md:flex-row items-center">
                        <img src="/images/partners/7.png" alt="" />
                        <div className="space-y-2">
                            <h2>For Technology Consultants</h2>
                            <h3>Expand your portfolio by offering Adanian Labs cutting- edge tools to your clients, if you're already established in the technology consulting space.</h3>
                        </div>

                    </div>
                    <div className="flex flex-col-reverse md:flex-row items-center">

                        <div className="space-y-2">
                            <h2>For Technology Companies</h2>
                            <h3>Tap into new markets and enhance your product offerings by white-labeling Adanian Labs innovative technology solutions as your own.</h3>
                        </div>
                        <img src="/images/partners/8.png" alt="" />

                    </div>
                    <div className="flex flex-col md:flex-row items-center">
                        <img src="/images/partners/9.png" alt="" />
                        <div className="space-y-2">
                            <h2>For Entrepreneurs</h2>
                            <h3>If you're seeking a new venture, the Adanian Labs Reseller Program is an excellent opportunity to launch a technology-focused business.</h3>
                        </div>

                    </div>
                    <h1>Webinars</h1>
                    <div className="w-full flex justify-center items-center my-4"><div className="divider"></div></div>
                    <div className="flex flex-col md:flex-row gap-10">
                        <img src="/images/partners/10.png" alt="" />
                        <div className="space-y-3">
                            <p>ZOOM WEBINAR    |    02 NOV 2023    |    4:30 PM</p>
                            <h3 className="banner">Adanian Labs Affiliate Webinar</h3>
                            <p className="text-start">Join our webinar to learn more. Then submit your application to become an Adanian Labs Affiliate. Our team will review your application & contact you. Once accepted, you'll receive training on our products and how to effectively market them.</p>
                            <h3 className="mid my-3">SPEAKERS</h3>
                            <div className="flex speaker gap-4">
                                <img src="/images/partners/kamara.png" alt="Kamara" />
                                <div>
                                    <h3>John Kamara - Key Speaker</h3>
                                    <p>Chief Executive Officer, Adanian Labs</p>
                                </div>

                            </div>

                            <Button variant={'orange'} className="text-white my-10" onClick={handleOpenModal}>APPLY FOR THE WEBINAR</Button>
                        </div>

                    </div>

                </section>
                <section className="px-5 lg:px-28 py-10 relative">
                    <div className="eclipse-1 absolute top-0 left-0"></div>
    
                    <h1>Adanian Labs Affiliate Partner Registration</h1>
                    <div className="w-full flex justify-center items-center my-2"><div className="divider"></div></div>
                    <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-4 z-10">
                        {/* First Name and Last Name fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10 z-10 ">
                            <div className="space-y-2 w-full z-10">
                                <p>First Name</p>
                                <input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} className="z-10 text-white" />
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Last Name</p>
                                <input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} className="text-white"/>
                            </div>
                        </div>
                        {/* Email and Country fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10 ">
                            <div className="space-y-2 w-full z-10">
                                <p>Email Address</p>
                                <input type="text" name="emailAddress" value={formData.emailAddress} onChange={handleInputChange} className="text-white"/>
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>What country (countries) is your business operating?</p>
                                <input type="text" name="country" value={formData.country} onChange={handleInputChange} className="text-white"/>
                            </div>
                        </div>

                        {/* Contact Number and Business Name fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Contact Number</p>
                                <input type="text" name="contactNumber" value={formData.contactNumber} onChange={handleInputChange} className="text-white" />
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Business Name</p>
                                <input type="text" name="businessName" value={formData.businessName} onChange={handleInputChange} className="text-white" />
                            </div>
                        </div>

                        {/* Business Type and Business Website fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Business Type(Sole Proprietorship, LLC, Corporation...etc):</p>
                                <input type="text" name="businessType" value={formData.businessType} onChange={handleInputChange} className="text-white"/>
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Business Website</p>
                                <input type="text" name="businessWebsite" value={formData.businessWebsite} onChange={handleInputChange} className="text-white"/>
                            </div>
                        </div>

                        {/* Business Size and Sector fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Business Size</p>
                                <input type="text" name="businessSize" value={formData.businessSize} onChange={handleInputChange} className="text-white"/>
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Sector</p>
                                <input type="text" name="sector" value={formData.sector} onChange={handleInputChange} className="text-white"/>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p> Marketing Strategy (briefly describe how you plan to promote our products/services):</p>
                                <input
                                    type="text"
                                    name="marketingStrategy"
                                    value={formData.marketingStrategy}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    className="text-white"
                                />
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Describe Your Marketing Channels (website, social media, email marketing):</p>
                                <input
                                    type="text"
                                    name="marketingChannels"
                                    value={formData.marketingChannels}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    className="text-white"
                                />
                            </div>

                        </div>
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Previous Affiliate Experience (if any):</p>
                                <input
                                    type="text"
                                    name="prevExperience"
                                    value={formData.prevExperience}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    className="text-white"
                                />
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>What are the key technical skills for your team?</p>
                                <input
                                    type="text"
                                    name="technicalSkills"
                                    value={formData.technicalSkills}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    className="text-white"
                                />
                            </div>

                        </div>
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Target Audience/Niche:</p>
                                <input
                                    type="text"
                                    name="audience"
                                    value={formData.audience}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    className="text-white"
                                />
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Please attach a copy of your profile</p>
                                <input type="file" name="portfolio" onChange={handleInputChange} />
                                <label class="input-file h-[139px] flex items-center justify-center">
                                    <input
                                        type="file"
                                        ame="portfolio" onChange={handleInputChange}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="62" viewBox="0 0 96 62" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M53.0579 0C64.5198 0 74.5843 7.19947 78.3975 18.0126C88.6528 20.1318 96.0015 29.14 96.0015 39.7886C96.0015 52.0346 86.1033 61.9971 73.9308 61.9971H61.2839C60.4279 61.9971 59.7355 61.2988 59.7355 60.4359C59.7355 59.5759 60.4279 58.8746 61.2839 58.8746H73.9308C84.3948 58.8746 92.9048 50.312 92.9048 39.7886C92.9048 30.3627 86.203 22.4313 76.9762 20.9279L76.0357 20.7723L75.7455 19.8575C72.5973 9.84643 63.4793 3.12545 53.0641 3.12545C42.9708 3.12545 33.9527 9.58416 30.6153 19.2052L29.9774 21.0438L28.3382 20.0191C26.4315 18.8298 24.248 18.1987 22.0236 18.1987C15.3915 18.1987 9.9936 23.6389 9.9936 30.3293L10.0707 31.6342L9.3584 32.3478C5.4376 35.071 3.0968 39.5597 3.0968 44.347C3.0968 52.3549 9.5777 58.8683 17.5435 58.8683L17.8593 58.8715C17.8832 58.872 17.9074 58.8725 17.9316 58.8731C18.0606 58.8761 18.1932 58.8792 18.3205 58.8715H34.7207C35.5751 58.8715 36.2691 59.5728 36.2691 60.4328C36.2691 61.2956 35.5751 61.9939 34.7207 61.9939H18.4007C18.3267 62 18.2344 62 18.1285 62C18.0801 62 18.0291 61.9993 17.9767 61.9986C17.9212 61.9979 17.864 61.9971 17.8065 61.9971C7.872 61.9939 0 54.0776 0 44.3502C0 38.8278 2.5644 33.6377 6.8968 30.2987C6.9117 21.9005 13.6936 15.0762 22.0236 15.0762C24.2102 15.0762 26.3634 15.555 28.3413 16.4789C32.5434 6.55 42.2586 0 53.0579 0ZM64.1751 47.0244C63.7786 47.0244 63.3826 46.8753 63.08 46.5701L49.55 32.9305V60.4328C49.55 61.2956 48.8575 61.9939 48.0031 61.9939C47.1487 61.9939 46.4547 61.2956 46.4547 60.4328V32.9305L32.9242 46.5701C32.321 47.1771 31.3397 47.1771 30.7348 46.5701C30.1316 45.9603 30.1316 44.9691 30.7348 44.3626L46.9068 28.0573L46.9099 28.0543L48.0031 26.9535L49.0962 28.0543L49.0993 28.0573L65.2698 44.3626C65.8745 44.9691 65.8745 45.9603 65.2698 46.5701C64.9672 46.8721 64.5711 47.0244 64.1751 47.0244Z" fill="#F39710" />
                                    </svg>
                                    <p className="text-black">
                                        Click Here / Drop FIle Here
                                    </p>
                                </label>

                            </div>
                        </div>

                        {/* Terms and Conditions Checkbox */}
                        <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full ">I consent to the collection and processing of my personal data for the purpose of affiliate program management. <br/>
                                I understand that my data will be handled in accordance with Adanian Labs' privacy policy.</p>
                        </div>

                        {/* Submit Button */}
                        <Button type="submit" className={formData.agreeToTerms? 'cursor-pointer w-full text-white z-10 uppercase' : 'cursor-not-allowed w-full text-white z-10 uppercase'} variant={'orange'} disabled={!formData.agreeToTerms}>sign up</Button>
                    </form>

                </section>
            </div>
            <Footer/>
        </div>
    );
}

export default AffiliatePartner;