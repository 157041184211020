import { useState, useEffect } from "react";
import ThankyouPage from "./thankyou-page";
import { useNavigate } from "react-router-dom";
import { sendMail } from "../../services/mail";
import { submitForm } from "../../services";

const TechtoolForm = () => {
  const [companyName, setcompanyName] = useState("");
  const [website, setwebsite] = useState("");
  const [location, setlocation] = useState("");
  const [channel, setchannel] = useState("");
  const [tool, setTool] = useState("");
  const [system, setSystem] = useState("");
  const [enquiries, setEnquiries] = useState("");
  const [step, setStep] = useState(0);
  const [timeline, settimeline] = useState("");
  const [features, setfeatures] = useState("");
  const [contact, setcontact] = useState("");
  const [extracomments, setextracomments] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (step < 0) {
      navigate(0);
    }
  }, [step]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      "What specific technology tools or solutions are you interested in?": companyName,
      "Company website": website,
      "What are your goals for implementing these technology tools or solutions?": location,
      "What is your estimated budget for these technology tools or solutions?":
        tool,
      "What are the biggest challenges or pain points you're currently experiencing in your business?":
        system,
      "Are you looking for a solution that can be customised to fit your specific needs?": enquiries,
      "What is your timeline for implementing these technology tools or solutions?":timeline,
      "Are there any specific features or functionality you're looking for in these technology tools or solutions?":features,
      "What is the best way to contact you to discuss your needs further?":contact,
      "Please, provide any enquiries or extra comments.":extracomments
    };
    const personalDetails =JSON.parse(window.localStorage.getItem('details'));

    const data={...payload, ...personalDetails}
    const tableRows = Object.entries(data).map(([question, answer]) => `
    <tr>
      <td>${question}</td>
      <td>${answer}</td>
    </tr>
  `);
  
  const table = `
    <table>
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        ${tableRows.join('')}
      </tbody>
    </table>
  `;
    submitForm( 'tech_tools',{
      title:personalDetails.email,
      content:table,
      status:'publish',
      meta:data
    }).then((res)=>{
      return
    })
  };
  return (
    <div className="space-y-3 md:space-y-10 z-10">
      {step === 0 && (
        <>
          <div className="input-group">
            <p className="input-label z-10">What specific technology tools or solutions are you interested in?</p>
            <hr className="input-hr z-10"></hr>
            <textarea
              className="form-textarea z-10"
              value={companyName}
              onChange={(e) => {
                setcompanyName(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">What are your goals for implementing these technology tools or solutions?</p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={website}
              onChange={(e) => {
                setwebsite(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">What is your estimated budget for these technology tools or solutions?</p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={location}
              onChange={(e) => {
                setlocation(e.target.value);
              }}
            ></textarea>
          </div>
          <div
            className="input-group"
            value={tool}
            onChange={(e) => {
              setTool(e.target.value);
            }}
          >
            <p className="input-label w-[361px] lg:w-full">
            What are the biggest challenges or pain points you're currently experiencing in your business?
            </p>
            <hr className="input-hr"></hr>
            <textarea className="form-textarea"></textarea>
          </div>
        </>
      )}
      {step === 1 && (
        <>
          <div className="input-group">
            <p className="input-label">
            Are you looking for a solution that can be customised to fit your specific needs?
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={system}
              onChange={(e) => {
                setSystem(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
            What is your timeline for implementing these technology tools or solutions?
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={timeline}
              onChange={(e) => {
                settimeline(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
            Are there any specific features or functionality you're looking for in these technology tools or solutions?
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={features}
              onChange={(e) => {
                setfeatures(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
            What is the best way to contact you to discuss your needs further?
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={contact}
              onChange={(e) => {
                setcontact(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
            Please, provide any enquiries or extra comments.
            </p>
            <hr className="input-hr"></hr>
            <textarea
              className="form-textarea"
              value={extracomments}
              onChange={(e) => {
                setextracomments(e.target.value);
              }}
            ></textarea>
          </div>
        </>
      )}

      {step === 2 && <ThankyouPage></ThankyouPage>}

      {step !== 2 && (
        <>
          <div className="w-[250px] lg:w-[450px] h-[89px] flex gap-0  items-center">
            <div
              className="prev-btn
              "
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <img
                src="images/submission-form/back.svg"
                alt="back"
                className="h-[25px] w-[25px] lg:w-[50px] lg:h-[50px]"
              ></img>
              <h3 className="prev-btn-text">Back</h3>
            </div>
            <div
              className="next-btn"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              {step === 0 && <h3 className="prev-btn-text">Next</h3>}
              {step === 1 && (
                <h3 className="prev-btn-text" onClick={handleSubmit}>
                  Submit
                </h3>
              )}

              <img
                src="images/submission-form/next.svg"
                alt="next"
                className="h-[25px] w-[25px] lg:w-[50px] lg:h-[50px]"
              ></img>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TechtoolForm;
