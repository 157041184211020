const OurOffices = () => {
  return (
    <section className="our-offices-bg mb-10">
      <div >
        <h1 className="our-offices-title">Our Offices</h1>
        <div className="grid grid-cols-1 gap-4 mt-8 xl:mt-12  md:grid-cols-2 xl:grid-cols-3">
          <div className="lg:space-y-3 text-start">
            <h1 className="header-office ">Nairobi, Kenya</h1>
            <p className="office-desc">Mkungu Close<br></br>Westlands</p>
          </div>
          <div className="lg:space-y-3 text-start">
            <h1 className="header-office"> Lagos, Nigeria</h1>
            <p className="office-desc">
            3 Otunba Adedoyin Ogungbe  Crescent<br></br>Lekki Phase 1
            </p>
          </div>
          <div className="lg:space-y-3 text-start">
            <h1 className="header-office">England, UK</h1>
            <p className="office-desc">1 The Quays, Salford <br></br>M50 3UB</p>
          </div>
          <div className="lg:space-y-3">
            <h1 className="header-office"> Dar es salaam, Tanzania</h1>
            <p className="office-desc">InfoTech Place, Mwai Kibaki Road<br></br> Kawe</p>
          </div>
          <div className="lg:space-y-3">
            <h1 className="header-office"> Lusaka ,Zambia</h1>
            <p className="office-desc">Suite 223, Arcades <br></br>Greate East Road</p>
          </div>
          <div className="lg:space-y-3">
            <h1 className="header-office">Johannesburg, South Africa</h1>
            <p className="office-desc">
            41, 2nd Road, Hyde Park <br></br>Sandton, 2196
            </p>
          </div>
        </div>
    
      </div>
    </section>
  );
};

export default OurOffices;
