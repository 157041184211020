import "../css/contact-us.css";
import background from "../components/utils/contact-us-hero.jpg"

import { Container } from "../components/shared";
import { faqs, offices } from "../data/contact-us";
import { HashLink } from "react-router-hash-link";
import SharedHero from "../components/utils/hero";
import { useState } from "react";
import Faqs from "../components/faqs";

const Category = ({ title, content, to }) => {
  const styles = {
    background: "linear-gradient(140.96deg, rgba(19, 226, 255, 0.4) 4.48%, rgba(255, 255, 255, 0.1) 94.29%)",
    boxShadow: "-4px 4px 15px rgba(0, 0, 0, 0.25)",
    border: "1px solid #13E2FF",
  };

  return (
    <HashLink smooth to={to} className="flex">
      <div
        style={styles}
        className="flex max-h-[345px] max-w-[345px] flex-col items-center space-y-3 rounded-20 px-6 py-4 backdrop-blur-20"
      >
        <h1 className="text-center text-24 md:text-32">{title}</h1>
        <div className="h-px w-56 bg-white" />
        <p className="text-center text-20 md:text-[18px]">{content}</p>
      </div>
    </HashLink>
  );
};

const Office = ({ country, address, street, href }) => (
  <a href={href} className="block" target="_blank" rel="noreferrer">
    <div className="whitespace-nowrap underline text-lg">
      <h1>{country}</h1>
      <h1>{address}</h1>
      <h1>{street}</h1>
    </div>
  </a>
);
const ContactUs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <Container className="bg-[#2E2E2E]">
      <SharedHero title={'Contact Us'} subtitle={"Ignite your B2B, B2G, and B2C ventures with our state-of-the-art smart tech solutions. Embark on a transformative journey with our exceptional software suite, meticulously crafted to cater to businesses of all sizes. Developed by a security-focused company, we are pioneering decentralized access to technology, revolutionizing multiple sectors and empowering businesses like never before. Unleash the true potential of your enterprise today!"} background={background} />
      <div className="bg-cover bg-no-repeat md:bg-contact-us-md bg-contact-us px-6 pt-4 ">
        <section className="flex flex-col items-center space-y-2 md:px-32 md:pt-28 md:pb-14">
          {/* <h1 className="text-32 text-[#13E2FF] md:text-40">Contact Us</h1> */}
          <div className="h-0.5 w-80 bg-white md:my-8 md:h-px md:w-3/5" />
          {/* <p className="text-center text-20 md:text-24 md:uppercase">
            Share your idea. <br className="md:hidden" />
            Introduce your startup. <br className="md:hidden" />
            Reach out to partner.
          </p> */}
        </section>

        <section className="flex flex-col items-center gap-7 pt-12 md:flex-row md:items-stretch md:justify-center md:pt-0">
          <Category
            title={
              <>
                SEND US A <br /> MESSAGE
              </>
            }
            to="/submission-form"
            content="Using our online form, your enquiry/request is effectively routed to the correct department within Adanian Labs, allowing us to respond ASAP."
          />
          <Category
            title={
              <>
                FIND YOUR LOCAL <br /> ADANIAN OFFICE
              </>
            }
            to="#offices"
            content="With a presence in multiple African countries, we are able to assist no matter where you are. Click here to find your local Adanian Office"
          />
          <Category
            title={
              <>
                VIEW OUR <br /> FAQS
              </>
            }
            to="#faqs"
            content="Your questions may already be answered. Click here to navigate to the list of FAQs featured."
          />
        </section>

        <section className="pt-20 md:pt-32" id="offices">
          <h1 className="text-center text-32 text-[#13E2FF] md:text-40">Our Offices</h1>

          <div className="grid grid-cols-1 gap-8 pt-7 text-20 md:grid-cols-3 md:px-44 md:pt-11 md:pb-24">
            <Office {...offices["NAIROBI, KENYA"]} />
            <Office {...offices["DAR ES SALAAM, TANZANIA"]} />
            <Office {...offices["LAGOS, NIGERIA"]} />
            <Office {...offices["LUSAKA, ZAMBIA"]} />
            <Office {...offices["ENGLAND, UK"]} />
            <Office {...offices["JOHANNESBURG, SOUTH AFRICA"]} />
          </div>
        </section>

       <Faqs/>
      </div>
    </Container>
  );
};

export default ContactUs;
