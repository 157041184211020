
import { useState, useEffect } from "react";
import { regions } from "../../data/borderless-africa";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { Button } from "../shared";

function Modal({ isOpen, onClose, children }) {
  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      // ESC key
      onClose();
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  const modalStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: isOpen ? "block" : "none",
    zIndex: 20,
  };

  return isOpen ? (
    <div
      className="modal z-32"
      onKeyDown={handleKeyDown}
      onClick={handleOverlayClick}
      style={modalStyles}
    >
      <div className="startup-modal-content p-8">
        <div className="relatve h-full w-full">
          <div className="modal-ellipse-1"></div>
          <div className="modal-ellipse-2"></div>
          {children}
        </div>
      </div>
    </div>
  ) : null;
}
const StartupsContent = () => {
  const { id } = useParams();
  const myId = id || 'all';
  const [details, setDetails] = useState(null);
  const countries = Object.keys(regions);
  // Declare state variables for filtering startups
  const [countryFilter, setCountryFilter] = useState("All");
  const [fundingRoundFilter, setFundingRoundFilter] = useState("All");
  const [industryFilter, setIndustryFilter] = useState("All");
  const [cohortFilter, setcohortFilter] = useState("All");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [startups, setstartups] = useState([]);
  const [likedPostId, setlikedPostId] = useState(null);
  const [shareModal, setshareModal] = useState(false);
  const [activeIndex, setactiveIndex] = useState(0);
  const [startupLink, setstartupLink] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [allPostsLoaded, setAllPostsLoaded] = useState(false);


  const handleOpenModal = (index,name) => {
    setactiveIndex(index);
    setshareModal(true);
    setstartupLink(name)
  };

  const handleCloseModal = () => {
    setshareModal(false);
  };
  // Define function to filter startups
  const filterStartups = () => {
    if (
      countryFilter === "All" &&
      fundingRoundFilter === "All" &&
      industryFilter === "All"&&
      cohortFilter==="All"
    ) {
      return startups;
    }

    let filtered = startups.filter((startup) => {
      let matchesCountry = true;
      let matchesFundingRound = true;
      let matchesIndustry = true;
      let matchesCohort=true

      if (countryFilter !== "All" && !startup.acf.countries.includes(countryFilter)) {
        matchesCountry = false;
      }
      if (cohortFilter !== "All" && startup.acf.cohort !==cohortFilter) {
        matchesCohort = false;
      }

      if (
        fundingRoundFilter !== "All" &&
        startup.acf.stage !== fundingRoundFilter
      ) {
        matchesFundingRound = false;
      }

      if (industryFilter !== "All" && startup.acf.industry !== industryFilter) {
        matchesIndustry = false;
      }

      return matchesCountry && matchesFundingRound && matchesIndustry&& matchesCohort;
    });

    return filtered;
  };
  // Get the filtered startups based on the selected filters
  const filteredStartups = filterStartups();
  const [loading, setLoading] = useState(true);
  const [postLiked, setpostLiked] = useState(false);
  const [copied, setcopied] = useState(false);
const [startupsyouLiked, setstartupsyouLiked] = useState([]);
  // Number of posts to display per page
  const [postsPerPage] = useState(4);

  // Calculate the index of the first and last post to display
  const lastIndex = currentPage * postsPerPage;
  const firstIndex = lastIndex - postsPerPage;

  // Get the posts to display on the current page
  const currentPosts = filteredStartups.slice(firstIndex, lastIndex);
    // Logic to determine if there is a next or previous page
    const hasPreviousPage = currentPage > 1;
    const hasNextPage = lastIndex < filteredStartups.length;

  // Handle navigation to the next page
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Handle navigation to the previous page
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  // generate and store JWT token
  const generateToken = async () => {
    const username = "techadanian";
    const password = "i!gFe&ui3lX0BpaP(";

    try {
      const response = await axios.post(
        "https://dashboard.adanianlabs.io/wp-json/jwt-auth/v1/token",
        {
          username,
          password,
        }
      );
      const token = response.data.data.token;
      localStorage.setItem("jwtToken", token);
      return token;
    } catch (error) {
      console.error(error);
    }
  };
  const getStartups = () => {
    fetch("https://dashboard.adanianlabs.io/wp-json/wp/v2/startups/?per_page=100")
      .then((response) => response.json())
      .then((posts) => {
        const promises = posts.map((post) => {
          return fetch(
            `https://dashboard.adanianlabs.io/wp-json/wp/v2/media/${post.acf.company_logo}/?per_page=100`
          )
            .then((response) => response.json())
            .then((media) => {
              post.featured_image_url = media.source_url;
              return post;
            });
        });
        return Promise.all(promises);
      })
      .then((posts) => {
        if (posts.length === 0) {
          setAllPostsLoaded(true);
        } else {
          setstartups((prevPosts) => [...prevPosts, ...posts]);
        }
        const storedArray = localStorage.getItem('startups');

        const myArray = storedArray ? JSON.parse(storedArray) : [];
        setstartupsyouLiked([...new Set(myArray)])
        setstartups(posts);
        setLoading(false);
        if(id !='all'){
          let startup=posts.find((i)=>i.acf.startupName==myId)
          setDetails(startup)
        }
      })
      .catch((error) => console.error(error));
  };

  const handleLike = async (id, likes) => {
    generateToken();
    const token = localStorage.getItem("jwtToken");
    fetch(`https://dashboard.adanianlabs.io/wp-json/wp/v2/startups/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        acf: {
          likes: likes + 1,
        },
      }),
    }).then(() => {
      setlikedPostId(id);
      localStorage.setItem("startups",JSON.stringify([...startupsyouLiked,id]))
      setpostLiked(!postLiked);
      getStartups();
    });
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setcopied(!copied)
      })
      .catch((error) => {
      });
  };

    const shareToFacebook = (text) => {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=&quote=${encodeURIComponent(text)}`);
    };
  
    const shareToTwitter = (text) => {
      window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`);
    };
  
    const shareToLinkedIn = (text) => {
      window.open(`https://www.linkedin.com/shareArticle?mini=true&url=&title=&summary=&source=&text=${encodeURIComponent(text)}`);
    };
    const shareToTelegram = (text) => {
      window.open(`https://telegram.me/share/url?url=&text=${encodeURIComponent(text)}`);
    };
    const shareToInstagram = (text) => {
      window.open(`https://www.instagram.com/?text=${encodeURIComponent(text)}`);
    };
  
    const shareToYouTube = (text) => {
      window.open(`https://www.youtube.com/watch?v=&text=${encodeURIComponent(text)}`);
    };
  useEffect(() => {
    getStartups();
  }, [currentPage, postsPerPage,postLiked]);
  return (
    <section className="startups-section bg-cover bg-center  px-5 lg:px-[129px] bg-[#454545] relative pb-[76px]">
      <Modal isOpen={shareModal} onClose={handleCloseModal}>
      <div className="flex justify-end">
        <svg
          x-show="isOpen"
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 z-10 justify-self-end"
          fill="#ffffff"
          viewBox="0 0 24 24"
          stroke="#ffffff"
          strokeWidth="2"
          onClick={handleCloseModal}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        </div>
        <div className="flex flex-col items-center justify-center space-y-10">
          <div className="flex flex-wrap items-center justify-center gap-8">
            <img src="/icons/linkedin.svg" alt="linkedin"  className="z-10" onClick={()=>{shareToLinkedIn(`https://beta.adanianlabs.io/startups/${startupLink}`)}} loading="lazy"/>
            <img src="/icons/telegram.svg" alt="linkedin" className="z-10" onClick={()=>{shareToTelegram(`https://beta.adanianlabs.io/startups/${startupLink}`)}} loading="lazy"/>
            <img src="/icons/twitter.svg" alt="linkedin" className="z-10" onClick={()=>{shareToTwitter(`https://beta.adanianlabs.io/startups/${startupLink}`)}} loading="lazy"/>
            <img src="/icons/facebook.svg" alt="linkedin" className="z-10" onClick={()=>{shareToFacebook(`https://beta.adanianlabs.io/startups/${startupLink}`)}} loading="lazy"/>
            <img src="/icons/youtube.svg" alt="linkedin" className="z-10" onClick={()=>{shareToYouTube(`https://beta.adanianlabs.io/startups/${startupLink}`)}} loading="lazy"/>
            <img src="/icons/instagram.svg" alt="linkedin" className="z-10" onClick={()=>{shareToInstagram(`https://beta.adanianlabs.io/startups/${startupLink}`)}} loading="lazy"/>
            
          </div>
          <div className="flex items-center z-10 gap-2">
          <input type="text"  className=" h-[58px] w-[262px] lg:w-[660px] rounded-lg z-10 flex items-center justify-center text-center" value={`https://beta.adanianlabs.io/startups/${startupLink}`} id={startupLink}/>
          <div className="bg-white w-[48px] h-[58px] flex items-center justify-center rounded-lg">
            {!copied&&(
 <img src="/icons/copy.svg" alt="" onClick={()=>{copyToClipboard(`https://beta.adanianlabs.io/startups/${startupLink}`)}} loading="lazy" />
            )}
            {copied&&(
 <img src="/icons/Files.svg" alt="" onClick={()=>{copyToClipboard(`https://beta.adanianlabs.io/startups/${startupLink}`)}} loading="lazy" />
            )}
           
          </div>
          </div>
        </div>
      </Modal>
      <div className="w-full flex  flex-col items-center space-y-3 mb-[56px] ">
        <p className="heading">All Startups</p>
        <hr />
      </div>
      <div className="flex justify-between items-center">
        <p className="text-[24px] text-white">filters</p>
        <p className="text-[24px] text-white">{filteredStartups.length} Startups</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 fliters z-10 gap-2 lg:gap-[56px] mb-[100px] mt-[17px]">
        <select
          name="sectors1"
          id="sectors1"
          placeholder="Sectors"
          className="z-10 px-[23px]"
          value={industryFilter}
          onChange={(e) => setIndustryFilter(e.target.value)}
        >
          <option value="All" id="ai">
            All Sectors
          </option>
          <option value="Animation" id="Animation">
          Animation
          </option>
          <option value="HealthTech" id="HealthTech">
          HealthTech
          </option>
          <option value="AgriTech" id="AgriTech">
          AgriTech
          </option>
          <option value="MediaTech" id="MediaTech">
          MediaTech
          </option>
          <option value="LogTech" id="LogTech">
          Logistics
          </option>
          <option value="Fintech" id="Fintech">
          Fintech
          </option>
          <option value="Edtech" id="Edtech">
          Edtech
          </option>
          <option value="Gaming" id="Gaming">
          Gaming
          </option>
          <option value="web 3" id="web 3">
          Web 3 (Blockchain; AI; IOT)
          </option>
        </select>
        <select
          name="sectors2"
          id="sectors2"
          placeholder="Sectors"
          className="z-10 px-[23px]"
          value={countryFilter}
          onChange={(e) => setCountryFilter(e.target.value)}
        >
          <option value="All" id="countries">
            All Countries
          </option>
          <option value="Kenya" id="Kenya">
          Kenya
          </option>
          <option value="Tanzania" id="Tanzania">
          Tanzania
          </option>
          <option value="Nigeria" id="Nigeria">
          Nigeria
          </option>
          <option value="South Africa" id="South Africa">
          South Africa
          </option>
          <option value="Zambia" id="Zambia">
          Zambia
          </option>
          <option value="Malawi" id="Malawi">
          Malawi
          </option>
          <option value="Lesotho" id="Lesotho">
          Lesotho
          </option>
          <option value="UK" id="UK">
          UK
          </option>

          
        </select>
        <select
          name="sectors3"
          id="sectors3"
          placeholder="Sectors"
          className="z-10 px-[23px]"
          onChange={(e) => setcohortFilter(e.target.value)}
        >
          <option value="All" id="All">
            All Cohorts
          </option>
          <option value="Cohort 1" id="Cohort 1">
            Cohort one
          </option>
          <option value="Cohort 2" id="Cohort 2">
            Cohort two
          </option>
        </select>
        <select
          name="sectors4"
          id="sectors4"
          placeholder="Sectors"
          className="z-10 px-[13px]"
          value={fundingRoundFilter}
          onChange={(e) => setFundingRoundFilter(e.target.value)}
        >
          <option value="All" id="rounds">
          Fundraising Round/Stage
          </option>
          <option value="pre-seed" id="pre seed">
            Pre seed
          </option>
          <option value="seed" id="Seed">
            Seed
          </option>
          <option value="Pre Series A" id="Pre Series A">
            Pre Series A
          </option>
          <option value="Series A" id="Series A">
            Series A
          </option>
          <option value="Series B" id="Series B">
            Series B
          </option>
          <option value="Other" id="Other">
            Other
          </option>
        </select>
      </div>
      <div className="ellipse-1"></div>
      <div className="ellipse-2"></div>
      <div className="ellipse-3"></div>
      {details && (
        <div
          className="relative mx-auto box-border flex  flex-col lg:flex-row  max-h-[625px] lg:max-h-[459px] max-w-[1157px]  justify-between rounded-2xl border border-[#13E2FF] p-7 text-24 gap-6 overflow-scroll no-scrollbar"
          style={{
            background:
              "linear-gradient(113.54deg, rgba(217, 217, 217, 0.6) 7.92%, rgba(217, 217, 217, 0.3) 97.86%)",
          }}
        >
          <button
            className="absolute top-0 left-10 -translate-x-10 z-10"
            onClick={() => setDetails(null)}
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="z-10"
              
            >
              <circle cx="12.5" cy="12.5" r="12.5" fill="#D9D9D9" />
              <path
                d="M3.93934 10.9393C3.35355 11.5251 3.35355 12.4749 3.93934 13.0607L13.4853 22.6066C14.0711 23.1924 15.0208 23.1924 15.6066 22.6066C16.1924 22.0208 16.1924 21.0711 15.6066 20.4853L7.12132 12L15.6066 3.51472C16.1924 2.92893 16.1924 1.97918 15.6066 1.3934C15.0208 0.807611 14.0711 0.807611 13.4853 1.3934L3.93934 10.9393ZM21 10.5L5 10.5L5 13.5L21 13.5L21 10.5Z"
                fill="#454545"

              />

            </svg>
          </button>
          <div>
            <img
              src={details.featured_image_url}
              alt=""
              className="mx-auto mb-5 max-h-[330px] object-contain"
            />
            <h1>{details.acf.industry}</h1>

            <div className="flex items-center space-x-2">
            {startupsyouLiked.includes(details.id)  ? (
                    <img
                      src="/icons/liked.svg"
                      alt="like"
                      onClick={() => {
                        handleLike(details.id, details.acf.likes);
                      }}
                      className="z-10"
                    />
                  ) : (
                    <img
                      src="/icons/heart.svg"
                      alt="like"
                      onClick={() => {
                        handleLike(details.id, details.acf.likes);
                      }}
                      className="z-10"
                    />
                  )}
              <span className="text-base">{details.acf.likes}</span>
            </div>
          </div>
          <div className="max-h-[900px] max-w-[785px] space-y-8 overflow-y-scroll no-scrollbar">
            <p>{details.acf.description}</p>
            <div>
              <h1 className="startup-modal-heading">Services:</h1>
              <p dangerouslySetInnerHTML={{ __html:details.acf.services }}></p>
            </div>
            <div>
              <h1 className="startup-modal-heading">The Challenge:</h1>
              <p  dangerouslySetInnerHTML={{ __html:details.acf.the__challenge }}></p>
            </div>
            <div>
              <h1 className="startup-modal-heading">The Solution:</h1>
              <p dangerouslySetInnerHTML={{ __html:details.acf.the_solution }}></p>
            </div>
            <div>
              <h1 className="startup-modal-heading">Next Steps:</h1>
              <div >
                <p dangerouslySetInnerHTML={{ __html:details.acf.next_steps}}></p>
                </div>
            </div>
          </div>
        </div>
      )}
      {!details && (
        <>
         <div className="grid grid-cols-1 md:grid-cols-2 gap-[57px]">
          {currentPosts.map((a) => (
            <div className="startup-card z-10 flex flex-col md:flex-row items-start  md:items-center justify-between px-[32px] gap-3 md:gap-[36px]" key={a.id}>
              <img src={a.featured_image_url} alt="logo"/>
              <div className="startup-details space-y-[10px]">
                <h2>{a.acf.startupName}</h2>
                <h3>{a.acf.description}...</h3>
                <h3 className="mt-8 underline" onClick={() => setDetails(a)}>
                  Click to learn more
                </h3>
                <div className="flex items-center justify-end gap-[12px]">
                  <img src="/icons/share.svg" alt="share" className="mr-8 z-10"  onClick={() => {
              handleOpenModal(a.id,a.acf.startupName);
            }}/>
                  {startupsyouLiked.includes(a.id) ? (
                    <img
                      src="/icons/liked.svg"
                      alt="like"
                      onClick={() => {
                        handleLike(a.id, a.acf.likes);
                      }}
                      className="z-10"
                    />
                  ) : (
                    <img
                      src="/icons/heart.svg"
                      alt="like"
                      onClick={() => {
                        handleLike(a.id, a.acf.likes);
                      }}
                      className="z-10"
                    />
                  )}
                  <span className="text-white">{a.acf.likes}</span>
                </div>
              </div>
            </div>
          ))}
        
        </div>
        {!loading &&(
       <div className="flex flex-col md:flex-row items-center justify-center w-full my-8 gap-4 md:gap-10">
         <Button  className={`${!hasPreviousPage && 'opacity-50 cursor-not-allowed'} mb-3  text-white`} onClick={handlePrevPage} disabled={!hasPreviousPage} >BACK</Button>
         <Button  onClick={handleNextPage} disabled={!hasNextPage}   variant="orange"
            className={`${!hasNextPage && 'opacity-50 cursor-not-allowed'} mb-3  text-white`}>NEXT</Button>
       </div>
      )}
        </>
       
      )}
    </section>
  );
};
export default StartupsContent;
