import { Container } from "../components/shared";
import SharedHero from "../components/utils/hero";
import background from "../components/utils/leadership-team.jpg";

const LeaderShipPage = () => {
  return (
    <Container className="bg-[#404040]">
      <SharedHero
        title={"LEADERSHIP TEAM"}
        subtitle={"Our founders bring a wealth of experience in software development, data analytics, and machine learning, and are committed to delivering exceptional results for our clients. Learn more about our team and our vision for the future of technology."}
        background={background}
      />
      <div className=" px-5 lg:px-32 py-16 h-fit relative">
        <div className="ellipse-1"></div>
        <div className="ellipse-2"></div>
        <div className="flex flex-col md:flex-row  items-center gap-10 z-10">
          <div className="basis-1/3 text-center space-y-5 founder-details z-10">
            <img src="/pages/content-portal/founders/john-kamara.png" alt="" />
           <div className="flex items-center gap-10">
            <div> <p>John Kamara</p>
            <span>Co-Founder & CEO</span></div>
            <a href="https://www.linkedin.com/in/johnkamara/"><img src="/icons/linkedin.svg" alt="" /></a>
           </div>
          </div>
          <p className="text-[24px] basis-2/3">
            Growth Strategy Consultant, former CEO of Cortex Africa and
            Non–Executive Director of Machine Intelligence Institute of Africa.
            20+ years of experience launching tech-driven solutions in new
            markets in Europe, South America, the Caribbean and Africa. Tech &
            Gaming Entrepreneur <br />
            <br />
            Tech Entrepreneur using smart technology to drive exponential growth
            in data driven organizations. Visionary and thought leader with
            experience in new markets and vertical across multiple sectors.
          </p>
        </div>
        <div className="flex flex-col-reverse md:flex-row items-center gap-10 z-10">
          <p className="text-[24px] basis-2/3">
            Marketing Consultant with 18+ years in Media, Communication, PR and
            supporting pan-African companies with their go-to-market strategies.
            Youth & women empowerment specialist with 10+ years experience
            running sustainability programs to empower two segments. Media
            entrepreneur.
          </p>
          <div className="basis-1/3 text-center space-y-5 founder-details z-10">
            <img src="/pages/content-portal/founders/irene-kiwia.png" alt="" className="z-10"/>
            <div className="flex items-center gap-10">
            <div> <p>Irene Kiwia</p>
            <span>Co-Founder & CGO</span></div>
            <a href="https://www.linkedin.com/in/kiwiairene/"><img src="/icons/linkedin.svg" alt="" /></a>
           </div>
           
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-10">
          <div className="basis-1/3 text-center space-y-5 founder-details">
            <img
              src="/pages/content-portal/founders/bendon-murgor.png"
              alt=""
            />
             <div className="flex items-center gap-10">
            <div> <p>Bendon Murgor</p>
            <span>Co-Founder & CTO</span></div>
            <a href="https://www.linkedin.com/in/bendonmurgor/"><img src="/icons/linkedin.svg" alt="" /></a>
           </div>
           
          </div>
          <p className="text-[24px] basis-2/3">
            Senior software engineer with 16+ years experience architecting and
            building tech solutions for Pan-African clients. Certifications in
            CCNA, ITILv3 and GCIH, with extensive experience in Gaming, USSD
            Development, Mobile Money integrations, Blockchain programming,
            Mobile Applications and Systems Security.
          </p>
        </div>
      </div>
    </Container>
  );
};
export default LeaderShipPage;
