const WhoWeAre = () => {
  return (
    <section className="container relative  px-5 lg:px-[150px]">
      <div className="about-ellipse-5"></div>
      <div className="about-ellipse-6"></div>
      <div className="about-ellipse-7"></div>
      <div className="about-ellipse-8"></div>
      <div className="about-ellipse-10"></div>
      <div className="flex flex-col md:flex-row justify-between items-start gap-10 lg:gap-[64px] z-10">
        <div className="about-text flex flex-col items-center pt-2 md:pt-12 z-10">
          <h1 className="mb-[37px] z-10">Who we are</h1>
          <hr className=" mb-10 lg:mb-[95px] z-10" />
          <p className="z-10">
            Adanian Labs as a #VentureBuilder or #Venture Studio means we are
            using our unique positioning to create and spearhead the sharing
            economy for Africa by building a socio-economic ecosystem revolving
            around the sharing of resources, shared creation, production,
            distribution, trade and consumption.
          </p>
        </div>
        <img src="images/about-us/vision.png" alt="why us" className="z-10" loading="lazy" />
      </div>
      <div className="flex  flex-col-reverse md:flex-row justify-between items-start gap-[64px] z-10">
        <img src="images/about-us/why-us.png" alt="why us" className="z-10" loading="lazy" />
        <div className="about-text flex flex-col items-center pt-12 z-10">
          <h1 className="mb-[37px] z-10">What we do</h1>
          <hr className="l mb-10 g:mb-[95px] z-10" />
          <p className="z-10">
            As the startup that builds startups we apply #LeanStartup principles
            in processes, learning, iteration, scaling and innovating solutions
            in our deployment. Our aim is to build solutions across #SDG impact
            goals that will power African youth and economy while creating
            massive impact.
          </p>
        </div>
      </div>
    </section>
  );
};
export default WhoWeAre;
