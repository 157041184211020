
import SharedHero from "../components/utils/hero";
import { Button, Container, Line, Modal } from "../components/shared";
import background from "../components/utils/studio.jpg";
import { useState } from "react";
import swal from "sweetalert";
import { submitForm } from "../services";
const StudioPage = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        country: '',
        contactNumber: '',
        businessName: '',
        businessType: '',
        businessWebsite: '',
        businessSize: '',
        sector: '',
        technicalSkills: '',
        marketingChannels: '',
        prevExperience: "",
        marketingStrategy: '',
        audience: '',
        portfolio: null,
        agreeToTerms: false,
    });
    const [shareModal, setshareModal] = useState(false);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://dashboard.adanianlabs.io/wp-json/wp/v2/affiliate_partners', {
                method: 'POST',
                body: formData,
            });
            swal('Thank you for your submission')

            if (response.ok) {
                // Successfully created the item
            } else {
                // Handle errors
            }
        } catch (error) {

        }
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        const payload = {
            "First Name": formData.firstName,
            "Last Name": formData.lastName,
            "Business Name": formData.businessName,
            "Business Size": formData.businessSize,
            "business type": formData.businessType,
            "Contact Number": formData.contactNumber,
            'email': formData.emailAddress,
            'Would you prefer a fully managed service or a self-managed solution?': formData.country,
            'profile': formData.portfolio,
            "Business website": formData.businessWebsite,
            "What country (countries) is your business operating?":formData.country,
            "Target Audience/Niche:":formData.audience,
            "Describe Your Marketing Channels":formData.marketingChannels,
            "Marketing Strategy":formData.marketingStrategy,
            "Previous Affiliate Experience":formData.prevExperience,
            "Sector":formData.sector,
            "What are the key technical skills for your team?":formData.technicalSkills


        }
        const tableRows = Object.entries(payload).map(([question, answer]) => `
          <tr  style="border:1px solid black;">
            <td >${question}</td>
            <td>${answer}</td>
          </tr>
        `);

        const table = `
          <table style="border:1px solid black;">
            <thead style="border:1px solid black;">
              <tr style="border:1px solid black;">
                <th style="border:1px solid black;">Question</th>
                <th style="border:1px solid black;">Answer</th>
              </tr>
            </thead>
            <tbody style="border:1px solid black;">
              ${tableRows.join('')}
            </tbody>
          </table>
        `;
        const data = payload
        submitForm('affiliate_partners', {
            title: formData.emailAddress,
            content: table,
            status: 'publish',
            meta: data
        }).then((res) => {

        })
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        // Handle different input types
        const inputValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value;

        setFormData({
            ...formData,
            [name]: inputValue,
        });
    };

    const handleOpenModal = (index, name) => {
        setshareModal(true);
    };

    const handleCloseModal = () => {
        setshareModal(false);
    };
    return (
        <Container className="bg-[#404040] studio">
            <Modal isOpen={shareModal} onClose={handleCloseModal}>
                <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-4 z-10 partner">
                        {/* First Name and Last Name fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10 z-10">
                            <div className="space-y-2 w-full z-10">
                                <p>First Name</p>
                                <input type="text" name="firstName" id="firstName" value={formData.firstName} onChange={handleInputChange} className="z-10 text-white" />
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Last Name</p>
                                <input type="text" name="lastName" id="lastName" value={formData.lastName} onChange={handleInputChange}  className="text-white" />
                            </div>
                        </div>
                        {/* Email and Country fields */}
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10 ">
                            <div className="space-y-2 w-full z-10">
                                <p>Email Address</p>
                                <input type="text" name="emailAddress" id="emailAddress" value={formData.emailAddress} onChange={handleInputChange}  className="text-white"/>
                            </div>
                            
                            <div className="space-y-2 w-full z-10">
                                <p>Contact Number</p>
                                <input type="text" name="contactNumber" id="contactNumber" value={formData.contactNumber} onChange={handleInputChange}  className="text-white"/>
                            </div>
                        </div>

                        {/* Contact Number and Business Name fields */}
                        <div className="flex flex-col justify-between w-full gap-10">
                        <div className="space-y-2 w-full z-10">
                                <p>Project Type?</p>
                                <input type="text" name="country" id="country" value={formData.country} onChange={handleInputChange}  className="text-white" placeholder="Audio Post Production"/>
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Project Brief</p>
                                <textarea type="text" name="businessName" id="businessName" value={formData.businessName} onChange={handleInputChange} className="text-white" ></textarea>
                            </div>
                        </div>

                        {/* Business Type and Business Website fields */}
                        <div className="flex flex-col justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Session Date & Time</p>
                                <input type="date" name="businessType" id="businessType" value={formData.businessType} onChange={handleInputChange}  className="text-white"/>
                            </div>
                            <div className="space-y-2 w-full z-10">
                                <p>Estimated Time Needed
Studio Time Subject to Requirements</p>
                                <input type="text" name="businessWebsite" id="businessWebsite" value={formData.businessWebsite} onChange={handleInputChange}  className="text-white"/>
                            </div>
                        </div>
                    
                        <div className="flex flex-col md:flex-row justify-between w-full gap-10">
                            <div className="space-y-2 w-full z-10">
                                <p>Studio Services Required (Select All that Apply)</p>
                                <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" id="agreeToTerms" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full">Voice Over Recording</p>
                        </div>
                        <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" id="agreeToTerms2" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full">Final Mix</p>
                        </div>
                        <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" id="agreeToTerms3" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full">SFX Design</p>
                        </div>
                        <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" id="agreeToTerms4" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full">Remote Studio Link</p>
                        </div>
                        <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" id="agreeToTerms4" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full">Audio Forensics</p>
                        </div>
                        <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" id="agreeToTerms5" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full">Voice Artist Casting</p>
                        </div>
                        <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" id="agreeToTerms6" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full">Voice Artist Demo</p>
                        </div>
                        <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" id="agreeToTerms7" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full">Scriptwriting</p>
                        </div>
                        <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" id="agreeToTerms9" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full">Translation</p>
                        </div>
                        <div className="flex justify-start gap-4 items-center">
                            <div>
                                <input type="checkbox" name="agreeToTerms" id="agreeToTerms11" checked={formData.agreeToTerms} onChange={handleInputChange} className="checkbox" />
                            </div >
                            <p className="w-full">Language Supervision</p>
                        </div>
                               
                            </div>
                        
                        </div>

                        {/* Terms and Conditions Checkbox */}
                        <div className="flex justify-start gap-4 items-center">
                           
                            <span className="w-full text-[#f39711] text-center">Please note that WE ARE NOT A MUSIC PRODUCTION STUDIO. We cannot assist with any music production/band recording productions.</span>
                        </div>

                        {/* Submit Button */}
                        <Button type="submit" className={formData.agreeToTerms? 'cursor-pointer w-full text-white z-10 uppercase' : 'cursor-not-allowed w-full text-white z-10 uppercase'} variant={'orange'} disabled={!formData.agreeToTerms}>sign up</Button>
                    </form>
                </Modal>
            <SharedHero
                title={"EHEE STUDIO."}
                subtitle={"Compelling Content, Captivating Connections."}
                background={background}
            />
            <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col relative">
                <div className="studio-orange absolute top-0 left-[20%]">

                </div>
                <h1 className="text-center">
                    Welcome to Ehee Studios
                </h1>
                <Line
                    variant='orange'
                    className="mx-auto my-3 h-[1px] w-[240px]"
                ></Line>
                <p className="text-center">Professional Content Studio for Podcasts, Vlogs, and More</p>
                <div className="card w-full h-fit py-10 space-y-4 my-5">
                    <h1 className="text-center">Bookings Open Now for January 2024!</h1>
                    <p className="text-center">Where Quality Sound Meets Endless Creativity – Your One-Stop Destination for All Your Content Creation Needs!</p>
                    <div className="flex flex-col md:flex-row gap-4 items-center justify-evenly">
                        <div className="flex gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <mask id="mask0_1324_4440" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <rect width="24" height="24" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1324_4440)">
                                    <path d="M12 12C12.55 12 13.0208 11.8042 13.4125 11.4125C13.8042 11.0208 14 10.55 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.55 10.1958 11.0208 10.5875 11.4125C10.9792 11.8042 11.45 12 12 12ZM12 22C9.31667 19.7167 7.3125 17.5958 5.9875 15.6375C4.6625 13.6792 4 11.8667 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 11.8667 19.3375 13.6792 18.0125 15.6375C16.6875 17.5958 14.6833 19.7167 12 22Z" fill="white" />
                                </g>
                            </svg>  <h4>Hyde Park, Johannesburg, South Africa</h4>
                        </div>
                        <div className="flex gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <mask id="mask0_1323_4405" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <rect width="24" height="24" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1323_4405)">
                                    <path d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM12 13L20 8V6L12 11L4 6V8L12 13Z" fill="white" />
                                </g>
                            </svg>
                            <h4>studio@adanianlabs.io</h4>
                        </div>
                        <div className="flex gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <mask id="mask0_1323_4411" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <rect width="24" height="24" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1323_4411)">
                                    <path d="M19.95 21C17.8667 21 15.8083 20.5458 13.775 19.6375C11.7417 18.7292 9.89167 17.4417 8.225 15.775C6.55833 14.1083 5.27083 12.2583 4.3625 10.225C3.45417 8.19167 3 6.13333 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.07917 8.725 3.2375C8.90833 3.39583 9.01667 3.58333 9.05 3.8L9.7 7.3C9.73333 7.56667 9.725 7.79167 9.675 7.975C9.625 8.15833 9.53333 8.31667 9.4 8.45L6.975 10.9C7.30833 11.5167 7.70417 12.1125 8.1625 12.6875C8.62083 13.2625 9.125 13.8167 9.675 14.35C10.1917 14.8667 10.7333 15.3458 11.3 15.7875C11.8667 16.2292 12.4667 16.6333 13.1 17L15.45 14.65C15.6 14.5 15.7958 14.3875 16.0375 14.3125C16.2792 14.2375 16.5167 14.2167 16.75 14.25L20.2 14.95C20.4333 15.0167 20.625 15.1375 20.775 15.3125C20.925 15.4875 21 15.6833 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21Z" fill="white" />
                                </g>
                            </svg>
                            <h4>+27 60 567 7245</h4>

                        </div>

                    </div>
                    <div className="w-full flex justify-center mt-5">
                        <Button variant='orange'  className="flex items-center gap-2" onClick={handleOpenModal}>
                            Book Now <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
<mask id="mask0_1324_4825" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
<rect width="36" height="36" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1324_4825)">
<path d="M9.6 27L7.5 24.9L21.9 10.5H9V7.5H27V25.5H24V12.6L9.6 27Z" fill="#F39710"/>
</g>
</svg>

                        </Button>
                    </div>

                </div>
            </section>
            <section className="px-5 lg:px-28 py-10 relative ">
                <div className="studio-blue absolute left-0 top-0">

                </div>
                <div className="flex flex-col md:flex-row items-center">
                    <div className="basis-2/2 md:basis-2/3">
                        <h1>What We Do</h1>
                        <Line
                            variant='orange'
                            className=" my-3 h-[1px] w-[240px]"
                        ></Line>
                        <p>At Ehee Studio, we expertly capture the essence of enthusiasm and affirmation. The word 'Ehee' embodies a profound sense of agreement, excitement, and acknowledgment, infusing your story with a collaborative and energetic essence. Our studio is dedicated to embracing and amplifying positive narratives, ensuring that your content resonates with the vibrance and the unique vitality of Ehee! <br /> <br />For bookings and inquiries, reach out to us at:</p>
                        <div className="flex flex-col md:flex-row gap-6 my-5">
                            <Button variant='orange' to="/contact-us">
                                <div className="flex gap-2 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <mask id="mask0_1322_4130" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                            <rect width="24" height="24" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_1322_4130)">
                                            <path d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM12 13L20 8V6L12 11L4 6V8L12 13Z" fill="url(#paint0_linear_1322_4130)" />
                                        </g>
                                        <defs>
                                            <linearGradient id="paint0_linear_1322_4130" x1="12" y1="4" x2="12" y2="20" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#F39710" />
                                                <stop offset="0.317708" stopColor="#E08C10" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    studio@adanianlabs.io
                                </div>

                            </Button>
                            <Button variant='orange' to="/contact-us">
                                <div className="flex gap-2 items-center whitespace-nowrap">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <mask id="mask0_1322_4124" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                            <rect width="24" height="24" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_1322_4124)">
                                            <path d="M19.95 21C17.8667 21 15.8083 20.5458 13.775 19.6375C11.7417 18.7292 9.89167 17.4417 8.225 15.775C6.55833 14.1083 5.27083 12.2583 4.3625 10.225C3.45417 8.19167 3 6.13333 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.07917 8.725 3.2375C8.90833 3.39583 9.01667 3.58333 9.05 3.8L9.7 7.3C9.73333 7.56667 9.725 7.79167 9.675 7.975C9.625 8.15833 9.53333 8.31667 9.4 8.45L6.975 10.9C7.30833 11.5167 7.70417 12.1125 8.1625 12.6875C8.62083 13.2625 9.125 13.8167 9.675 14.35C10.1917 14.8667 10.7333 15.3458 11.3 15.7875C11.8667 16.2292 12.4667 16.6333 13.1 17L15.45 14.65C15.6 14.5 15.7958 14.3875 16.0375 14.3125C16.2792 14.2375 16.5167 14.2167 16.75 14.25L20.2 14.95C20.4333 15.0167 20.625 15.1375 20.775 15.3125C20.925 15.4875 21 15.6833 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21Z" fill="url(#paint0_linear_1322_4124)" />
                                        </g>
                                        <defs>
                                            <linearGradient id="paint0_linear_1322_4124" x1="12" y1="3" x2="12" y2="21" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#F39710" />
                                                <stop offset="0.317708" stopColor="#E08C10" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    +27 60 567 7245
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div>
                        <img src="/images/podcast.png" alt="podcast" loading="lazy"></img>
                    </div>

                </div>
            </section>
            <section className="px-5 lg:px-28 py-10 relative">
                <div className="studio-orange absolute top-0 right-0">

                </div>
                <h1 className="text-center">Our Services</h1>
                <Line
                    variant='orange'
                    className="mx-auto my-3 h-[1px] w-[240px]"
                ></Line>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                    <div className="space-y-5">
                    <h3>We Offer</h3>
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <mask id="mask0_1324_4798" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                                <rect width="30" height="30" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_1324_4798)">
                                <path d="M15 17.5C13.9583 17.5 13.0729 17.1354 12.3438 16.4062C11.6146 15.6771 11.25 14.7917 11.25 13.75V6.25C11.25 5.20833 11.6146 4.32292 12.3438 3.59375C13.0729 2.86458 13.9583 2.5 15 2.5C16.0417 2.5 16.9271 2.86458 17.6562 3.59375C18.3854 4.32292 18.75 5.20833 18.75 6.25V13.75C18.75 14.7917 18.3854 15.6771 17.6562 16.4062C16.9271 17.1354 16.0417 17.5 15 17.5ZM13.75 26.25V22.4062C11.5833 22.1146 9.79167 21.1458 8.375 19.5C6.95833 17.8542 6.25 15.9375 6.25 13.75H8.75C8.75 15.4792 9.35938 16.9531 10.5781 18.1719C11.7969 19.3906 13.2708 20 15 20C16.7292 20 18.2031 19.3906 19.4219 18.1719C20.6406 16.9531 21.25 15.4792 21.25 13.75H23.75C23.75 15.9375 23.0417 17.8542 21.625 19.5C20.2083 21.1458 18.4167 22.1146 16.25 22.4062V26.25H13.75ZM15 15C15.3542 15 15.651 14.8802 15.8906 14.6406C16.1302 14.401 16.25 14.1042 16.25 13.75V6.25C16.25 5.89583 16.1302 5.59896 15.8906 5.35938C15.651 5.11979 15.3542 5 15 5C14.6458 5 14.349 5.11979 14.1094 5.35938C13.8698 5.59896 13.75 5.89583 13.75 6.25V13.75C13.75 14.1042 13.8698 14.401 14.1094 14.6406C14.349 14.8802 14.6458 15 15 15Z" fill="url(#paint0_linear_1324_4798)" />
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_1324_4798" x1="15" y1="2.5" x2="15" y2="26.25" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#13E2FF" />
                                    <stop offset="1" stopColor="#13A8BD" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <p>
                            <span className="text-[#13E2FF]">Content Strategy</span>: Craft a roadmap for your content that aligns with your goals.
                        </p>
                    </div>
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <mask id="mask0_1324_4798" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                                <rect width="30" height="30" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_1324_4798)">
                                <path d="M15 17.5C13.9583 17.5 13.0729 17.1354 12.3438 16.4062C11.6146 15.6771 11.25 14.7917 11.25 13.75V6.25C11.25 5.20833 11.6146 4.32292 12.3438 3.59375C13.0729 2.86458 13.9583 2.5 15 2.5C16.0417 2.5 16.9271 2.86458 17.6562 3.59375C18.3854 4.32292 18.75 5.20833 18.75 6.25V13.75C18.75 14.7917 18.3854 15.6771 17.6562 16.4062C16.9271 17.1354 16.0417 17.5 15 17.5ZM13.75 26.25V22.4062C11.5833 22.1146 9.79167 21.1458 8.375 19.5C6.95833 17.8542 6.25 15.9375 6.25 13.75H8.75C8.75 15.4792 9.35938 16.9531 10.5781 18.1719C11.7969 19.3906 13.2708 20 15 20C16.7292 20 18.2031 19.3906 19.4219 18.1719C20.6406 16.9531 21.25 15.4792 21.25 13.75H23.75C23.75 15.9375 23.0417 17.8542 21.625 19.5C20.2083 21.1458 18.4167 22.1146 16.25 22.4062V26.25H13.75ZM15 15C15.3542 15 15.651 14.8802 15.8906 14.6406C16.1302 14.401 16.25 14.1042 16.25 13.75V6.25C16.25 5.89583 16.1302 5.59896 15.8906 5.35938C15.651 5.11979 15.3542 5 15 5C14.6458 5 14.349 5.11979 14.1094 5.35938C13.8698 5.59896 13.75 5.89583 13.75 6.25V13.75C13.75 14.1042 13.8698 14.401 14.1094 14.6406C14.349 14.8802 14.6458 15 15 15Z" fill="url(#paint0_linear_1324_4798)" />
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_1324_4798" x1="15" y1="2.5" x2="15" y2="26.25" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#13E2FF" />
                                    <stop offset="1" stopColor="#13A8BD" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <p>
                            <span className="text-[#13E2FF]">Content Production</span>: Bring your ideas to life with our professional production team.
                        </p>
                    </div>
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <mask id="mask0_1324_4798" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                                <rect width="30" height="30" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_1324_4798)">
                                <path d="M15 17.5C13.9583 17.5 13.0729 17.1354 12.3438 16.4062C11.6146 15.6771 11.25 14.7917 11.25 13.75V6.25C11.25 5.20833 11.6146 4.32292 12.3438 3.59375C13.0729 2.86458 13.9583 2.5 15 2.5C16.0417 2.5 16.9271 2.86458 17.6562 3.59375C18.3854 4.32292 18.75 5.20833 18.75 6.25V13.75C18.75 14.7917 18.3854 15.6771 17.6562 16.4062C16.9271 17.1354 16.0417 17.5 15 17.5ZM13.75 26.25V22.4062C11.5833 22.1146 9.79167 21.1458 8.375 19.5C6.95833 17.8542 6.25 15.9375 6.25 13.75H8.75C8.75 15.4792 9.35938 16.9531 10.5781 18.1719C11.7969 19.3906 13.2708 20 15 20C16.7292 20 18.2031 19.3906 19.4219 18.1719C20.6406 16.9531 21.25 15.4792 21.25 13.75H23.75C23.75 15.9375 23.0417 17.8542 21.625 19.5C20.2083 21.1458 18.4167 22.1146 16.25 22.4062V26.25H13.75ZM15 15C15.3542 15 15.651 14.8802 15.8906 14.6406C16.1302 14.401 16.25 14.1042 16.25 13.75V6.25C16.25 5.89583 16.1302 5.59896 15.8906 5.35938C15.651 5.11979 15.3542 5 15 5C14.6458 5 14.349 5.11979 14.1094 5.35938C13.8698 5.59896 13.75 5.89583 13.75 6.25V13.75C13.75 14.1042 13.8698 14.401 14.1094 14.6406C14.349 14.8802 14.6458 15 15 15Z" fill="url(#paint0_linear_1324_4798)" />
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_1324_4798" x1="15" y1="2.5" x2="15" y2="26.25" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#13E2FF" />
                                    <stop offset="1" stopColor="#13A8BD" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <p>
                            <span className="text-[#13E2FF]">Content Distribution</span>: Amplify your reach with strategic distribution across platforms.
                        </p>
                    </div>
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <mask id="mask0_1324_4798" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                                <rect width="30" height="30" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_1324_4798)">
                                <path d="M15 17.5C13.9583 17.5 13.0729 17.1354 12.3438 16.4062C11.6146 15.6771 11.25 14.7917 11.25 13.75V6.25C11.25 5.20833 11.6146 4.32292 12.3438 3.59375C13.0729 2.86458 13.9583 2.5 15 2.5C16.0417 2.5 16.9271 2.86458 17.6562 3.59375C18.3854 4.32292 18.75 5.20833 18.75 6.25V13.75C18.75 14.7917 18.3854 15.6771 17.6562 16.4062C16.9271 17.1354 16.0417 17.5 15 17.5ZM13.75 26.25V22.4062C11.5833 22.1146 9.79167 21.1458 8.375 19.5C6.95833 17.8542 6.25 15.9375 6.25 13.75H8.75C8.75 15.4792 9.35938 16.9531 10.5781 18.1719C11.7969 19.3906 13.2708 20 15 20C16.7292 20 18.2031 19.3906 19.4219 18.1719C20.6406 16.9531 21.25 15.4792 21.25 13.75H23.75C23.75 15.9375 23.0417 17.8542 21.625 19.5C20.2083 21.1458 18.4167 22.1146 16.25 22.4062V26.25H13.75ZM15 15C15.3542 15 15.651 14.8802 15.8906 14.6406C16.1302 14.401 16.25 14.1042 16.25 13.75V6.25C16.25 5.89583 16.1302 5.59896 15.8906 5.35938C15.651 5.11979 15.3542 5 15 5C14.6458 5 14.349 5.11979 14.1094 5.35938C13.8698 5.59896 13.75 5.89583 13.75 6.25V13.75C13.75 14.1042 13.8698 14.401 14.1094 14.6406C14.349 14.8802 14.6458 15 15 15Z" fill="url(#paint0_linear_1324_4798)" />
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_1324_4798" x1="15" y1="2.5" x2="15" y2="26.25" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#13E2FF" />
                                    <stop offset="1" stopColor="#13A8BD" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <p>
                            <span className="text-[#13E2FF]">Content Repurposing</span>: Maximize the value of your content by repurposing it for various channels.
                        </p>
                    </div>
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <mask id="mask0_1324_4798" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                                <rect width="30" height="30" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_1324_4798)">
                                <path d="M15 17.5C13.9583 17.5 13.0729 17.1354 12.3438 16.4062C11.6146 15.6771 11.25 14.7917 11.25 13.75V6.25C11.25 5.20833 11.6146 4.32292 12.3438 3.59375C13.0729 2.86458 13.9583 2.5 15 2.5C16.0417 2.5 16.9271 2.86458 17.6562 3.59375C18.3854 4.32292 18.75 5.20833 18.75 6.25V13.75C18.75 14.7917 18.3854 15.6771 17.6562 16.4062C16.9271 17.1354 16.0417 17.5 15 17.5ZM13.75 26.25V22.4062C11.5833 22.1146 9.79167 21.1458 8.375 19.5C6.95833 17.8542 6.25 15.9375 6.25 13.75H8.75C8.75 15.4792 9.35938 16.9531 10.5781 18.1719C11.7969 19.3906 13.2708 20 15 20C16.7292 20 18.2031 19.3906 19.4219 18.1719C20.6406 16.9531 21.25 15.4792 21.25 13.75H23.75C23.75 15.9375 23.0417 17.8542 21.625 19.5C20.2083 21.1458 18.4167 22.1146 16.25 22.4062V26.25H13.75ZM15 15C15.3542 15 15.651 14.8802 15.8906 14.6406C16.1302 14.401 16.25 14.1042 16.25 13.75V6.25C16.25 5.89583 16.1302 5.59896 15.8906 5.35938C15.651 5.11979 15.3542 5 15 5C14.6458 5 14.349 5.11979 14.1094 5.35938C13.8698 5.59896 13.75 5.89583 13.75 6.25V13.75C13.75 14.1042 13.8698 14.401 14.1094 14.6406C14.349 14.8802 14.6458 15 15 15Z" fill="url(#paint0_linear_1324_4798)" />
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_1324_4798" x1="15" y1="2.5" x2="15" y2="26.25" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#13E2FF" />
                                    <stop offset="1" stopColor="#13A8BD" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <p>
                            <span className="text-[#13E2FF]">Content Creation Training</span>: Equip yourself with the skills to create compelling content.
                        </p>
                    </div>
                    </div>
                    <div>
                        <h3 className="mb-5">Available to:</h3>
                        <div className="flex flex-col md:flex-row gap-5 ">
                            <div className="space-y-5">
                            <div className="card-2 flex items-center w-full gap-2 px-12">
                            <img src="/images/studio/corporate.png" alt="corporate" />
                            Corporates

                        </div>
                        <div className="card-2 flex items-center w-full gap-2 px-12">
                            <img src="/images/studio/influencers.png" alt="influencers" />
                            Influencers

                        </div>
                        <div className="card-2 flex items-center w-full gap-2 px-12">
                            <img src="/images/studio/coaches.png" alt="corporate" />
                            Coaches

                        </div>
                            </div>
                            <div className="space-y-5 md:mt-20">
                            <div className="card-2 flex items-center w-full gap-2 px-12">
                            <img src="/images/studio/creators.png" alt="creators" />
                            Creators

                        </div>
                        <div className="card-2 flex items-center w-full gap-2 px-12">
                            <img src="/images/studio/brands.png" alt="brands" />
                            Brands

                        </div>
                        <div className="card-2 flex items-center w-full gap-2 px-12">
                            <img src="/images/studio/trainers.png" alt="corporate" />
                            Trainers

                        </div>
                            </div>
                      

                        </div>
                      

                    </div>
                  

                </div>
            </section>
            <section className=" py-10 ">
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="grid-1 flex flex-col justify-between items-start py-10 px-10 gap-10 md:px-28">
                        <div>
                            <h1>Studio Hire</h1>
                            <ul className="list-discmy-5">
                                <li>Half day: R450 per hour </li>
                                <li>Full day: R350 per hour</li>
                            </ul>
                        </div>
                        <Button variant='orange' className="flex items-center gap-2">
Choose Plan<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
<mask id="mask0_1324_4825" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
<rect width="36" height="36" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1324_4825)">
<path d="M9.6 27L7.5 24.9L21.9 10.5H9V7.5H27V25.5H24V12.6L9.6 27Z" fill="#F39710"/>
</g>
</svg>
                        </Button>

                    </div>
                    <div className="grid-2 space-y-4 p-10">
                        <h1>Full Production</h1>
                        <p>1 Episode: R2000 (20% Discount for 4 Episodes)</p>
                        <ul className=" list-disc">
                            <li>1.5 hours of studio time</li>
                            <li>Includes an audio engineer</li>
                            <li> Up to 5 people in the studio</li>
                            <li>Guests can be in the studio or remote</li>
                            <li>Maximum 45-minute podcast duration</li>
                            <li> Extras will be charged accordingly</li>
                        </ul>
                        <Button variant='orange' className="flex items-center gap-2">
Choose Plan<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
<mask id="mask0_1324_4825" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
<rect width="36" height="36" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1324_4825)">
<path d="M9.6 27L7.5 24.9L21.9 10.5H9V7.5H27V25.5H24V12.6L9.6 27Z" fill="#F39710"/>
</g>
</svg>
                        </Button>

                    </div>

                </div>
            </section>
            <section className="px-5 lg:px-28 py-10 ">
                <h1 className="text-center">Embrace the Ehee Experience - Where Your Story Comes to Life!</h1>
            </section>
        </Container>

    );
}

export default StudioPage;