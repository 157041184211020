import AbcHero from "../components/abc/abc-hero";
import { aiceServices } from "../data/our-startups";
import { Footer } from "../components/shared";
import MeetLeaders from "../components/capacity-building/meet-leaders";
import InteractiveAfrica from "../components/borderless-africa/interactive-africa";
import { useState } from "react";
import { BackIcon, Button } from "../components/shared";
import { aiceList, regions } from "../data/borderless-africa";
import clsx from "clsx";
import "../css/borderless-africa.css";
import AiceServices from "../components/abc/aice-services";

const Aice = () => {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const heroContent = {
    title: "ARTIFICIAL INTELLIGENCE CENTER OF EXCELLENCE",
    substitle: "Empowering Africa's Potential with AI Innovation. Passionate about creating value and long term impact within the AI and ML space",
    links: [],
    bottomContent:
      "Welcome to the AI Centre of Excellence, a world-class institution at the forefront of spearheading Africa's AI technological revolution. Founded in 2020, our mission is to champion Artificial Intelligence across the continent and create value with sustainable impact in the AI space.",
  };
  const leaders = [
    {
      overview:
        "",
      skills: [
    
      ],
      image: "/images/abc/natasha.jpg",
      desc: "",
      name: "Natasha Ochiel",
      role: "Co-Founder & Head of Business",
    },
    {
      overview:
        "",
      skills: [

      ],
      image: "/images/abc/fancy.jpg",
      desc: "",
      name: "Fancy Chepkoech",
      role: "Tech Lead",
    },
    {
      overview:
        "",
      skills: [
       
      ],
      image: "/images/abc/joel.jpg",
      desc: "",
      name: "Joel Osebe",
      role: "Lead Data Scientist",
    },
  ];
  return (
    <div className="body-container">
      <div
        className="container mx-auto tools-body overflow-hidden"
        style={{ background: "#454545}" }}
      >
        <AbcHero content={heroContent} />
        <AiceServices services={aiceServices}></AiceServices>

        <section className="flex flex-col bg-borderless-africa-ellipses-1 bg-center px-4 pb-8 pt-6 md:flex-row md:bg-borderless-africa-ellipses-1-md md:px-24 md:pt-12 md:pb-24">
          <div>
            <div className="relative flex items-center">
              <button
                className={clsx(
                  "absolute -left-10 cursor-pointer",
                  !selectedRegion && "hidden"
                )}
                onClick={() => setSelectedRegion(null)}
              >
                <BackIcon />
              </button>
              <h1 className="text-center text-32 text-[#F5931D] md:text-left ">
                {selectedRegion
                  ? regions[selectedRegion][0]
                  : "Our Traction"}
              </h1>
            </div>
            <div className="my-3 h-0.5 w-full bg-white md:my-7" />

            <ul className="list-disc space-y-3 py-3 pl-5 md:space-y-4">
              {selectedRegion ? (
                <>
                  {aiceList.slice(2).map((item, ix) => (
                    <li className="text-white text-[18px]" key={ix}>
                      {item}
                    </li>
                  ))}
                  {/* <Button
                    variant="orange"
                    to="/submission-form"
                    className="text-white"
                  >
                    CONTACT REGION
                  </Button> */}
                </>
              ) : (
                aiceList.map((item, ix) => (
                  <li className="text-white text-[18px]" key={ix}>
                    {item}
                  </li>
                ))
              )}
            </ul>
          </div>

          <InteractiveAfrica
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            className="h-auto w-auto shrink-0"
          />
        </section>

        <MeetLeaders
          desc={
            "Contact us today to learn how our AI products can help digitally transform your business."
          }
          subtitle={
            "Are you an investor looking to invest in Artificial Intelligence Center of Excellence?"
          }
          btnText="INVEST"
          link="submission-form"
          leaders={leaders}
        />
        <Footer></Footer>
      </div>
    </div>
  );
};

export default Aice;
