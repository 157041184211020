import clsx from "clsx";
import { Button, Line, Ellipse, Container } from "../components/shared";
import Hero from "../components/venture-building/hero";
import "../css/venture-building.css"
import "../css/capacity-building.css"
import { useState } from "react";
import SharedHero from "../components/utils/hero";
import background from "../components/utils/venture-building.jpg"
import MeetLeaders from "../components/capacity-building/meet-leaders";
import BusinessAcceleration from "../components/venture-building/business-acceleration";
const OurDeRiskedProcessItem = ({
  title,
  content,
  containerStyles,
  contentStyles,
  lineStyles,
}) => (
  <div
    className={clsx("absolute inset-0 z-10 hiddden lg:block", containerStyles)}
  >
    <h1 className="text-32 text-[#13E2FF]">{title}</h1>
    <Line variant="blue" className={clsx(lineStyles, "my-4")} isBlur />
    <p className={clsx("text-20", contentStyles)}>{content}</p>
  </div>
);

const OurDeRiskedProcess = () => (
  <section className="relative flex flex-col items-center justify-center pt-[51px] lg:pt-[171px]">
    <Ellipse top="43%" left="21%" size={291} blur={150} variant="blue" className="ellipse" />
    <Ellipse top="45%" left="65%" size={291} blur={150} variant="blue"  className="ellipse"/>
    <Ellipse top="88%" left="11.5%" size={291} blur={150} variant="blue" className="ellipse" />
    <Ellipse top="88%" left="65%" size={291} blur={150} variant="blue" className="ellipse" />

    <h1
      className="mb-7 bg-clip-text text-40 text-transparent hidden lg:block"
      style={{
        textShadow: "-4px 4px 7px rgba(0, 0, 0, 0.25)",
        backgroundImage:
          "linear-gradient(180deg, #13A8BD -22.88%, #13E2FF 52%)",
      }}
    >
      Our De-Risked Process
    </h1>
    <h1 className="vr-title block lg:hidden"> Our De-Risked Process</h1>

    <Line variant="blue" className="lg:mb-28 w-[345px]" isBlur />
    <div className="block lg:hidden relative h-[482px] w-full">
      <h3 className="vr-header absolute left-[11px] top-[42px]">
        1. Selection
      </h3>
      <p className="vr-subtext absolute top-[66px] left-[22px] w-[158px]">
        A thorough Ai selection tool, due diligence, independent IC selection
        and onboarding for start-ups
      </p>
      <h3 className="vr-header absolute left-[202px] top-[42px]">
      2. Tech Support
      </h3>
      <p className="vr-subtext absolute top-[66px] left-[202px] w-[158px]">
        Architecture design, technology development, validation of technology
        solutions for all our startups to ensure value is delivered through
        world class platforms. Offer existing tech tools to minimize MVP churn
        out time frame.
      </p>
      <h3 className="vr-header absolute left-[202px] top-[332px]">
        3. Business Strategy
      </h3>
      <p className="vr-subtext absolute top-[355px] left-[202px] w-[158px]">
        Offer Pan-African teams to our startups and beyond that enable scaling
        and growth opportunities through access to markets, access to funds and
        scale partnerships.
      </p>
      <img
      src="/pages/venture-building/derisk-process-mobile.svg"
      alt=""
      className="absolute -left-[15px] top-[232px] "
    />
     <h3 className="vr-header absolute left-[11px] top-[476px] w-[124px]">
     4. Borderless Africa Market
      </h3>
      <p className="vr-subtext absolute top-[540px] left-[22px] w-[345px]">
        Offer Pan-African teams to our startups and beyond that enable scaling
        and growth opportunities through access to markets, access to funds and
        scale partnerships.
      </p>
    </div>

    <OurDeRiskedProcessItem
      title="1. SELECTION"
      content="A thorough Ai selection tool, due diligence, independent IC selection and onboarding for start-ups"
      containerStyles="top-[43%] left-[21%] hidden lg:block"
      contentStyles="w-[350px] translate-x-[-25%] "
      lineStyles="w-[202px]"
    />

    <OurDeRiskedProcessItem
      title="2. TECH SUPPORT"
      content="Architecture design, technology development, validation of technology solutions for all our startups to ensure value is delivered through world class platforms. Offer existing tech tools to minimize MVP churn out time frame."
      containerStyles="top-[45%] left-[65%] hidden lg:block"
      contentStyles="w-[360px]"
      lineStyles="w-[266px]"
    />

    <OurDeRiskedProcessItem
      title="3. BUSINESS STRATEGY"
      content="Implement design thinking for our startups, design go-to-market strategies and offer business development, financial modelling & investor readiness through a team of experts, templates support and workshops."
      containerStyles="top-[88%] left-[11.5%] hidden lg:block"
      contentStyles="w-[350px]"
      lineStyles="w-[348px]"
    />

    <OurDeRiskedProcessItem
      title="4. BORDERLESS AFRICA MARKET"
      content="Offer Pan-African teams to our startups and beyond that enable scaling and growth opportunities through access to markets, access to funds and scale partnerships."
      containerStyles="top-[88%] left-[65%] hidden lg:block"
      contentStyles="w-[360px]"
      lineStyles="w-[486px]"
    />
    <img
      src="/pages/venture-building/our-de-risked-process.png"
      alt=""
      className="hidden lg:block"
    />
  </section>
);

const TheInvestmentItem = ({
  title,
  content,
  containerStyles,
  contentStyles,
  lineStyles,
}) => (
  <div className={clsx("absolute inset-0 z-10", containerStyles)}>
    <h1 className="text-32">{title}</h1>
    <div className={clsx(lineStyles, "my-[18px] h-0.5 bg-white")} />
    <p className={clsx("text-24", contentStyles)}>{content}</p>
  </div>
);

const TheInvestment = () => (
  <section className="relative flex flex-col items-center pt-[205px]">
    <Ellipse top="41%" left="12%" size={291} blur={100} variant="orange" />
    <Ellipse top="58%" left="73%" size={291} blur={100} variant="orange" />
    <Ellipse top="77%" left="12%" size={291} blur={100} variant="orange" />

    <h1
      className="z-10 mb-5 bg-clip-text text-36 text-transparent"
      style={{
        textShadow: "-4px 4px 4px rgba(0, 0, 0, 0.25)",
        backgroundImage: "linear-gradient(180deg, #F39711 0%, #F54C11 100%)",
      }}
    >
      The Investment
    </h1>

    <Line variant="orange" className="lg:mb-20 w-[336px]" isBlur />
    <div className="block lg:hidden relative w-full h-[500px]">
      <h3 className="vs-title absolute top-[24.92px] left-[24px]">1. Screening</h3>
      <p  className="vr-subtext absolute top-[50px] left-[24px] w-[254px]">AI driven screening process with proven scoring system for identifying application that meets criteria.</p>
      <h3 className="vs-title absolute top-[145px] left-[24px]">2. Selection</h3>
      <p  className="vr-subtext absolute top-[171px] left-[24px] w-[188px]">
      Investment committee consisting of sector experts to shortlist outstanding ideas followed by final in-person interviews with founders.
      </p>
      <h3 className="vs-title absolute top-[323px] left-[24px]">3. Investment</h3>
      <p  className="vr-subtext absolute top-[349px] left-[24px] w-[254px]">
      Due diligence, legal setup and onboarding using our startup management framework.
      </p>
      <img
      src="/pages/venture-building/investment-mobile.svg"
      alt=""
      className="z-10 absolute left-[108px] top-[14px]"
    />

    </div>

    <TheInvestmentItem
      title="1. SCREENING"
      content="AI driven screening process with proven scoring system for identifying application that meets criteria"
      containerStyles="top-[41%] left-[12%] hidden lg:block"
      contentStyles="w-[424px]"
      lineStyles="w-[215px]"
    />

    <TheInvestmentItem
      title="2. SELECTION"
      content="Investment committee consisting of sector experts to shortlist outstanding ideas followed by final in-person interviews with founders"
      containerStyles="top-[58%] left-[73%] hidden lg:block"
      contentStyles="w-[328px]"
      lineStyles="w-[208px]"
    />

    <TheInvestmentItem
      title="3. INVESTMENT"
      content="Due diligence, legal setup and onboarding using our startup management framework"
      containerStyles="top-[77%] left-[12%] hidden lg:block"
      contentStyles="w-[424px]"
      lineStyles="w-[236px]"
    />

    <img
      src="/pages/venture-building/the-investment.png"
      alt=""
      className="z-10 hidden lg:block"
    />
  </section>
);

const MeetTheCompanies = () => (
  <>
   <section
    className="pr-[102px] lg:pl-[200px] lg:pt-[141px] mt-bg hidden lg:block"
  >
    <div className="flex items-center justify-between lg:space-x-[238px]">
      <div className="flex flex-col items-center">
        <h1
          className="mb-[75px] max-w-[380px] text-center lg:text-32 mt-header"
          style={{ textShadow: "-4px 4px 4px rgba(0, 0, 0, 0.25)" }}
        >
          MEET THE COMPANIES WE HAVE GROWN
        </h1>
        <Button variant="orange" href="/our-startups">
          STARTUPS & SHOWCASE
        </Button>
      </div>
      {/* <img src="/pages/venture-building/meet-the-companies.png" alt="" /> */}
    </div>

    <div className="flex items-center justify-between space-x-[158px] pt-[224px]">
      <img src="/pages/venture-building/begin-your-journey.png" alt="" />
      <div>
        <h1
          className="mb-10 text-32"
          style={{ textShadow: "-4px 4px 4px rgba(0, 0, 0, 0.25)" }}
        >
          BEGIN YOUR JOURNEY
        </h1>
        <p className="mb-10 text-24">
          Connect with us for a bespoke growth pack for your start-up.
        </p>
        <a  href="/contact-us" className="contact-us-btn contact-us-btn-text">
          CONTACT US
        </a>
      </div>
    </div>
  </section>
  <section className="block lg:hidden">
      <div className="container mx-auto relative ml-cover overflow-hidden">
        <div className="flex  flex-col items-start justify-start  absolute top-[35px] left-[25px] lg:left-[237px] lg:top-[174px]">
          <h2 className="mt-header mb-4 lg:mb-10 w-[124px]">MEET THE COMPANIES WE HAVE GROWN</h2>
          <a className="ml-btn ml-btn-text" href="/our-startups">STARTUPS & SHOWCASE</a>
        </div>
        <div className="user-1 flex justify-center items-center">
          <img src="/pages/content-portal/founders/irene-kiwia.png" className="user-img-1"></img>
        </div>
        <div className="user-2 flex justify-center items-center">
          <img src="/pages/content-portal/founders/bendon-murgor.png " className="user-img-2"></img>
        </div>
        <div className="user-3 flex justify-center items-center">
          <img src="/pages/content-portal/founders/john-kamara.png" className="user-img-3"></img>
        </div>
        {/* <div className="user-4 flex justify-center items-center">
          <img src="images/user4.png" className="user-img-4"></img>
        </div> */}
        <img
          src="pages/venture-building/Icons.svg"
          className="absolute  -left-[10px] top-[248px]"
        />
    
        <div className="ml-left-content absolute top-[247px] left-[202px] lg:top-[775px] lg:left-[795px]">
          <h2 className="ml-header mb-4">Begin your journey</h2>
          <p className="ml-subheader mb-8 w-[462px]">
          Connect with us for a bespoke growth pack for your start-up.
          </p>
          <a className="vb-btn vb-btn-text" href="contact-us">CONTACT US</a>
        </div>
      </div>
    </section>
  </>
 
);

const VentureBuilding = () => {
  const [step,setStep]=useState(1)
  const handleClick=(id)=>{
    setStep(id)

  }
  const leaders = [
    {
      overview:
        "Growth Strategy Consultant, former Google Europe strategist, former CEO of Cortex Africa and Non –Executive Director of Machine Intelligence Institute of Africa.",
      skills: [
    
      ],
      image: "/pages/content-portal/founders/john-kamara.png",
      desc: "20+ years experience launching tech driven solutions in new markets in Europe, South America, the Caribbean and Africa. Tech & Gaming Entrepreneur.",
      name: "John Kamara",
      role: "Co-Founder & CEO",
    },
    {
      overview:
        "Senior software engineer with 16+ years experience architecturing and building tech solutions for Pan African clients. ",
      skills: [

      ],
      image: "/pages/content-portal/founders/bendon-murgor.png",
      desc: "Certifications in CCNA, ITILv3 and GCIH, with extensive experience in Gaming technologies, USSD Development, Mobile Money integrations, Blockchain programming, Mobile Applications and Systems Security. Tech Entrepreneur",
      name: "Bendon Murgor",
      role: "Co-Founder & CTO",
    },
    {
      overview:
        "Marketing Consultant with 18+ years in Media, Communication, PR and Marketing supporting pan African companies with their go to market strategies.",
      skills: [
       
      ],
      image: "/pages/content-portal/founders/irene-kiwia.png",
      desc: "Youth & women empowerment specialist with 10+ years experience running sustainability programs to empower the two segments. Media Entrepreneur.",
      name: "Irine Kiwia",
      role: "Co-Founder & CGO",
    },
  ];
  return (
    <Container className="bg-[#454545]">
      <SharedHero title={'VENTURE BUILDING'} subtitle={'Creating a vibrant and dynamic startup ecosystem with our strategic guidance, mentorship, and resources. We help Startups overcome the challenges of launching and growing a successful venture with our comprehensive solutions.'} background={background}/>
      <Hero handleClick={handleClick} step={step}/>
      <BusinessAcceleration/>
      <OurDeRiskedProcess />
      <TheInvestment />
      <MeetLeaders subtitle={''} btnText="Learn more" desc={' Connect with us for a bespoke growth pack for your start-up.'} link="leadershipTeam" leaders={leaders}/>
    </Container>
  );
};

export default VentureBuilding;
