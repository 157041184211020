import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import TechTools from "./pages/tech-tools";
import CapacityBuilding from "./pages/capacity-building";
import BorderlessAfrica from "./pages/borderless-africa";
import VentureBuilding from "./pages/venture-building";
import IncubationProcess from "./pages/incubation-process";
import SubmissionForm from "./pages/submission-form";
import ContactUs from "./pages/contact-us";
import ContentPortal from "./pages/content-portal";
import OurStartups from './pages/our-startups';
import { BlogPostTwo } from "./pages/blog/index";
import Abc from "./pages/abc";
import Aice from "./pages/aice";
import AboutUS from "./pages/about";
import LeaderShipPage from "./pages/leadership";
import IndustryPartners from "./pages/industry-partners";
import Iotce from "./pages/iotce";
import PrivacyPolicy from "./pages/privacy-policy";
import AffiliatePartner from "./pages/affiliate-partner";
import PdiPage from "./pages/pdi";
import StudioPage from "./pages/studio";
import AdanianGazette from "./pages/adanian-report";
import BorderlessLab from "./pages/borderless-lab";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import OpenGraph from "./components/open-graph";
import Downloads from "./pages/downloads";
import Accelerator from "./pages/accelarator";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
  <BrowserRouter>
    <Routes>
    <Route
            path="/"
            element={
              <>
               <OpenGraph title={'Home'}/>
                <App />
              </>
            }
          />
      <Route path="aboutUs" element={<>
        <OpenGraph title={'About Us'}/>
                <AboutUS />
      </>
     } />
      <Route path="tech-tools" element={
        <>
        <OpenGraph title={'Technology Tools'}/>
                <TechTools />
      </>
      } />
      <Route path="capacity-building" element={<>
        <OpenGraph title={'Capacity Building'}/>
                <CapacityBuilding />
      </>} />
      <Route path="borderless-africa" element={<>
        <OpenGraph title={'Borderless Africa'}/>
                <BorderlessAfrica />
      </>} />
      <Route path="venture-building" element={<>
        <OpenGraph title={'Venture Building'}/>
                <VentureBuilding />
      </>} />
      <Route path="blogposts/:id" element={<>
        <OpenGraph title={'Blog'}/>
                <BlogPostTwo />
      </>} />
      <Route path="contact-us" element={<>
        <OpenGraph title={'Contact Us'}/>
                <ContactUs />
      </>} />
      <Route path="content-portal" element={<>
        <OpenGraph title={'Content Portal'}/>
                <ContentPortal />
      </>} />
      <Route path="incubation-process" element={<>
        <OpenGraph title={'Incubation Process'}/>
                <IncubationProcess />
      </>} />
      <Route path="submission-form" element={<>
        <OpenGraph title={'Submission Form'}/>
                <SubmissionForm />
      </>} />
      <Route path="our-startups" element={<>
        <OpenGraph title={'Our Startups'}/>
                <OurStartups />
      </>} />
      <Route path="startups/:id" element={<>
        <OpenGraph title={'Startups'}/>
                <OurStartups />
      </>} />
      <Route path="africa-blockchain-center" element={<>
        <OpenGraph title={'ABC'}/>
                <Abc />
      </>} />
      <Route path="aice" element={<>
        <OpenGraph title={'AICE'}/>
                <Aice />
      </>} />
      <Route path="iotce" element={<>
        <OpenGraph title={'IOTCE'}/>
                <Iotce />
      </>} />
      <Route path="leadershipTeam" element={<>
        <OpenGraph title={'Leadership'}/>
                <LeaderShipPage />
      </>} />
      <Route path="accelarator-programme" element={<>
        <OpenGraph title={'Accelarator programme'}/>
                <Accelerator />
      </>} />
      <Route path="industryPartners" element={<>
        <Helmet>
                  <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossOrigin
                  />
                  <link
                    href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;1,700&family=Roboto&display=swap" 
                    rel="stylesheet"
                    as="style"
                    onLoad="this.onload=null;this.rel='stylesheet'"
                  />
                </Helmet>
                <IndustryPartners />
      </>} />
      <Route path="privacyPolicy" element={<>
        <OpenGraph title={'Privacy Policy'}/>
                <PrivacyPolicy />
      </>} />
      <Route path="affiliatePartners" element={<>
        <OpenGraph title={'Affiliate partners'}/>
                <AffiliatePartner />
      </>} />
      <Route path="pdi-model" element={<>
        <OpenGraph title={'PDI Model'}/>
                <PdiPage />
      </>} />
      <Route path="studio" element={<>
        <OpenGraph title={'Studio'}/>
                <StudioPage />
      </>} />
      <Route path="adanian-gazette" element={<>
        <OpenGraph title={'Gazette'}/>
                <AdanianGazette />
      </>} />
      <Route path="borderless-lab" element={<>
        <OpenGraph title={'Borderless Lab'}/>
                <BorderlessLab />
      </>} />
      <Route path="Downloads" element={<>
        <OpenGraph title={'Downloads'}/>
                <Downloads />
      </>} />
      <Route path="*" element={
      <main className="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="font-semibold text-indigo-600 text-8xl">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a href="/" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Go back home</a>
            <a href="/contact-us" className="text-sm font-semibold text-gray-900">Contact support <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
      </main>      
} />
    </Routes>
  </BrowserRouter>
  </HelmetProvider>

);
