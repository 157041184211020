export const faqs = [
  {
    question: "Who can apply for Venture Building?",
    answers: [
      "Technology based startups with a prototype that can be developed.",
      "The product should be commercially viable and scalable.",
      "Startups that have high potential for impact.",
      "Passionate entrepreneurs who are ready to learn.",
    ],
  },
  {
    question: "Any preference for a specific industry?",
    answers:
      ["We are open to all Tech based start ups that offer solutions in any sector eg: FinTech, EduTech, AgriTech, HealthTech, EnviroTech, InsurTech, EnergyTech , Mobility, Smart city and others."],
  },
  {
    question: "How long does the Venture Building program last?",
    answers: ["12 months- housed at Adanian Labs locations in respective countries"],
  },
  {
    question: "How much pre seed or seed funding can be availed?",
    answers: ["Up to $120,000 worth of pre seed/seed funding."],
  },
  {
    question: "What supporting documents do I need during application?",
    answers: ["Please prepare a pitch document and highlights from your financial analysis."],
  },
  {
    question: "What is the turnaround time on application feedback?",
    answers: ["Five working days."],
  },
  {
    question: "Do I have to relocate to the incubation hub if selected?",
    answers: ["Yes, you will have to relocate to where respective country hubs are for at least 1 year."],
  },
  {
    question: "What kind of technology does Adanian Labs have access to?",
    answers: ["Blockchain, AI, IoT."],
  },
  {
    question: "What if I only need technical support?",
    answers: ["We can design a bespoke growth pack for your start-up, we encourage you to apply."],
  },
  {
    question: "What is a Technology Venture Studio?",
    answers: ["A Technology Venture Studio is a service provider that specialises in helping technology-based businesses to develop and succeed. We offer Venture Building, Capacity Building, Technology Consulting and Development, and Borderless Africa marketplace offering services to our clients."],
  },
  {
    question:"What Capacity Building services do you provide?",
    answers:["Our Capacity Building services help our clients to build their capacity and gain the necessary skills and knowledge to succeed in the AI & Blockchain technology space. We provide training and mentorship programs, project management, and industry research and analysis to help our clients maximise their potential."]
  },
  {
    question:"How do you help companies develop their technology?",
    answers:["We provide a range of Technology Consulting and Development services to help companies develop their technology. We offer strategic planning, technical architecture design, implementation and integration services, testing and quality assurance, and project management services to ensure our clients can achieve their desired outcomes."]
  },
  {
    question:"What is your Borderless Africa Marketplace Offering?",
    answers:["The Borderless Africa Marketplace Offering is a service we provide to help businesses access and benefit from the African market where we present in. Our services include market analysis and research, identification of potential partners and customers, access to venture capital and other funding sources, and assistance with setting up and managing legal and financial operations."]
  },
  {
    question:"What type of companies do you work with?",
    answers:[" We work with a range of technology-based companies, from start-ups to established organisations. We help our clients to develop innovative products and services, access new markets and partners, and gain a competitive edge in their industry."]
  },
  {
    question:"What is your process for working with clients?",
    answers:[" We offer a comprehensive and tailored approach to working with our clients. We start by understanding their needs and goals, then develop a tailored strategy to help them achieve success. We then provide our expertise and services to help them reach their desired outcomes."]
  },
  {
    question:"What sets you apart from other service providers?",
    answers:["We believe in providing the highest-quality services and solutions to our clients. We are committed to providing our clients with the best technology solutions to help them succeed in their business. We also provide a personal and tailored approach to our clients, ensuring that their needs are met and their success is our priority."]
  },
  {
    question:"What makes your services valuable?",
    answers:["We provide our clients with the technological knowledge and skills to succeed in their industry. We have a team of experienced professionals who are available and looking forward to helping your business achieve its goals."]
  }
];

export const offices = {
  "NAIROBI, KENYA": {
    country: "NAIROBI, KENYA",
    address: "Mkungu Close",
    street: "Westlands",
    href: "https://goo.gl/maps/Q2EgtXqQdoEJyk6N8",
  },
  "DAR ES SALAAM, TANZANIA": {
    country: "DAR ES SALAAM, TANZANIA",
    address: "InfoTech Place, Mwai Kibaki Road",
    street: "Kawe",
    href: "https://goo.gl/maps/uWGiiwRzmhUNwrV57",
  },
  "LAGOS, NIGERIA": {
    country: "LAGOS, NIGERIA",
    address: "3 Otunba Adedoyin Ogungbe  Crescent",
    street: "Lekki Phase 1",
    href: "https://goo.gl/maps/DKgRZs8xzBBAwgDNA",
  },
  "LUSAKA, ZAMBIA": {
    country: "LUSAKA, ZAMBIA",
    address: "Suite 223, Arcades",
    street: "Greate East Road",
    href: "https://goo.gl/maps/PpUNbD9tC3cwWFBM6",
  },
  "ENGLAND, UK": {
    country: "ENGLAND, UK",
    address: "1 The Quays, Salford",
    street: " M50 3UB",
    href: "https://goo.gl/maps/UreBah3BnzcWyFym8",
  },
  "JOHANNESBURG, SOUTH AFRICA": {
    country: "JOHANNESBURG, SOUTH AFRICA",
    address: "41, 2nd Road, Hyde Park",
    street: "Sandton, 2196",
    href: "https://goo.gl/maps/RKsceDn66cK3782N7",
  },
};
export const downloads=[
  {
    title:'The PDI BluePrint 2024',
    url:'https://drive.google.com/file/d/1HIOcsxWxub6UmvMzvSqqz0V4FippoLv0/view?usp=drive_link'
  },
  {
    title:'Adanian Labs Technology Solutions 2023',
    url:'https://drive.google.com/file/d/1ygcBase57j5dN1ejjneYxVmsFs1To8X0/view?usp=drive_link'
  },
  {
    title:'Adanian Labs Tech Capabilities',
    url:'https://drive.google.com/file/d/1vsZ1iSfc2uf6aBtijs17iYSTNM-S2EdK/view?usp=drive_link'
  },
  {
    title:'Adanian Labs 2023 Portfolio Companies',
    url:'https://drive.google.com/file/d/1Zl9vwQ2QGnDW-WMuDYrDUfm-FrbcQdKl/view?usp=drive_link'
  },
  {
    title:'Adanian Labs - Co Profile',
    url:'https://drive.google.com/file/d/1ceqkpPtM4sJZDeZhEp93MInINfh-Rxj2/view?usp=drive_link'
  },
]
