import { Container, Line } from "../components/shared";
import background from "../components/utils/borderless.jpg";
import SharedHero from "../components/utils/hero";

const BorderlessLab = () => {
  return (
    <Container className="bg-[#404040] pdi">
      <SharedHero
        title={"BORDERLESS LAB"}
        subtitle={"Catalyzing Innovation for a Connected World."}
        background={background}
      />
      <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col relative">
      <div className="studio-orange absolute right-[50%] top-0">

</div>
        <h1 className="text-center">
          Welcome to Borderless Lab: Supporting the Windrush Project
        </h1>
        <Line
          variant="orange"
          className="mx-auto my-3 h-[1px] w-[240px]"
        ></Line>
        <p className="text-center z-10">
          At Borderless Lab, we are on a mission to transform the digital
          landscape, fostering a connected world where innovation knows no
          borders. Through strategic initiatives and impactful programs, we aim
          to support the Windrush Project and build the next generation of
          talent and entrepreneurs in Manchester. Our focus lies in catalyzing
          technology and innovation to create a lasting impact on payments,
          credit, and market access.
        </p>
      </section>
      <section className="px-5 lg:px-28 py-10 space-y-4 md:space-y-0
       relative">
                <div className="studio-blue absolute top-[30%] right-0">

</div>
        <div className="flex flex-col md:flex-row items-center justify-center gap-10 md:gap-0">
          <div className="md:basis-1/2">
            <img src="/images/borderless-lab/1.png" />
          </div>
          <div className="space-y-2 md:pl-10 md:basis-1/2">
            <h1 className="text-start">Digital Transformation Opportunity</h1>
            <p>
              The digital economy is currently underperforming due to various
              challenges such as a lack of connectivity, trust issues, and
              fragmentation across the continent. To address these issues, we
              believe in leveraging the SME and tech-enabled ecosystem to drive
              trade and economic growth. By providing a proactive approach to
              scaling businesses with payment at the core, we envision
              overcoming obstacles and fostering a thriving digital economy.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row items-center justify-center gap-10 md:gap-0">
          <div className="space-y-2 md:basis-1/2">
            <h1 className="text-start">
              Challenges and Solutions for Borderless Project
            </h1>
            <p>
              The Borderless Project faces significant challenges, including
              limited access to resources, funding for research and development,
              and foundational elements essential for its acceleration. To
              fast-track progress, we propose a proactive program that addresses
              these challenges head-on, promoting real-time development and
              trade among African countries.
            </p>
          </div>
          <div className="md:basis-1/2">
            <img src="/images/borderless-lab/2.png" />
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="md:basis-1/2">
            <img src="/images/borderless-lab/3.png" />
          </div>

          <div className="space-y-2 md:basis-1/2 md:pl-10">
            <h1 className="text-start">SME’s AI Opportunity</h1>
            <p>
              We recognize the acute challenges faced by SMEs in financing,
              especially when dealing with the informal sector. Our programs
              focus on capacity development to mitigate risks, sharpen
              entrepreneurship skills, and provide comprehensive support,
              including business tools, risk management, and operational
              performance enhancement.
            </p>
          </div>
        </div>
      </section>
      <section className="px-5 lg:px-28 py-10 sectors">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div>
            <h1>Funding Requirements</h1>
            <p>
              To execute our vision, we require £3 million over two years. We
              propose diverse funding models, including:
            </p>
            <ul className=" list-disc list-inside">
              <li>private sector companies</li>
              <li>fundraising events</li>
              <li> government support</li>
              <li>partnerships with universities.</li>
            </ul>
          </div>
          <div>
            <h1>Sectors for Accelerator</h1>
            <p>
              Our accelerator will focus on sectors crucial for growth,
              including:
            </p>
            <ul className=" list-disc list-inside">
              <li>hospitality</li>
              <li>FMCG</li>
              <li> fintech</li>
              <li> education</li>
              <li>SME digitization</li>
              <li> climate technology</li>
              <li> AI</li>
              <li> creative economy</li>
              <li>gaming</li>
              <li>financial and digital inclusion</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col">
        <h1 className="text-center">Adanian Labs Verticals</h1>
        <Line
          variant="orange"
          className="mx-auto my-3 h-[1px] w-[240px]"
        ></Line>
        <div className="vertical w-full h-fit flex flex-wrap lg:flex-nowrap items-center px-10 py-2 gap-4 md:gap-10 mb-10">
          <img src="/images/borderless-lab/4.png" alt="anaina labs" />
          <h3 className="font-semibold text-white">Tech Tool<br></br> Development</h3>
          <p>We align our AI and blockchain tech team to multi-faceted companies in order to build fluid, agnostic and scalable tech solutions in a wide variety of industries.</p>
          <img src="/images/borderless-lab/5.png" alt="" />
        </div>
        <div className="vertical w-full h-fit flex flex-wrap lg:flex-nowrap items-center px-10 py-2 gap-4 md:gap-10 mb-10">
          <img src="/images/borderless-lab/4.png" alt="anaina labs" />
          <h3 className="font-semibold text-white">Capacity
Building</h3>
          <p>Through a special focus in digital skills, AI, Blockchain and the creatives industry, we aim to fill the knowledge gap in Africa and facilitate technology knowledge transfer</p>
          <img src="/images/borderless-lab/5.png" alt="" />
        </div>
        <div className="vertical w-full h-fit flex  flex-wrap lg:flex-nowrap items-center px-10 py-2 gap-4 md:gap-10 mb-10">
          <img src="/images/borderless-lab/4.png" alt="anaina labs" />
          <h3 className="font-semibold text-white">A Borderless
Africa</h3>
          <p>Our startups can leverage our pan-Africa network of studios and corporate partnerships enabling swift
and ease of market entries. </p>
          <img src="/images/borderless-lab/5.png" alt="" />
        </div>
        <div className="vertical w-full h-fit flex flex-wrap lg:flex-nowrap items-center px-10 py-2 gap-4 md:gap-10 mb-10">
          <img src="/images/borderless-lab/4.png" alt="anaina labs" />
          <h3 className="font-semibold text-white">Venture
Building</h3>
          <p>Our startups can leverage our pan-Africa network of studios and corporate partnerships enabling swift
and ease of market entries.</p>
          <img src="/images/borderless-lab/5.png" alt="" />
        </div>
      </section>
      <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col">
        <h1 className="text-center">Accelerator Program Overview</h1>
        <Line
          variant="orange"
          className="mx-auto my-3 h-[1px] w-[240px]"
        ></Line>
        <div className="grid grid-cols-1 md:grid-cols-2  gap-5 md:gap-16 my-10">
          <div className="accel p-10 space-y-2">
            <h3 className="font-bold text-center">Month 1</h3>
            <p className="text-center">Induction, recruitment, resource allocation, tech architecture, and KPI setting.</p>

          </div>
          <div className="accel p-10 space-y-2">
            <h3 className="font-bold text-center">Months 2 - 3</h3>
            <p className="text-center">Product and business development, legal support, human resource management, investor readiness, and mentorship.</p>

          </div>

        </div>

        </section>
        <section className="px-5 lg:px-28 py-10 flex items-center justify-center flex-col">
        <h1 className="text-center">Success Stories</h1>
        <Line
          variant="orange"
          className="mx-auto my-3 h-[1px] w-[240px]"
        ></Line>
          <div className="grid grid-cols-1 md:grid-cols-2 my-10">
          <img src="/images/borderless-lab/6.png"></img>
          <div className="space-y-5 ">
            <h1>YVGB SME & MSME Program</h1>
            <p>Our #YouVeGotBusiness program aims to impact 20,000 MSMEs directly, with one banking partner onboarded and 10,000 views on YouTube. The program focuses on supporting women-led/owned SMEs, digitizing businesses, and reducing the business failure rate.</p>
            <button className="flex items-center gap-2 justify-center success-button py-2 px-10">Youtube<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
  <mask id="mask0_1432_4310" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
    <rect width="36" height="36" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1432_4310)">
    <path d="M9.6 27L7.5 24.9L21.9 10.5H9V7.5H27V25.5H24V12.6L9.6 27Z" fill="#F39710"/>
  </g>
</svg></button>
          </div>
          
        </div>
        </section>
        <section className="px-5 lg:px-28 py-12 flex items-center justify-center flex-col">
          <div className="join-us p-4 md:p-10 space-y-5">
            <h1 className="text-center">Join Us</h1>
            <p className="text-center text-xl">Join us at Borderless Lab as we drive innovation, empower businesses, and create a borderless future for the next generation of talent and entrepreneurs. Together, let's shape a connected world!</p>
            <div className="flex justify-center w-full">
            <button className="flex items-center gap-2 justify-center success-button py-2 px-10">Join Us<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
  <mask id="mask0_1432_4310" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
    <rect width="36" height="36" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1432_4310)">
    <path d="M9.6 27L7.5 24.9L21.9 10.5H9V7.5H27V25.5H24V12.6L9.6 27Z" fill="#F39710"/>
  </g>
</svg></button>
            </div>

          </div>
        </section>
      
    </Container>
  );
};

export default BorderlessLab;
