export const startupData = [
  {
    name: "AFYA REKOD",
    desc: "Here goes a short and brief description of this startup.",
    logo: "images/our-startups/afya-rekod.png",
    likes: 12,
  },
  {
    name: "TWAA",
    desc: "Here goes a short and brief description of this startup.",
    logo: "images/our-startups/twaa-logo.png",
    likes: 1,
  },
  {
    name: "PAYLEND",
    desc: "Here goes a short and brief description of this startup.",
    logo: "images/our-startups/paylend-logo.png",
    likes: 1,
  },
  {
    name: "SAVABYTE",
    desc: "Here goes a short and brief description of this startup.",
    logo: "images/our-startups/savabyte.png",
    likes: 1,
  },
  {
    name: "JOBZY",
    desc: "Here goes a short and brief description of this startup.",
    logo: "images/our-startups/jobzy.png",
    likes: 1,
  },
  {
    name: "SAVABYTE",
    desc: "Here goes a short and brief description of this startup.",
    logo: "images/our-startups/savabyte.png",
    likes: 1,
  },
];

export const abcServices = [
  {
    title: "capacity building & baas",
    desc: "We actively train blockchain engineers to enhance blockchain development expertise.We offer comprehensive training programs and workshops to equip individuals with the skills and knowledge needed to excel in the blockchain and DLT space. Our goal is to empower a new generation of blockchain professionals and entrepreneurs in Africa.",
  },
  {
    title: "Research & Development",
    desc: "We Conduct Blockchain R&D for key industries with partners and inhouse ideation, incubate blockchain start-ups and launch an ABC Chain",
  },
  {
    title: "Blockchain Consultancy",
    desc: "ABC offers comprehensive support to organisations, including advisory services, architecture design, programming and development, and deployment of blockchain and DLT solutions. We help businesses navigate the complexities of this technology, develop tailored strategies, and implement successful projects.",
  },
  {
    title: "Product Development",
    desc: "We create efficient, Web3.0-based enterprise-grade solutions for diverse markets, delivering a strong value proposition to all stakeholders in the ecosystem.",
  },
  {
    title: "Incubation",
    desc: "ABC provides support to innovative African projects, enabling their entry into the Web3 space. We nurture, grow, and scale these projects by leveraging our advanced development skill set, deep knowledge of the Web3 ecosystem, and our extensive partner network. ",
  },
  {title:"Community Building",
desc:"We foster a vibrant and inclusive community of blockchain enthusiasts, experts, and innovators across Africa. Through events, meetups, and networking opportunities, we encourage collaboration, knowledge sharing, and the exchange of ideas."}
];

export const aiceServices = [
  {
    title: "Data Cleaning",
    desc: "Streamline and enhance data quality through automated processes, ensuring accurate and reliable data for further analysis and decision-making.",
  },
  {
    title: "Data Analysis",
    desc: "Uncover valuable insights from complex datasets using advanced statistical techniques and analytical tools, enabling data-driven decision-making and strategic planning.",
  },
  {
    title: "Neural Networks",
    desc: " Leverage artificial neural networks to simulate human brain functions, enabling powerful deep learning algorithms for tasks like image recognition, natural language processing, and more.",
  },
  {
    title: "Computer Vision",
    desc: " Harness the power of AI to interpret and understand visual content, enabling applications such as object detection, image classification, and video analysis.",
  },
  {
    title: "Insights & Reporting",
    desc: " Transform raw data into actionable insights and generate visually appealing reports, empowering organisations with valuable information for informed decision-making.",
  },
  {
    title:"Machine Learning Models",
    desc:"Develop customised machine learning models that can learn from data and make predictions or automate complex tasks, driving efficiency and innovation.    "
  },
  {
    title:"Natural Language Processing",
    desc:"Analyse and understand human language, enabling applications like sentiment analysis, text classification, chatbots, and language translation for improved customer experiences and communication."
  },
{
  title:"Knowledge Centre",
  desc:"The AI Centre of Excellence develops and runs programs that impact knowledge across the AI ecosystem. The programs are designed for different niches, true to what AICE aims to accomplish for AI Africa."
},
{
  title:"Research & Development",
  desc:" We partner with industry and institutions to run AI R&D on key sectors that will create exponential impact. Industries in focus include: finance, climate change and clean energy, blockchain, health, smart cities and smart agriculture."
},
{
  title:"AI as a Service (AIaaS)",
  desc:"AICE offers a 360-degree approach for data and AI as a Service (AIaaS) that includes consultation, development, incubation, and deployment of AI solutions for organisations."
},
{
  title:"Community Building",
  desc:"Ignite innovation in AI. Collaborate with tech professionals across disciplines. Discover partnerships, mentorship, and collaborative projects. We have created a platform, where like-minded individuals and organisations converge to shape the future of AI through dynamic community building."
}
];
