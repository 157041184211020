import BockchainSection from "../components/capacity-building/bockchain-section";
import BuildingAudience from "../components/capacity-building/building-audience";
import CreatingKnowledge from "../components/capacity-building/creating-knowledge";
import CapacityHero from "../components/capacity-building/hero-section";
import MeetLeaders from "../components/capacity-building/meet-leaders";
import TrainingDevelopment from "../components/capacity-building/Training-development";
import {Container, Footer } from "../components/shared";
import "../css/tech-tools.css";
import { trainingDevelopmentData } from "../data";

const CapacityBuilding = () => {
  const leaders = [
    {
      overview:
        "Growth Strategy Consultant, former Google Europe strategist, former CEO of Cortex Africa and Non –Executive Director of Machine Intelligence Institute of Africa.",
      skills: [
    
      ],
      image: "/pages/content-portal/founders/john-kamara.png",
      desc: "20+ years experience launching tech driven solutions in new markets in Europe, South America, the Caribbean and Africa. Tech & Gaming Entrepreneur.",
      name: "John Kamara",
      role: "Co-Founder & CEO",
    },
    {
      overview:
        "Senior software engineer with 16+ years experience architecturing and building tech solutions for Pan African clients. ",
      skills: [

      ],
      image: "/pages/content-portal/founders/bendon-murgor.png",
      desc: "Certifications in CCNA, ITILv3 and GCIH, with extensive experience in Gaming technologies, USSD Development, Mobile Money integrations, Blockchain programming, Mobile Applications and Systems Security. Tech Entrepreneur",
      name: "Bendon Murgor",
      role: "Co-Founder & CTO",
    },
    {
      overview:
        "Marketing Consultant with 18+ years in Media, Communication, PR and Marketing supporting pan African companies with their go to market strategies.",
      skills: [
       
      ],
      image: "/pages/content-portal/founders/irene-kiwia.png",
      desc: "Youth & women empowerment specialist with 10+ years experience running sustainability programs to empower the two segments. Media Entrepreneur.",
      name: "Irine Kiwia",
      role: "Co-Founder & CGO",
    },
  ];
  return (
    
    <Container className="bg-[#454545]">
      <CapacityHero></CapacityHero>
        <BockchainSection></BockchainSection>
        <BuildingAudience></BuildingAudience>  
        <CreatingKnowledge></CreatingKnowledge>
        <TrainingDevelopment data={trainingDevelopmentData} title=" Our 5 year training development Goal"></TrainingDevelopment> 
        <MeetLeaders subtitle={''} btnText="Learn more" desc={' Empower your team to become thought leaders in technology in your organisation and the industry you are in.'} link="leadershipTeam" leaders={leaders}></MeetLeaders>
    </Container>
  );
};

export default CapacityBuilding;
