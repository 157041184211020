import { useState } from "react";
import { ReactComponent as Blue } from "./home/blue-map.svg";
import { ReactComponent as Orange } from "./home/orange-map.svg";
import { ReactComponent as Arrow } from "./home/arrow.svg";
import OurOffices from "./our-offices";
const BorderlessAfrica = () => {
  const [active, setActive] = useState(true);

  return (
    <section>
      <div className="container px-3  lg:px-28 pt-[77px] mx-auto bg-center bg-cover h-fit relative borderless-bg overflow-hidden">
        <div
          className="px-12 py-12 rounded-xl  borderless-card-1 hidden md:flex  relative items-center  mb-7"
          onMouseEnter={() => {
            setActive(false);
          }}
          onMouseLeave={() => {
            setActive(true);
          }}
        >
          <div className="flex flex-col items-center justify-start space-y-12">
            <h1 className="borderless-title  max-w-[438px]">
              our adanian story
            </h1>
            <hr className="hr-broderless"></hr>
            <p className="borderles-desc max-w-[642px]">
              Adanian Labs is a Pan African Web3.0 Technology Studio founded to
              provide Technology solutions for Africa’s key growing sectors that
              are driven by SME industries.
            </p>
          </div>

          <Blue
            className={`max-w-[456px] ${active ? "hidden" : "block"}`}
          ></Blue>
          <Orange
            className={`max-w-[456px] ${active ? "block" : "hidden"}`}
          ></Orange>
          <a href="aboutUs" aria-label="learn more about adanian labs">
            <Arrow className="absolute bottom-7 right-7"></Arrow>
          </a>
        </div>
        <div className="grid grid-cols-1 md:hidden gap-[22px] mb-7">
          <div className="rounded-[20px]  h-fit relative bg-[#242424] px-3 py-14">
            <div className="flex justify-between items-center mt-4">
              <div>
                <h1 className="borderless-title">our adanian story</h1>
                <div className="flex items-center text-center justify-center my-4">
                  <hr className="hr-broderless my-1"></hr>
                </div>
              </div>
              <div className="africa-img h-full"></div>
            </div>
            <p className="borderles-desc w-[322px]">
              Adanian Labs is a Pan African Web3.0 Technology Studio founded to
              provide Technology solutions for Africa’s key growing sectors that
              are driven by SME industries.
            </p>
            <div className="w-full  flex items-center justify-end absolute right-7 bottom-7">
              <a className="chevron-borderless" href="aboutUs" aria-label="learn more about adanian labs">
                <img
                  src="images/right-orange.png"
                  style={{
                    height: "34px",
                    width: "34px",
                    objectFit: "contain",
                  }}
                  loading="lazy" alt="adanian labs"
                ></img>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-[20px] mb-32 lg:mb-80">
          <div className=" borderless-card relative  px-4 md:px-14 pt-10 pb-20 mb-7">
            <div className="flex items-center flex-col justify-center space-y-8 ">
              <h1 className="borderless-title max-w-[438px] flex items-center justify-center">
                industry partners
              </h1>
              <hr className="hr-broderless"></hr>
              <p className="borderles-desc  max-w-[483px] flex items-center justify-center">
                At Adanian Labs, we believe that realising a technological
                revolution requires a comprehensive approach. As such, we work
                with a diverse range of partners, including learning
                institutions, tech companies, industry leaders across various
                sectors, development partners, and government institutions. Our
                collaborations with these local and international partners
                enable us to bring exponential value to the Adanian Ecosystem.
              </p>
            </div>

            <a
              className="chevron-borderless  absolute right-7 bottom-7 "
              href="industryPartners" aria-label="learn more about adanian labs"
            >
              <img
                src="images/right-orange.png"
                className=" w-[34px] md:w-[50px] h-[34px] lg:h-[50px] object-contain"
                loading="lazy" alt="adanian labs"
              ></img>
            </a>
          </div>
          <div className=" borderless-card relative px-4 md:px-14 pt-10 pb-20">
            <div className="flex items-center flex-col justify-center space-y-8 ">
              <h1 className="borderless-title max-w-[438px] flex items-center justify-center">
                THE ADANIAN CAMEL THEORY
              </h1>
              <hr className="hr-broderless"></hr>
              <p className="borderles-desc  max-w-[483px] flex items-center justify-center">
                Adanian is a state. It is a state of mind. Adanian Labs fosters
                a community of innovative and transformative individuals and
                companies in the Adanian State, where entrepreneurs can thrive
                and reach their potential. The community focuses on utilizing
                technology to build sustainable businesses in Africa, providing
                resources and support to create a generation of resilient
                entrepreneurs
              </p>
            </div>

            <a
              className="chevron-borderless  absolute right-7 bottom-7"
              href="aboutUs" aria-label="learn more about adanian labs" 
            >
              <img
                src="images/right-orange.png"
                className=" w-[34px] md:w-[50px] h-[34px] lg:h-[50px] object-contain"
                loading="lazy" alt="adanian Labs"
              ></img>
            </a>
          </div>
        </div>
        <OurOffices />
      </div>
    </section>
  );
};

export default BorderlessAfrica;
