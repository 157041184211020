const BottomContent = () => {
  return (
    <div className="container  mx-auto bg-dark bottom-content py-3">
      <h3 className="content-title lg-content-title ">
      Unleashing the power of AI and Blockchain for innovative,<br></br> impact-driven solutions.
      </h3>
      <h3 className="content-title lg:hidden sm-content-title">
      Unleashing the power of AI and Blockchain for innovative, impact-driven solutions.</h3>
    </div>
  );
};

export default BottomContent;
