import { useState,useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import "../../css/submission-form.css";

const ThankyouPage = () => {
  const navigate =useNavigate()
  const reload =()=>{
    window.location.reload()
  }
  const backToHome =()=>{
    navigate('/')
  }
  
  return (
    <>
      <div className="">
        <p className="thankyou-text">
          Thank you for reaching out.<br></br>Your form has been successfully
          admitted.
        </p>
      </div>
      <div className="  w-[250px] lg:w-[450px] h-[89px] flex gap-0">
        <div
          className="prev-lg"
          onClick={reload}
        >
          <img
            src="images/submission-form/back.svg"
            alt="back"
            className="h-[25px] w-[25px] lg:w-[50px] lg:h-[50px]"
          ></img>
          <h3 className="prev-btn-text">Back to <br></br>forms</h3>
        </div>
        <div
          className="next-lg"
          onClick={backToHome}
          
        >
          <h3 className="prev-btn-text">Adanian Labs <br></br>Home page</h3>

          <img
            src="images/submission-form/next.svg"
            alt="next"
            className="h-[25px] w-[25px] lg:w-[50px] lg:h-[50px]"
          ></img>
        </div>
      </div>
    </>
  );
};

export default ThankyouPage;
