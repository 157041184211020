import '../css/incubation-process.css'

import IncubationHero from "../components/incubation-process/incubation-hero";
import SelectionCreteria from '../components/incubation-process/selection-creteria';
import {Footer } from "../components/shared";
import Navbar from '../components/navbar';
import background from "../components/utils/incubation-process.jpg"


const IncubationProcess = () => {
  return (
    <div className="body-container bg-[#454545]">
        <div className="container pt-16  mx-auto text-center bg-center bg-cover relative  bg-[#454545]">
       <Navbar/>
        <div className="h-[558px] flex flex-col justify-start align-start space-y-3 py-[150px] px-5 md:px-[220px] relative" style={{ backgroundImage: `url(${background})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center' }}>
        <span className="h-full w-full absolute top-0 left-0"></span>
        <h3 className="hero-title z-10">INCUBATION PROCESS</h3>
        <p className="hero-subtitle z-10">Innovating, Nurturing, Launching, Scaling - Together with Adanian Labs</p>
      </div></div>
      <div className="container mx-auto incubation-body relative overflow-hidden">
    
        <IncubationHero></IncubationHero>
        <SelectionCreteria></SelectionCreteria>
          <Footer></Footer>
      </div>
    </div>
  );
};

export default IncubationProcess;
