import "../../css/tech-tools.css";
const ResourcesSection = () => {
  return (
    <section className="h-fit relative resource-section px-[18px] lg:px-24 py-10 lg:py-20 overflow-x-hidden">
      <div className="resources-circle"></div>
      <div className="r-circle2"></div>

      <h1 className="resource-title text-center hidden lg:block ">
        Your Needs, Our Expertise! Explore our range of solutions for your
        digital transformation needs
      </h1>
      <h1 className="resource-title text-center block lg:hidden ">
        Your Needs, Our Expertise! Explore our range of solutions for your
        digital transformation needs
      </h1>
      <div className="flex items-center text-center justify-center mt-[11px]  lg:my-6">
        <hr className="resoureces-hr"></hr>
      </div>
      {/* <div className="lg:px-[197px] mt-[24px] lg:w-full px-6">
        <p className="r-subtitle">
          We help you streamline mundane tasks so that you can devote more
          energy to business growth.
        </p>
      </div> */}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-14 gap-5 lg:gap-4">
        <div className="r-card relative w-full h-[414px] p-4 flex flex-col justify-between items-center">
          <h1 className="r-card-title absolute  top-[31px] left-auto">
            MARKET READY SaaS PRODUCTS
          </h1>

          <hr className="r-hr absolute top-[125px] lg:top-[131px] left-auto"></hr>
          <p className="r-card-subtitle  absolute top-[156px] left-auto ">
            Explore our off-the-shelf tools designed to elevate your business.
            From streamlining operations to giving full visibility and
            maximizing productivity, our powerful tools are here to drive
            business success.
          </p>
        </div>
        <div className="r-card relative w-full h-[414px] p-4 flex flex-col justify-between items-center">
          <h1 className="r-card-title absolute  top-[31px] left-auto">
            White Label Solutions
          </h1>

          <hr className="r-hr absolute top-[125px] lg:top-[131px] left-auto"></hr>
          <p className="r-card-subtitle absolute top-[156px] left-auto">
            Seamlessly customize and brand our cutting edge platforms to
            resonate with your unique identity. From captivating user
            experiences to driving customer engagement, our solutions empower
            your brand to deliver on its promise.
          </p>
        </div>
        <div className="r-card relative w-full h-[414px] p-4  flex flex-col justify-between items-center">
          <h1 className="r-card-title absolute top-[31px] left-auto">
            Outsourcing Services
          </h1>

          <hr className="r-hr absolute top-[125px] lg:top-[131px] left-auto mx-4"></hr>
          <p className="r-card-subtitle absolute top-[156px] left-0 mx-4 h-[250px] overflow-y-scroll style-scrollbar">
            Elevate your business by outsourcing your tech build to our
            accomplished team. Harness the unrivaled expertise of our engineers,
            who will conduct a comprehensive transformation analysis that dives
            deep into your business identifying untapped opportunities for
            growth, building you bespoke software solutions tailored to your
            unique needs.
          </p>
        </div>
        <div className="r-card relative w-full h-[414px] p-4 flex flex-col justify-between items-center">
          <h1 className="r-card-title absolute top-[31px] left-auto">
            Product Development
          </h1>

          <hr className="r-hr absolute top-[125px] lg:top-[131px] left-auto"></hr>
          <p className="r-card-subtitle absolute top-[156px] left-auto mx-4">
            Experience the power of customized solutions guided by our seasoned
            professionals. Our experts will collaborate with you to identify
            transformational opportunities, develop tailored strategies and
            build solutions that align with your unique business needs.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ResourcesSection;
