import Navbar from "../navbar";
import "../../css/capacity-building.css";
import { useState } from "react";
import background from "../utils/capacity-building.jpg";

const CapacityHero = () => {
  const [click, setClick] = useState(false);
  const [card, setCard] = useState(null);
  const handleClick = (x, y) => {
    setCard(x);
    setClick(y);
  };
  return (
    <section>
      <div className=" pt-16 text-center bg-center bg-cover h-fit relative">
        <Navbar></Navbar>
        <div
          className="h-[558px] flex flex-col justify-start align-start space-y-3 py-[150px] px-5 md:px-[220px] relative"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <span className="h-full w-full absolute top-0 left-0"></span>
          <h3 className="z-10 hero-title uppercase">Tech Talent Empowerment</h3>
          <p className="z-10 hero-subtitle">
            At Adanian Labs, we are committed to providing you with access to a
            highly skilled and innovative team that will deliver excellence and
            drive success for your business. We understand the importance of
            harnessing Africa's tech talent, and our goal is to unleash its full
            force to meet your unique needs. With a deep understanding of the
            evolving technological landscape and a passion for innovation, our
            team is dedicated to pushing boundaries and delivering impactful
            solutions. Whether your team needs upskilling or you need to
            outsource your technology development, we are your ideal
            partner.Unleashing the full force of Africa’s tech talent - At
            Adanian Labs, our goal is to ensure that you as our clients have
            access to a highly skilled and innovative team to work in delivering
            excellence and success for your business.
          </p>
        </div>
        <div className="flex flex-col items-center  c-hero-bg py-20 lg:py-28 ">
          {click && (
            <>
              <div className="large-hero-card">
                <span
                  className="rounded-full flex items-center justify-center -mt-32 c-icon absolute left-[122px]"
                  onClick={() => {
                    handleClick("scholarship", true);
                  }}
                >
                  <img
                    src="images/scholarship.png"
                    className="c-hero-icon"
                    loading="lazy" alt="Adanian Labs" 
                  ></img>
                </span>
                <span
                  className="rounded-full flex items-center justify-center -mt-32 c-icon absolute left-[548px]"
                  onClick={() => {
                    handleClick("advisory", true);
                  }}
                >
                  <img src="images/advice.png" className="c-hero-icon" loading="lazy" alt="Adanian Labs" ></img>
                </span>
                <span
                  className="rounded-full flex items-center justify-center -mt-32 c-icon absolute right-[8%]"
                  onClick={() => {
                    handleClick("leadership", true);
                  }}
                >
                  <img src="images/teacher.png" className="c-hero-icon" loading="lazy" alt="Adanian Labs" ></img>
                </span>
                <p
                  className="hero-inner-title absolute top-[61px] left-[63px] text-center uppercase"
                  onClick={() => {
                    handleClick("scholarship", true);
                  }}
                >
                  Find The<br></br> Best Talent
                </p>
                <p
                  className="hero-inner-title absolute top-[61px] left-[508px] text-center"
                  onClick={() => {
                    handleClick("advisory", true);
                  }}
                >
                  Corporate<br></br> advisory
                </p>
                <p
                  className="hero-inner-title absolute top-[61px] right-[2%] text-center"
                  onClick={() => {
                    handleClick("leadership", true);
                  }}
                >
                  Leaders’ <br></br> Masterclasses
                </p>
                {card === "scholarship" && (
                  <>
                    <hr className="absolute left-[48px] top-[153px] hero-card-inner-hr"></hr>
                    <img
                      src="images/capacity-building/scholarship.svg" loading="lazy" alt="Adanian Labs" 
                      className="rounded-lg mr-24 h-[302px] absolute top-[182px] left-[58px]"
                    ></img>
                    <p className="p-inner-card absolute top-[182px] left-[375px] w-[720px] h-[375px] overflow-y-scroll style-scrollbar">
                      Engineering and Software Solutions - Empowering Your
                      Success Tap into the expertise of our highly skilled
                      software engineering professionals to unlock tailored
                      solutions for your business needs. Our team excels in both
                      frontend and backend development, specializing in AI,
                      Blockchain, and IoT engineering:
                      <br></br>
                      <br></br>
                      Frontend Expertise:
                      <ul className=" list-disc list-inside my-4">
                        <li>React Native</li>
                        <li>Flutter</li>
                        <li>Angular</li>
                      </ul>
                      Backend Proficiency:
                      <ul className=" list-disc list-inside my-4">
                        <li>System Design</li>
                        <li>Database Architecture</li>
                        <li>Python</li>
                        <li>TypeScript</li>
                        <li>JavaScript</li>
                        <li>.NET</li>
                        <li>AWS</li>
                        <li>Azure</li>
                      </ul>
                      IoT Mastery:
                      <ul className=" list-disc list-inside my-4">
                        <li>IoT Design</li>
                        <li>Flutter</li>
                        <li>IoT Network Engineering</li>
                      </ul>
                      AI Engineering:
                      <ul className=" list-disc list-inside my-4">
                        <li>Machine Learning</li>
                        <li>Natural Language Processing</li>
                        <li>Computer Vision</li>
                      </ul>
                      Blockchain Prowess:
                      <ul className=" list-disc list-inside my-4">
                        <li>Smart Contract Development</li>
                        <li>Decentralized Applications (DApps)</li>
                        <li>Cryptocurrency Integration</li>
                      </ul>
                    </p>
                    <button
                      className="card-btn card-btn-text absolute bottom-[25px] left-[939px] "
                      disabled
                    >
                      Apply Now
                    </button>
                  </>
                )}
                {card === "advisory" && (
                  <>
                    <hr className="absolute left-[474px] top-[153px] hero-card-inner-hr"></hr>
                    <img
                      src="images/capacity-building/advisory.svg" loading="lazy" alt="Adanian Labs" 
                      className="rounded-lg mr-24 h-[302px] absolute top-[182px] left-[58px]"
                    ></img>
                    <p className="p-inner-card absolute top-[182px] left-[375px] w-[720px] h-[385px] overflow-y-scroll style-scrollbar">
                      From legal and accounting to financial planning,
                      technology management, human resources, and marketing, we
                      have the expertise to support your diverse business needs.
                      <br></br>
                      <br></br> In addition, we offer a range of cutting-edge
                      technology services, including software development,
                      website design, cloud computing, IT consulting, capacity
                      building, and data management. With our specialised
                      knowledge, we can empower your business to achieve its
                      goals and reach new heights of success.
                      <br></br>
                      <br></br>
                      Ready to take your business to the next level? <br></br>{" "}
                      Contact us today to leverage our industry-leading
                      expertise and comprehensive suite of corporate advisory
                      services. Let's collaborate and propel your business
                      towards growth and success.
                    </p>
                    <a
                      className="card-btn card-btn-text absolute bottom-[25px] left-[939px]"
                      href="submission-form"
                    >
                      Apply Now
                    </a>
                  </>
                )}
                {card === "leadership" && (
                  <>
                    <hr className="absolute right-[2%] top-[153px] hero-card-inner-hr"></hr>
                    <img loading="lazy" alt="Adanian Labs" 
                      src="images/capacity-building/leadership.svg"
                      className="rounded-lg mr-24 h-[302px] absolute top-[182px] left-[58px]"
                    ></img>
                    <p className="p-inner-card absolute top-[182px] left-[375px] w-[720px] h-[375px] overflow-y-scroll style-scrollbar">
                      <span> Upskill your IT teams:</span> <br></br>
                      With our diverse Software development, Data Science, IoT,
                      AI and Blockchain Training Programs in the form of short
                      courses, bootcamps and masterclasses. <br></br> <br></br>
                      Unlock Your Leadership Potential in AI & Blockchain.
                      <br></br>
                      <br></br> We offer AI and Blockchain Masterclasses that
                      run between Three hours to One week based on client
                      requirements and budget. <br></br> <br></br>
                      Masterclass Options:<br></br>3 hours /6 hours /1 day /3
                      days /1 week session Whether you are new to the field or
                      already have some knowledge, our programs cater to
                      individuals at all levels of expertise. <br></br> Our
                      Leaders' Masterclasses are designed for AI and blockchain
                      enthusiasts, decision-makers, managers, government
                      officials, and policymakers. <br></br>
                      <br></br>
                      No prerequisites or technical skills are required. All you
                      need is enthusiasm and a passion for learning. Our AI For
                      Leaders program specifically trains business and
                      government leaders to understand the value and ethics of
                      data and AI, enabling successful implementation in their
                      organisations <br></br>
                      <br></br>
                      Our training programs provide practical learning
                      experiences for business leaders, empowering them to drive
                      growth in their organisations through Web 3.0
                      technologies. <br></br>
                      <br></br> Join our Leaders' Masterclasses today to enhance
                      your understanding and leverage the transformative power
                      of AI and blockchain. Contact us now to book your
                      masterclass and unlock your leadership potential in this
                      rapidly evolving digital landscape. Together, let's shape
                      the future of technology and drive innovation. Ready to
                      become an AI and blockchain leader?
                    </p>
                    <a
                      className="card-btn card-btn-text absolute bottom-[25px] left-[939px]"
                      href="submission-form"
                    >
                      CONTACT US
                    </a>
                  </>
                )}
              </div>
              <div className="sm-hero-card">
                <div className="w-full flex justify-between -mb-10">
                  <span
                    className="rounded-full flex items-center justify-center c-icon z-10"
                    onClick={() => {
                      handleClick("scholarship", true);
                    }}
                  >
                    <img
                      src="images/scholarship.png"
                      className="c-hero-icon"
                    ></img>
                  </span>
                  <span
                    className="rounded-full flex items-center justify-center c-icon z-10"
                    onClick={() => {
                      handleClick("advisory", true);
                    }}
                  >
                    <img src="images/advice.png" className="c-hero-icon" loading="lazy" alt="Adanian Labs" ></img>
                  </span>
                  <span
                    className="rounded-full flex items-center justify-center c-icon z-10"
                    onClick={() => {
                      handleClick("leadership", true);
                    }}
                  >
                    <img src="images/teacher.png" className="c-hero-icon" loading="lazy" alt="Adanian Labs" ></img>
                  </span>
                </div>

                <p
                  className="hero-inner-title absolute top-[44px] left-[43px] text-center z-10"
                  onClick={() => {
                    handleClick("scholarship", true);
                  }}
                >
                  Scholarship Support
                </p>
                <p
                  className="hero-inner-title absolute top-[44px] left-[508px] text-center z-10"
                  onClick={() => {
                    handleClick("advisory", true);
                  }}
                >
                  Corporate advisory
                </p>
                <p
                  className="hero-inner-title absolute top-[44px] left-[916px] text-center z-10"
                  onClick={() => {
                    handleClick("leadership", true);
                  }}
                >
                  Leaders’ Masterclasses
                </p>
                {card === "scholarship" && (
                  <>
                    <img
                      src="images/capacity-building/scholarship-sm.svg"
                      className="rounded-lg absolute top-[106px] left-[77px]" loading="lazy" alt="Adanian Labs" 
                    ></img>
                    <p className="p-inner-card h-[249px]  w-[300px] overflow-y-scroll absolute top-[320px] left-[25.5px] ">
                      In partnership with private and public sector
                      stakeholders, we aim lower barrier to access to technology
                      training for African youth. Predictions by the World
                      Economic Forum state that AI and automation will lead to
                      97 million new jobs by the year 2025.
                      <br></br>
                      <br></br> Many jobs are evolving with the popularity and
                      use of AI technology. Through scholarship support, more
                      Africans are being equipped with much needed technology
                      skills to address the gap tech Startups and SMMEs face in
                      finding quality tech talent globally.
                    </p>
                    <a
                      className="card-btn card-btn-text absolute top-[597px] left-[203.5px]"
                      href="submission-form"
                    >
                      Apply Now
                    </a>
                  </>
                )}
                {card === "advisory" && (
                  <>
                    <img
                      src="images/capacity-building/advisory-sm.svg"
                      className="rounded-lg absolute top-[106px] left-[77px]" loading="lazy" alt="Adanian Labs" 
                    ></img>
                    <p className="p-inner-card h-[249px]  w-[300px] overflow-y-scroll absolute top-[320px] left-[25.5px] ">
                      We provide specialized services to help your business
                      thrive. Our services encompass a wide range of business
                      functions, including legal, accounting, financial
                      planning, technology management, human resources, and
                      marketing.<br></br>
                      <br></br> Additionally, we offer a variety of technology
                      services, including software development, website design,
                      cloud computing, IT consulting, capacity building, and
                      data management. With our specialized expertise, we can
                      assist your business in achieving its goals and reaching
                      new heights.
                    </p>
                    <a
                      className="card-btn card-btn-text absolute top-[597px]  left-[203.5px]"
                      href="submission-form"
                    >
                      Apply Now
                    </a>
                  </>
                )}
                {card === "leadership" && (
                  <>
                    <img
                      src="images/capacity-building/leadership-sm.svg"
                      className="rounded-lg absolute top-[106px] left-[77px]" loading="lazy" alt="Adanian Labs" 
                    ></img>
                    <p className="p-inner-card h-[249px]  w-[300px] overflow-y-scroll absolute top-[320px] left-[25.5px] ">
                      The program is for AI & Blockchain Enthusiasts, Decision
                      makers, Management, Government and Policymakers.<br></br>{" "}
                      <br></br>Our programs are designed for beginners so there
                      are no pre-requisite skills required. Enthusiasm and
                      passion to learn is all that is needed. AI For Leaders
                      Trains leaders (business and government), to understand
                      the value and ethics of Data and AI and its
                      implementation.<br></br>
                      <br></br>
                      Outcomes include but not limited to: <br></br>Increased
                      access to local and global opportunities through
                      development of key skills.<br></br> Creation of and
                      increased access to AI solutions that positively impact
                      business and institutions. <br></br>Develop leaders'
                      intuitive understanding of AI and its implementation for
                      business growth.<br></br>
                      <br></br>
                      Blockchain for Leaders: The program is designed for
                      business leaders to learn how to leverage blockchain
                      technology to build products, services or service their
                      clients.<br></br>
                      <br></br> Program Benefits<br></br> Knowledge: The
                      executives who undergo the training will receive
                      blockchain knowledge that they can use to make innovative
                      decisions and new business models for organizations.
                      Networking: The executives will be exposed to a community
                      of A & blockchain experts within the web3 ecosystem where
                      they can interact and exchange ideas. Access to BaaS
                      Services: The executives and their representative
                      companies will have access to development services using
                      different blockchain protocols.
                    </p>
                    <a
                      className="card-btn card-btn-text absolute top-[597px]  left-[203.5px]"
                      href="submission-form"
                    >
                      Apply Now
                    </a>
                  </>
                )}
              </div>
            </>
          )}

          {!click && (
            <div className="grid  gap-[68px] lg:gap-8 xl:gap-16 md:grid-cols-2 xl:grid-cols-3  px-[20px] lg:px-28 transition duration-75 ease-in-out ">
              <div
                className="flex flex-col items-center justify-around lg:pt-8 pt-[32px] pb-8 lg:px-4 text-center rounded-xl c-hero-card"
                onClick={() => {
                  handleClick("scholarship", true);
                }}
              >
                <span className="rounded-full flex items-center justify-center lg:-mt-24 -mt-20 c-icon mr-auto lg:mr-0">
                  <img
                    src="images/scholarship.png"
                    className="c-hero-icon"
                    loading="lazy" alt="Adanian Labs" 
                  ></img>
                </span>

                <h1 className="c-card-title capitalize lg:my-4 ">
                  Find The Best Talent
                </h1>

                <hr className="c-hr mt-2 lg:mb-8 mb-4"></hr>
                <p className="c-card-desc mx-4 lg:mx-0">
                  As Africa evolves with technology, so does the demand for
                  software engineers and tech project managers rise. We help you
                  tap into qualified and experienced developers and project
                  managers to meet this demand.
                </p>
              </div>
              <div
                className="flex flex-col items-center justify-around lg:pt-8 pt-[32px] pb-8  lg:px-4 text-center rounded-xl c-hero-card"
                onClick={() => {
                  handleClick("advisory", true);
                }}
              >
                <span className="rounded-full flex items-center justify-center lg:-mt-24 -mt-20 c-icon mr-auto lg:mr-0">
                  <img src="images/advice.png" className="c-hero-icon"></img>
                </span>

                <h1 className="c-card-title capitalize lg:my-4">
                  Corporate Advisory
                </h1>

                <hr className="c-hr mt-2 lg:mb-8 mb-4"></hr>
                <p className="c-card-desc mx-4 lg:mx-0">
                  Unlock Your Business Potential with Our Specialised Tech
                  Expertise.Our comprehensive suite of corporate advisory
                  services is tailored to help your business thrive.
                </p>
              </div>

              <div
                className="flex flex-col items-center justify-around lg:pt-8 pt-[32px] pb-8  lg:px-4 text-center rounded-xl c-hero-card z-10"
                onClick={() => {
                  handleClick("leadership", true);
                }}
              >
                <span className="rounded-full flex items-center justify-center lg:-mt-24 -mt-20 c-icon mr-auto lg:mr-0">
                  <img src="images/teacher.png" className="c-hero-icon"></img>
                </span>

                <h1 className="c-card-title capitalize lg:mt-6 lg:mb-8">
                  Leaders’ Masterclasses
                </h1>

                <hr className="c-hr mt-2 lg:mb-8 mb-4"></hr>
                <p className="c-card-desc mx-4 lg:mx-0">
                  Our training programs provide practical learning experiences
                  for developers and business leaders, empowering them to drive
                  growth in their organisations through Web 3.0 technologies.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CapacityHero;
