import { Button, Ellipse } from "../shared";
import InteractiveCamelSvg from "./svg/interactive-camel";
import "../../css/venture-building.css"
import { ReactComponent as Variant1 } from "./svg/variant-6.svg";
import { ReactComponent as Variant2 } from "./svg/variant-1.svg";
import { ReactComponent as Variant3 } from "./svg/variant-3.svg";
import { ReactComponent as Variant4 } from "./svg/variant-5.svg";


const Hero = ({handleClick,step}) => (
  <section className="relative flex flex-col md:flex-row items-center justify-between lg:space-x-[58px] px-5 lg:px-28  pt-[69.52px] lg:pt-[182px]  h-fit">
    <Ellipse left={-25} top={-5} size={308} blur={170} variant="orange"/>
    <Ellipse right={50} top={100} size={451} blur={150} variant="blue"/>

    <div className="z-10 basis-1/2">
      <h1 className="mb-4 text-40 whitespace-nowrap">Venture Building</h1>
      <div className="mb-10" />
      {step===1&&(
          <div className="flex  flex-col items-start justify-between">
          <p className=" text-[19px] lg:text-[18px] mb-4">
            We invest, incubate, nurture and launch scalable technology startups that create sustainable impact in Africa. We
            run 12 months venture building program with relevant partners to build scalable de-risked commercial tech ventures
            positioned for scale.
          </p>
          <Button variant="orange" href="/incubation-process" className="hidden lg:block">
            OUR VENTURE BUILDING PROGRAM
          </Button>
         <a href="/incubation-process"> <button className="block lg:hidden v-hero-btn v-hero-btn-text mt-8 z-10"  >Our venture building program</button></a>
        </div>
      )}
        {step===2&&(
          <div className="flex flex-col items-start justify-between">
            <ul className="list-disc mb-4 list-inside lg:list-outside">
            <li className="text-32">Investments </li>

            </ul>
          <p className=" text-[16px] lg:text-[18px] lg:w-[532px]">
          Since our inception in January 2020 we have invested in 40+ companies.
          </p>
        </div>
      )}
        {step===3&&(
          <div className="flex flex-col items-start justify-start ">
            <ul className="list-disc mb-4 list-inside lg:list-outside">
            <li className="text-32">Investments </li>
            <li className="text-32">Training </li>
            </ul>
          <p className=" text-[16px] lg:text-[18px] mb-[20px]">
          Trained 300 Data Scientists and AI Engineers
          </p>
          <p className=" text-[16px] lg:text-[18px]">
          Trained 170 Blockchain Developers
          </p>
        </div>
      )}

        {step===4&&(
          <div className="flex flex-col items-start justify-start">
            <ul className="list-disc mb-4 list-inside lg:list-outside">
            <li className="text-32">Investments </li>
            <li className="text-32 ">Training </li>
             <li className="text-32 ">Business & Tech Resources </li>
            </ul>
          <p className="text-[16px] lg:text-[18px]">
          Setup Infrastructure in 5 countries 
          </p>
        </div>
      )}
            {step===5&&(
          <div className="flex flex-col items-start justify-start">
            <ul className="list-disc mb-4 list-inside lg:list-outside">
            <li className="text-32">Investments </li>
            <li className="text-32 ">Training </li>
             <li className="text-32 ">Business & Tech Resources </li>
              <li className="text-32 "> Borderless Africa Marketplace </li>
            </ul>
          <p className="text-[16px] lg:text-[18px]">
          The Africa blockchain center and the IOT company to build AI, Blockchain and IOT capacity in Africa
          </p>
        </div>
      )}
   
      {step===6&&(
          <div className="flex flex-col items-start justify-start">
            <ul className="list-disc mb-4 list-inside lg:list-outside">
            <li className="text-32">Investments </li>
            <li className="text-32 ">Training </li>
             <li className="text-32 ">Business & Tech Resources </li>
              <li className="text-32 "> Borderless Africa Marketplace </li>
            </ul>
            <Button variant="orange" href="/submission-form" className="hidden lg:block uppercase z-10">
            Expand your business
          </Button>
         <a href="/submission-form"> <button className="block lg:hidden v-hero-btn v-hero-btn-text mt-8 uppercase z-10">Expand your business</button></a>
        </div>

      )}
    
    </div>
    <div className="basis-1/2">
    {step===1&&(
 <div className="z-10 relative" onClick={()=>{handleClick(step+1)}}>
  <span className="absolute top-[260px] left-[174px] uppercase">explore</span>
 <InteractiveCamelSvg/>
 </div>
    )}
     {step===2&&(
 <div className="z-10  relative" onClick={()=>{handleClick(step+1)}}>
    <span className="absolute  bottom-[200px] lg:bottom-[180px] left-[170px] lg:left-[235px] uppercase">more</span>
 <Variant1></Variant1>
 </div>
    )}
     {step===3&&(
 <div className=" z-10 relative" onClick={()=>{handleClick(step+1)}}>
    <span className="absolute bottom-[220px] left-[170px] lg:left-[240px] uppercase">more</span>
 <Variant2></Variant2>
 </div>
 
    )}
     {step===4&&(
 <div className="z-10 relative" onClick={()=>{handleClick(step+1)}}>
    <span className="absolute bottom-[200px] left-[170px] lg:left-[230px] uppercase">more</span>
 <Variant3></Variant3>
 </div>
 
    )}
        {step===5&&(
 <div className=" z-10 relative " onClick={()=>{handleClick(step+1)}}>
   <span className="absolute bottom-[240px] lg:bottom-[200px] left-[170px] lg:left-[270px] uppercase">more</span>
 <Variant4></Variant4>
 </div>
 
    )}
          {step===6&&(
 <div className=" z-10 last-animation ">
 </div>
 
    )}
    </div>

    
  </section>
);

export default Hero;
