import { Container } from "../components/shared";
import SharedHero from "../components/utils/hero";
import background from "../components/utils/industry-partners.jpg";

const IndustryPartners = () => {
  const images = [
    "airtel",
    "gtbank",
    "MTN",
    "Huawei",
    "Safaricom",
    "zoho",
    "Access",
    "Carddano",
    "Nvidia",
    "aice-logo",
    "microsoft",
    "lbank",
    "Starndard",
    "abc-logo",
    'plp',
    "AI",
  ];
  const url='/images/industry-partners'
  return (
    <Container className="bg-[#404040]">
      <SharedHero
        title={"Industry Partners"}
        subtitle={"At Adanian Labs, we partner with diverse local and international institutions, governments, and industry leaders to drive technological progress, building a resilient ecosystem for real-world impact and value."}
        background={background}
      />
      <div className="px-5 lg:px-32 py-16 h-fit industry-partners relative overflow-hidden">
        <div className="ellipse-1"></div>
        <div className="ellipse-2"></div>
        <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20">
          {images.map((i) => (
            <div className="card p-7 flex items-center z-10">
              <img src={`${url}/${i}.png`} alt="logo"  className="z-10"/>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default IndustryPartners;
