import DownloadsComponent from "../components/downloads";
import { Container } from "../components/shared";

const Hero = () => {
    return (
      <div className="content-portal-hero text-center h-[558px] flex flex-col justify-start align-start space-y-3 py-[150px] px-5 lg:px-[220px] relative">
        <span className="h-full w-full absolute top-0 left-0"></span>
        <h3 className="hero-title z-10 uppercase">ADANIAN Labs</h3>
        <p className=" hero-subtitle z-10 ">
          Stay ahead of the curve with Adanian Labs' latest developments
        </p>
      </div>
    );
  };

const Downloads = () => {
    return ( <div>
               
               <Container
      className="bg-[#1E1E1E]"
      footerProps={{ className: "bg-[#1E1E1E]" }}
    >
      
      <Hero />
      <DownloadsComponent/>
    </Container>
    </div>);
}
 
export default Downloads;