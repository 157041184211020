import useScrollPosition from "../hooks/useScrollPosition";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const scrollPosition = useScrollPosition();
  const [navbar, setNavbar] = useState(false);
  const [click, setClick] = useState(true);
  const [navOpen,setnavOpen] =useState(true);

  const handleClick = () => setClick(!click);
  const handlesetNavOpen=()=> setnavOpen(!navOpen)

  return (
    <nav
      x-data="{ isOpen: false }"
      className={classNames(
        scrollPosition > 0 ? "backdrop-filter backdrop-blur-lg bg-opacity-20" : "shadow-none navbar-default",
        "container mx-auto fixed top-0 right-0 left-0 z-30 transition-shadow "
      )}
    >
      <div className="lg:px-32 py-4 lg:mx-auto">
        <div className="lg:flex lg:items-center lg:justify-between ">
          <div className="flex items-center justify-between">
            <div className="">
              <NavLink className="" exact="true" to="/">
                <img src="/images/logo.png" className="nav-logo" loading="lazy" alt="logo"></img>
              </NavLink>
            </div>
            <div className="flex lg:hidden px-3 lg:p-0">
              <button
              x-cloak="true"
                type="button"
                className="z-30"
                aria-label="toggle menu"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    x-show="isOpen"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="#13E2FF
                                                      "
                    viewBox="0 0 24 24"
                    stroke="#13E2FF
                                                      "
                    stroke-width="2"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="#F5931D"
                    viewBox="0 0 24 24"
                    stroke="#F5931D"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div
          x-cloak="true"
            className={`nav-menu absolute inset-x-0 z-20 :lg:w-full px-8 pt-12 pb-4 transition-all duration-300 ease-in-out lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center ${
              navbar ? "flex justify-end place-items-end" : "hidden"
            }`}
          >
            <div className="flex flex-col -mx-6 lg:flex-row lg:items-center lg:mx-8 bg-[#404040] md:bg-transparent">
            <a aria-label="learn more about PDI Model"
                exact="true"
                href="/pdi-model"
                className="px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links whitespace-nowrap"
              >
                PDI Model
              </a>
              <span
                onClick={handleClick}
                className="cursor-pointer px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
              >
                Services
              </span>
            <div className={classNames(click ? "hidden" : "md:hidden space-y-2 flex flex-col justify-start")}>
            <NavLink
          exact="true"
          to="/capacity-building"
          className=" transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
        >
          Capacity Building
        </NavLink>
        <NavLink
          exact="true"
          to="/borderless-africa"
          className="transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
        >
          Borderless Africa
        </NavLink>
        <NavLink
          exact="true"
          to="/venture-building"
          className="transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
        >
          Venture Building
        </NavLink>
        <NavLink
          exact="true"
          to="/borderless-lab"
          className="transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
        >
          Borderless Lab
        </NavLink>
            </div>
          
              <NavLink
                exact="true"
                to="/tech-tools"
                className="px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links whitespace-nowrap"
              >
                Technology Tools
              </NavLink>
              <NavLink
                exact="true"
                to="/our-startups"
                className="px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
              >
                Startups
              </NavLink>
              <NavLink
                exact="true"
                to="/studio"
                className="px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
              >
                Studio
              </NavLink>
              <span
                onClick={handlesetNavOpen}
                className="cursor-pointer px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links whitespace-nowrap"
              >
                Content Portal
              </span>
              <div className={classNames(navOpen ? "hidden" : "md:hidden space-y-2 flex flex-col justify-start")}>
              <NavLink
          exact="true"
          to="/content-portal"
          className=" transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
        >
          Latest News
        </NavLink>  
            <NavLink
          exact="true"
          to="/downloads"
          className=" transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
        >
          Downloads
        </NavLink>

            </div>
              <a aria-label="reach out to us via contact"
                href="/contact-us"
                className="px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(click ? "hidden" : "md:block dropdown")} onMouseLeave={handleClick}>
        <NavLink
          exact="true"
          to="/capacity-building"
          className=" transition-colors duration-300 transform rounded-md lg:mt-0  dropdown-link hidden md:block"
        >
          Capacity Building
        </NavLink>
        <NavLink
          exact="true"
          to="/borderless-africa"
          className="transition-colors duration-300 transform rounded-md lg:mt-0  dropdown-link hidden md:block"
        >
          Borderless Africa
        </NavLink>
        <NavLink
          exact="true"
          to="/venture-building"
          className="transition-colors duration-300 transform rounded-md lg:mt-0  dropdown-link hidden md:block"
        >
          Venture Building
        </NavLink>
        <NavLink
          exact="true"
          to="/borderless-lab"
          className="transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
        >
          Borderless Lab
        </NavLink>
      </div>
      <div className={classNames(navOpen ? "hidden" : "md:block dropdown-2")} onMouseLeave={handlesetNavOpen}>
        
        <NavLink
          exact="true"
          to="/content-portal"
          className="transition-colors duration-300 transform rounded-md lg:mt-0  dropdown-link hidden md:block"
        >
          Latest News
        </NavLink>
        <NavLink
          exact="true"
          to="/downloads"
          className=" transition-colors duration-300 transform rounded-md lg:mt-0  dropdown-link hidden md:block"
        >
          Downloads
        </NavLink>

      </div>
    </nav>
  );
};

export default Navbar;
