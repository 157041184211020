import { selectionCriteria,criteria } from "../../data/incubation-process";

const SelectionCreteria = () => {
  return (
    <section>
      <div className="container mx-auto w-full pt-[30px] lg:pt-[61px] bg-[#434343] px-5 lg:px-20">
       
        <div className="flex flex-col justify-center items-center my-5 lg:my-8 gap-3">
        <p className="text-center inc-select-title z-40">Our Selection Criteria</p>
        <hr className="inc-select-hr"></hr>
        </div>
        <div className="ellipse-10"></div>
        <div className="ellipse-11"></div>
        
        {/* <div className="rounded-corners-gradient-borders"></div> */}
        <div className="grid  grid-cols-1 lg:grid-cols-3 gap-[26px] lg:gap-10 z-10">
          {selectionCriteria.map((c)=>( 
          <div className="select-card-outer relative flex flex-col items-center">
            <div className="sc-card-circle flex items-center justify-center rounded-full">{c.id}</div>
            <img
              src={`images/incubation-process/${c.image}.svg`}
              className=" h-[67px] w-[67px] lg:h-[150px] lg:w-[150px] c-icons lg:mt-8"
              alt="icon"
            ></img>
             <h2 className="ba-card-title block lg:hidden">{c.title}</h2>
             <div className="sect-card-hr my-5 block lg:hidden"></div>
            <div className="select-card-inner mt-8 flex flex-col items-center justify-start lg:px-8 lg:py-8">
              <h2 className="ba-card-title hidden lg:block">{c.title}</h2>
              <div className="sect-card-hr my-5 hidden lg:block"></div>
              <p className="ba-card-subtitle">
                {c.desc}
              </p>
            </div>
          </div>))}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-[26px] lg:gap-10 z-10 mt-5">
        {criteria.map((c)=>( 
          <div className="select-card-outer relative flex flex-col items-center ">

            <div className="sc-card-circle flex items-center justify-center rounded-full">{c.id}</div>
            <img
              src={`images/incubation-process/${c.image}.svg`}
              className=" h-[67px] w-[67px] lg:h-[150px] lg:w-[150px] c-icons lg:mt-8"
              alt="icon"
            ></img>
             <h2 className="ba-card-title block lg:hidden w-[256px]">{c.title}</h2>
             <div className="sect-card-hr my-5 block lg:hidden"></div>
            <div className="select-card-inner mt-8 flex flex-col items-center justify-start lg:px-8 lg:py-8">
              <h2 className="ba-card-title hidden lg:block  w-[220px]">{c.title}</h2>
              <div className="sect-card-hr my-5 hidden lg:block"></div>
              <p className="ba-card-subtitle">
                {c.desc}
              </p>
            </div>
          </div>))}
        </div>
        <div className="absolute top-[1508px] left-[177px] bg-[#F5931D] w-[373px] h-[373px] blur-[290px] eclipse-23"></div>
<div className="flex flex-col lg:hidden justify-center items-center my-5">
<h2 className="expand-title">expand your venture</h2>
        <hr className="line-44 my-2"></hr>
        <a className="sm-expand-btn expand-btn-text" href="submission-form">contact form</a>
</div>
        
        <div className="text-center hidden lg:flex flex-col items-center gap-4 justify-center my-10">
          <h2 className="expand-title">expand your venture</h2>
          <hr className="line-44"></hr>
          <a className="expand-btn btn-text" href="submission-form">contact/application form</a>
        </div>
      </div>
    </section>
  );
};

export default SelectionCreteria;
