import { useState,useEffect } from "react";
import SharedForm from "./shared-from";
import ThankyouPage from "./thankyou-page";
import {useNavigate} from 'react-router-dom';
import { sendMail } from "../../services/mail";
import { submitForm } from "../../services";

const BorderlessAfricaForm = () => {
  const [avenues,setAvenues]=useState('')
  const [enquiries,setEnquires] =useState('')
  const [companyName, setcompanyName] = useState('');
  const [website, setwebsite] = useState('');
  const [location, setlocation] = useState('');
  const [channel, setchannel] = useState('');
  const [budget, setbudget] = useState('');
  const [preference, setpreference] = useState('');
  const [compliance, setcompliance] = useState('');

  const [step, setStep] = useState(0);


  const navigate=useNavigate()
  useEffect(() => {
    if(step<0){
      navigate(0)
    }
   
  },[step]);

  const handleSubmit=(e)=>{
    e.preventDefault()
    const payload={"What type of borderless Africa access solution are you looking for?": companyName,
    "Which countries in Africa are you interested in accessing?": website,
    "What is the primary purpose of your access needs in Africa (e.g., business, education, travel, etc.)? ": location,
    "What is the estimated duration of your access needs in Africa?": channel,
    " What is the expected frequency of your access needs in Africa?": enquiries,
      "What specific features are you looking for in a borderless Africa access solution?":avenues,
      'What is your budget for the borderless Africa access solution? ':budget,
      'Would you prefer a fully managed service or a self-managed solution?':preference,
      'Do you have any specific security or compliance requirements for the borderless Africa access solution?':compliance
    
    
    }
      const personalDetails =JSON.parse(window.localStorage.getItem('details'));

      const data={...payload, ...personalDetails}
      const tableRows = Object.entries(data).map(([question, answer]) => `
      <tr  style="border:1px solid black;">
        <td >${question}</td>
        <td>${answer}</td>
      </tr>
    `);
    
    const table = `
      <table style="border:1px solid black;">
        <thead style="border:1px solid black;">
          <tr style="border:1px solid black;">
            <th style="border:1px solid black;">Question</th>
            <th style="border:1px solid black;">Answer</th>
          </tr>
        </thead>
        <tbody style="border:1px solid black;">
          ${tableRows.join('')}
        </tbody>
      </table>
    `;
    submitForm( 'borderless_africa',{
      title:personalDetails.email,
      content:table,
      status:'publish',
      meta:data
    }).then((res)=>{
    })
  }
  return (
    <div className="space-y-6">
      {step === 0 && (  <>
        <div className="input-group">
        <p className="input-label">What type of borderless Africa access solution are you looking for?</p>
        <hr className="input-hr"></hr>
        <textarea className="form-textarea"  value={companyName} onChange={(e)=>{setcompanyName(e.target.value)}}></textarea>
      </div>
      <div className="input-group">
        <p className="input-label">Which countries in Africa are you interested in accessing?</p>
        <hr className="input-hr"></hr>
        <textarea className="form-textarea"  value={website} onChange={(e)=>{setwebsite(e.target.value)}}></textarea>
      </div>
      <div className="input-group">
        <p className="input-label">What is the primary purpose of your access needs in Africa (e.g., business, education, travel, etc.)?</p>
        <hr className="input-hr"></hr>
        <textarea className="form-textarea"  value={location} onChange={(e)=>{setlocation(e.target.value)}}></textarea>
      </div>
      <div className="input-group">
        <p className="input-label">What is the estimated duration of your access needs in Africa?</p>
        <hr className="input-hr"></hr>
        <textarea className="form-textarea"  value={channel} onChange={(e)=>{setchannel(e.target.value)}}></textarea>
      </div>
    </>)}

      {step === 1 && (
        <>
          <div className="input-group">
            <p className="input-label w-[361px] lg:w-full">
            What is the expected frequency of your access needs in Africa?
            </p>
            <hr className="input-hr"></hr>
            <textarea className="form-textarea" value={avenues} onChange={(e)=>{setAvenues(e.target.value)}}></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
            What specific features are you looking for in a borderless Africa access solution?
            </p>
            <hr className="input-hr"></hr>
            <textarea className="form-textarea" value={enquiries} onChange={(e)=>{setEnquires(e.target.value)}}></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
            What is your budget for the borderless Africa access solution?
            </p>
            <hr className="input-hr"></hr>
            <textarea className="form-textarea" value={budget} onChange={(e)=>{setbudget(e.target.value)}}></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
            Would you prefer a fully managed service or a self-managed solution?
            </p>
            <hr className="input-hr"></hr>
            <textarea className="form-textarea" value={preference} onChange={(e)=>{setpreference(e.target.value)}}></textarea>
          </div>
          <div className="input-group">
            <p className="input-label">
            Do you have any specific security or compliance requirements for the borderless Africa access solution?
            </p>
            <hr className="input-hr"></hr>
            <textarea className="form-textarea" value={compliance} onChange={(e)=>{setcompliance(e.target.value)}}></textarea>
          </div>
        </>
      )}
      {step === 2 && <ThankyouPage></ThankyouPage>}

      {step !== 2 && (
               <>
               <div className="w-[250px] lg:w-[450px] h-[89px] flex gap-0  items-center z-10">
                 <div
                   className="prev-btn
                   "
                   onClick={() => {
                     setStep(step - 1);
                   }}
                 >
                   <img
                     src="images/submission-form/back.svg"
                     alt="back"
                     className="h-[25px] w-[25px] lg:w-[50px] lg:h-[50px]"
                   ></img>
                   <h3 className="prev-btn-text">Back</h3>
                 </div>
                 <div
                   className="next-btn"
                   onClick={() => {
                     setStep(step + 1);
                   }}
                 >
                   {step === 0 && <h3 className="prev-btn-text">Next</h3>}
                   {step === 1 && (
                     <h3 className="prev-btn-text" onClick={handleSubmit}>
                       Submit
                     </h3>
                   )}
     
                   <img
                     src="images/submission-form/next.svg"
                     alt="next"
                     className="h-[25px] w-[25px] lg:w-[50px] lg:h-[50px]"
                   ></img>
                 </div>
               </div>
             </>
      )}
    </div>
  );
};

export default BorderlessAfricaForm;
